import {
  getMatchIdFromTeMatches,
  newTeMatch,
  TeMatch,
  TeMatchOrId,
} from "model/TournamentEditor/TeMatch";
import { trueOrUndefined } from "Utility";
import { IdData } from "model/IdData";
import { TeMatchFormat } from "model/TournamentEditor/TeMatchFormat";
import { FsBracketMatch } from "db/model/tournament/Segment/Bracket/FsBracketMatch";
import { BracketType } from "fsModel/Bracket/Bracket";

export type TeBracketMatchSource = {
  position: number | undefined;
  matchKey: string | undefined;
};

export type TeBracketMatch = {
  isFinal: boolean;
  isUpperFinal: boolean;
  match: TeMatchOrId;
  matchKey: string;
  teamOneSourceKey: string | undefined;
  teamTwoSourceKey: string | undefined;
  teamSources: TeBracketMatchSource[] | undefined;
};

export const newTeBracketMatch = (
  matchKey: string,
  bracketType?: BracketType
): TeBracketMatch => {
  return {
    isFinal: false,
    isUpperFinal: false,
    match: newTeMatch(),
    matchKey,
    teamOneSourceKey: undefined,
    teamTwoSourceKey: undefined,
    teamSources:
      bracketType === "SingleElimShuffle" || bracketType === "DoubleElimShuffle"
        ? []
        : undefined,
  };
};

const getTeamSource = (
  sourceKey: string,
  bracketMatches: TeBracketMatch[],
  teMatchesWithIds: IdData<TeMatch>[]
) => {
  if (!sourceKey) {
    return "Seed";
  }

  const sourceMatch = bracketMatches.find((m) => m.matchKey === sourceKey);
  if (!sourceMatch) {
    return undefined;
  }

  return getMatchIdFromTeMatches(sourceMatch.match, teMatchesWithIds);
};

export const mapTeBracketMatchToFsData = (
  teBracketMatch: TeBracketMatch,
  teBracketMatches: TeBracketMatch[],
  teMatchesWithIds: IdData<TeMatch>[]
): FsBracketMatch => {
  return {
    isFinal: trueOrUndefined(teBracketMatch.isFinal),
    isUpperFinal: trueOrUndefined(
      teBracketMatch.isFinal && teBracketMatch.isUpperFinal
    ),
    matchId: getMatchIdFromTeMatches(teBracketMatch.match, teMatchesWithIds),
    teamOneSource: !!teBracketMatch.teamOneSourceKey
      ? getTeamSource(
          teBracketMatch.teamOneSourceKey,
          teBracketMatches,
          teMatchesWithIds
        )
      : !teBracketMatch.teamSources
      ? "Seed"
      : undefined,
    teamTwoSource: !!teBracketMatch.teamTwoSourceKey
      ? getTeamSource(
          teBracketMatch.teamTwoSourceKey,
          teBracketMatches,
          teMatchesWithIds
        )
      : !teBracketMatch.teamSources
      ? "Seed"
      : undefined,
    teamSources: teBracketMatch.teamSources?.map((s) => ({
      position: s.position,
      source: !!s.matchKey
        ? getTeamSource(s.matchKey, teBracketMatches, teMatchesWithIds)
        : "Seed",
    })),
  };
};

export const updateTeBracketMatchSourceStructure = (
  match: TeBracketMatch,
  bracketType: BracketType | undefined,
  matchFormat: TeMatchFormat | undefined
) => {
  const matchCopy = { ...match };
  if (!bracketType) {
    matchCopy.teamSources = undefined;
  }

  if (
    bracketType === "SingleElimShuffle" ||
    bracketType === "DoubleElimShuffle"
  ) {
    matchCopy.teamOneSourceKey = undefined;
    matchCopy.teamTwoSourceKey = undefined;
    const numSources = matchFormat?.numTeams ?? 0;
    if (!matchCopy.teamSources) {
      matchCopy.teamSources = [];
      for (var i = 0; i < numSources; i++) {
        matchCopy.teamSources.push({
          position: undefined,
          matchKey: undefined,
        });
      }
    } else if (matchCopy.teamSources.length < numSources) {
      for (var j = matchCopy.teamSources.length; j < numSources; j++) {
        matchCopy.teamSources.push({
          position: undefined,
          matchKey: undefined,
        });
      }
    } else if (matchCopy.teamSources.length > numSources) {
      matchCopy.teamSources.splice(numSources);
    }
  } else if (matchCopy.teamSources) {
    matchCopy.teamSources = undefined;
  }

  return matchCopy;
};
