import { newFsMatchSetBase } from "../FsMatchSet";
import { FsKnockoutSet } from "db/model/match/Set/Knockout/FsKnockoutSet";
import { newFsKnockoutFormat } from "./FsKnockoutFormat";

export const newFsKnockoutSet = (): FsKnockoutSet => {
  return {
    ...newFsMatchSetBase(),
    format: newFsKnockoutFormat(),
    tracks: [],
    type: "Knockout",
  };
};
