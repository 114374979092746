import { ReactNode, useState } from "react";
import { FlexRow } from "styles/layout";
import {
  CollapsableHeaderRow,
  ExpandCollapseButton,
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelSpinner,
} from "./styles";

export type PanelContentType = "normal" | "information" | "warning";
export type PanelPaddingType = "none" | "padded" | "square";

interface Props {
  children: ReactNode;
  collapsedContent?: ReactNode;
  renderCollapsed?: boolean;
  header?: string;
  type?: PanelContentType;
  padding?: PanelPaddingType;
  bottomMargin?: boolean;
  loading?: boolean;
  allowOverflow?: boolean; // todo - temp solution to allow the SearchSelect dropdown to appear below the Panel bounds
}

export const Panel = ({
  children,
  collapsedContent,
  renderCollapsed,
  header,
  type,
  padding,
  bottomMargin,
  loading,
  allowOverflow,
}: Props) => {
  const [collapsed, setCollapsed] = useState(renderCollapsed ?? false);
  return (
    <PanelContainer bottomMargin={bottomMargin ?? true}>
      {!!collapsedContent ? (
        <FlexRow>
          <CollapsableHeaderRow onClick={() => setCollapsed(!collapsed)}>
            {!!header && <PanelHeader>{header}</PanelHeader>}
            <ExpandCollapseButton>{collapsed ? "▼" : "▲"}</ExpandCollapseButton>
          </CollapsableHeaderRow>
        </FlexRow>
      ) : (
        !!header && <PanelHeader>{header}</PanelHeader>
      )}
      <PanelContent
        type={type ?? "normal"}
        padding={padding ?? "none"}
        allowOverflow={allowOverflow ?? false}
      >
        {loading ? (
          <PanelSpinner />
        ) : (
          <>{collapsed ? collapsedContent : children}</>
        )}
      </PanelContent>
    </PanelContainer>
  );
};
