import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsMatchTrack } from "db/model/match/Track/FsMatchTrack";
import { Scores } from "fsModel/Match/Scores";
import { applyRoundsRoundScore } from "./Round";
import { FsPointsDistribution } from "db/model/match/Set/FsPointsDistribution";

export const applyRoundsTrackScore = (
  track: FsMatchTrack,
  teams: FsMatchTeam[],
  runningScores: Scores,
  pointsDist: FsPointsDistribution
) => {
  if (!track.rounds) {
    return;
  }

  for (let i = 0; i < track.rounds.length; i++) {
    const round = track.rounds[i];
    if (!round.times) {
      continue;
    }

    applyRoundsRoundScore(round.times, teams, runningScores, pointsDist);
  }
};

export const isRoundsTrackComplete = (
  track: FsMatchTrack,
  roundsPerTrack: number
) => {
  if (track.overrideComplete) {
    return true;
  }

  const completeRounds =
    track.rounds?.filter((r) => !!r.times?.length).length ?? 0;
  return completeRounds >= roundsPerTrack;
};
