import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { Scores, applyScoreOverrides } from "fsModel/Match/Scores";
import { FsKnockoutSet } from "db/model/match/Set/Knockout/FsKnockoutSet";
import { applyKnockoutTrackScore } from "./Track";
import { getTrackLivesRules } from "./Lives";
import { calculateFinalPositions } from "fsModel/Match/Positions";

export const getKnockoutSetScores = (
  set: FsKnockoutSet,
  teams: FsMatchTeam[]
): Scores => {
  const scores: Scores = {};
  const livesRules = set.format?.lives;
  if (!!set.tracks && !!livesRules) {
    set.tracks.forEach((t, i) => {
      const rules = getTrackLivesRules(livesRules, i + 1);
      if (!!rules) {
        applyKnockoutTrackScore(t, teams, scores, rules);
      }
    });
  }

  applyScoreOverrides(scores, set.scoreOverrides);

  return scores;
};

export const isKnockoutSetComplete = (
  set: FsKnockoutSet,
  scores: Scores,
  numTeams: number
) => {
  if (set.overrideComplete) {
    return true;
  }

  const playTillLeft = set.format?.playTillLeft ?? 1;
  const numFinished = Object.values(scores).filter((s) => s > 0).length;
  return numTeams - numFinished <= playTillLeft;
};

export const getKnockoutSetFinalPositions = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
