import { OrgForm } from "components/AddEditForms/Org/OrgForm";
import { ctorTextColumn } from "components/DataGrid/Columns/Text";
import { ctorValidationColumn } from "components/DataGrid/Columns/Validation";
import { DataGridColumn } from "components/DataGrid/DataGridColumn";
import { orgCollectionName } from "db/info/collectionNames";
import { getOrgName } from "fsModel/Org/Org";
import { Org } from "model/IdData";
import { Validated } from "model/validators/base";
import { validateOrgs } from "model/validators/OrgValidator";
import { DataPage } from "pages/Data/DataPage";
import { useSelector } from "react-redux";
import { selectAllOrgs } from "selectors/Org";
import { selectAllTeams } from "selectors/Team";
import { anyStringContains } from "Utility";

export const OrgsPage = () => {
  const orgs = useSelector(selectAllOrgs);
  const teams = useSelector(selectAllTeams);
  const validatedOrgs = validateOrgs(orgs, teams);

  return (
    <DataPage
      dataName="Org"
      collectionName={orgCollectionName}
      data={orgs}
      columns={orgsColumns}
      validated={validatedOrgs}
      newData={() => ({
        altDetails: undefined,
        details: undefined,
        teamHistory: undefined,
      })}
      searchFunction={orgSearch}
      form={OrgForm}
    />
  );
};

const orgsColumns: DataGridColumn<Validated<Org>>[] = [
  ctorTextColumn({
    textGetter: (arg: Validated<Org>) => arg.model.id,
    sortable: true,
    numberSort: true,
    header: "ID",
  }),
  ctorTextColumn({
    textGetter: (arg: Validated<Org>) =>
      getOrgName(arg.model.data, undefined, undefined),
    sortable: true,
    header: "Name",
  }),
  ctorValidationColumn({
    validationGetter: (arg: Validated<Org>) => arg.problems,
    sortable: true,
    header: "Validation",
  }),
];

const orgSearch = (arg: Validated<Org>, searchText: string) => {
  return anyStringContains(searchText, [
    arg.model.id,
    getOrgName(arg.model.data, undefined, undefined),
  ]);
};
