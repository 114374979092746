import { MatchListItem } from "components/MatchList/MatchListItem";
import { Panel } from "components/Panel/Panel";
import { GrowColumn } from "pages/styles";
import { useSelector } from "react-redux";
import { selectMatch } from "selectors/Match";
import { IStore } from "redux/store";
import { FsSingle } from "db/model/tournament/Segment/Single/FsSingle";

interface Props {
  single: FsSingle;
}

export const SingleTabContent = ({ single }: Props) => {
  const match = useSelector((state: IStore) =>
    selectMatch(state, single.matchId)
  );

  return (
    <GrowColumn>
      {!!match && (
        <Panel>
          <MatchListItem matchId={match.id} match={match.data} />
        </Panel>
      )}
    </GrowColumn>
  );
};
