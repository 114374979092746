import { FsWorldTourScoring } from "db/model/match/Set/WorldTour/FsWorldTourScoring";
import { newFsSetFormatBase } from "../FsSetFormat";
import { FsWorldTourFormat } from "db/model/match/Set/WorldTour/FsWorldTourFormat";

export const newFsWorldTourFormat = (): FsWorldTourFormat => {
  return {
    ...newFsSetFormatBase(),
    playCondition: "BestOf",
    scoring: newFsWorldTourScoring(),
    trackWinReq: undefined,
  };
};

export const newFsWorldTourScoring = (): FsWorldTourScoring => {
  return {
    "1-": [3, 0],
    "1,2-": [3, 0],
    "1,2-3": [3, 0],
    "1,2-3,4": [3, 0],
    "1-2": [2, 1],
    "1-2,3": [1, 1],
    "1,3-2": [2, 1],
    "1,3-2,4": [2, 1],
    "1,4-2,3": [1, 1],
  };
};
