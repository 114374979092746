import {
  FsMatchSet,
  isMatchSetType,
  matchSetTypes,
} from "db/model/match/Set/FsMatchSet";
import { FlexColumn, FlexRow } from "styles/layout";
import { SetFormatFields } from "./SetFormatFields";
import { Button } from "styles/elements";
import { ExpandableHeader } from "components/Core/ExpandableHeader/ExpandableHeader";
import { SetPicks } from "./Picks/SetPicks";
import { useSelector } from "react-redux";
import { IStore } from "redux/store";
import { selectTeamOptions } from "selectors/options/Team";
import { selectTrackOptions } from "selectors/options/Track";
import { SetTracks } from "./Tracks/SetTracks";
import { DriverKeys } from "../Teams/DriverKeyEditor";
import { NumberField } from "components/Fields/Number";
import { selectTeamOrgDisplayName } from "selectors/Team";
import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import { CheckboxField } from "components/Fields/Checkbox";
import { FieldRow, FieldsContainer } from "components/Fields/styles";
import { TypedSelectField } from "components/Fields/Select";
import { newFsMatchSet } from "db/ctor/match/Set/FsMatchSet";

interface Props {
  set: FsMatchSet;
  numTeams: number | undefined;
  driverKeys: DriverKeys;
  update: (set: FsMatchSet) => void;
  remove: () => void;
  teamIds: string[];
  trackIds: string[];
  orgTime: number | undefined;
}

export const SetEditor = ({
  set,
  numTeams,
  driverKeys,
  update,
  remove,
  teamIds,
  trackIds,
  orgTime,
}: Props) => {
  const teamOptions = useSelector((state: IStore) =>
    selectTeamOptions(state, teamIds, orgTime)
  );

  const trackOptions = useSelector((state: IStore) =>
    selectTrackOptions(state, trackIds)
  );
  return (
    <FlexColumn>
      <FlexRow>
        <Button
          style={{ marginLeft: "auto", fontSize: "7pt" }}
          onClick={remove}
        >
          remove set
        </Button>
      </FlexRow>

      <span>Score Overrides</span>
      <FlexRow style={{ columnGap: "10px", margin: "5px 0 0 5px" }}>
        {teamIds.map((teamId) => (
          <FlexRow key={teamId} style={{ columnGap: "5px" }}>
            <TeamDisplayName teamId={teamId} />
            <NumberField
              value={
                !!set.scoreOverrides ? set.scoreOverrides[teamId] : undefined
              }
              update={(newVal) => {
                const newScoreOverrides = !!set.scoreOverrides
                  ? { ...set.scoreOverrides }
                  : {};
                if (newVal === undefined) {
                  delete newScoreOverrides[teamId];
                } else {
                  newScoreOverrides[teamId] = newVal;
                }

                update({ ...set, scoreOverrides: newScoreOverrides });
              }}
              args={{ width: "40px", allowNegative: true }}
            />
          </FlexRow>
        ))}
      </FlexRow>
      <br />

      <FieldsContainer>
        <FieldRow>
          <LabelledField label="Set Type">
            <TypedSelectField
              value={set.type}
              options={matchSetTypes}
              isType={isMatchSetType}
              update={(newType) => {
                update(newFsMatchSet(newType ?? "ForwardCupMode"));
              }}
              args={{ includeEmptyOption: false }}
            />
          </LabelledField>
          <LabelledField label="Override Set Complete:">
            <CheckboxField
              value={set.overrideComplete}
              update={(overrideComplete) =>
                update({ ...set, overrideComplete })
              }
              args={{ falseAsUndefined: true }}
            />
          </LabelledField>
          <LabelledField label="Is Tiebreaker Set:">
            <CheckboxField
              value={set.isTiebreaker}
              update={(isTiebreaker) => update({ ...set, isTiebreaker })}
              args={{ falseAsUndefined: true }}
            />
          </LabelledField>
        </FieldRow>
      </FieldsContainer>
      <br />
      <ExpandableHeader header="Format" initiallyExpanded={true}>
        <SetFormatFields set={set} update={update} numTeams={numTeams} />
      </ExpandableHeader>
      <br />

      <ExpandableHeader header="Picks" initiallyExpanded={true}>
        <SetPicks
          picks={set.picks}
          update={(picks) => update({ ...set, picks })}
          teamOptions={teamOptions}
          trackOptions={trackOptions}
        />
      </ExpandableHeader>
      <br />

      <ExpandableHeader header="Tracks" initiallyExpanded={true}>
        {set.type !== "TimeAttack" ? (
          <SetTracks
            tracks={set.tracks}
            trackOptions={trackOptions}
            teamIds={teamIds}
            update={(tracks) => update({ ...set, tracks })}
            driverKeys={driverKeys}
            driverIds={Object.keys(driverKeys)}
          />
        ) : (
          <span>
            Time Attack Tracks not currently supported. Please contact a member
            of staff if you wish to add Time Attack times
          </span>
        )}
      </ExpandableHeader>
    </FlexColumn>
  );
};

// todo - extract to reusable / better code where used
const TeamDisplayName = ({ teamId }: { teamId: string | undefined }) => {
  const displayName = useSelector((state: IStore) =>
    selectTeamOrgDisplayName(state, teamId, undefined)
  );

  return <span>{displayName}</span>;
};
