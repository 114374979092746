import { Scores } from "./Scores";

export type Positions = {
  [teamId: string]: number;
};

export const calculateFinalPositions = (
  isComplete: boolean,
  scores: Scores,
  compareScores: (scores: Scores, a: string, b: string) => number,
  numTeams?: number,
  ffTeamIds?: string[],
  ffForceLast?: boolean,
  dqTeamIds?: string[] | undefined,
  dqForceLast?: boolean
) => {
  if (!isComplete) {
    return undefined;
  }

  const finalPositions: Positions = {};
  const forceLastTeamIds: string[] = [];
  if (!!ffTeamIds && ffForceLast) {
    forceLastTeamIds.push(...ffTeamIds);
  }

  if (!!dqTeamIds && dqForceLast) {
    forceLastTeamIds.push(...dqTeamIds);
  }

  const teamIds = Object.keys(scores);
  const teamIdsByRankOrder = teamIds
    .filter((teamId) => !forceLastTeamIds.includes(teamId))
    .sort((a, b) => compareScores(scores, a, b));

  for (let i = 0; i < teamIdsByRankOrder.length; i++) {
    const teamId = teamIdsByRankOrder[i];
    const previousTeamId = i > 0 ? teamIdsByRankOrder[i - 1] : undefined;
    if (
      !!previousTeamId &&
      compareScores(scores, teamId, previousTeamId) === 0
    ) {
      finalPositions[teamId] = finalPositions[previousTeamId];
    } else {
      finalPositions[teamId] = i + 1;
    }
  }

  for (let i = 0; i < forceLastTeamIds.length; i++) {
    finalPositions[forceLastTeamIds[i]] = numTeams ?? teamIds.length;
  }

  return finalPositions;
};
