import { Navigate, Route, Routes } from "react-router-dom";
import {
  homePath,
  loginPath,
  PageConfig,
  PageRestriction,
  PAGE_CONFIGS,
} from "./PageConfigs";
import { useEffect, useState } from "react";
import { auth } from "../firebase-config";
import { useDispatch, useSelector } from "react-redux";
import { LoadingPage } from "./Loading/LoadingPage";
import { resetUser } from "redux/UserSlice";
import { NavBar } from "components/NavBar/NavBar";
import { PageWrapper, ContentWrapper } from "styles/layout";
import { InitialUsernameSelector } from "components/Username/InitialUsernameSelector";
import { IStore } from "redux/store";
import { Footer } from "components/Footer/Footer";
import { userSubscription, setUserInfoFromDb } from "db/info/userInfo";
import { isEcmStaff } from "eCM";

interface Props {
  hasDataLoaded: boolean;
}

const UNKNOWN_ROUTE_REDIRECT = (
  <Route key="*" path="*" element={<Navigate replace to={homePath} />} />
);

export const Main = ({ hasDataLoaded }: Props) => {
  const dispatch = useDispatch();
  const [authLoaded, setAuthLoaded] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loggedInIsEcmStaff, setLoggedInEcmStaff] = useState(false);
  const [hasUsername, setHasUsername] = useState(false);
  const username = useSelector((state: IStore) => state.user.username);

  // Code to update 'Main' user state when authState changes
  useEffect(() => {
    auth.onAuthStateChanged(() => {
      setAuthLoaded(false);
      const uid = auth.currentUser?.uid;
      // If !uid, no current signed in user
      if (!uid) {
        setLoggedIn(false);
        setLoggedInEcmStaff(false);
        setAuthLoaded(true);
        setHasUsername(false);
        if (!!userSubscription) {
          userSubscription();
        }

        dispatch(resetUser());
        return;
      }

      // Else user signed in, figure if username setup and set state accordingly
      setUserInfoFromDb(uid).then((result) => {
        setLoggedIn(true);
        setLoggedInEcmStaff(isEcmStaff(uid));
        setAuthLoaded(true);
        setHasUsername(result);
      });
    });
  }, []);

  useEffect(() => {
    const newHasUsername = username !== "" && !!username;
    if (hasUsername !== newHasUsername) {
      setHasUsername(newHasUsername);
    }
  }, [username]);

  const allPaths = PAGE_CONFIGS.map((p) => p.path);

  if (!authLoaded || !hasDataLoaded) {
    return (
      <Routes>
        {UNKNOWN_ROUTE_REDIRECT}
        {allPaths.map((p) => (
          <Route key={p} path={p} element={<LoadingPage />} />
        ))}
      </Routes>
    );
  }

  if (loggedIn && !hasUsername) {
    return (
      <Routes>
        {UNKNOWN_ROUTE_REDIRECT}
        {allPaths.map((p) => (
          <Route
            path={p}
            element={
              !!auth.currentUser?.uid ? (
                <InitialUsernameSelector uid={auth.currentUser?.uid} />
              ) : (
                <span>
                  State suggests user is logged in, but auth has no currentUser
                  stored. Note for User - refreshing the page may fix this.
                </span>
              )
            }
          />
        ))}
      </Routes>
    );
  }

  return (
    <Routes>
      {UNKNOWN_ROUTE_REDIRECT}
      {PAGE_CONFIGS.map((config) =>
        getPageRoute(config, loggedIn, loggedInIsEcmStaff)
      )}
    </Routes>
  );
};

const WithNavBarAndFooter = (element: JSX.Element): JSX.Element => {
  return (
    <PageWrapper>
      <NavBar />
      <ContentWrapper>{element}</ContentWrapper>
      <Footer />
    </PageWrapper>
  );
};

const getPageElement = (
  config: PageConfig,
  loggedIn: boolean,
  loggedInIsEcmStaff: boolean
) => {
  if (config.restriction === PageRestriction.STAFF) {
    if (!loggedInIsEcmStaff) {
      if (loggedIn) {
        return <Navigate replace to={homePath} />;
      }

      return <Navigate replace to={loginPath} state={{ prev: config.path }} />;
    }
  } else if (config.restriction === PageRestriction.LOGGED_IN) {
    if (!loggedIn) {
      return <Navigate replace to={loginPath} state={{ prev: config.path }} />;
    }
  } else if (config.restriction === PageRestriction.NOT_LOGGED_IN) {
    if (loggedIn) {
      return <Navigate replace to={homePath} />;
    }
  }

  return config.showNavBarAndFooter
    ? WithNavBarAndFooter(config.page)
    : config.page;
};

const getPageRoute = (
  config: PageConfig,
  loggedIn: boolean,
  loggedInIsEcmStaff: boolean
) => {
  return (
    <Route
      key={config.path}
      path={config.path}
      element={getPageElement(config, loggedIn, loggedInIsEcmStaff)}
    />
  );
};
