import { nonUndefined } from "Utility";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsMatchmakingSet } from "db/model/match/Set/Matchmaking/FsMatchmakingSet";
import { getWinnerIds } from "fsModel/Match/Results";
import { Scores } from "fsModel/Match/Scores";
import {
  getMatchmakingTrackResults,
  getMatchmakingTrackScores,
  isMatchmakingTrackComplete,
} from "./Track";
import { isPlayCondition } from "fsModel/Match/PlayCondition";
import { isPlayConditionComplete } from "../MatchSet";
import { calculateFinalPositions } from "fsModel/Match/Positions";

const NUM_WINNERS = 1;

export const getMatchmakingSetScores = (
  set: FsMatchmakingSet,
  teams: FsMatchTeam[]
): Scores => {
  const teamIds = nonUndefined(teams.map((t) => t.teamId));
  const scores: Scores = {};

  const pointsDist = set.format?.points;
  const winReq = set.format?.trackWinReq;
  const roundsLimit = set.format?.roundsLimit;

  const trackWinnerIds: string[][] = [];
  if (!!pointsDist && !!winReq) {
    set.tracks?.forEach((t) => {
      const scores = getMatchmakingTrackScores(t, teams, pointsDist);
      const isComplete = isMatchmakingTrackComplete(
        t,
        roundsLimit,
        scores,
        winReq
      );

      const finalPositions = getMatchmakingTrackResults(scores, isComplete);
      const winnerIds = getWinnerIds(finalPositions, NUM_WINNERS);
      if (!!winnerIds) {
        trackWinnerIds.push(winnerIds);
      }
    });
  }

  for (var i = 0; i < teamIds.length; i++) {
    const teamId = teamIds[i];
    const scoreOverride = !!set.scoreOverrides
      ? set.scoreOverrides[teamId]
      : undefined;
    if (scoreOverride !== undefined) {
      scores[teamId] = scoreOverride;
    } else {
      scores[teamId] = trackWinnerIds.filter((winnerIds) =>
        winnerIds.includes(teamId)
      ).length;
    }
  }

  return scores;
};

export const isMatchmakingSetComplete = (
  set: FsMatchmakingSet,
  scores: Scores
) => {
  if (set.overrideComplete) {
    return true;
  }

  const playCondition = set.format?.playCondition;
  const numTracks = set.format?.numTracks;
  const winReq = set.format?.trackWinReq;
  if (!winReq || !numTracks) {
    return false;
  }

  const numCompleteTracks =
    set.tracks?.filter((t) =>
      isMatchmakingTrackComplete(t, set.format?.roundsLimit, scores, winReq)
    ).length ?? 0;
  if (!isPlayCondition(playCondition)) {
    return false;
  }

  return isPlayConditionComplete(
    scores,
    playCondition,
    numTracks,
    numCompleteTracks
  );
};

export const getMatchmakingSetFinalPositions = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

// todo - Add tiebreaker logic here
const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
