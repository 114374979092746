import {
  ctorDataGridColumnBase,
  DataGridColumn,
  DataGridColumnBase,
  DataGridColumnBaseCtorArgs,
} from "../DataGridColumn";

export type TextColumn<T> = DataGridColumnBase & {
  textGetter: (arg: T) => string | undefined;
  subTextGetter: ((arg: T) => string) | undefined;
  numberSort: boolean;
};

interface CtorArgs<T> extends DataGridColumnBaseCtorArgs {
  textGetter: (arg: T) => string | undefined;
  subTextGetter?: (arg: T) => string;
  numberSort?: boolean;
}

export function ctorTextColumn<T>(args: CtorArgs<T>): TextColumn<T> {
  return {
    ...ctorDataGridColumnBase(args),
    textGetter: args.textGetter,
    subTextGetter: args.subTextGetter,
    numberSort: args.numberSort ?? false,
  };
}

export function isTextColumn<T>(
  column: DataGridColumn<T>
): column is TextColumn<T> {
  return (column as TextColumn<T>).textGetter !== undefined;
}
