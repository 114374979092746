import styled from "styled-components";
import { Theme } from "theme";

export const ErrorText = styled.span`
  color: ${Theme.errorTextColor};
`;

export const SuccessText = styled.span`
  color: ${Theme.successTextColor};
`;

export const WarningText = styled.span`
  font-weight: bold;
  color: maroon;
`;

const EmptyPanelText = styled.span`
  text-align: center;
  display: block;
  font-style: italic;
  font-size: 11pt;
`;

export const EmptyCompactPanelText = styled(EmptyPanelText)`
  padding: 5px 0;
`;

export const EmptyGrowPanelText = styled(EmptyPanelText)`
  padding: 10px 0;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const DemiBold = styled.span`
  font-weight: 500;
`;

export const NoteText = styled.div`
  font-style: italic;
  text-align: right;
  font-size: 10pt;
  color: gray;
`;

const TextBoxDiv = styled("div")<{ visible: boolean }>`
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  display: none;
  padding: 10px;
  font-size: 12pt;
  ${({ visible }) => visible && "display:block"};

  @media (max-width: 700px) {
    font-size: 11pt;
  }

  @media (max-width: 600px) {
    font-size: 10.5pt;
  }
`;

export const MessageBox = styled(TextBoxDiv)`
  border-color: rgba(3, 169, 252, 1);
  background-color: rgba(115, 205, 250, 0.4);
`;

export const ClipText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  width: 100%;
  box-sizing: border-box;
`;
