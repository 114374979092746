import { IStore } from "redux/store";

export const selectExistingDriverIds = (state: IStore) => {
  return Object.keys(state.driver.drivers);
};

export const selectExistingMatchIds = (state: IStore) => {
  return Object.keys(state.match.matches);
};

export const selectExistingOrgIds = (state: IStore) => {
  return Object.keys(state.org.orgs);
};

export const selectExistingTeamIds = (state: IStore) => {
  return Object.keys(state.team.teams);
};

export const selectExistingTrackIds = (state: IStore) => {
  return Object.keys(state.track.tracks);
};
