import { FullPageSpinner } from "styles/components";
import { CenterFlexDiv } from "styles/layout";

export const LoadingPage = () => {
  return (
    <CenterFlexDiv>
      <FullPageSpinner />
    </CenterFlexDiv>
  );
};
