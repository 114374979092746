import styled from "styled-components";
import { FlexRow, FlexColumn } from "styles/layout";
import { PANEL_GAP } from "theme";

export const PageContent = styled.div`
  padding: 6px 8px 20px 8px;
`;

export const ColumnContainer = styled(FlexRow)`
  column-gap: 20px;
  align-items: flex-start;
`;

export const Column = styled(FlexColumn)<{ hideUnderWidth?: number }>`
  align-items: stretch;
  row-gap: ${PANEL_GAP};

  @media (max-width: ${({ hideUnderWidth }) => hideUnderWidth ?? 0}px) {
    display: none;
  }
`;

export const GrowColumn = styled(Column)`
  flex-grow: 1;
  justify-content: stretch;
  width: 100%;
`;
