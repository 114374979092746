import {
  FsCupModePoints,
  isFsStaticCupModePoints,
} from "db/model/match/Set/CupMode/FsCupModePoints";
import { FsPointsDistribution } from "db/model/match/Set/FsPointsDistribution";

export const getPointsDistribution = (
  points: FsCupModePoints,
  numDrivers: number
): FsPointsDistribution | undefined => {
  if (isFsStaticCupModePoints(points)) {
    return points;
  }

  return points[numDrivers.toString()];
};
