import {
  TeCupModeSetFormatArgs,
  newTeCupModeSetFormatBase,
  mapTeCupModeSetFormatBase,
} from "../TeCupModeMatchFormat";
import { FsForwardCupModeFormat } from "db/model/match/Set/CupMode/Forward/FsForwardCupModeFormat";
import { FsSetFormatBase } from "db/model/match/Set/FsSetFormat";

export type TeForwardCupModeSetFormat = TeCupModeSetFormatArgs & {
  pointsLimit: number | undefined;
  type: "CupMode";
};

export const newTeForwardCupModeSetFormat = (): TeForwardCupModeSetFormat => {
  return {
    ...newTeCupModeSetFormatBase(),
    pointsLimit: undefined,
    type: "CupMode",
  };
};

export const mapTeForwardCupModeSetFormat = (
  base: FsSetFormatBase,
  format: TeForwardCupModeSetFormat
): FsForwardCupModeFormat => {
  return {
    ...mapTeCupModeSetFormatBase(base, format),
    finalist: true,
    pointsLimit: format.pointsLimit,
  };
};
