import { configureStore } from "@reduxjs/toolkit";
import { IUserSlice, userReducer } from "./UserSlice";
import { IUsersSlice, usersReducer } from "./UsersSlice";
import { IOrgSlice, orgReducer } from "./OrgSlice";
import { driverReducer, IDriverSlice } from "./DriverSlice";
import { IMatchSlice, matchReducer } from "./MatchSlice";
import {
  IPickemDetailsSlice,
  pickemDetailsReducer,
} from "./PickemDetailsSlice";
import { ITeamSlice, teamReducer } from "./TeamSlice";
import { ITourSlice, tourReducer } from "./TourSlice";
import { ITrackSlice, trackReducer } from "./TrackSlice";

export interface IStore {
  driver: IDriverSlice;
  match: IMatchSlice;
  org: IOrgSlice;
  pickemDetails: IPickemDetailsSlice;
  team: ITeamSlice;
  tour: ITourSlice;
  track: ITrackSlice;
  user: IUserSlice;
  users: IUsersSlice;
}

export const store = configureStore({
  reducer: {
    driver: driverReducer,
    match: matchReducer,
    org: orgReducer,
    pickemDetails: pickemDetailsReducer,
    team: teamReducer,
    tour: tourReducer,
    track: trackReducer,
    user: userReducer,
    users: usersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
