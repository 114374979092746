import { FsOrg } from "db/model/org/FsOrg";
import { getAltOrgDetails, getOrgDetails } from "./OrgDetails";
import { FsOrgDetailsBase } from "db/model/org/FsOrgDetails";

export const getAllOrgDetails = (org: FsOrg): FsOrgDetailsBase[] => {
  return [...(org.details ?? []), ...(org.altDetails ?? [])];
};

export const getOrgCountry = (
  org: FsOrg,
  teamId: string | undefined,
  timeMs: number | undefined
) => {
  const altDetails = getAltOrgDetails(org, teamId);
  if (!!altDetails?.country) {
    return altDetails.country;
  }

  const details = getOrgDetails(org, timeMs);
  return details?.country;
};

export const getOrgName = (
  org: FsOrg,
  teamId: string | undefined,
  timeMs: number | undefined
) => {
  const altDetails = getAltOrgDetails(org, teamId);
  if (!!altDetails?.name) {
    return altDetails.name;
  }

  const details = getOrgDetails(org, timeMs);
  return details?.name;
};

export const getOrgPrimaryColor = (
  org: FsOrg,
  teamId: string | undefined,
  timeMs: number | undefined
) => {
  const altDetails = getAltOrgDetails(org, teamId);
  if (!!altDetails?.primaryColor) {
    return altDetails.primaryColor;
  }

  const details = getOrgDetails(org, timeMs);
  return details?.primaryColor;
};

export const getOrgTrigram = (
  org: FsOrg,
  teamId: string | undefined,
  timeMs: number | undefined
) => {
  const altDetails = getAltOrgDetails(org, teamId);
  if (!!altDetails?.trigram) {
    return altDetails.trigram;
  }

  const details = getOrgDetails(org, timeMs);
  return details?.trigram;
};
