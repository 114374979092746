import { auth, googleAuthProvider } from "firebase-config";
import {
  Persistence,
  browserSessionPersistence,
  browserLocalPersistence,
  signInWithPopup,
} from "firebase/auth";
import { homePath, privacyPath, termsOfServicePath } from "pages/PageConfigs";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { SignInWithGoogleButton } from "./styles";
import { useRef, useState } from "react";

export const SignInWithGoogle = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [signInPopupOpen, setSignInPopupOpen] = useState<boolean>(false);

  // Prevents the signInPopupOpen being set to false when popup reopened on clicking button again
  let secondSignInOpened = useRef<boolean>(false);

  const handleSignIn = () => {
    if (signInPopupOpen) {
      secondSignInOpened.current = true;
    } else {
      setSignInPopupOpen(true);
    }
    var persistance: Persistence = rememberMe
      ? browserLocalPersistence
      : browserSessionPersistence;
    auth.setPersistence(persistance).then(() => {
      signInWithPopup(auth, googleAuthProvider)
        .then(() => {
          navigate(state.prev ?? homePath);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (secondSignInOpened.current) {
            secondSignInOpened.current = false;
          } else {
            setSignInPopupOpen(false); // todo - can remove this stuff if using redirect
          }
        });
    });
  };

  return (
    <>
      <SignInWithGoogleButton onClick={handleSignIn}>
        Sign in with Google
      </SignInWithGoogleButton>
      <div>
        <label>Remember me?</label>
        <input
          type="checkbox"
          checked={rememberMe}
          onChange={() => setRememberMe(!rememberMe)}
          disabled={signInPopupOpen}
        />
      </div>
      <br />
      <span>
        By signing in you agree to our{" "}
        <NavLink to={privacyPath}>Privacy Policy</NavLink> and{" "}
        <NavLink to={termsOfServicePath}>Terms Of Service</NavLink>
      </span>
    </>
  );
};
