import { useRef, useState } from "react";
import { FormProps } from "components/AddEditForms/FormProps";
import {
  SearchSelectContainer,
  Options,
  Option,
  Input,
  InputButton,
} from "./styles";
import { DataModal } from "components/Modal/DataModal";
import { IdDisplay } from "model/IdDisplay";

interface Props<T> {
  items: IdDisplay[];
  selectedItem: T | string | undefined;
  setSelectedItem: (item: T | string | undefined) => void;
  newItem: () => T;
  itemForm: (props: FormProps<T>) => JSX.Element;
  itemDisplay: (item: T) => string;
  searchPlaceholder?: string;
}

export function SearchSelect<T>({
  items,
  selectedItem,
  setSelectedItem,
  newItem,
  itemForm,
  itemDisplay,
  searchPlaceholder,
}: Props<T>) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchText, setSearchText] = useState("");
  const filteredItems = filterItems(items, searchText);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalTarget, setModalTarget] = useState<T | undefined>();

  const onAddNewClick = () => {
    setModalTarget(newItem());
    setModalVisible(true);
  };

  const onModalSave = async () => {
    if (!!modalTarget) {
      setSearchText("");
      setSelectedItem(modalTarget);
      setModalVisible(false);
      setModalTarget(undefined);
    }
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalTarget(undefined);
  };

  const deselectItem = () => {
    setSearchText("");
    setSelectedItem(undefined);
  };

  return (
    <SearchSelectContainer>
      {!!selectedItem ? (
        <Input
          ref={inputRef}
          value={
            typeof selectedItem === "string"
              ? items.find((i) => i.id === selectedItem)?.display
              : itemDisplay(selectedItem)
          }
          disabled={true}
        />
      ) : (
        <Input
          ref={inputRef}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={searchPlaceholder}
          style={{ color: "gray" }}
        />
      )}
      {searchText || selectedItem ? (
        <InputButton onClick={deselectItem}>X</InputButton>
      ) : (
        <InputButton
          onClick={() => {
            inputRef.current?.focus();
          }}
        >
          &#x1F50D;
        </InputButton>
      )}
      <Options hide={!!selectedItem}>
        {filteredItems.map((i) => (
          <Option
            onClick={() => {
              console.log(`Current = ${selectedItem}, New = ${i.id}`);
              setSelectedItem(i.id);
            }}
          >
            {i.display}
          </Option>
        ))}
        <Option onClick={onAddNewClick}>Add New...</Option>
      </Options>

      <DataModal
        visible={modalVisible}
        onSave={onModalSave}
        onCancel={onModalCancel}
      >
        {modalTarget &&
          itemForm({ data: modalTarget, setData: setModalTarget })}
      </DataModal>
    </SearchSelectContainer>
  );
}

const filterItems = (items: IdDisplay[], searchText: string) => {
  if (!searchText) {
    return items;
  }

  const lowerSearch = searchText.toLowerCase();
  return items
    .filter((i) => i.display.toLowerCase().startsWith(lowerSearch))
    .concat(
      items.filter(
        (i) =>
          !i.display.toLowerCase().startsWith(lowerSearch) &&
          i.display.toLowerCase().includes(lowerSearch)
      )
    );
};
