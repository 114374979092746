import { ReactNode } from "react";
import { Button } from "styles/elements";
import { CenterFlexRow } from "styles/layout";
import { ModalContent, ModalWrapper } from "./styles";

interface Props {
  children: ReactNode;
  visible: boolean;
  onCloseClick?: () => void;
}

export const Modal = ({ children, visible, onCloseClick }: Props) => {
  return (
    <ModalWrapper visible={visible}>
      <ModalContent>
        {children}
        <CenterFlexRow style={{ padding: "5px" }}>
          {onCloseClick && (
            <Button style={{ fontSize: "8pt" }} onClick={onCloseClick}>
              Close
            </Button>
          )}
        </CenterFlexRow>
      </ModalContent>
    </ModalWrapper>
  );
};
