import { newTeTeam, TeTeam } from "model/TournamentEditor/TeTeam";
import { arrayReplaceAt } from "Utility";
import { AddButton, TeamContainer, TeamsContainer } from "./styles";
import { Team } from "./Team";
import { TeTournament } from "model/TournamentEditor/TeTournament";

interface Props {
  tournament: TeTournament;
  update: () => void;
}

export const Teams = ({ tournament, update }: Props) => {
  const onAddTeamClick = () => {
    tournament.teams = [...tournament.teams, newTeTeam("67")];
    update();
  };

  const removeTeam = (team: TeTeam) => {
    tournament.teams = tournament.teams.filter((t) => t !== team);
    update();
  };

  return (
    <TeamsContainer>
      {tournament.teams.map((team, teamIdx) => (
        <TeamContainer>
          <Team
            team={team}
            updateTeam={(team: TeTeam) => {
              tournament.teams = arrayReplaceAt(
                tournament.teams,
                team,
                teamIdx
              );
              update();
            }}
            removeTeam={() => removeTeam(team)}
          />
        </TeamContainer>
      ))}
      <AddButton onClick={onAddTeamClick}>+</AddButton>
    </TeamsContainer>
  );
};
