import { FsDriver, perihperals } from "db/model/driver/FsDriver";
import { FormProps } from "../FormProps";
import { StringArrayField } from "../StringArrayField/StringArrayField";
import { FormTable } from "../styles";
import { nonEmpty } from "Utility";

export const DriverForm = ({
  data: driver,
  setData: setDriver,
}: FormProps<FsDriver>) => {
  return (
    <FormTable>
      <tbody>
        <tr>
          <td>Nickname</td>
          <td>
            <input
              value={driver.nickname}
              onChange={(e) =>
                setDriver({ ...driver, nickname: nonEmpty(e.target.value) })
              }
            />
          </td>
        </tr>
        <tr>
          <td>Home Country</td>
          <td>
            <input
              placeholder="optional"
              value={driver.country}
              onChange={(e) =>
                setDriver({ ...driver, country: nonEmpty(e.target.value) })
              }
            />
          </td>
        </tr>
        <tr>
          <td>Peripheral</td>
          <td>
            <select
              value={driver.peripheral}
              onChange={(e) =>
                setDriver({ ...driver, peripheral: nonEmpty(e.target.value) })
              }
            >
              {perihperals.map((p) => (
                <option value={p}>{p}</option>
              ))}
            </select>
          </td>
        </tr>
        <tr>
          <td>Team History</td>
          <td>
            <StringArrayField
              values={driver.teamHistory ?? []}
              update={(teamHistory: string[]) =>
                setDriver({ ...driver, teamHistory })
              }
            />
          </td>
        </tr>
        <tr>
          <td>Ubisoft Uids</td>
          <td>
            <StringArrayField
              values={driver.ubisoftUids ?? []}
              update={(ubisoftUids: string[]) =>
                setDriver({ ...driver, ubisoftUids })
              }
            />
          </td>
        </tr>
      </tbody>
    </FormTable>
  );
};
