import {
  addTournamentAdminFirebase,
  db,
  removeTournamentAdminFirebase,
} from "firebase-config";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { store } from "redux/store";
import { FsTournamentAdmin } from "./FsTournamentAdmin";
import { updateTournamentAdmin } from "redux/UserSlice";
import { tournamentAdminCollectionName } from "db/info/collectionNames";

var tournamentAdminSubscriptions: { [key: string]: Unsubscribe } = {};

// todo - does this warrant its own firebase collection?
// todo this updating of subscription logic can be made generic and extracted
// perhaps also this whole method
export const fetchTournamentAdmins = (ids: string[]) => {
  const existingIds = Object.keys(tournamentAdminSubscriptions);
  const newIds = ids.filter((id) => !existingIds.includes(id));

  for (var j = 0; j < newIds.length; j++) {
    const newId = newIds[j];
    const existingSubscription = tournamentAdminSubscriptions[newId];
    if (!!existingSubscription) {
      existingSubscription();
    }

    const docRef = doc(db, tournamentAdminCollectionName, newId);
    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        store.dispatch(
          updateTournamentAdmin({
            id: docSnapshot.id,
            data: docSnapshot.data() as FsTournamentAdmin,
          })
        );
      }
    });

    tournamentAdminSubscriptions[newId] = unsubscribe;
  }
};

export const fetchAllTournamentAdmins = () => {
  const queryRef = collection(db, tournamentAdminCollectionName);
  const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
    querySnapshot.docChanges().forEach((change) => {
      store.dispatch(
        updateTournamentAdmin({
          id: change.doc.id,
          data: change.doc.data() as FsTournamentAdmin,
        })
      );
    });
  });

  tournamentAdminSubscriptions[tournamentAdminCollectionName] = unsubscribe;
};

export const unsubscribeAllTournamentAdmins = () => {
  const unsubscribes = Object.values(tournamentAdminSubscriptions);
  for (var i = 0; i < unsubscribes.length; i++) {
    unsubscribes[i]();
  }

  tournamentAdminSubscriptions = {};
};

export const addTournamentAdminAsync = async (
  tournamentId: string,
  adminUid: string
) => {
  try {
    const result: any = await addTournamentAdminFirebase({
      adminUid: adminUid,
      tournamentId,
    });

    return result.data.message;
  } catch (error: any) {
    return error.message;
  }
};

export const removeTournamentAdminAsync = async (
  tournamentId: string,
  adminUid: string
) => {
  try {
    const result: any = await removeTournamentAdminFirebase({
      adminUid: adminUid,
      tournamentId,
    });

    return result.data.message;
  } catch (error: any) {
    return error.message;
  }
};
