import { FsSetFormatBase } from "db/model/match/Set/FsSetFormat";
import { TeSetFormatArgsBase, newTeSetFormatArgsBase } from "../TeSetFormat";
import { FsKnockoutFormat } from "db/model/match/Set/Knockout/FsKnockoutFormat";

export type TeKnockoutSetFormat = TeSetFormatArgsBase & {
  playTillLeft: number | undefined;
  roundsPerTrack: number | undefined;
  startingLives: number | undefined;
  type: "Knockout";
};

export const newTeKncokoutSetFormat = (): TeKnockoutSetFormat => {
  return {
    ...newTeSetFormatArgsBase(),
    playTillLeft: undefined,
    roundsPerTrack: undefined,
    startingLives: undefined,
    type: "Knockout",
  };
};

export const mapTeKnockoutSetFormat = (
  base: FsSetFormatBase,
  format: TeKnockoutSetFormat
): FsKnockoutFormat => {
  return {
    ...base,
    playTillLeft: format.playTillLeft,
    roundsPerTrack: format.roundsPerTrack,
    startingLives: format.startingLives,
    lives: undefined,
  };
};
