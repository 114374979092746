import { PayloadAction } from "@reduxjs/toolkit";
import { createFetchSlice, IFetchSlice, initialFetchState } from "./FetchSlice";
import { DocumentChange, DocumentData } from "firebase/firestore";
import { FsTrack } from "db/model/track/FsTrack";
import { Track } from "model/IdData";

export interface ITrackSlice extends IFetchSlice {
  tracks: { [key: string]: Track };
}

const initialState: ITrackSlice = {
  ...initialFetchState,
  tracks: {},
};

// todo - should the FSModel -> Model mapping be done in the slice ??
export const trackSlice = createFetchSlice({
  name: "track",
  initialState,
  reducers: {
    updateTracks: (
      state,
      action: PayloadAction<{
        docChanges: DocumentChange<DocumentData>[];
      }>
    ) => {
      const { docChanges } = action.payload;
      for (let i = 0; i < docChanges.length; i++) {
        const { doc } = docChanges[i];
        state.tracks[doc.id] = { id: doc.id, data: doc.data() as FsTrack };
      }

      if (!state.initialFetchComplete) {
        state.initialFetchComplete = true;
      }
    },
  },
});

export const { updateTracks } = trackSlice.actions;
export const trackReducer = trackSlice.reducer;
