import { IStore } from "redux/store";
import { selectIsEcmStaff } from "../User";
import { mapIdDataToOptions } from "./core";
import { selectTrack } from "selectors/Track";
import { selectMultiple } from "selectors/Core";

export const selectTrackOptions = (state: IStore, trackIds: string[]) => {
  return mapIdDataToOptions(
    selectMultiple(state, selectTrack, trackIds),
    (track) => track.data.name ?? "",
    selectIsEcmStaff(state)
  );
};
