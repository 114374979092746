import { OrgLogo } from "components/OrgLogo/OrgLogo";
import { useSelector } from "react-redux";
import { selectTournamentFromMatchId } from "selectors/Match";
import { selectTeamOrgId } from "selectors/Team";
import { IStore } from "redux/store";
import { arrayReplaceAt, nonEmpty } from "Utility";
import { OrgLogoContainer } from "./styles";
import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import { CenterFlexColumn, CenterFlexRow } from "styles/layout";
import { Button } from "styles/elements";
import { NumberField } from "components/Fields/Number";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { CheckboxField } from "components/Fields/Checkbox";
import { IdSelectField } from "components/Fields/Select";
import { getTeamActiveDriverIds } from "fsModel/Team/Team";
import { selectTeamOptions } from "selectors/options/Team";
import { selectDriverOptionsFromTeamId } from "selectors/options/Driver";

interface Props {
  matchId: string;
  team: FsMatchTeam | string;
  updateTeam: (team: FsMatchTeam | string) => void;
  removeTeam: () => void;
  scoreOverride: number | undefined;
  updateScoreOverride: (
    teamId: string | undefined,
    scoreOverride: number | undefined
  ) => void;
  orgTime: number | undefined;
}

export const TeamEditor = ({
  matchId,
  team,
  updateTeam,
  removeTeam,
  scoreOverride,
  updateScoreOverride,
  orgTime,
}: Props) => {
  const selectedOrgId = useSelector((state: IStore) =>
    typeof team !== "string"
      ? selectTeamOrgId(state, team.teamId, orgTime)
      : undefined
  );

  const tournament = useSelector((state: IStore) =>
    selectTournamentFromMatchId(state, matchId)
  )?.data;

  const teamIds = useSelector((state: IStore) =>
    !!tournament?.teamIds ? tournament.teamIds : Object.keys(state.team.teams)
  );

  const teams = useSelector((state: IStore) => state.team.teams);
  const teamOptions = useSelector((state: IStore) => [
    { id: "TBD", display: "TBD" },
    { id: "BYE", display: "BYE" },
    ...selectTeamOptions(state, teamIds, orgTime),
  ]);

  const driverOptions =
    useSelector((state: IStore) =>
      typeof team !== "string" && !!team.teamId
        ? selectDriverOptionsFromTeamId(state, team.teamId, orgTime)
        : undefined
    ) ?? [];

  return (
    <CenterFlexColumn>
      <OrgLogoContainer>
        <OrgLogo key={selectedOrgId} orgId={selectedOrgId} />
      </OrgLogoContainer>
      <IdSelectField
        value={typeof team === "string" ? team : team.teamId}
        options={teamOptions}
        args={{ includeEmptyOption: false }}
        update={(newVal) => {
          if (newVal === "TBD" || newVal === "BYE") {
            updateTeam(newVal);
          } else {
            // todo - put into a useEffect and use the existing driverOptions to avoid having to get 'teams' in its entirety
            const activeDriverIds = !!newVal
              ? getTeamActiveDriverIds(teams[newVal].data, orgTime)
              : [];

            updateTeam({
              driverIds: activeDriverIds.length === 1 ? activeDriverIds : [],
              disqualified: undefined,
              forfeited: undefined,
              teamId: nonEmpty(newVal),
            });
          }
        }}
      />
      {typeof team !== "string" && !!team.teamId && (
        <>
          <CenterFlexRow style={{ width: "100%", overflowX: "auto" }}>
            {team.driverIds?.map((driverId, driverIdx) => (
              <IdSelectField
                value={driverId}
                options={driverOptions}
                update={(driverId) =>
                  updateTeam({
                    ...team,
                    driverIds: arrayReplaceAt(
                      team.driverIds ?? [],
                      nonEmpty(driverId),
                      driverIdx
                    ),
                  })
                }
              />
            ))}
            <Button
              disabled={!team.teamId}
              onClick={() => {
                if (team.teamId === undefined) {
                  return;
                }

                updateTeam({
                  ...team,
                  driverIds: [...(team.driverIds ?? []), undefined],
                });
              }}
            >
              +
            </Button>
            <Button
              disabled={(team.driverIds?.length ?? 0) === 0}
              onClick={() => {
                if (team.teamId === undefined) {
                  return;
                }
                updateTeam({
                  ...team,
                  driverIds: team.driverIds?.slice(0, -1),
                });
              }}
            >
              -
            </Button>
          </CenterFlexRow>
          <LabelledField label="Forfeited">
            <CheckboxField
              value={team.forfeited}
              update={(forfeited) => updateTeam({ ...team, forfeited })}
              args={{ falseAsUndefined: true }}
            />
          </LabelledField>
          <LabelledField label="Score Override">
            <NumberField
              value={scoreOverride}
              update={(scoreOverride) =>
                updateScoreOverride(team.teamId, scoreOverride)
              }
              args={{ width: "60px" }}
            />
          </LabelledField>
        </>
      )}
      <button
        style={{ marginTop: "2px", fontSize: "7pt" }}
        onClick={removeTeam}
      >
        remove
      </button>
    </CenterFlexColumn>
  );
};
