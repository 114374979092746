import { checkNaN } from "Utility";

export const mapStringToPointsDistribution = (str: string | undefined) => {
  if (!str) {
    return undefined;
  }

  const pointsDistribution: number[] = [];
  const parts = str.split(",");
  parts.forEach((p) => {
    if (!!p.replaceAll(" ", "")) {
      const partNumber = checkNaN(parseInt(p));
      pointsDistribution.push(!!partNumber ? partNumber : 0);
    }
  });

  return pointsDistribution;
};
