import { IStore } from "redux/store";

export const selectUsername = (state: IStore, uid: string) => {
  return state.users.usernames[uid];
};

export const selectUsernamesToFetch = (state: IStore, uids: string[]) => {
  const fetchedUsernames = Object.keys(state.users.usernames);
  const fetchingUsernames = state.users.usernamesBeingFetched;
  return uids.filter(
    (uid) => !fetchedUsernames.includes(uid) && !fetchingUsernames.includes(uid)
  );
};
