import styled from "styled-components";
import { Button } from "styles/elements";
import { FlexRow } from "styles/layout";

export const AddButton = styled(Button)`
  font-size: 8pt;
`;

export const ValuesContainer = styled(FlexRow)`
  flex-wrap: wrap;
  max-width: 500px;
`;

export const Value = styled(FlexRow)`
  border: 1px solid gray;
  text-align: center;
  min-width: 50px;
  padding: 2px;
`;

export const RemoveButton = styled(Button)`
  font-size: 8pt;
  margin-left: auto;
`;
