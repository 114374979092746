import styled from "styled-components";
import { Theme } from "theme";

export const DataGridTable = styled.table`
  text-align: center;
  border-collapse: collapse;
  width: 100%;
`;

const DataGridTr = styled.tr<{ thinner: boolean }>`
  cursor: default;

  td {
    padding: 4px 3px;
    white-space: nowrap;

    ${({ thinner }) => thinner && "padding: 1px 3px;"}
    @media (max-width: 700px) {
      padding: 2px;
      ${({ thinner }) => thinner && "padding: 1px 2px;"}
    }
  }

  th {
    padding: 4px 3px;
    white-space: nowrap;
    border-right: ${Theme.panelContentBorder};

    @media (max-width: 700px) {
      padding: 4px 2px;
    }

    &:last-child {
      border-right: 0;
    }
  }
`;

export const PaddingTh = styled.th`
  min-width: 4px;
  max-width: 4px;

  @media (max-width: 700px) {
  }
`;

export const PaddingTd = styled.td`
  min-width: 4px;
  max-width: 4px;

  @media (max-width: 700px) {
    width: ${Theme.smallPanelPadding};
  }
`;

export const DataGridHeaderTr = styled(DataGridTr)`
  background: ${Theme.tabBackground};
`;

export const DataGridDataTr = styled(DataGridTr)<{ clickable: boolean }>`
  border: ${Theme.panelContentBorder};
  border-width: 1px 0 0 0;

  ${({ clickable }) =>
    clickable &&
    `cursor: pointer;  &:hover {
  background: ${Theme.tabHoverBackground};
}`}
`;

export const DataGridTh = styled.th<{
  clickable: boolean;
  highlight: boolean;
  highlightSort: boolean;
}>`
  @media (max-width: 700px) {
    font-size: 10.5pt;
  }

  ${({ clickable }) =>
    clickable &&
    `cursor: pointer;  &:hover {
    background: ${Theme.tabHoverBackground};
  }`}

  ${({ highlight }) =>
    highlight && `background: ${Theme.thHighlightBackground};`}
    
  ${({ highlightSort }) =>
    highlightSort &&
    `background: ${Theme.tabSelectedBackground};
  color: ${Theme.tabSelectedForeground};
`}
`;

export const DataGridTd = styled.td<{ highlight: boolean }>`
  ${({ highlight }) =>
    highlight &&
    `background: ${Theme.tdHighlightBackground}; color: ${Theme.tdHighlightForeground}; font-weight: 500;`}
`;

export const DataCell = styled(DataGridTd)`
  font-size: 0;
`;

export const Data = styled.span`
  font-size: 12pt;
  @media (max-width: 1000px) {
    font-size: 11pt;
  }
`;

export const SortArrowContainer = styled.div`
  width: 10px;
  padding: 2px 4px 0 4px;
  font-size: 7pt;

  @media (max-width: 700px) {
    font-size: 6pt;
    width: 8px;
  }
`;

export const PaginationContainer = styled.div`
  border-top: ${Theme.panelContentBorder};
  padding: 4px 0;
`;

export const FilterInputContainer = styled.div`
  padding: 10px 3px;
  margin-left: 10px;
  input {
    margin: auto;
    font-size: 11.5pt;
    width: 300px;
    padding: 4px;
  }
`;
