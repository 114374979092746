import { FsLivesRules } from "db/model/match/Set/Knockout/FsLivesRules";

export const getTrackLivesRules = (rules: FsLivesRules[], trackNum: number) => {
  return rules.find((r) => {
    if (!r.startTrack) {
      return false;
    }

    if (!r.endTrack) {
      return r.startTrack === trackNum;
    }

    return r.startTrack >= trackNum && r.endTrack <= trackNum;
  });
};

export const getLivesLost = (
  rules: FsLivesRules,
  numDrivers: number,
  position: number
) => {
  const bestWinningPos = numDrivers - (rules.numDrivers ?? 1);
  if (position > bestWinningPos) {
    return rules.livesLost ?? 1;
  }

  return 0;
};
