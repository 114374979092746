import { Button } from "styles/elements";
import { FlexColumn } from "styles/layout";
import { arrayRemoveAt, arrayReplaceAt, getNextStringKey } from "Utility";
import { MatchFormatEditor } from "./MatchFormatEditor";
import {
  TeMatchFormat,
  newTeMatchFormat,
} from "model/TournamentEditor/TeMatchFormat";

interface Props {
  matchFormats: TeMatchFormat[];
  setMatchFormats: (formats: TeMatchFormat[]) => void;
}

export const MatchFormats = ({ matchFormats, setMatchFormats }: Props) => {
  const addNewMatchFormat = () => {
    const newKey = getNextStringKey(matchFormats.map((m) => m.key));
    setMatchFormats([...matchFormats, newTeMatchFormat(newKey)]);
  };

  const removeMatchFormat = (idx: number) => {
    setMatchFormats(arrayRemoveAt(matchFormats, idx));
  };

  return (
    <FlexColumn style={{ rowGap: "10px" }}>
      {matchFormats.map((matchFormat, idx) => (
        <MatchFormatEditor
          key={matchFormat.key}
          matchFormat={matchFormat}
          removeMatchFormat={() => removeMatchFormat(idx)}
          update={(format) =>
            setMatchFormats(arrayReplaceAt(matchFormats, format, idx))
          }
        />
      ))}
      <Button style={{ marginTop: "5px" }} onClick={addNewMatchFormat}>
        +
      </Button>
    </FlexColumn>
  );
};
