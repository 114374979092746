import { useSelector } from "react-redux";
import { auth } from "firebase-config";
import { IStore } from "redux/store";
import {
  AccountArrow,
  DropdownButton,
  DropdownContainer,
  DropdownContent,
  DropdownItem,
} from "./styles";

export const AccountDropdown = () => {
  const { username } = useSelector((state: IStore) => state.user);

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        console.log("Signed Out");
      })
      .catch((e) => {
        console.error("Sign Out Error", e);
      });
  };

  return (
    <DropdownContainer>
      <DropdownButton>
        {username} <AccountArrow>&#x25BC;</AccountArrow>
      </DropdownButton>
      <DropdownContent>
        {/* <DropdownItem onClick={toAccount}>Account</DropdownItem> */}
        <DropdownItem onClick={handleSignOut}>Sign Out</DropdownItem>
      </DropdownContent>
    </DropdownContainer>
  );
};
