import { IStore } from "redux/store";
import { updateDrivers } from "redux/DriverSlice";
import { updateMatches } from "redux/MatchSlice";
import { updateTracks } from "redux/TrackSlice";
import { updateTours } from "redux/TourSlice";
import { updateTeams } from "redux/TeamSlice";
import { updatePickemDetails } from "redux/PickemDetailsSlice";
import { updateOrgs } from "redux/OrgSlice";
import {
  driverCollectionName,
  matchCollectionName,
  orgCollectionName,
  pickemDetailsCollectionName,
  teamCollectionName,
  tourCollectionName,
  trackCollectionName,
} from "db/info/collectionNames";
import { setupSnapshot } from "db/info/core";

export const onStart = () => {
  setupSnapshot(driverCollectionName, updateDrivers);
  setupSnapshot(matchCollectionName, updateMatches);
  setupSnapshot(orgCollectionName, updateOrgs);
  setupSnapshot(pickemDetailsCollectionName, updatePickemDetails);
  setupSnapshot(teamCollectionName, updateTeams);
  setupSnapshot(tourCollectionName, updateTours);
  setupSnapshot(trackCollectionName, updateTracks);
};

export const selectInitialLoadComplete = (state: IStore) => {
  return (
    state.driver.initialFetchComplete &&
    state.match.initialFetchComplete &&
    state.org.initialFetchComplete &&
    state.pickemDetails.initialFetchComplete &&
    state.team.initialFetchComplete &&
    state.tour.initialFetchComplete &&
    state.track.initialFetchComplete
  );
};
