import { FsRoundsSegmentGroupBase } from "db/model/tournament/Segment/FsRoundsSegmentGroup";
import { FsSeason } from "db/model/tournament/Segment/Season/FsSeason";
import { FsSwiss } from "db/model/tournament/Segment/Swiss/FsSwiss";
import { IdData } from "model/IdData";
import {
  TeMatch,
  getMatchIdsFromTeMatches,
} from "model/TournamentEditor/TeMatch";
import {
  mapTeSegmentGroupBaseToFsData,
  newTeSegmentGroupBase,
  TeSegmentGroupBase,
} from "../TeSegmentGroup";
import { newTeRound, TeRound } from "./TeRound";

export type TeRoundsGroup = TeSegmentGroupBase & {
  rounds: TeRound[];
  roundsPrefix: string | undefined;
};

export const newTeRoundsGroup = (): TeRoundsGroup => {
  return {
    ...newTeSegmentGroupBase(),
    rounds: [newTeRound(1)],
    roundsPrefix: "Round",
  };
};

export const mapTeRoundsGroupsToFsData = (
  teGroups: TeRoundsGroup[],
  teMatchesWithIds: IdData<TeMatch>[]
): FsRoundsSegmentGroupBase[] => {
  return teGroups.map((g) => mapTeRoundsGroupToFsData(g, teMatchesWithIds));
};

const mapTeRoundsGroupToFsData = (
  teGroup: TeRoundsGroup,
  teMatchesWithIds: IdData<TeMatch>[]
): FsRoundsSegmentGroupBase => {
  return {
    ...mapTeSegmentGroupBaseToFsData(teGroup),
    roundPrefix: teGroup.roundsPrefix,
    rounds: teGroup.rounds.map((r) => ({
      roundNum: r.roundNum,
      matchIds: getMatchIdsFromTeMatches(r.matches, teMatchesWithIds),
    })),
  };
};

export const mapFsRoundsGroupToTeRoundsGroup = (
  fsRounds: FsSeason | FsSwiss
): TeRoundsGroup => {
  return {
    groupId: fsRounds.groupId,
    name: fsRounds.name,
    rounds:
      fsRounds.rounds?.map((r) => ({
        matches: r.matchIds ?? [],
        roundNum: r.roundNum,
      })) ?? [],
    roundsPrefix: fsRounds.roundPrefix,
  };
};
