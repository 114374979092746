import { nonUndefined } from "Utility";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { Scores } from "fsModel/Match/Scores";
import { isPlayCondition } from "fsModel/Match/PlayCondition";
import { isPlayConditionComplete } from "../MatchSet";
import { FsWorldTourSet } from "db/model/match/Set/WorldTour/FsWorldTourSet";
import {
  getWorldTourTrackFinalPositions,
  getWorldTourTrackScores,
  isWorldTourTrackComplete,
} from "./Track";
import { calculateFinalPositions } from "fsModel/Match/Positions";
import { getWinnerIds } from "fsModel/Match/Results";

const NUM_WINNERS = 1;

export const getWorldTourSetScores = (
  set: FsWorldTourSet,
  teams: FsMatchTeam[]
): Scores => {
  const teamIds = nonUndefined(teams.map((t) => t.teamId));
  const scores: Scores = {};

  const scoring = set.format?.scoring;
  const winReq = set.format?.trackWinReq;

  const trackWinnerIds: string[][] = [];
  if (!!scoring && !!winReq) {
    set.tracks?.forEach((t) => {
      const scores = getWorldTourTrackScores(t, teams, scoring);
      const isComplete = isWorldTourTrackComplete(t, scores, winReq);
      const finalPositions = getWorldTourTrackFinalPositions(
        scores,
        isComplete
      );
      const winnerIds = getWinnerIds(finalPositions, NUM_WINNERS);
      if (!!winnerIds) {
        trackWinnerIds.push(winnerIds);
      }
    });
  }

  // Below logic same as Matchmaking equivalent
  for (var i = 0; i < teamIds.length; i++) {
    const teamId = teamIds[i];
    const scoreOverride = !!set.scoreOverrides
      ? set.scoreOverrides[teamId]
      : undefined;
    if (scoreOverride !== undefined) {
      scores[teamId] = scoreOverride;
    } else {
      scores[teamId] = trackWinnerIds.filter((winnerIds) =>
        winnerIds.includes(teamId)
      ).length;
    }
  }

  return scores;
};

export const isWorldTourSetComplete = (set: FsWorldTourSet, scores: Scores) => {
  if (set.overrideComplete) {
    return true;
  }

  const playCondition = set.format?.playCondition;
  const numTracks = set.format?.numTracks;
  const winReq = set.format?.trackWinReq;
  if (!winReq || !numTracks || !isPlayCondition(playCondition)) {
    return false;
  }

  const numCompleteTracks =
    set.tracks?.filter((t) => isWorldTourTrackComplete(t, scores, winReq))
      .length ?? 0;

  return isPlayConditionComplete(
    scores,
    playCondition,
    numTracks,
    numCompleteTracks
  );
};

export const getWorldTourSetFinalPositions = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

// todo - Add tiebreaker logic here
const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
