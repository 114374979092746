export type FsTrackPick = {
  pickingTeamId: string | undefined;
  pickType: string | undefined;
  trackId: string | undefined;
};

export type PickType = "Pick" | "Ban" | "Random";
export const pickTypes = ["Pick", "Ban", "Random"];
export const isPickType = (value: string | undefined): value is PickType => {
  if (!value) {
    return false;
  }

  return pickTypes.includes(value);
};
