import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsPointsDistribution } from "db/model/match/Set/FsPointsDistribution";
import { FsMatchTrack } from "db/model/match/Track/FsMatchTrack";
import { Scores } from "fsModel/Match/Scores";
import { getMatchmakingRoundResults } from "./Round";
import { nonUndefined } from "Utility";
import { calculateFinalPositions } from "fsModel/Match/Positions";

export const getMatchmakingTrackScores = (
  track: FsMatchTrack,
  teams: FsMatchTeam[],
  pointsDist: FsPointsDistribution
): Scores => {
  const teamIds = nonUndefined(teams.flatMap((t) => t.teamId));
  const results = (track.rounds ?? []).flatMap((round) =>
    getMatchmakingRoundResults(round.times ?? [], teams, pointsDist)
  );

  const scores: Scores = {};
  for (var i = 0; i < teamIds.length; i++) {
    const teamId = teamIds[i];
    const scoreOverride = !!track.scoreOverrides
      ? track.scoreOverrides[teamId]
      : undefined;
    if (scoreOverride !== undefined) {
      scores[teamId] = scoreOverride;
    } else {
      const teamResults = results.filter((r) => r.teamId === teamId);
      scores[teamId] = teamResults.reduce((a, b) => a + b.trackPoints, 0);
    }
  }

  return scores;
};

export const isMatchmakingTrackComplete = (
  track: FsMatchTrack,
  roundsLimit: number | undefined,
  scores: Scores,
  winReq: number
) => {
  if (track.overrideComplete) {
    return true;
  }

  const roundsPlayed = (track.rounds?.filter((r) => !!r.times?.length) ?? [])
    .length;

  if (!!roundsLimit && roundsPlayed >= roundsLimit) {
    return true;
  }

  return Math.max(...Object.values(scores), 0) >= winReq;
};

export const getMatchmakingTrackResults = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
