import { RecentMatchesIds } from "components/Matches/RecentMatchesIds";
import { UpcomingMatchesIds } from "components/Matches/UpcomingMatchesIds";
import { Panel } from "components/Panel/Panel";
import { TabBar } from "components/TabBar/TabBar";
import { Filter } from "model/Filter";
import { useState } from "react";

interface Props {
  matchIds: string[];
}

export const TournamentMatchesTab = ({ matchIds }: Props) => {
  const filterOptions = ["Recent", "Upcoming"];
  const [selectedTab, setSelectedTab] = useState(filterOptions[0]);
  const tabFilter: Filter<string> = {
    options: filterOptions,
    selectedValue: selectedTab,
    setSelectedValue: setSelectedTab,
  };

  return (
    <>
      <Panel>
        <TabBar filter={tabFilter} isSecondaryTabBar={true} />
      </Panel>
      {getTabContent(selectedTab, matchIds)}
    </>
  );
};

const getTabContent = (selectedTab: string, matchIds: string[]) => {
  switch (selectedTab) {
    case "Recent":
      return <RecentMatchesIds matchIds={matchIds} />;
    case "Upcoming":
      return <UpcomingMatchesIds matchIds={matchIds} />;
    default:
      return <></>;
  }
};
