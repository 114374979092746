import { MatchList } from "components/MatchList/MatchList";
import { Panel } from "components/Panel/Panel";
import format from "date-fns/format";
import { MatchesContainer } from "./styles";
import { splitMatchesByDay } from "fsModel/Match/Match";
import { Match } from "model/IdData";

interface Props {
  matches: Match[];
}

export const RecentMatches = ({ matches }: Props) => {
  const { matchesByDate } = splitMatchesByDay(matches);
  const sortedDateKeys = Array.from(matchesByDate.keys()).sort(sortDateKeys);

  return (
    <MatchesContainer>
      {sortedDateKeys.map((k) => (
        <Panel header={getPanelHeader(k)}>
          <MatchList matches={matchesByDate.get(k)!} ascending={false} />
        </Panel>
      ))}
    </MatchesContainer>
  );
};

const sortDateKeys = (
  a: { day: number; monthIdx: number; year: number },
  b: { day: number; monthIdx: number; year: number }
) => {
  const aDate = new Date(a.year, a.monthIdx, a.day);
  const bDate = new Date(b.year, b.monthIdx, b.day);
  return bDate.getTime() - aDate.getTime();
};

const getPanelHeader = (dateParts: {
  day: number;
  monthIdx: number;
  year: number;
}) => {
  const date = new Date(dateParts.year, dateParts.monthIdx, dateParts.day);
  const formattedDate = format(date, "MMMM do, yyyy");

  return `${formattedDate}`;
};
