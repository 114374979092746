import {
  ctorDataGridColumnBase,
  DataGridColumn,
  DataGridColumnBase,
  DataGridColumnBaseCtorArgs,
} from "../DataGridColumn";

export type Action<T> = {
  onAction: (arg: T) => Promise<void>;
  textGetter: (arg: T) => string;
};

export type ActionColumn<T> = DataGridColumnBase & {
  actionsGetter: (arg: T) => Action<T>[];
};

interface CtorArgs<T> extends DataGridColumnBaseCtorArgs {
  actionsGetter: (arg: T) => Action<T>[];
}

export function ctorActionColumn<T>(args: CtorArgs<T>): ActionColumn<T> {
  return {
    ...ctorDataGridColumnBase(args),
    actionsGetter: args.actionsGetter,
    sortable: false,
  };
}

export function isActionColumn<T>(
  column: DataGridColumn<T>
): column is ActionColumn<T> {
  return (column as ActionColumn<T>).actionsGetter !== undefined;
}
