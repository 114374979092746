import {
  TeTournamentSegment,
  teSegmentTypes,
  TeSegmentType,
  newTeTournamentSegment,
} from "model/TournamentEditor/Segment/TeTournamentSegment";
import { MatchFormats } from "../MatchFormat/MatchFormats";
import { SegmentArgs } from "./SegmentArgs/SegmentArgs";
import { Panel } from "components/Panel/Panel";
import { Button } from "styles/elements";
import { FieldRow, StyledInput, StyledSelect } from "components/Fields/styles";
import { useSelector } from "react-redux";
import { selectIsEcmStaff } from "selectors/User";
import { TeMatchFormat } from "model/TournamentEditor/TeMatchFormat";
import { useState } from "react";

interface Props {
  segment: TeTournamentSegment;
  matchFormats: TeMatchFormat[];
  setMatchFormats: (newFormats: TeMatchFormat[]) => void;
  removeSegment: () => void;
  update: (newSegment: TeTournamentSegment) => void;
}

export const Segment = ({
  segment,
  matchFormats,
  setMatchFormats,
  removeSegment,
  update,
}: Props) => {
  const isEcmStaff = useSelector(selectIsEcmStaff);
  const [type, setType] = useState<TeSegmentType>(segment.type);
  const updateSegmentType = (type: TeSegmentType) => {
    update(newTeTournamentSegment(type));
  };

  return (
    <>
      <Panel header="Match Formats" padding="padded">
        <MatchFormats
          matchFormats={matchFormats}
          setMatchFormats={setMatchFormats}
        />
      </Panel>
      <Panel padding="padded">
        <FieldRow>
          <StyledSelect
            style={{ width: "150px" }}
            onChange={(e) => {
              const newSegmentType = e.target.value as TeSegmentType;
              if (newSegmentType !== type) {
                setType(newSegmentType);
                updateSegmentType(newSegmentType);
              }
            }}
            value={type}
          >
            {teSegmentTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </StyledSelect>
        </FieldRow>
        <FieldRow>
          {isEcmStaff && (
            <StyledInput
              value={segment.segmentId}
              onChange={(e) =>
                update({ ...segment, segmentId: e.target.value })
              }
              placeholder="Id"
            />
          )}
          <StyledInput
            value={segment.name}
            onChange={(e) => update({ ...segment, name: e.target.value })}
            placeholder="Name"
          />
          <Button onClick={removeSegment} style={{ marginLeft: "auto" }}>
            Remove Segment
          </Button>
        </FieldRow>
        <SegmentArgs
          matchFormats={matchFormats}
          segment={segment}
          update={update}
        />
      </Panel>
    </>
  );
};
