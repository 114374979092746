import { TeTeam } from "model/TournamentEditor/TeTeam";
import { TeamDrivers } from "./Drivers/TeamDrivers";
import { TeDriver } from "model/TournamentEditor/TeDriver";
import { TeamOrg } from "./TeamOrg/TeamOrg";
import { TeOrg } from "model/TournamentEditor/TeOrg";
import { Button } from "styles/elements";

interface Props {
  team: TeTeam;
  updateTeam: (team: TeTeam) => void;
  removeTeam: () => void;
}

export const Team = ({ team, updateTeam, removeTeam }: Props) => {
  return (
    <>
      <TeamOrg
        org={team.org}
        updateOrg={(org: string | TeOrg | undefined) => {
          team.org = org;
          updateTeam(team);
        }}
      />
      <TeamDrivers
        drivers={team.drivers}
        updateDrivers={(drivers: (string | TeDriver | undefined)[]) => {
          team.drivers = drivers;
          updateTeam(team);
        }}
      />
      <Button onClick={removeTeam}>Remove Team</Button>
    </>
  );
};
