import { FsTournament } from "db/model/tournament/FsTournament";
import { getSegmentMatchIds } from "./Segment/TournamentSegment";

export type TournamentStatus = "Upcoming" | "Ongoing" | "Completed";

export const getTournamentMatchIds = (tournament: FsTournament) => {
  return tournament.segments?.flatMap((s) => getSegmentMatchIds(s) ?? []) ?? [];
};

export const getCombinedTournamentSegmentNames = (tournament: FsTournament) => {
  if (!tournament.segments) {
    return [];
  }

  const segmentNames: string[] = [];
  const segmentIdsAccountedFor: string[] = [];
  for (let i = 0; i < tournament.segments.length; i++) {
    const { segmentId, name } = tournament.segments[i];
    if (!segmentId || !name || segmentIdsAccountedFor.includes(segmentId)) {
      continue;
    }

    const segmentLink = tournament.segmentLinks?.find(
      (l) => l.segmentIds?.includes(segmentId) ?? false
    );

    if (!segmentLink || !segmentLink.name || !segmentLink.segmentIds) {
      segmentNames.push(name);
      segmentIdsAccountedFor.push(segmentId);
    } else {
      segmentNames.push(segmentLink.name);
      segmentIdsAccountedFor.push(...segmentLink.segmentIds);
    }
  }

  return segmentNames;
};

export const getTournamentSegmentIdsFromName = (
  tournament: FsTournament,
  name: string
) => {
  const segmentLink = tournament.segmentLinks?.find((l) => l.name === name);
  if (!!segmentLink) {
    return segmentLink.segmentIds;
  }

  const segmentId = tournament.segments?.find(
    (s) => s.name === name
  )?.segmentId;
  return !!segmentId ? [segmentId] : undefined;
};
