import { nonUndefined } from "Utility";
import { FsDriver } from "db/model/driver/FsDriver";

export type TeDriver = {
  country: string | undefined;
  nickname: string | undefined;
  peripheral: string;
  ubisoftUid: string | undefined;
};

export const newTeDriver = (): TeDriver => {
  return {
    country: undefined,
    nickname: undefined,
    peripheral: "unknown",
    ubisoftUid: undefined,
  };
};

export const mapTeDriverToFsData = (
  driver: TeDriver,
  teamId: string
): FsDriver => {
  return {
    country: driver.country,
    name: undefined,
    nickname: driver.nickname,
    peripheral: driver.peripheral,
    teamHistory: [teamId],
    ubisoftUids: nonUndefined([driver.ubisoftUid]),
  };
};
