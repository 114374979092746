import { CenterFlexRow } from "styles/layout";
import { SwitcherOption } from "./styles";

export type SwitchOption<T> = {
  display: JSX.Element | string;
  selectable: boolean;
  title?: string;
  value?: T;
  onClick?: () => void;
};

interface Props<T> {
  options: SwitchOption<T>[];
  selectedValue: T;
  setSelectedValue: React.Dispatch<React.SetStateAction<T>>;
}

export function Switcher<T>({
  options,
  selectedValue,
  setSelectedValue,
}: Props<T>) {
  return (
    <CenterFlexRow>
      {options.map((o, i) => (
        <SwitcherOption
          key={`${i} ${o.display.toString()}`}
          selectable={o.selectable}
          selected={o.value === selectedValue}
          hasOnClick={!!o.onClick}
          title={o.title}
          onClick={() => {
            if (o.onClick) {
              o.onClick();
            }

            if (o.selectable) {
              if (o.value !== undefined) {
                setSelectedValue(o.value);
              }
            }
          }}
        >
          {o.display}
        </SwitcherOption>
      ))}
    </CenterFlexRow>
  );
}
