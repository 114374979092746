import { FsReverseCupModeSet } from "db/model/match/Set/CupMode/Reverse/FsReverseCupModeSet";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { Scores, applyScoreOverrides } from "fsModel/Match/Scores";
import { applyReverseCupModeTrackScore } from "./Track";
import { calculateFinalPositions } from "fsModel/Match/Positions";

export const getReverseCupModeSetScores = (
  set: FsReverseCupModeSet,
  teams: FsMatchTeam[],
  numTeams: number
): Scores => {
  const scores: Scores = {};
  const points = set.format?.points;
  const startingPoints = set.format?.startingPoints;
  if (!!set.tracks && !!points && !!startingPoints) {
    set.tracks.forEach((t) =>
      applyReverseCupModeTrackScore(t, teams, scores, points, startingPoints)
    );
  }

  const forfeitTeams = teams.filter((t) => t.forfeited);
  forfeitTeams.forEach((t) => {
    if (!!t.teamId) {
      scores[t.teamId] = numTeams * -1;
    }
  });

  applyScoreOverrides(scores, set.scoreOverrides);

  return scores;
};

export const isReverseCupModeSetComplete = (
  set: FsReverseCupModeSet,
  scores: Scores,
  numTeams: number
) => {
  if (set.overrideComplete) {
    return true;
  }

  const playTillLeft = set.format?.playTillLeft;
  if (!playTillLeft) {
    return false;
  }

  const numFinished = Object.values(scores).filter((s) => s < 0).length;
  return numTeams - numFinished <= playTillLeft;
};

export const getReverseCupModeSetFinalPositions = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
