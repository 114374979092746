import { arrayReplaceAt, getNextStringKey } from "Utility";
import { Button } from "styles/elements";
import { FlexColumn } from "styles/layout";
import {
  TeBracketMatch,
  newTeBracketMatch,
} from "model/TournamentEditor/Segment/Bracket/TeBracketMatch";
import { BracketMatch } from "../BracketMatch";
import { BracketType } from "fsModel/Bracket/Bracket";
import { TeMatchFormat } from "model/TournamentEditor/TeMatchFormat";

interface Props {
  matchFormats: TeMatchFormat[];
  bracketMatches: TeBracketMatch[];
  updateBracketMatches: (matches: TeBracketMatch[]) => void;
  bracketType: BracketType | undefined;
}

export const BracketMatchList = ({
  matchFormats,
  bracketMatches,
  updateBracketMatches,
  bracketType,
}: Props) => {
  const onAddMatchClick = () => {
    updateBracketMatches([
      ...bracketMatches,
      newTeBracketMatch(
        getNextStringKey(
          bracketMatches.map((m) => m.matchKey),
          true
        ),
        bracketType
      ),
    ]);
  };

  const onRemoveMatchClick = (match: TeBracketMatch) => {
    updateBracketMatches(bracketMatches.filter((m) => m !== match));
  };

  const updateMatch = (newMatch: TeBracketMatch, matchIdx: number) => {
    updateBracketMatches(arrayReplaceAt(bracketMatches, newMatch, matchIdx));
  };

  // todo - extract shared fields with MatchList?
  return (
    <FlexColumn>
      {bracketMatches.map((bracketMatch, bracketMatchIdx) => (
        <BracketMatch
          matchFormats={matchFormats}
          bracketMatch={bracketMatch}
          bracketMatchKeys={bracketMatches.map((m) => m.matchKey)}
          updateMatch={(newMatch) => updateMatch(newMatch, bracketMatchIdx)}
          removeMatch={() => onRemoveMatchClick(bracketMatch)}
        />
      ))}
      <Button onClick={onAddMatchClick}>Add Match</Button>
    </FlexColumn>
  );
};
