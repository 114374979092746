import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Theme } from "theme";

export const NavBarContainer = styled.nav`
  background-color: ${Theme.navBackground};
`;

export const NavOptions = styled.div`
  display: flex;
  flex-direction: row;
  height: 45px;
  line-height: 45px;
`;

export const HomeNavItem = styled(NavLink)`
  cursor: pointer;
  padding: 5px 25px;
  display: flex;
  &:hover {
    filter: brightness(1.1) drop-shadow(0 0 30px ${Theme.navHomeHover});
    transform: scale(1.02);
  }

  @media (max-width: 700px) {
    margin-left: 5px;
  }
`;

export const LogoImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const NavItem = styled(NavLink)`
  font-size: 13pt;
  text-align: center;
  color: ${Theme.navForeground};
  padding: 0 20px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: ${Theme.navHoverBackground};
    color: ${Theme.navHoverForeground};
  }

  &.active {
    background: ${Theme.navActiveBackground};
    color: ${Theme.navActiveForeground};
    border-bottom: 2px solid ${Theme.navActiveForeground};

    &:hover {
      background: ${Theme.navActiveHoverBackground};
    }
  }

  display: block;
`;

export const NavBarAccountContainer = styled.div`
  margin-left: auto;
  height: 100%;
`;

export const LoginButton = styled.button`
  background: lightgray;
  border-radius: 8px;
  border: 0.5px solid black;
  cursor: pointer;
  padding: 4px 8px;
  margin: 8px;
  font-size: 11pt;
  &:hover {
    background: MediumSeaGreen;
  }
`;
