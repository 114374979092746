import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentChange, DocumentData } from "firebase/firestore";
import { IFetchSlice, initialFetchState } from "./FetchSlice";
import { FsMatch } from "db/model/match/FsMatch";
import { Match } from "model/IdData";

export interface IMatchSlice extends IFetchSlice {
  matches: { [key: string]: Match };
}

const initialState: IMatchSlice = {
  ...initialFetchState,
  matches: {},
};

// todo - should the FSModel -> Model mapping be done in the slice ??
export const matchSlice = createSlice({
  name: "match",
  initialState,
  reducers: {
    updateMatches: (
      state,
      action: PayloadAction<{
        docChanges: DocumentChange<DocumentData>[];
      }>
    ) => {
      const { docChanges } = action.payload;
      for (let i = 0; i < docChanges.length; i++) {
        const { doc } = docChanges[i];
        state.matches[doc.id] = { id: doc.id, data: doc.data() as FsMatch };
      }

      if (!state.initialFetchComplete) {
        state.initialFetchComplete = true;
      }
    },
  },
});

export const { updateMatches } = matchSlice.actions;
export const matchReducer = matchSlice.reducer;
