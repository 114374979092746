import styled from "styled-components";
import { Theme } from "theme";

export const DropdownButton = styled.button`
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 12pt;
  min-width: 120px;
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const AccountArrow = styled.span`
  font-size: 8pt;
  vertical-align: middle;
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background: white;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const DropdownItem = styled.button`
  color: black;
  padding: 10px 12px;
  text-decoration: none;
  background: transparent;
  display: block;
  width: 100%;
  border: none;
  cursor: pointer;
  font-size: 11pt;
  text-align: center;
  &:hover {
    background: ${Theme.navHoverBackground};
    color: ${Theme.navActiveForeground};
  }
`;

export const DropdownContainer = styled.div`
  height: 100%;
  cursor: pointer;

  &:hover {
    background: ${Theme.navHoverBackground};

    ${DropdownContent} {
      display: block;
    }
  }
`;
