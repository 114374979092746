import { Dispatch, SetStateAction } from "react";
import { Tab, TabButton, TabContainer, TabText } from "./styles";

export type TabBarOptionBase = {
  displayText: string;
};

type SelectableTabBarOption = TabBarOptionBase & {
  item: any;
};

type ButtonTabBarOption = TabBarOptionBase & {
  onClick: () => void;
};

export const isButtonTabBarOption = (
  option: TabBarOption
): option is ButtonTabBarOption => {
  return !!(option as ButtonTabBarOption).onClick;
};

export type TabBarOption = SelectableTabBarOption | ButtonTabBarOption;

interface Props {
  options: TabBarOption[];
  selectedTab: any;
  setSelectedTab: Dispatch<SetStateAction<any>>;
  isSecondaryTabBar?: boolean;
}
export const TabBarV2 = ({
  options,
  selectedTab,
  setSelectedTab,
  isSecondaryTabBar,
}: Props) => {
  return (
    <TabContainer>
      {options.map((option, optionIdx) => {
        return isButtonTabBarOption(option) ? (
          <TabButton
            key={`${optionIdx} - ${option.displayText}`}
            onClick={option.onClick}
          >
            {option.displayText}
          </TabButton>
        ) : (
          <Tab
            key={`${optionIdx} - ${option.displayText}`}
            selected={option.item === selectedTab}
            secondary={isSecondaryTabBar ?? false}
            onClick={() => setSelectedTab(option.item)}
          >
            <TabText>{option.displayText}</TabText>
          </Tab>
        );
      })}
    </TabContainer>
  );
};
