import { FsRoundsSet } from "db/model/match/Set/Rounds/FsRoundsSet";
import { newFsMatchSetBase } from "../FsMatchSet";
import { newFsRoundsFormat } from "./FsRoundsFormat";

export const newFsRoundsSet = (): FsRoundsSet => {
  return {
    ...newFsMatchSetBase(),
    format: newFsRoundsFormat(),
    tracks: [],
    type: "Rounds",
  };
};
