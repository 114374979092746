import { MatchesViewer } from "components/MatchesViewer/MatchesViewer";
import { Panel } from "components/Panel/Panel";
import { FsQualifier } from "db/model/tournament/Segment/Qualifier/FsQualifier";
import { GrowColumn } from "pages/styles";

interface Props {
  qualifier: FsQualifier;
  orgTime: number | undefined;
}

export const QualifierTabContent = ({ qualifier, orgTime }: Props) => {
  return (
    <GrowColumn>
      <Panel>
        <MatchesViewer matchIds={qualifier.matchIds ?? []} orgTime={orgTime} />
      </Panel>
    </GrowColumn>
  );
};
