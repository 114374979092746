import styled from "styled-components";
import { Theme } from "theme";

export const OverlayDimDiv = styled("div")<{ visible: boolean }>`
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  ${({ visible }) => visible && "visibility: visible"}
`;

export const Spinner = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  border: ${Theme.spinnerForeground};
  border-top: ${Theme.spinnerBackground};
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 2s linear infinite;
`;

export const FullPageSpinner = styled(Spinner)`
  width: 150px;
  height: 150px;
`;

export const ButtonSpinner = styled(Spinner)`
  border-width: ${Theme.spinnerSmallBorderWidth};
  width: 8px;
  height: 8px;
  margin: auto;
`;
