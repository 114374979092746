import styled from "styled-components";
import { FlexRow, FlexColumn } from "styles/layout";
import { Theme } from "theme";

export const FieldsContainer = styled(FlexRow)`
  column-gap: 10px;
`;

export const FieldColumn = styled(FlexColumn)`
  row-gap: 10px;
`;

export const GrowFieldColumn = styled(FieldColumn)`
  flex: 1;
`;

export const FieldRow = styled(FlexRow)`
  column-gap: 5px;
  white-space: nowrap;
  flex-wrap: wrap;
`;

export const StyledInput = styled.input`
  border-width: 0px 0px 2px 0px;
  border-color: ${Theme.styledInputBorderColor};
  outline: none;
  &:focus {
    border-color: ${Theme.styledInputBorderFocusColor};
  }
`;

export const StyledSelect = styled.select`
  border-width: 0px 0px 2px 0px;
  border-color: ${Theme.styledInputBorderColor};
  outline: none;
  &:focus {
    border-color: ${Theme.styledInputBorderFocusColor};
  }
`;
