import { Filter } from "model/Filter";
import { Tab, TabContainer, TabText } from "./styles";

interface Props {
  filter: Filter<string>;
  isSecondaryTabBar?: boolean;
}
export const TabBar = ({ filter, isSecondaryTabBar }: Props) => {
  return (
    <TabContainer>
      {filter.options.map((o) => (
        <Tab
          selected={filter.selectedValue === o}
          secondary={!!isSecondaryTabBar}
          onClick={() => filter.setSelectedValue(o)}
        >
          <TabText>{o}</TabText>
        </Tab>
      ))}
    </TabContainer>
  );
};
