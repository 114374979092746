import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsPointsDistribution } from "db/model/match/Set/FsPointsDistribution";
import { FsRoundTime } from "db/model/match/Track/FsRoundTime";
import { getPoints } from "../PointsDistribution";

export type MatchmakingRoundResult = {
  roundPoints: number;
  trackPoints: number;
  teamId: string;
};

export const getMatchmakingRoundResults = (
  times: FsRoundTime[],
  teams: FsMatchTeam[],
  pointsDist: FsPointsDistribution
) => {
  const teamRoundScores: { [teamId: string]: number } = {};
  for (var i = 0; i < teams.length; i++) {
    const { teamId, driverIds } = teams[i];
    if (!teamId || !driverIds) {
      continue;
    }
    const teamTimes = times.filter(
      (t) => !!t.driverId && driverIds.includes(t.driverId)
    );
    var teamPoints = 0;
    teamTimes.forEach(({ finishTimeMs, position }) => {
      if (!!position) {
        teamPoints += getPoints(pointsDist, position, finishTimeMs);
      }
    });

    teamRoundScores[teamId] = teamPoints;
  }

  return getRoundResults(teamRoundScores);
};

const getRoundResults = (teamRoundScores: {
  [teamId: string]: number;
}): MatchmakingRoundResult[] => {
  const results: MatchmakingRoundResult[] = [];
  const roundScores = Object.values(teamRoundScores);
  if (roundScores.length === 0) {
    return results;
  }

  const bestScore = Math.max(...roundScores);
  const bestIsPoint = roundScores.filter((s) => s === bestScore).length === 1;
  const teamIds = Object.keys(teamRoundScores);
  for (var i = 0; i < teamIds.length; i++) {
    const teamId = teamIds[i];
    const teamRoundPoints = teamRoundScores[teamId];
    const teamTrackPoints =
      teamRoundPoints > 0 && teamRoundPoints === bestScore && bestIsPoint
        ? 1
        : 0;

    const teamResult: MatchmakingRoundResult = {
      teamId,
      roundPoints: teamRoundPoints,
      trackPoints: teamTrackPoints,
    };

    results.push(teamResult);
  }

  return results;
};
