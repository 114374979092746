import { FsTimeAttackRoundWeighting } from "./FsTimeAttackRoundWeighting";
import {
  FsTimeAttackScoring,
  FsTimeAttackScoringBase,
} from "./FsTimeAttackScoring";

export type FsTimeAttackScoringAverage = FsTimeAttackScoringBase & {
  roundWeighting: FsTimeAttackRoundWeighting[] | undefined;
};

export const isFsTimeAttackScoringAverage = (
  scoring: FsTimeAttackScoring
): scoring is FsTimeAttackScoringAverage => {
  return scoring.type === "Average";
};
