export type FsPositionSetPointRules = {
  position: number[] | undefined;
};

export type FsWDLSetPointRules = {
  win: number | undefined;
  draw: number | undefined;
  loss: number | undefined;
};

export type FsSetPointRules = FsPositionSetPointRules | FsWDLSetPointRules;

export const isFsPositionSetPointRules = (
  rules: FsSetPointRules
): rules is FsPositionSetPointRules => {
  return (rules as FsPositionSetPointRules).position !== undefined;
};
