export type AdjustedTime = {
  dnfAdjusted: boolean;
  time: number;
};

export const getAdjustedTime = (
  time: number | undefined,
  dnfTime: number
): AdjustedTime | undefined => {
  if (!time) {
    return undefined;
  }

  if (time < 0) {
    return { dnfAdjusted: true, time: dnfTime };
  }

  return { dnfAdjusted: false, time };
};

export const combineAdjustedTimes = (
  adjustedTimes: AdjustedTime[]
): AdjustedTime => {
  return {
    time: adjustedTimes.reduce((a, b) => a + b.time, 0),
    dnfAdjusted: adjustedTimes.some((t) => t.dnfAdjusted),
  };
};
