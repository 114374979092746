import { arrayRemoveAt, arrayReplaceAt } from "Utility";
import { Button } from "styles/elements";
import { FlexColumn } from "styles/layout";
import { DriverKeys } from "components/MatchEditor/Teams/DriverKeyEditor";
import { IdDisplay } from "model/IdDisplay";
import { FsMatchTrack } from "db/model/match/Track/FsMatchTrack";
import { SetTrack } from "./SetTrack";

interface Props {
  tracks: FsMatchTrack[] | undefined;
  trackOptions: IdDisplay[];
  teamIds: string[];
  update: (tracks: FsMatchTrack[]) => void;
  driverKeys: DriverKeys;
  driverIds: string[];
}

export const SetTracks = ({
  tracks,
  trackOptions,
  teamIds,
  update,
  driverKeys,
  driverIds,
}: Props) => {
  return (
    <FlexColumn style={{ rowGap: "5px" }}>
      {tracks?.map((track, trackIdx) => (
        <div style={{ marginLeft: "8px" }}>
          <SetTrack
            track={track}
            trackOptions={trackOptions}
            teamIds={teamIds}
            update={(track) => update(arrayReplaceAt(tracks, track, trackIdx))}
            remove={() => update(arrayRemoveAt(tracks, trackIdx))}
            driverKeys={driverKeys}
            driverIds={driverIds}
          />
        </div>
      ))}
      <Button
        style={{ width: "120px", fontSize: "9pt", margin: "5px 0 0 10px" }}
        onClick={() =>
          update([
            ...(tracks ?? []),
            {
              overrideComplete: undefined,
              rounds: [],
              scoreOverrides: undefined,
              trackId: undefined,
            },
          ])
        }
      >
        Add Track
      </Button>
    </FlexColumn>
  );
};
