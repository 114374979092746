import { FsBracket } from "db/model/tournament/Segment/Bracket/FsBracket";
import { BracketType, isBracketType } from "fsModel/Bracket/Bracket";
import { IdData } from "model/IdData";
import { newTeMatch, TeMatch } from "model/TournamentEditor/TeMatch";
import { getNextStringKey } from "Utility";
import {
  mapTeSegmentGroupBaseToFsData,
  newTeSegmentGroupBase,
  TeSegmentGroupBase,
} from "../TeSegmentGroup";
import { mapTeBracketMatchToFsData, TeBracketMatch } from "./TeBracketMatch";

export type TeBracketGroup = TeSegmentGroupBase & {
  bracketType: BracketType | undefined;
  matches: TeBracketMatch[];
};

export const newTeBracketGroup = (): TeBracketGroup => {
  return {
    ...newTeSegmentGroupBase(),
    bracketType: undefined,
    matches: [],
  };
};

export const mapTeBracketGroupsToFsData = (
  teGroups: TeBracketGroup[],
  teMatchesWithIds: IdData<TeMatch>[]
): FsBracket[] => {
  return teGroups.map((g) => mapTeBracketGroupToFsData(g, teMatchesWithIds));
};

const mapTeBracketGroupToFsData = (
  teGroup: TeBracketGroup,
  teMatchesWithIds: IdData<TeMatch>[]
): FsBracket => {
  return {
    ...mapTeSegmentGroupBaseToFsData(teGroup),
    bracketType: teGroup.bracketType,
    matches: teGroup.matches.map((m) =>
      mapTeBracketMatchToFsData(m, teGroup.matches, teMatchesWithIds)
    ),
  };
};

export const mapFsBracketToTeBracketGroup = (
  fsBracket: FsBracket
): TeBracketGroup => {
  const keys: string[] = [];
  const matches: TeBracketMatch[] =
    fsBracket.matches?.map((m) => {
      const nextKey = getNextStringKey(keys, true);
      keys.push(nextKey);
      return {
        isFinal: m.isFinal ?? false,
        isUpperFinal: m.isUpperFinal ?? false,
        match: m.matchId ?? newTeMatch(),
        matchKey: nextKey,
        teamOneSourceKey: undefined,
        teamTwoSourceKey: undefined,
        teamSources: undefined,
      };
    }) ?? [];

  // assign the match keys for the TournamentEditor
  for (var i = 0; i < matches.length; i++) {
    const bracketMatch = matches[i];
    const originalMatch = fsBracket.matches?.find(
      (m) => m.matchId === bracketMatch.match
    );
    if (!originalMatch) {
      continue;
    }

    if (!!originalMatch.teamOneSource) {
      bracketMatch.teamOneSourceKey = matches.find(
        (m) => m.match === originalMatch.teamOneSource
      )?.matchKey;
    }

    if (!!originalMatch.teamTwoSource) {
      bracketMatch.teamTwoSourceKey = matches.find(
        (m) => m.match === originalMatch.teamTwoSource
      )?.matchKey;
    }

    if (!!originalMatch.teamSources) {
      originalMatch.teamSources.forEach((s) => {
        if (!bracketMatch.teamSources) {
          bracketMatch.teamSources = [];
        }
        bracketMatch.teamSources!.push({
          position: s.position,
          matchKey: matches.find((m) => m.match === s.source)?.matchKey,
        });
      });
    }
  }

  return {
    bracketType: isBracketType(fsBracket.bracketType)
      ? fsBracket.bracketType
      : undefined,
    groupId: fsBracket.groupId,
    matches,
    name: fsBracket.name,
  };
};
