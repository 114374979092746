// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDI4l06X26JXoam2H0nZ5u_TuizCaE-cjo",
  authDomain: "ecircuitmania.com",
  projectId: "fantasy-trackmania",
  storageBucket: "fantasy-trackmania.appspot.com",
  messagingSenderId: "764743991351",
  appId: "1:764743991351:web:b547b84db9f66f083affb2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();

// Must be called before getFirestore()
initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

export const functions = getFunctions(app);
export const saveTournamentDraftFirebase = httpsCallable(
  functions,
  "tournament-saveDraft"
);
export const changeTournamentDraftPublishRequestFirebase = httpsCallable(
  functions,
  "tournament-changePublishRequest"
);
export const addTournamentAdminFirebase = httpsCallable(
  functions,
  "tournament-addAdmin"
);
export const removeTournamentAdminFirebase = httpsCallable(
  functions,
  "tournament-removeAdmin"
);
export const updateMatchFirebase = httpsCallable(
  functions,
  "tournament-updateMatch"
);
// todo - add method signatures here too? e.g. getMatchApiKeyAsync = async (matchId: string) => await getMatchApiKeyFirebase(matchId);
export const getMatchApiKeyFirebase = httpsCallable(
  functions,
  "match-getApiKey"
);
export const rescindApiKeyFirebase = httpsCallable(
  functions,
  "match-rescindApiKey"
);
