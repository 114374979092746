import { RoundTabs } from "components/TournamentEditor/RoundTabs/RoundTabs";
import { TeRoundsGroup } from "model/TournamentEditor/Segment/Rounds/TeRoundsGroup";
import { TeSwissSegment } from "model/TournamentEditor/Segment/Rounds/TeSwissSegment";
import { arrayReplaceAt } from "Utility";

interface Props {
  formatKeys: string[];
  swiss: TeSwissSegment;
  update: (segment: TeSwissSegment) => void;
}
export const Swiss = ({ formatKeys, swiss, update }: Props) => {
  const group = swiss.groups[0];
  return (
    <RoundTabs
      formatKeys={formatKeys}
      group={group}
      update={(group: TeRoundsGroup) =>
        update({ ...swiss, groups: arrayReplaceAt(swiss.groups, group, 0) })
      }
    />
  );
};
