import { useState } from "react";
import { FlexColumn, FlexRow } from "styles/layout";
import { AddButton, RemoveButton, Value, ValuesContainer } from "./styles";
import { arrayRemoveAt } from "Utility";

interface Props {
  values: string[];
  update: (values: string[]) => void;
}

export const StringArrayField = ({ values, update }: Props) => {
  const [newValue, setNewValue] = useState<string>("");
  const onAddClick = () => {
    update([...values, newValue]);
    setNewValue("");
  };

  const onRemoveValueClick = (idx: number) => {
    update(arrayRemoveAt(values, idx));
  };

  return (
    <FlexColumn>
      <FlexRow>
        <input value={newValue} onChange={(e) => setNewValue(e.target.value)} />
        <AddButton onClick={onAddClick} disabled={newValue.length === 0}>
          +
        </AddButton>
      </FlexRow>
      <ValuesContainer>
        {values.map((v, idx) => (
          <Value>
            <span>{v}</span>
            <RemoveButton onClick={() => onRemoveValueClick(idx)}>
              -
            </RemoveButton>
          </Value>
        ))}
      </ValuesContainer>
    </FlexColumn>
  );
};
