import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { Scores, applyScoreOverrides } from "fsModel/Match/Scores";
import { FsRoundsSet } from "db/model/match/Set/Rounds/FsRoundsSet";
import { applyRoundsTrackScore, isRoundsTrackComplete } from "./Track";
import { calculateFinalPositions } from "fsModel/Match/Positions";

export const getRoundSetScores = (
  set: FsRoundsSet,
  teams: FsMatchTeam[]
): Scores => {
  const scores: Scores = {};
  const pointsDist = set.format?.points;
  if (!!set.tracks && !!pointsDist) {
    set.tracks.forEach((t) =>
      applyRoundsTrackScore(t, teams, scores, pointsDist)
    );
  }

  applyScoreOverrides(scores, set.scoreOverrides);

  return scores;
};

export const isRoundsSetComplete = (set: FsRoundsSet) => {
  if (set.overrideComplete) {
    return true;
  }

  const numTracks = set.format?.numTracks;
  const roundsPerTrack = set.format?.roundsPerTrack;
  if (!numTracks || !roundsPerTrack) {
    return false;
  }

  const numCompleteTracks =
    set.tracks?.filter((t) => isRoundsTrackComplete(t, roundsPerTrack))
      .length ?? 0;

  return numCompleteTracks >= numTracks;
};

export const getRoundsSetFinalPositions = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
