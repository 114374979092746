import styled from "styled-components";
import { Button } from "styles/elements";
import { FlexColumn, FlexRow } from "styles/layout";

export const TeamsContainer = styled(FlexRow)`
  flex-wrap: wrap;
  gap: 10px;
`;

export const TeamContainer = styled(FlexColumn)`
  width: 250px;
  row-gap: 5px;
`;

export const AddButton = styled(Button)`
  width: 50px;
  height: 100px;
`;
