import { IdData } from "model/IdData";
import { TeMatch } from "model/TournamentEditor/TeMatch";
import {
  mapTeTournamentSegmentBaseToFsData,
  newTeTournamentSegmentBase,
  TeTournamentSegmentBase,
} from "../TeTournamentSegment";
import {
  mapTeRoundsGroupsToFsData,
  newTeRoundsGroup,
  TeRoundsGroup,
} from "./TeRoundsGroup";
import { FsSwissSegment } from "db/model/tournament/Segment/Swiss/FsSwiss";

export type TeSwissSegment = TeTournamentSegmentBase<TeRoundsGroup> & {
  type: "Swiss";
};

export const newTeSwissSegment = (): TeSwissSegment => {
  return {
    ...newTeTournamentSegmentBase(),
    groups: [newTeRoundsGroup()],
    type: "Swiss",
  };
};

export const mapTeSwissSegmentToFsData = (
  teSegment: TeSwissSegment,
  teMatchesWithIds: IdData<TeMatch>[]
): FsSwissSegment => {
  return {
    ...mapTeTournamentSegmentBaseToFsData(
      teSegment,
      teMatchesWithIds,
      mapTeRoundsGroupsToFsData
    ),
    type: "Swiss",
  };
};
