import { newFsSetFormatBase } from "../FsSetFormat";
import { FsMatchmakingFormat } from "db/model/match/Set/Matchmaking/FsMatchmakingFormat";

export const newFsMatchmakingFormat = (): FsMatchmakingFormat => {
  return {
    ...newFsSetFormatBase(),
    playCondition: "BestOf",
    points: {
      distribution: [],
      dnfPoints: undefined,
    },
    roundsLimit: undefined,
    trackWinReq: undefined,
  };
};
