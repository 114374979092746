import { DriverForm } from "components/AddEditForms/Driver/DriverForm";
import { ctorTextColumn } from "components/DataGrid/Columns/Text";
import { ctorValidationColumn } from "components/DataGrid/Columns/Validation";
import { DataGridColumn } from "components/DataGrid/DataGridColumn";
import { driverCollectionName } from "db/info/collectionNames";
import { perihperals } from "db/model/driver/FsDriver";
import { Driver } from "model/IdData";
import { Validated } from "model/validators/base";
import { validateDrivers } from "model/validators/DriverValidator";
import { DataPage } from "pages/Data/DataPage";
import { useSelector } from "react-redux";
import { selectAllDrivers } from "selectors/Driver";
import { selectAllTeams } from "selectors/Team";
import { anyStringContains } from "Utility";

export const DriversPage = () => {
  const drivers = useSelector(selectAllDrivers);
  const teams = useSelector(selectAllTeams);
  const validatedDrivers = validateDrivers(drivers, teams);
  return (
    <DataPage
      dataName="Driver"
      collectionName={driverCollectionName}
      data={drivers}
      columns={driversColumns}
      validated={validatedDrivers}
      newData={() => ({
        country: undefined,
        name: undefined,
        nickname: undefined,
        peripheral: perihperals[0],
        teamHistory: [],
        ubisoftUids: [],
      })}
      searchFunction={driverSearch}
      form={DriverForm}
    />
  );
};

const driversColumns: DataGridColumn<Validated<Driver>>[] = [
  ctorTextColumn({
    textGetter: (arg: Validated<Driver>) => arg.model.id,
    sortable: true,
    numberSort: true,
    header: "ID",
  }),
  ctorTextColumn({
    textGetter: (arg: Validated<Driver>) => arg.model.data.nickname,
    sortable: true,
    header: "Nickname",
  }),
  ctorTextColumn({
    textGetter: (arg: Validated<Driver>) => arg.model.data.ubisoftUids?.at(0),
    sortable: true,
    header: "Account ID",
  }),
  ctorValidationColumn({
    validationGetter: (arg: Validated<Driver>) => arg.problems,
    sortable: true,
    header: "Validation",
  }),
];

const driverSearch = (arg: Validated<Driver>, searchText: string) => {
  return anyStringContains(searchText, [
    arg.model.id,
    arg.model.data.nickname,
    arg.model.data.ubisoftUids?.at(0),
  ]);
};
