import { IdData } from "model/IdData";
import { checkTypeExhausted, nonUndefined } from "Utility";
import { TeMatch } from "../TeMatch";
import {
  FsTournamentSegment,
  FsTournamentSegmentBase,
} from "db/model/tournament/Segment/FsTournamentSegment";
import { TeSegmentGroup } from "./TeSegmentGroup";
import { FsTournamentSegmentGroup } from "db/model/tournament/Segment/FsTournamentSegmentGroup";
import {
  mapTeBracketSegmentToFsData,
  newTeBracketSegment,
  TeBracketSegment,
} from "./Bracket/TeBracketSegment";
import {
  mapTeQualifierSegmentToFsData,
  newTeQualfierSegment,
  TeQualifierSegment,
} from "./Qualifier/TeQualifierSegment";
import {
  mapTeSeasonSegmentToFsData,
  newTeSeasonSegment,
  TeSeasonSegment,
} from "./Rounds/TeSeasonSegment";
import {
  mapTeSwissSegmentToFsData,
  newTeSwissSegment,
  TeSwissSegment,
} from "./Rounds/TeSwissSegment";
import {
  mapTeSingleSegmentToFsData,
  newTeSingleSegment,
  TeSingleSegment,
} from "./Single/TeSingleSegment";

export type TeSegmentType =
  | "Bracket"
  | "Qualifier"
  | "Season"
  | "Single"
  | "Swiss";

export const teSegmentTypes: TeSegmentType[] = [
  "Bracket",
  "Qualifier",
  "Season",
  "Single",
  "Swiss",
];

export type TeTournamentSegmentBase<TGroup extends TeSegmentGroup> = {
  groups: TGroup[];
  name: string | undefined;
  segmentId: string | undefined;
};

export type TeTournamentSegment =
  | TeBracketSegment
  | TeQualifierSegment
  | TeSeasonSegment
  | TeSingleSegment
  | TeSwissSegment;

export const newTeTournamentSegmentBase = <
  TGroup extends TeSegmentGroup
>(): TeTournamentSegmentBase<TGroup> => {
  return {
    groups: [],
    name: undefined,
    segmentId: undefined,
  };
};

export const newTeTournamentSegment = (type: TeSegmentType) => {
  switch (type) {
    case "Bracket":
      return newTeBracketSegment();
    case "Qualifier":
      return newTeQualfierSegment();
    case "Season":
      return newTeSeasonSegment();
    case "Single":
      return newTeSingleSegment();
    case "Swiss":
      return newTeSwissSegment();
    default:
      return checkTypeExhausted(type);
  }
};

export const getTeTournamentSegmentMatches = (segment: TeTournamentSegment) => {
  switch (segment.type) {
    case "Bracket":
      return segment.groups.flatMap((g) => g.matches.map((m) => m.match));
    case "Qualifier":
      return segment.groups.flatMap((g) => g.matches);
    case "Season":
    case "Swiss":
      return segment.groups.flatMap((g) => g.rounds.flatMap((r) => r.matches));
    case "Single":
      return segment.groups.flatMap((g) => g.match);
    default:
      checkTypeExhausted(segment);
  }
};

export const mapTeTournamentSegmentBaseToFsData = <
  TTeGroup extends TeSegmentGroup,
  TFsGroup extends FsTournamentSegmentGroup
>(
  teSegmentBase: TeTournamentSegmentBase<TTeGroup>,
  teMatchesWithIds: IdData<TeMatch>[],
  mapGroups: (
    teGroups: TTeGroup[],
    teMatchesWithIds: IdData<TeMatch>[]
  ) => TFsGroup[]
): FsTournamentSegmentBase<TFsGroup> => {
  return {
    dqForceLast: undefined,
    dqTeamIds: undefined,
    ffForceLast: undefined,
    ffTeamIds: undefined,
    groups: mapGroups(teSegmentBase.groups, teMatchesWithIds),
    name: teSegmentBase.name,
    segmentId: teSegmentBase.segmentId,
  };
};

export const mapTeTournamentSegmentsToFsData = (
  segments: TeTournamentSegment[],
  teMatchesWithIds: IdData<TeMatch>[]
): FsTournamentSegment[] => {
  return nonUndefined(
    segments.map((s) => mapTeTournamentSegmentToFsData(s, teMatchesWithIds))
  );
};

const mapTeTournamentSegmentToFsData = (
  teSegment: TeTournamentSegment,
  teMatchesWithIds: IdData<TeMatch>[]
): FsTournamentSegment | undefined => {
  switch (teSegment.type) {
    case "Bracket":
      return mapTeBracketSegmentToFsData(teSegment, teMatchesWithIds);
    case "Qualifier":
      return mapTeQualifierSegmentToFsData(teSegment, teMatchesWithIds);
    case "Season":
      return mapTeSeasonSegmentToFsData(teSegment, teMatchesWithIds);
    case "Single":
      return mapTeSingleSegmentToFsData(teSegment, teMatchesWithIds);
    case "Swiss":
      return mapTeSwissSegmentToFsData(teSegment, teMatchesWithIds);
    default:
      checkTypeExhausted(teSegment);
  }
};
