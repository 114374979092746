import { AsyncButton } from "components/Core/AsyncButton/AsyncButton";
import { Panel } from "components/Panel/Panel";
import {
  FsLeaderboardPart,
  FsSegmentLeaderboardUser,
} from "db/model/leaderboard/FsSegmentLeaderboardUser";
import { FsPickem } from "db/model/pickem/FsPickem";
import { db } from "firebase-config";
import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { PageContent, ColumnContainer, GrowColumn } from "pages/styles";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IStore } from "redux/store";
import { selectTeamOrgDisplayName } from "selectors/Team";
import { Button } from "styles/elements";
import { CenterFlexRow, FlexColumn, FlexRow, MaxWidth } from "styles/layout";

const TOURNAMENT_ID = "Pzchsx1";
const PICKEM_ID = "Playoff";
const GOLD = "#D6AF36";
const SILVER = "#A7A7AD";
const BRONZE = "#A77044";
const BAR_WIDTH = 320;
const BAR_HEIGHT = "24px";
const KEY_SIZE = "12px";

type PickStats = { [id: string]: number[] };

const TEAM_PLACES: { [teamId: string]: number } = {
  "1136": 1, // MIME
  "1127": 2, // ELCONN
  "1135": 3, // TWEEN
  "1132": 4, // MURY
  "1123": 5, // LETZTER
  "1128": 5, // LASER
  "1126": 7, // SPEEDSELF
  "1125": 7, // YAMI
  "1129": 9, // TARPOR
  "1137": 9, // MILMY
  "1119": 9, // R0STAK
  "1121": 9, // DANIK
  "1118": 13, // NEKO
  "1122": 13, // KURISU
  "1131": 13, // ZIZOO
  "1133": 13, // EGGY
};

const POSITION_POINTS: { [position: number]: number } = {
  1: 50,
  2: 40,
  3: 30,
  4: 20,
  5: 20,
  7: 20,
  9: 10,
  13: 10,
};

const POSITIONS = [1, 2, 3, 4, 5, 7, 9, 13];
export const PickemPage = () => {
  const [picks, setPicks] = useState<FsPickem[]>([]);
  const [lbPicks, setLbPicks] = useState<{ [uid: string]: FsPickem }>({});

  const [pickStats, setPickStats] = useState<PickStats>({});
  const [lbUsers, setLbUsers] = useState<{
    [uid: string]: FsSegmentLeaderboardUser;
  }>({});

  const getPickemsAsync = async () => {
    setPicks([]);
    const pickemUids = (await getDocs(collection(db, "userPicks"))).docs.map(
      (d) => d.id
    );

    const newPicks: FsPickem[] = [];
    const promises: Promise<DocumentSnapshot<DocumentData>>[] = [];

    pickemUids.forEach((uid) => {
      const pickemDocRef = doc(db, "userPicks", uid, TOURNAMENT_ID, PICKEM_ID);
      promises.push(getDoc(pickemDocRef));
    });

    const snapshots = await Promise.all(promises);
    snapshots.forEach((snapshot) => {
      if (snapshot.exists()) {
        newPicks.push({
          ...snapshot.data(),
        } as FsPickem);
      }
    });

    setPicks(newPicks);
  };

  const getLbPickemsAsync = async () => {
    setLbPicks({});
    const pickemUids = (await getDocs(collection(db, "userPicks"))).docs.map(
      (d) => d.id
    );

    const newPicks: { [uid: string]: FsPickem } = {};
    const promises: Promise<DocumentSnapshot<DocumentData>>[] = [];

    pickemUids.forEach((uid) => {
      const pickemDocRef = doc(db, "userPicks", uid, TOURNAMENT_ID, PICKEM_ID);
      promises.push(getDoc(pickemDocRef));
    });

    const snapshots = await Promise.all(promises);
    snapshots.forEach((snapshot) => {
      if (snapshot.exists()) {
        newPicks[snapshot.ref.parent.parent?.id ?? ""] = {
          ...snapshot.data(),
        } as FsPickem;
      }
    });

    setLbPicks(newPicks);
  };

  const calculatePickStats = () => {
    setPickStats({});
    const newStats: PickStats = {};
    for (let i = 0; i < picks.length; i++) {
      const userPicks = picks[i];
      if (!userPicks?.picks) {
        continue;
      }

      const teamPicks = userPicks.picks;
      const teamIds = Object.keys(teamPicks);
      for (let j = 0; j < teamIds.length; j++) {
        const teamId = teamIds[j];
        const teamPick = teamPicks[teamId];
        if (!teamPick || typeof teamPick !== "number") {
          continue;
        }

        const current = newStats[teamId] ?? [0, 0, 0, 0, 0];
        current[teamPick - 1] = current[teamPick - 1] + 1;
        newStats[teamId] = current;
      }
    }

    setPickStats(newStats);
  };

  const calculateLeaderboard = () => {
    setLbUsers({});
    const newLbUsers: { [uid: string]: FsSegmentLeaderboardUser } = {};
    const uids = Object.keys(lbPicks);
    if (uids.length === 0) {
      return lbUsers;
    }

    for (let i = 0; i < uids.length; i++) {
      const uid = uids[i];
      const userPicks = lbPicks[uid];

      const userLb: FsSegmentLeaderboardUser = {
        parts: [],
        points: undefined,
        rank: undefined,
        sort: undefined,
      };

      const lbPart: FsLeaderboardPart = {
        pickemId: PICKEM_ID,
        points: {},
        driverPoints: undefined,
      };

      const pickedTeamIds = Object.keys(userPicks.picks ?? {});
      if (pickedTeamIds.length === 0) {
        continue;
      }

      for (let j = 0; j < pickedTeamIds.length; j++) {
        const teamId = pickedTeamIds[j];

        const userPick = userPicks.picks![teamId];
        if (!userPick || typeof userPick !== "number") {
          continue;
        }

        const teamPos = TEAM_PLACES[teamId];
        if (!teamPos) {
          lbPart.points![teamId] = 0;
          continue;
        }

        const pointsForPick = POSITION_POINTS[userPick];
        if (userPick === teamPos) {
          lbPart.points![teamId] = pointsForPick;
          continue;
        }

        if (
          Math.abs(POSITIONS.indexOf(userPick) - POSITIONS.indexOf(teamPos)) ===
          1
        ) {
          lbPart.points![teamId] = pointsForPick / 2;
          continue;
        }

        lbPart.points![teamId] = 0;
      }

      userLb.parts!.push(lbPart);
      newLbUsers[uid] = userLb;
    }

    Object.keys(newLbUsers).forEach((uid) => {
      const newLbUser = newLbUsers[uid];
      newLbUser.points = (newLbUser.parts ?? [])
        .flatMap((p) =>
          Object.values(p.points ?? {}).concat(
            Object.values(p.driverPoints ?? {})
          )
        )
        .reduce((x, y) => x + y, 0);
    });

    const sortedPoints = Object.values(newLbUsers)
      .sort((a, b) => (b.points ?? 0) - (a.points ?? 0))
      .map((x) => x.points ?? 0);

    const sortedUids = Object.keys(newLbUsers).sort((a, b) => {
      const userA = newLbUsers[a];
      const userB = newLbUsers[b];
      const totalDiff = (userB.points ?? 0) - (userA.points ?? 0);
      if (totalDiff !== 0) {
        return totalDiff;
      }

      return a.localeCompare(b);
    });

    Object.keys(newLbUsers).forEach((k) => {
      const newLbUser = newLbUsers[k];
      newLbUser.rank =
        sortedPoints.findIndex((x) => x === newLbUser.points) + 1;
      newLbUser.sort = sortedUids.findIndex((x) => x === k) + 1;
    });

    setLbUsers(newLbUsers);
  };

  const writeLeaderboardToDbAsync = async () => {
    Object.keys(lbUsers).forEach(async (uid) => {
      const docRef = doc(db, "newLeaderboard", TOURNAMENT_ID, "user", uid);
      await setDoc(docRef, lbUsers[uid]);
      console.log("Write LB User: " + uid);
    });

    const lbUsersValues = Object.values(lbUsers);
    const count = Math.max(...lbUsersValues.map((l) => l.sort ?? 0));
    const docRef2 = doc(db, "newLeaderboard", TOURNAMENT_ID);
    await setDoc(docRef2, {
      lastUpdated: Date.now(),
      count,
    });

    console.log("done all");
  };

  const numberOfPicks = picks.length;

  const sortedTeamIds = Object.keys(pickStats)
    .sort((a, b) => {
      const aPicks = pickStats[a];
      const bPicks = pickStats[b];

      for (let i = 0; i < 5; i++) {
        if (aPicks[i] > bPicks[i]) {
          return -1;
        }

        if (bPicks[i] > aPicks[i]) {
          return 1;
        }
      }

      return 0;
    })
    .slice(0, 10);

  return (
    <PageContent>
      <MaxWidth>
        <ColumnContainer>
          <GrowColumn>
            <Panel padding="padded">
              <FlexRow style={{ gap: "10px" }}>
                <AsyncButton onClickAsync={getPickemsAsync}>
                  Get Pickems
                </AsyncButton>
                <Button onClick={calculatePickStats}>Calc Stats</Button>
                <AsyncButton onClickAsync={getLbPickemsAsync}>
                  Get Lb Pickems
                </AsyncButton>
                <Button onClick={calculateLeaderboard}>Calc Lb</Button>
                <AsyncButton onClickAsync={writeLeaderboardToDbAsync}>
                  Write Lb
                </AsyncButton>
              </FlexRow>
            </Panel>
            <Panel padding="padded">
              <FlexColumn style={{ rowGap: "8px" }}>
                <span>Number Of Picks: {numberOfPicks}</span>
                <br />
                <br />
                <CenterFlexRow
                  style={{
                    paddingLeft: "60px",
                  }}
                >
                  <h3>BYMC Monthly Final Picks</h3>
                </CenterFlexRow>
                {sortedTeamIds.map((id) => (
                  <TeamPicks
                    teamId={id}
                    numPicks={numberOfPicks}
                    teamPicks={pickStats[id]}
                  />
                ))}
                <CenterFlexRow
                  style={{
                    columnGap: "14px",
                    paddingLeft: "50px",
                    paddingTop: "10px",
                  }}
                >
                  <FlexRow style={{ columnGap: "4px" }}>
                    <div
                      style={{
                        height: KEY_SIZE,
                        width: KEY_SIZE,
                        background: GOLD,
                      }}
                    />
                    <span>First</span>
                  </FlexRow>
                  <FlexRow style={{ columnGap: "4px" }}>
                    <div
                      style={{
                        height: KEY_SIZE,
                        width: KEY_SIZE,
                        background: SILVER,
                      }}
                    />
                    <span>Second</span>
                  </FlexRow>
                  <FlexRow style={{ columnGap: "4px" }}>
                    <div
                      style={{
                        height: KEY_SIZE,
                        width: KEY_SIZE,
                        background: BRONZE,
                      }}
                    />
                    <span>Third</span>
                  </FlexRow>
                  <FlexRow style={{ columnGap: "4px" }}>
                    <div
                      style={{
                        height: KEY_SIZE,
                        width: KEY_SIZE,
                        background: "rosybrown",
                      }}
                    />
                    <span>Fourth</span>
                  </FlexRow>
                  <FlexRow style={{ columnGap: "4px" }}>
                    <div
                      style={{
                        height: KEY_SIZE,
                        width: KEY_SIZE,
                        background: "slategray",
                      }}
                    />
                    <span>Fifth</span>
                  </FlexRow>
                </CenterFlexRow>
              </FlexColumn>
            </Panel>
            <Panel padding="padded">
              <FlexColumn>
                {Object.keys(lbUsers).map((uid) => (
                  <FlexRow>
                    {`${uid}  -  Points: ${lbUsers[uid].points}`}
                  </FlexRow>
                ))}
              </FlexColumn>
            </Panel>
          </GrowColumn>
        </ColumnContainer>
      </MaxWidth>
    </PageContent>
  );
};

interface TeamPicksProps {
  teamId: string;
  teamPicks: number[];
  numPicks: number;
}

const TeamPicks = ({ teamId, teamPicks, numPicks }: TeamPicksProps) => {
  const displayName = useSelector((state: IStore) =>
    selectTeamOrgDisplayName(state, teamId, 9999999999999999)
  );

  const preFirst = teamPicks[0] / numPicks;
  const first = preFirst > 0 ? Math.max(preFirst, 0.04) : 0;
  const preSecond = teamPicks[1] / numPicks;
  const second = preSecond > 0 ? Math.max(preSecond, 0.04) : 0;
  const preThird = teamPicks[2] / numPicks;
  const third = preThird > 0 ? Math.max(preThird, 0.04) : 0;
  const preFourth = teamPicks[3] / numPicks;
  const fourth = preFourth > 0 ? Math.max(preFourth, 0.04) : 0;
  const preFifth = teamPicks[4] / numPicks;
  const fifth = preFifth > 0 ? Math.max(preFifth, 0.04) : 0;
  const rest = 1 - first - second - third - fourth - fifth;
  return (
    <CenterFlexRow>
      <span
        style={{
          fontSize: "13pt",
          fontWeight: "500",
          width: "130px",
          textAlign: "right",
          paddingRight: "10px",
        }}
      >
        {displayName}
      </span>
      <FlexRow style={{ border: "1px solid black" }}>
        <div
          style={{
            background: GOLD,
            height: BAR_HEIGHT,
            width: `${first * BAR_WIDTH}px`,
          }}
        />
        <div
          style={{
            background: SILVER,
            height: BAR_HEIGHT,
            width: `${second * BAR_WIDTH}px`,
          }}
        />
        <div
          style={{
            background: BRONZE,
            height: BAR_HEIGHT,
            width: `${third * BAR_WIDTH}px`,
          }}
        />
        <div
          style={{
            background: "rosybrown",
            height: BAR_HEIGHT,
            width: `${fourth * BAR_WIDTH}px`,
          }}
        />
        <div
          style={{
            background: "lightslategray",
            height: BAR_HEIGHT,
            width: `${fifth * BAR_WIDTH}px`,
          }}
        />
        <div
          style={{
            background: "dimgray",
            height: BAR_HEIGHT,
            width: `${rest * BAR_WIDTH}px`,
          }}
        />
      </FlexRow>
    </CenterFlexRow>
  );
};
