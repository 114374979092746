import styled from "styled-components";
import { Theme } from "theme";

export const SwitcherOption = styled.button<{
  selectable: boolean;
  selected: boolean;
  hasOnClick: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 4px 8px;
  margin-top: 6px;
  font-size: 13pt;
  width: 150px;
  border: 0px;
  border-bottom: 2px solid transparent;
  color: gray;
  white-space: nowrap;

  @media (max-width: 700px) {
    font-size: 12pt;
    width: 135px;
    ${({ selectable }) => !selectable && "font-size: 11pt;"}
  }
  @media (max-width: 600px) {
    font-size: 11pt;
    width: 125px;
    ${({ selectable }) => !selectable && "font-size: 10.5pt;"}
  }

  @media (max-width: 500px) {
    font-size: 10.5pt;
    padding: 6px;
    ${({ selectable }) => !selectable && "font-size: 10pt;"}
  }

  ${({ selectable }) =>
    !selectable &&
    `color: ${Theme.switcherNonSelectableForeground}; font-size: 12pt;`}
  ${({ selectable, hasOnClick }) =>
    (selectable || hasOnClick) && "cursor: pointer;"}
  ${({ selected }) =>
    selected &&
    `color: ${Theme.switcherSelectedForeground}; font-weight: bold; border-color: ${Theme.switcherSelectedForeground};`}
`;
