import { FsCupModeFormatBase } from "db/model/match/Set/CupMode/FsCupModeFormat";
import { newFsSetFormatBase } from "../FsSetFormat";

export const newFsCupModeFormat = (): FsCupModeFormatBase => {
  return {
    ...newFsSetFormatBase(),
    playTillLeft: undefined,
    points: undefined,
    requiredTeamWinners: undefined,
    roundsPerTrack: undefined,
  };
};
