import { DriverKeys } from "../Teams/DriverKeyEditor";
import { Panel } from "components/Panel/Panel";
import { FsMatch } from "db/model/match/FsMatch";
import { SetSwitcher } from "./SetSwitcher";
import { useState } from "react";
import { arrayReplaceAt, nonUndefined } from "Utility";
import { SetEditor } from "./SetEditor";
import { getNumTeams, getTeams } from "fsModel/Match/Match";
import { useSelector } from "react-redux";
import { IStore } from "redux/store";
import { selectTrackIdsByMatchId } from "selectors/Track";

interface Props {
  matchId: string;
  match: FsMatch;
  update: (match: FsMatch) => void;
  driverKeys: DriverKeys;
  orgTime: number | undefined;
}

export const SetsPanel = ({
  matchId,
  match,
  update,
  driverKeys,
  orgTime,
}: Props) => {
  const [selectedSetIdx, setSelectedSetIdx] = useState<number | undefined>(0);
  const teamIds = nonUndefined(getTeams(match).flatMap((t) => t.teamId));
  const trackIds =
    useSelector((state: IStore) => selectTrackIdsByMatchId(state, matchId)) ??
    [];

  const selectedSet =
    selectedSetIdx !== undefined ? match.sets?.at(selectedSetIdx) : undefined;

  return (
    <Panel padding="padded">
      <SetSwitcher
        sets={match.sets ?? []}
        selectedSetIdx={selectedSetIdx}
        setSelectedSetIdx={setSelectedSetIdx}
        updateSets={(sets) => update({ ...match, sets })}
      />
      <br />
      {!!selectedSet && !!match.sets && (
        <SetEditor
          key={selectedSetIdx}
          set={selectedSet}
          numTeams={getNumTeams(match)}
          driverKeys={driverKeys}
          update={(set) =>
            update({
              ...match,
              sets: arrayReplaceAt(
                match.sets!,
                set,
                match.sets!.indexOf(selectedSet)
              ),
            })
          }
          remove={() =>
            update({
              ...match,
              sets: match.sets?.filter((s) => s !== selectedSet),
            })
          }
          teamIds={teamIds}
          trackIds={trackIds}
          orgTime={orgTime}
        />
      )}
    </Panel>
  );
};
