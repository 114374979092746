import { IStore } from "redux/store";

export const selectDriver = (state: IStore, driverId: string | undefined) => {
  if (!driverId) {
    return undefined;
  }

  return state.driver.drivers[driverId];
};

export const selectAllDrivers = (state: IStore) => {
  return Object.values(state.driver.drivers);
};

export const selectDriverNickname = (
  state: IStore,
  driverId: string | undefined
) => {
  return selectDriver(state, driverId)?.data.nickname;
};
