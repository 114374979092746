import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsCupModePoints } from "db/model/match/Set/CupMode/FsCupModePoints";
import { Scores } from "fsModel/Match/Scores";
import { getPointsDistribution } from "../Points";
import { getPoints } from "../../PointsDistribution";
import { isDnf } from "fsModel/Match/DriverTime";
import { FsRoundTime } from "db/model/match/Track/FsRoundTime";

export const applyReverseCupModeRoundScore = (
  times: FsRoundTime[],
  teams: FsMatchTeam[],
  runningScores: Scores,
  points: FsCupModePoints,
  startingPoints: number
) => {
  const numDrivers = times.length;
  const pointsDist = getPointsDistribution(points, numDrivers);
  if (numDrivers <= 1 || !pointsDist) {
    return;
  }

  for (var i = 0; i < times.length; i++) {
    const { driverId, finishTimeMs, position } = times[i];
    if (!driverId || !position) {
      continue;
    }

    const teamId = teams.find(
      (t) => !!t.driverIds && t.driverIds.includes(driverId)
    )?.teamId;
    if (!teamId) {
      continue;
    }

    const pointsAtStart = runningScores[teamId] ?? startingPoints;
    const pointDelta = getPoints(pointsDist, position, finishTimeMs);
    var pointsAtEnd: number;
    if (
      pointsAtStart === 0 &&
      (isDnf(finishTimeMs) || position === numDrivers)
    ) {
      pointsAtEnd = 0 - position;
    } else {
      pointsAtEnd = Math.max(0, pointsAtStart + pointDelta);
    }

    runningScores[teamId] = pointsAtEnd;
  }
};
