import { useState } from "react";
import { useSelector } from "react-redux";
import { selectTeamOrgId } from "selectors/Team";
import { IStore } from "redux/store";
import { Logo, LogoContainer } from "./styles";

interface Props {
  orgId?: string;
  title?: string;
}

export const OrgLogo = ({ orgId, title }: Props) => {
  const [imgSrc, setImgSrc] = useState(`/images/orgs/${orgId}/logo.png`);
  if (!orgId) {
    return <LogoContainer key={orgId} />;
  }

  return (
    <LogoContainer>
      <Logo
        src={imgSrc}
        title={title}
        onError={() => setImgSrc(`/images/orgs/default/logo.png`)}
      />
    </LogoContainer>
  );
};

interface Props2 {
  teamId: string;
}

export const OrgLogo2 = ({ teamId }: Props2) => {
  const orgId = useSelector((state: IStore) =>
    selectTeamOrgId(state, teamId, -1)
  );
  const [imgSrc, setImgSrc] = useState(`/images/orgs/${orgId}/logo.png`);
  if (!orgId) {
    return <LogoContainer key={orgId} />;
  }

  return (
    <LogoContainer>
      <Logo
        src={imgSrc}
        onError={() => setImgSrc(`/images/orgs/default/logo.png`)}
      />
    </LogoContainer>
  );
};
