import { FsSingle } from "db/model/tournament/Segment/Single/FsSingle";
import { IdData } from "model/IdData";
import {
  TeMatchOrId,
  TeMatch,
  newTeMatch,
  getMatchIdFromTeMatches,
} from "model/TournamentEditor/TeMatch";
import {
  mapTeSegmentGroupBaseToFsData,
  newTeSegmentGroupBase,
  TeSegmentGroupBase,
} from "../TeSegmentGroup";

export type TeSingleGroup = TeSegmentGroupBase & {
  match: TeMatchOrId;
};

export const newTeSingleGroup = (): TeSingleGroup => {
  return {
    ...newTeSegmentGroupBase(),
    match: newTeMatch(),
  };
};

export const mapTeSingleGroupsToFsData = (
  teGroups: TeSingleGroup[],
  teMatchesWithIds: IdData<TeMatch>[]
): FsSingle[] => {
  return teGroups.map((g) => mapTeSingleGroupToFsData(g, teMatchesWithIds));
};

const mapTeSingleGroupToFsData = (
  teGroup: TeSingleGroup,
  teMatchesWithIds: IdData<TeMatch>[]
): FsSingle => {
  return {
    ...mapTeSegmentGroupBaseToFsData(teGroup),
    matchId: getMatchIdFromTeMatches(teGroup.match, teMatchesWithIds),
  };
};

export const mapFsSingleToTeSingleGroup = (
  fsModel: FsSingle
): TeSingleGroup => {
  return {
    groupId: fsModel.groupId,
    name: fsModel.name,
    match: fsModel.matchId ?? newTeMatch(),
  };
};
