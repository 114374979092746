import { useEffect, useState } from "react";
import { Main } from "./pages/Main";
import { GlobalContext } from "GlobalContext";
import { Theme } from "theme";
import { useSelector } from "react-redux";
import { onStart, selectInitialLoadComplete } from "startup";
import { OverlayDimDiv, FullPageSpinner } from "styles/components";

function App() {
  const [isLoadingOverlayVisible, setIsLoadingOverlayVisible] =
    useState<boolean>(false);
  const initialDataLoaded = useSelector(selectInitialLoadComplete);

  useEffect(() => {
    onStart();
    document.body.style.backgroundColor = Theme.pageBackground;
  }, []);

  return (
    <>
      <GlobalContext.Provider
        value={{ isLoadingOverlayVisible, setIsLoadingOverlayVisible }}
      >
        <OverlayDimDiv visible={isLoadingOverlayVisible}>
          <FullPageSpinner />
        </OverlayDimDiv>
        <Main hasDataLoaded={initialDataLoaded} />
      </GlobalContext.Provider>
    </>
  );
}

export default App;
