import { MaxWidth } from "styles/layout";
import { Bold } from "styles/text";
import {
  FooterColumn,
  FooterContainer,
  FooterContentContainer,
  FooterLink,
  FooterLogoContainer,
  ImageWrapper,
  NavItem,
} from "./styles";
import { termsOfServicePath, privacyPath } from "pages/PageConfigs";
import { DISCORD_LINK, EMAIL_CONTACT, TWITTER_LINK } from "eCM";

export const Footer = () => {
  return (
    <FooterContainer>
      <MaxWidth>
        <FooterContentContainer>
          <FooterColumn>
            <FooterLogoContainer>
              <ImageWrapper>
                <img src={`/images/ecm/logo_text_white.png`} />
              </ImageWrapper>
            </FooterLogoContainer>
          </FooterColumn>
          <FooterColumn>
            <NavItem to={termsOfServicePath}>Terms Of Service</NavItem>
            <NavItem to={privacyPath}>Privacy Policy</NavItem>
            <FooterLink href={TWITTER_LINK} target="_blank">
              Follow Us On Twitter
            </FooterLink>
          </FooterColumn>
          <FooterColumn>
            <span>
              Contact Us - <Bold>{EMAIL_CONTACT}</Bold>
            </span>
            <FooterLink href={DISCORD_LINK} target="_blank">
              Join Our Discord Server
            </FooterLink>
          </FooterColumn>
        </FooterContentContainer>
      </MaxWidth>
    </FooterContainer>
  );
};
