import { ActionColumn } from "./Columns/Action";
import { DateTimeColumn } from "./Columns/DateTime";
import { TextColumn } from "./Columns/Text";
import { ValidationColumn } from "./Columns/Validation";

export type DataGridColumnBase = {
  header: string | undefined;
  title: string | undefined;
  sortable: boolean;
  highlight: boolean;
};

export interface DataGridColumnBaseCtorArgs {
  header?: string;
  title?: string;
  sortable?: boolean;
  highlight?: boolean;
}

export function ctorDataGridColumnBase(
  args: DataGridColumnBaseCtorArgs
): DataGridColumnBase {
  return {
    header: args.header,
    title: args.title,
    sortable: args.sortable ?? false,
    highlight: args.highlight ?? false,
  };
}

export type DataGridColumn<T> =
  | ActionColumn<T>
  | DateTimeColumn<T>
  | TextColumn<T>
  | ValidationColumn<T>;
