import { IdDisplay } from "model/IdDisplay";
import { IdData } from "model/IdData";

export function mapIdDataToOptions<T>(
  data: IdData<T>[],
  getDisplay: (data: IdData<T>) => string,
  showId: boolean = false
): IdDisplay[] {
  return data
    .map((d) => ({
      id: d.id,
      display: `${getDisplay(d)}${showId ? ` (${d.id})` : ""}`,
    }))
    .sort((a, b) => a.display.localeCompare(b.display));
}
