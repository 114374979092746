import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { Scores } from "fsModel/Match/Scores";
import { FsTimeAttackTrack } from "db/model/match/Set/TimeAttack/Track/FsTimeAttackTrack";
import { FsTimeAttackScoring } from "db/model/match/Set/TimeAttack/Scoring/FsTimeAttackScoring";
import { AdjustedTime, getAdjustedTime } from "fsModel/Match/AdjustedTime";
import { FsTimeAttackRound } from "db/model/match/Set/TimeAttack/Track/Round/FsTimeAttackRound";
import {
  calculateTimeAttackTeamScoring,
  getTimeAttackRoundBestTime,
} from "./Round";
import { nonUndefined } from "Utility";
import { calculateFinalPositions } from "fsModel/Match/Positions";

const DNF_OFFSET_MS = 22000;

export const getTimeAttackTrackScores = (
  track: FsTimeAttackTrack,
  teams: FsMatchTeam[],
  scoring: FsTimeAttackScoring
) => {
  const scores: Scores = {};
  for (var i = 0; i < teams.length; i++) {
    const { teamId, driverIds } = teams[i];
    if (!teamId) {
      continue;
    }

    const scoreOverride = !!track.scoreOverrides
      ? track.scoreOverrides[teamId]
      : undefined;
    if (scoreOverride !== undefined) {
      scores[teamId] = scoreOverride;
    } else {
      if (!driverIds || !track.rounds) {
        continue;
      }

      const score = calculateTimeAttackTrackTeamScore(
        track.rounds,
        scoring,
        nonUndefined(driverIds)
      );

      if (score !== undefined) {
        scores[teamId] = score.score;
      }
    }
  }

  return scores;
};

export const calculateTimeAttackTrackTeamScore = (
  rounds: FsTimeAttackRound[],
  scoring: FsTimeAttackScoring,
  driverIds: string[]
) => {
  if (rounds.length === 0) {
    return undefined;
  }

  const teamTrackRoundTimes: (AdjustedTime | undefined)[][] = rounds.map(
    (round) => {
      const times = round.times ?? {};
      const best = getTimeAttackRoundBestTime(times);
      if (!best) {
        return [undefined];
      }

      return driverIds.map((driverId) =>
        getAdjustedTime(times[driverId], best + DNF_OFFSET_MS)
      );
    }
  );

  return calculateTimeAttackTeamScoring(teamTrackRoundTimes, scoring);
};

export const isTimeAttackTrackComplete = (track: FsTimeAttackTrack) => {
  if (track.overrideComplete) {
    return true;
  }

  return (
    track.rounds?.every((r) => !!Object.keys(r.times ?? {}).length) ?? false
  );
};

export const getTimeAttackTrackFinalPositions = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
