import { Panel } from "components/Panel/Panel";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IStore } from "redux/store";
import { TournamentHeader } from "./Header/TournamentHeader";
import { TournamentMatchesTab } from "./Tabs/TournamentMatchesTab";
import { selectIsTournamentOrganizer } from "selectors/User";
import { FsTournament } from "db/model/tournament/FsTournament";
import {
  getCombinedTournamentSegmentNames,
  getTournamentMatchIds,
  getTournamentSegmentIdsFromName,
} from "fsModel/Tournament/Tournament";
import { Tournament } from "model/IdData";
import { TournamentOverviewTab } from "./Tabs/TournamentOverviewTab";
import { GrowColumn } from "pages/styles";
import { EmptyGrowPanelText, ErrorText } from "styles/text";
import { TournamentSegmentTab } from "./Tabs/Segment/TournamentSegmentTab";
import { TournamentAdminsTab } from "./Tabs/TournamentAdminsTab";
import { Filter } from "model/Filter";

interface Props {
  tournament: Tournament;
}
export const TournamentManager = ({ tournament }: Props) => {
  const isTournamentOrganizer =
    useSelector((state: IStore) =>
      selectIsTournamentOrganizer(state, tournament.id)
    ) ?? false;
  const orgTime = tournament.data.endTimeMs;

  const tabOptions =
    getTabOptions(tournament.data, isTournamentOrganizer) ?? [];
  const [selectedTab, setSelectedTab] = useState("Overview");
  const tabFilter: Filter<string> = {
    options: tabOptions,
    selectedValue: selectedTab,
    setSelectedValue: setSelectedTab,
  };

  return (
    <>
      <Panel>
        <TournamentHeader
          tournamentId={tournament.id}
          tournament={tournament.data}
          tabFilter={tabFilter}
        />
      </Panel>
      {getTabContent(
        selectedTab,
        tournament.id,
        tournament.data,
        isTournamentOrganizer,
        orgTime
      )}
    </>
  );
};
const getTabOptions = (
  tournament: FsTournament | undefined,
  isTournamentOrganizer: boolean
) => {
  if (!tournament) {
    return undefined;
  }

  const options = [
    "Overview",
    ...getCombinedTournamentSegmentNames(tournament),
    "Matches",
  ];

  if (isTournamentOrganizer) {
    options.push("Admins");
  }

  return options;
};

const getTabContent = (
  selectedTab: string,
  tournamentId: string,
  tournament: FsTournament,
  isTournamentOrganizer: boolean,
  orgTime: number | undefined
) => {
  switch (selectedTab) {
    case "Overview":
      return <TournamentOverviewTab key={selectedTab} />;
    case "Matches":
      return (
        <TournamentMatchesTab
          key={selectedTab}
          matchIds={getTournamentMatchIds(tournament)}
        />
      );
    case "Admins":
      return isTournamentOrganizer ? (
        <TournamentAdminsTab tournamentId={tournamentId} />
      ) : (
        <ErrorText>Permission Denied</ErrorText>
      );
    default:
      const segmentIds = getTournamentSegmentIdsFromName(
        tournament,
        selectedTab
      );

      if (!segmentIds) {
        return (
          <GrowColumn>
            <Panel>
              <EmptyGrowPanelText>Unable to find segment</EmptyGrowPanelText>
            </Panel>
          </GrowColumn>
        );
      }

      return (
        <TournamentSegmentTab
          key={selectedTab}
          tournamentId={tournamentId}
          segmentIds={segmentIds}
          orgTime={orgTime}
        />
      );
  }
};
