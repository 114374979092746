import { PayloadAction } from "@reduxjs/toolkit";
import { createFetchSlice, IFetchSlice, initialFetchState } from "./FetchSlice";
import { DocumentChange, DocumentData } from "firebase/firestore";
import { FsTeam } from "db/model/team/FsTeam";
import { Team } from "model/IdData";

export interface ITeamSlice extends IFetchSlice {
  teams: { [key: string]: Team };
}

const initialState: ITeamSlice = {
  ...initialFetchState,
  teams: {},
};

// todo - should the FSModel -> Model mapping be done in the slice ??
export const teamSlice = createFetchSlice({
  name: "team",
  initialState,
  reducers: {
    updateTeams: (
      state,
      action: PayloadAction<{
        docChanges: DocumentChange<DocumentData>[];
      }>
    ) => {
      const { docChanges } = action.payload;
      for (let i = 0; i < docChanges.length; i++) {
        const { doc } = docChanges[i];
        state.teams[doc.id] = { id: doc.id, data: doc.data() as FsTeam };
      }

      if (!state.initialFetchComplete) {
        state.initialFetchComplete = true;
      }
    },
  },
});

export const { updateTeams } = teamSlice.actions;
export const teamReducer = teamSlice.reducer;
