import { FsRoundsFormat } from "db/model/match/Set/Rounds/FsRoundsFormat";
import { newFsSetFormatBase } from "../FsSetFormat";

export const newFsRoundsFormat = (): FsRoundsFormat => {
  return {
    ...newFsSetFormatBase(),
    points: {
      distribution: [],
      dnfPoints: undefined,
    },
    roundsPerTrack: undefined,
  };
};
