export const checkTypeExhausted = (check: never) => check;

//https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
export function onlyUnique<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index;
}

//https://stackoverflow.com/questions/49729550/implicitly-create-a-tuple-in-typescript
export const tuple = <T extends any[]>(...args: T): T => args;

export const asPercentageString = (num: number, decimalPlaces: number) => {
  if (isNaN(num)) {
    return "-";
  }

  return `${(num * 100).toFixed(decimalPlaces)}%`;
};

export function nonUndefined<T>(array: (T | undefined)[]) {
  return array.filter((item) => !!item) as T[];
}

export function nonEmptyStrings(array: (string | undefined)[]): string[] {
  return array.filter((s) => !!s && s !== "") as string[];
}

export function pushX<T>(value: T, x: number, array: T[]) {
  for (var i = 0; i < x; i++) {
    array.push(value);
  }
}

export function unshiftX<T>(value: T, x: number, array: T[]) {
  for (var i = 0; i < x; i++) {
    array.unshift(value);
  }
}
export function replace<T>(
  array: T[],
  indexToReplace: number,
  replacement: T
): T[] {
  var arrayCopy = [...array];
  arrayCopy[indexToReplace] = replacement;
  return arrayCopy;
}

export function matchingArrays<T>(arrayOne: T[], arrayTwo: T[]) {
  if (arrayOne.length !== arrayTwo.length) {
    return false;
  }

  for (var i = 0; i < arrayOne.length; i++) {
    if (arrayOne[i] !== arrayTwo[i]) {
      return false;
    }
  }

  return true;
}

export function anyStringContains(
  text: string,
  terms: (string | undefined)[],
  caseSensitive: boolean = false
) {
  const nonUndefinedTerms = nonUndefined(terms);
  if (!caseSensitive) {
    return nonUndefinedTerms.some((s) =>
      s.toLowerCase().includes(text.toLowerCase())
    );
  }

  return nonUndefinedTerms.some((s) => s.includes(text));
}

export function numberCompare(
  a: number | undefined,
  b: number | undefined,
  ascending: boolean
) {
  const adjustedA =
    a ?? (ascending ? Number.MAX_VALUE : Number.NEGATIVE_INFINITY);
  const adjustedB =
    b ?? (ascending ? Number.MAX_VALUE : Number.NEGATIVE_INFINITY);
  return ascending ? adjustedA - adjustedB : adjustedB - adjustedA;
}

export function stringCompare(
  a: string | undefined,
  b: string | undefined,
  ascending: boolean,
  numberSort: boolean
) {
  const adjustedA = a ?? "";
  const adjustedB = b ?? "";
  const numberA = parseInt(adjustedA);
  const numberB = parseInt(adjustedB);

  if (numberSort && !isNaN(numberA) && !isNaN(numberB)) {
    return numberCompare(numberA, numberB, ascending);
  }

  return ascending
    ? adjustedA.localeCompare(adjustedB)
    : adjustedB.localeCompare(adjustedA);
}

export function arrayReplaceAt<T>(array: T[], newItem: T, replaceAt: number) {
  return array.map((item, idx) => (idx === replaceAt ? newItem : item));
}

export function arrayRemoveAt<T>(array: T[], removeAt: number) {
  return array.filter((_, idx) => idx !== removeAt);
}

export function trueOrUndefined(bool: boolean) {
  return bool ? bool : undefined;
}

export function toggleTrueOrUndefined(bool: boolean | undefined) {
  return bool ? undefined : true;
}

export function getNextStringKey(keys: string[], startLowerCase?: boolean) {
  var maxKey = "";
  for (var i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (key.length > maxKey.length) {
      maxKey = key;
    } else if (key.length === maxKey.length) {
      for (var j = 0; j < key.length; j++) {
        if (key.charCodeAt(j) > maxKey.charCodeAt(j)) {
          maxKey = key;
        }
      }
    }
  }

  if (maxKey.length === 0) {
    return startLowerCase ? "a" : "A";
  }

  return getNextKeyRecursive(maxKey);
}

// credit - https://stackoverflow.com/a/31540111
function getNextKeyRecursive(key: string): string {
  if (key === "Z" || key === "z") {
    return (
      String.fromCharCode(key.charCodeAt(0) - 25) +
      String.fromCharCode(key.charCodeAt(0) - 25)
    );
  }

  const lastChar = key.slice(-1);
  const sub = key.slice(0, -1);
  if (lastChar === "Z" || lastChar === "z") {
    return (
      getNextKeyRecursive(sub) + String.fromCharCode(key.charCodeAt(0) - 25)
    );
  } else {
    return sub + String.fromCharCode(lastChar.charCodeAt(0) + 1);
  }
}

export function deepCopyObject<T>(object: T) {
  const string = JSON.stringify(object);
  return JSON.parse(string) as T;
}

export function checkNaN(num: number) {
  if (isNaN(num)) {
    return undefined;
  }

  return num;
}

export function nonEmpty(str: string | undefined) {
  return !!str ? str : undefined;
}

// credit - https://stackoverflow.com/a/38340730
export function removeNullUndefinedNaNFromObjectRecursive(obj: any): any {
  const clean = Object.fromEntries(
    Object.entries(obj)
      .filter(
        ([_, v]) =>
          v !== null && v !== undefined && (typeof v !== "number" || !isNaN(v))
      )
      .map(([k, v]) => [
        k,
        v === Object(v) ? removeNullUndefinedNaNFromObjectRecursive(v) : v,
      ])
  );

  return Array.isArray(obj) ? Object.values(clean) : clean;
}

export const getNextNumericalId = (existingIds: string[]) => {
  const numberIds = existingIds
    .map((id) => parseInt(id))
    .filter((id) => !isNaN(id));
  return Math.max(...numberIds, 0) + 1;
};
