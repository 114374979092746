import { CheckboxField } from "components/Fields/Checkbox";
import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import { NumberField } from "components/Fields/Number";
import {
  FieldColumn,
  FieldRow,
  FieldsContainer,
} from "components/Fields/styles";
import { Panel } from "components/Panel/Panel";
import { FsMatch } from "db/model/match/FsMatch";

interface Props {
  match: FsMatch;
  update: (match: FsMatch) => void;
}

export const MetadataPanel = ({ match, update }: Props) => {
  return (
    <Panel
      header="Metadata"
      padding="padded"
      collapsedContent="Metadata Collapsed"
    >
      <FieldsContainer>
        <FieldColumn>
          <FieldRow>
            <LabelledField label="Start time (ms):">
              <NumberField
                value={match.startTimeMs}
                update={(startTimeMs) => update({ ...match, startTimeMs })}
                args={{ width: "125px" }}
              />
            </LabelledField>
            <LabelledField label="Delayed:">
              <CheckboxField
                value={match.isDelayed}
                update={(isDelayed) => update({ ...match, isDelayed })}
                args={{ falseAsUndefined: true }}
              />
            </LabelledField>
            <LabelledField label="Override Complete:">
              <CheckboxField
                value={match.overrideComplete}
                update={(overrideComplete) =>
                  update({ ...match, overrideComplete })
                }
                args={{ falseAsUndefined: true }}
              />
            </LabelledField>
          </FieldRow>
        </FieldColumn>
      </FieldsContainer>
    </Panel>
  );
};
