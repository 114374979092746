import { checkTypeExhausted } from "Utility";
import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import { NumberField } from "components/Fields/Number";
import { TypedSelectField } from "components/Fields/Select";
import { TextField } from "components/Fields/Text";
import {
  FieldColumn,
  FieldRow,
  FieldsContainer,
} from "components/Fields/styles";
import { FsForwardCupModeFormat } from "db/model/match/Set/CupMode/Forward/FsForwardCupModeFormat";
import { FsReverseCupModeFormat } from "db/model/match/Set/CupMode/Reverse/FsReverseCupModeFormat";
import { FsForwardCupModeLongFormat } from "db/model/match/Set/CupModeLong/Forward/FsForwardCupModeLongFormat";
import { FsMatchSet } from "db/model/match/Set/FsMatchSet";
import { FsPointsDistribution } from "db/model/match/Set/FsPointsDistribution";
import { FsSetFormatBase } from "db/model/match/Set/FsSetFormat";
import { FsMatchmakingFormat } from "db/model/match/Set/Matchmaking/FsMatchmakingFormat";
import { FsRoundsFormat } from "db/model/match/Set/Rounds/FsRoundsFormat";
import { FsTimeAttackFormat } from "db/model/match/Set/TimeAttack/FsTimeAttackFormat";
import { FsWorldTourFormat } from "db/model/match/Set/WorldTour/FsWorldTourFormat";
import { isPlayCondition, playConditions } from "fsModel/Match/PlayCondition";
import { mapStringToPointsDistribution } from "model/PointsDistribution";
import { useEffect, useState } from "react";
import { FsKnockoutSet } from "db/model/match/Set/Knockout/FsKnockoutSet";
import { FsKnockoutFormat } from "db/model/match/Set/Knockout/FsKnockoutFormat";

interface Props {
  set: FsMatchSet;
  update: (newSet: FsMatchSet) => void;
  numTeams: number | undefined;
}

export const SetFormatFields = ({ set, update, numTeams }: Props) => {
  // todo - clean-up
  // For relevant formats, setup new points distributions to reflect new numTeams
  useEffect(() => {
    if (!set.format) {
      return;
    }

    if (set.type === "ForwardCupMode") {
      update({
        ...set,
        format: {
          ...set.format,
          // points: getAdjustedFsCupModePoints(
          //   set.format.points,
          //   numTeams,
          //   set.format.driversPerTeam,
          //   set.format.playTillLeft
          // ),
        },
      });
    } else if (set.type === "ReverseCupMode") {
      update({
        ...set,
        format: {
          ...set.format,
          // points: getAdjustedFsCupModePoints(
          //   set.format.points,
          //   numTeams,
          //   set.format.driversPerTeam,
          //   set.format.playTillLeft
          // ),
        },
      });
    } else if (set.type === "ForwardCupModeLong") {
      update({
        ...set,
        format: {
          ...set.format,
          // points: getAdjustedFsCupModePoints(
          //   set.format.points,
          //   numTeams,
          //   set.format.driversPerTeam,
          //   !!numTeams ? numTeams - 1 : undefined
          // ),
        },
      });
    }
  }, [numTeams]);

  return (
    <FieldsContainer key={set.type} style={{ margin: "5px 0 0 10px" }}>
      <FieldColumn>{getSetFormatFields(set, update, numTeams)}</FieldColumn>
    </FieldsContainer>
  );
};

const getSetFormatFields = (
  set: FsMatchSet,
  updateSet: (newSet: FsMatchSet) => void,
  numTeams: number | undefined
) => {
  if (!set.format) {
    return <></>;
  }

  switch (set.type) {
    case "ForwardCupMode": {
      const update = (format: FsForwardCupModeFormat) =>
        updateSet({
          ...set,
          format: {
            ...format,
            // points: getAdjustedFsCupModePoints(
            //   format.points,
            //   numTeams,
            //   format.driversPerTeam,
            //   format.playTillLeft
            // ),
          },
        });
      return (
        <>
          <FieldRow>
            {NumTracksField(set.format, update)}
            {RoundsPerTrackField(set.format, update)}
            {NumWinnersField(set.format, update)}
            {DriversPerTeamField(set.format, update)}
            {PlayTillLeftField(set.format, update)}
          </FieldRow>
          <FieldRow>
            {PointsLimitField(set.format, update)}
            {/* {CupModePointsFields(set.format, update)} */}
          </FieldRow>
        </>
      );
    }
    case "ForwardCupModeLong": {
      const update = (format: FsForwardCupModeLongFormat) =>
        updateSet({
          ...set,
          format: {
            ...format,
            // points: getAdjustedFsCupModePoints(
            //   format.points,
            //   numTeams,
            //   format.driversPerTeam,
            //   !!numTeams ? numTeams - 1 : undefined
            // ),
          },
        });
      return (
        <>
          <FieldRow>
            {NumTracksField(set.format, update)}
            {PlayConditionField(set.format, update)}
            {NumWinnersField(set.format, update)}
            {DriversPerTeamField(set.format, update)}
          </FieldRow>
          <FieldRow>
            {PointsLimitField(set.format, update)}
            {/* {CupModePointsFields(set.format, update)} */}
          </FieldRow>
        </>
      );
    }
    case "Knockout":
      const update = (format: FsKnockoutFormat) =>
        updateSet({
          ...set,
          format: {
            ...format,
          },
        });
      return (
        <>
          <FieldRow>
            {NumTracksField(set.format, update)}
            {RoundsPerTrackField(set.format, update)}
            {NumWinnersField(set.format, update)}
            {DriversPerTeamField(set.format, update)}
            {PlayTillLeftField(set.format, update)}
            {StartingLivesField(set.format, update)}
          </FieldRow>
        </>
      );
    case "ReverseCupMode": {
      const update = (format: FsReverseCupModeFormat) =>
        updateSet({
          ...set,
          format: {
            ...format,
            // points: getAdjustedFsCupModePoints(
            //   format.points,
            //   numTeams,
            //   format.driversPerTeam,
            //   format.playTillLeft
            // ),
          },
        });
      return (
        <>
          <FieldRow>
            {NumTracksField(set.format, update)}
            {RoundsPerTrackField(set.format, update)}
            {NumWinnersField(set.format, update)}
            {DriversPerTeamField(set.format, update)}
            {PlayTillLeftField(set.format, update)}
          </FieldRow>
          <FieldRow>
            {StartingPointsField(set.format, update)}
            {/* {CupModePointsFields(set.format, update)} */}
          </FieldRow>
        </>
      );
    }
    case "Matchmaking": {
      const update = (format: FsMatchmakingFormat) =>
        updateSet({ ...set, format });
      return (
        <>
          <FieldRow>
            {NumTracksField(set.format, update)}
            {PlayConditionField(set.format, update)}
            {TrackWinReqField(set.format, update)}
            {RoundsLimitField(set.format, update)}
            {NumWinnersField(set.format, update)}
            {DriversPerTeamField(set.format, update)}
          </FieldRow>
          <FieldRow>{PointsFields(set.format, update)}</FieldRow>
        </>
      );
    }
    case "Rounds": {
      const update = (format: FsRoundsFormat) => updateSet({ ...set, format });
      return (
        <>
          <FieldRow>
            {NumTracksField(set.format, update)}
            {RoundsPerTrackField(set.format, update)}
            {NumWinnersField(set.format, update)}
            {DriversPerTeamField(set.format, update)}
          </FieldRow>
          <FieldRow>{PointsFields(set.format, update)}</FieldRow>
        </>
      );
    }
    case "TimeAttack": {
      const update = (format: FsTimeAttackFormat) =>
        updateSet({ ...set, format });
      return (
        <FieldRow>
          {NumTracksField(set.format, update)}
          {NumWinnersField(set.format, update)}
          {DriversPerTeamField(set.format, update)}
        </FieldRow>
      );
    }
    case "WorldTour": {
      const update = (format: FsWorldTourFormat) =>
        updateSet({ ...set, format });
      return (
        <FieldRow>
          {NumTracksField(set.format, update)}
          {PlayConditionField(set.format, update)}
          {TrackWinReqField(set.format, update)}
          {NumWinnersField(set.format, update)}
          {DriversPerTeamField(set.format, update)}
        </FieldRow>
      );
    }
    default:
      return checkTypeExhausted(set);
  }
};

function DriversPerTeamField<T extends FsSetFormatBase>(
  format: T,
  update: (args: T) => void
) {
  return (
    <LabelledField label="Drivers/Team:">
      <NumberField
        value={format.driversPerTeam}
        update={(driversPerTeam) => update({ ...format, driversPerTeam })}
      />
    </LabelledField>
  );
}

function NumTracksField<T extends FsSetFormatBase>(
  format: T,
  update: (args: T) => void
) {
  return (
    <LabelledField label="Tracks:">
      <NumberField
        value={format.numTracks}
        update={(numTracks) => update({ ...format, numTracks })}
      />
    </LabelledField>
  );
}

function NumWinnersField<T extends FsSetFormatBase>(
  format: T,
  update: (args: T) => void
) {
  return (
    <LabelledField label="Winners:">
      <NumberField
        value={format.numWinners}
        update={(numWinners) => update({ ...format, numWinners })}
      />
    </LabelledField>
  );
}

function PlayConditionField<
  T extends FsForwardCupModeLongFormat | FsMatchmakingFormat | FsWorldTourFormat
>(format: T, update: (args: T) => void) {
  return (
    <LabelledField label="Play Condition:">
      <TypedSelectField
        value={format.playCondition}
        options={playConditions}
        isType={isPlayCondition}
        update={(playCondition) =>
          update({ ...format, playCondition: playCondition ?? "BestOf" })
        }
      />
    </LabelledField>
  );
}

function PlayTillLeftField<
  T extends FsForwardCupModeFormat | FsKnockoutFormat | FsReverseCupModeFormat
>(format: T, update: (args: T) => void) {
  return (
    <LabelledField label="Play Till Left:">
      <NumberField
        value={format.playTillLeft}
        update={(playTillLeft) => update({ ...format, playTillLeft })}
      />
    </LabelledField>
  );
}

interface PointsDistributionProps {
  label: string;
  points: FsPointsDistribution | undefined;
  update: (newDist: FsPointsDistribution) => void;
}

const PointsDistributionField = ({
  label,
  points,
  update,
}: PointsDistributionProps) => {
  const [inputText, setInputText] = useState<string | undefined>(
    points?.distribution?.join(", ")
  );

  const onBlur = () => {
    const newDist = mapStringToPointsDistribution(inputText);
    update({
      ...(points ?? { distribution: undefined, dnfPoints: undefined }),
      distribution: newDist,
    });
    setInputText(newDist?.join(", "));
  };

  return (
    <LabelledField label={label}>
      <TextField
        value={inputText}
        update={setInputText}
        onBlur={onBlur}
        args={{ width: "125px" }}
      />
    </LabelledField>
  );
};

const DnfPointsField = (
  dnfPoints: number | undefined,
  update: (dnfPoints: number | undefined) => void
) => {
  return (
    <LabelledField label="DNF Points:">
      <NumberField
        value={dnfPoints}
        update={update}
        args={{ allowNegative: true }}
      />
    </LabelledField>
  );
};

function PointsFields<T extends FsMatchmakingFormat | FsRoundsFormat>(
  format: T,
  update: (args: T) => void
) {
  return (
    <>
      <PointsDistributionField
        label={"Points Distribution:"}
        points={format.points}
        update={(points) => update({ ...format, points })}
      />
      {DnfPointsField(format.points?.dnfPoints, (dnfPoints) =>
        update({
          ...format,
          points: {
            ...(format.points ?? {
              dnfPoints: undefined,
              distribution: undefined,
            }),
            dnfPoints,
          },
        })
      )}
    </>
  );
}

// function CupModePointsFields<
//   T extends
//     | FsForwardCupModeFormat
//     | FsForwardCupModeLongFormat
//     | FsReverseCupModeFormat
// >(format: T, update: (args: T) => void) {
//   const formatKeys = Object.keys(format.points ?? {});
//   const updateCmPoints = (
//     newFsPointsDist: FsPointsDistribution,
//     key: string
//   ) => {
//     const newCmPoints: FsCupModePoints = {};
//     formatKeys.forEach((k) => {
//       if (k === key) {
//         newCmPoints[k] = newFsPointsDist;
//       } else {
//         newCmPoints[k] = format.points![k];
//       }
//     });

//     update({ ...format, points: newCmPoints });
//   };

//   const dnfPoints = !!format.points
//     ? Object.values(format.points).at(0)?.dnfPoints
//     : undefined;
//   const updateDnfPoints = (dnfPoints: number | undefined) => {
//     const newCmPoints: FsCupModePoints = {};
//     formatKeys.forEach((k) => {
//       const oldPoints = !!format.points ? format.points[k] : undefined;
//       newCmPoints[k] = {
//         ...(oldPoints ?? { distribution: undefined, dnfPoints: undefined }),
//         dnfPoints,
//       };
//     });

//     update({ ...format, points: newCmPoints });
//   };

//   return (
//     <>
//       {formatKeys.map((key) => {
//         const points = format.points![key];
//         return (
//           <PointsDistributionField
//             key={key}
//             label={`${key} left`}
//             points={points}
//             update={(newPoints) => updateCmPoints(newPoints, key)}
//           />
//         );
//       })}
//       {formatKeys.length > 0 && DnfPointsField(dnfPoints, updateDnfPoints)}
//     </>
//   );
// }

function PointsLimitField<
  T extends FsForwardCupModeFormat | FsForwardCupModeLongFormat
>(format: T, update: (args: T) => void) {
  return (
    <LabelledField label="Points Limit:">
      <NumberField
        value={format.pointsLimit}
        update={(pointsLimit) => update({ ...format, pointsLimit })}
      />
    </LabelledField>
  );
}

const RoundsLimitField = (
  format: FsMatchmakingFormat,
  update: (args: FsMatchmakingFormat) => void
) => {
  return (
    <LabelledField label="Rounds Limit:">
      <NumberField
        value={format.roundsLimit}
        update={(roundsLimit) => update({ ...format, roundsLimit })}
      />
    </LabelledField>
  );
};

function RoundsPerTrackField<
  T extends
    | FsForwardCupModeFormat
    | FsKnockoutFormat
    | FsReverseCupModeFormat
    | FsRoundsFormat
>(format: T, update: (args: T) => void) {
  return (
    <LabelledField label="Rounds/Track:">
      <NumberField
        value={format.roundsPerTrack}
        update={(roundsPerTrack) => update({ ...format, roundsPerTrack })}
      />
    </LabelledField>
  );
}

const StartingLivesField = (
  format: FsKnockoutFormat,
  update: (args: FsKnockoutFormat) => void
) => {
  return (
    <LabelledField label="Starting Lives:">
      <NumberField
        value={format.startingLives}
        update={(startingLives) => update({ ...format, startingLives })}
      />
    </LabelledField>
  );
};

const StartingPointsField = (
  format: FsReverseCupModeFormat,
  update: (args: FsReverseCupModeFormat) => void
) => {
  return (
    <LabelledField label="Starting Points:">
      <NumberField
        value={format.startingPoints}
        update={(startingPoints) => update({ ...format, startingPoints })}
      />
    </LabelledField>
  );
};

function TrackWinReqField<T extends FsMatchmakingFormat | FsWorldTourFormat>(
  format: T,
  update: (args: T) => void
) {
  return (
    <LabelledField label="Track Win Req:">
      <NumberField
        value={format.trackWinReq}
        update={(trackWinReq) => update({ ...format, trackWinReq })}
      />
    </LabelledField>
  );
}
