import { IStore } from "redux/store";

export const selectTournamentAdmin = (
  state: IStore,
  tournamentId: string | undefined
) => {
  if (!tournamentId) {
    return undefined;
  }

  return state.user.tournamentAdmins[tournamentId];
};
