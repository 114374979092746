import styled from "styled-components";
import { FlexRow } from "styles/layout";

export const MatchTeamsContainer = styled.div`
  flex-grow: 1;
`;

export const MatchTeamContainer = styled(FlexRow)`
  padding: 1px 0;
`;

export const OrgLogoContainer = styled.div`
  width: 25px;
  height: 28px;

  @media (max-width: 600px) {
    width: 22px;
    height: 24px;
  }

  @media (max-width: 500px) {
    width: 20px;
    height: 22px;
  }
`;

export const OrgName = styled.span`
  margin-left: 12px;
  width: 152px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;

  @media (max-width: 600px) {
    margin-left: 5px;
    width: 120px;
    font-size: 11pt;
  }

  @media (max-width: 500px) {
    margin-left: 4px;
    width: 110px;
  }
`;

export const TeamScoresContainer = styled(FlexRow)`
  flex-grow: 1;
  margin-left: 6px;

  @media (max-width: 500px) {
    margin-left: 4px;
  }
`;

export const TeamScore = styled.div`
  width: 35px;
  text-align: center;
  text-align: center;
  font-size: 12.5pt;
  @media (max-width: 600px) {
    font-size: 11pt;
  }
`;

export const SetScore = styled.div`
  width: 24px;
  text-align: center;
  margin-left: 6px;
  color: gray;
  font-size: 10pt;

  @media (max-width: 600px) {
    width: 20px;
    margin-left: 4px;
  }

  @media (max-width: 500px) {
    width: 15px;
    margin-left: 3px;
  }
`;

export const TrackScore = styled.div`
  width: 22px;
  text-align: center;
  font-size: 8.5pt;
  color: darkgray;

  @media (max-width: 1200px) {
    display: none;
  }
`;
