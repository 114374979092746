import { FsForwardCupModeSet } from "db/model/match/Set/CupMode/Forward/FsForwardCupModeSet";
import { newFsForwardCupModeFormat } from "./FsForwardCupModeFormat";
import { newFsMatchSetBase } from "../../FsMatchSet";

export const newFsForwardCupModeSet = (): FsForwardCupModeSet => {
  return {
    ...newFsMatchSetBase(),
    format: newFsForwardCupModeFormat(),
    tracks: [],
    type: "ForwardCupMode",
  };
};
