import { OrgLogo } from "components/OrgLogo/OrgLogo";
import { useSelector } from "react-redux";
import { IStore } from "redux/store";
import {
  MatchTeamContainer,
  OrgLogoContainer,
  OrgName,
  TeamScore,
  TeamScoresContainer,
} from "./styles";
import { selectTeamOrgDisplayName, selectTeamOrgId } from "selectors/Team";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";

interface Props {
  team: FsMatchTeam;
  teamScore: string | undefined;
  orgTime: number;
}

export const ListMatchTeam = ({ team, teamScore, orgTime }: Props) => {
  const orgId = useSelector((state: IStore) =>
    selectTeamOrgId(state, team.teamId, orgTime)
  );
  const displayName = useSelector((state: IStore) =>
    selectTeamOrgDisplayName(state, team.teamId, orgTime)
  );

  return (
    <MatchTeamContainer>
      <OrgLogoContainer>
        <OrgLogo orgId={orgId} />
      </OrgLogoContainer>
      <OrgName>{displayName}</OrgName>
      <TeamScoresContainer>
        {!!teamScore && <TeamScore>{teamScore ?? "-"}</TeamScore>}
      </TeamScoresContainer>
    </MatchTeamContainer>
  );
};
