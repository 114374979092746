import { FsSetFormatBase } from "db/model/match/Set/FsSetFormat";
import { TeSetFormatArgsBase, newTeSetFormatArgsBase } from "../../TeSetFormat";
import { FsForwardCupModeLongFormat } from "db/model/match/Set/CupModeLong/Forward/FsForwardCupModeLongFormat";
import {
  TeCupModePoints,
  mapTeCupModePoints,
} from "../../CupMode/TeCupModePointsDistribution";
import { PlayCondition } from "fsModel/Match/PlayCondition";

export type TeForwardCupModeLongSetFormat = TeSetFormatArgsBase & {
  dnfPoints: number | undefined;
  playCondition: PlayCondition;
  points: TeCupModePoints;
  pointsLimit: number | undefined;
  type: "CupModeLong";
};

export const newTeForwardCupModeLongSetFormat =
  (): TeForwardCupModeLongSetFormat => {
    return {
      ...newTeSetFormatArgsBase(),
      dnfPoints: undefined,
      playCondition: "BestOf",
      points: {},
      pointsLimit: undefined,
      type: "CupModeLong",
    };
  };

export const mapTeForwardCupModeLongSetFormat = (
  base: FsSetFormatBase,
  format: TeForwardCupModeLongSetFormat
): FsForwardCupModeLongFormat => {
  return {
    ...base,
    finalist: true,
    playCondition: format.playCondition,
    points: mapTeCupModePoints(format.points, format.dnfPoints),
    pointsLimit: format.pointsLimit,
    requiredTeamWinners: undefined,
  };
};
