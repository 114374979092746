import { IdData, Match } from "model/IdData";
import { nonUndefined } from "Utility";
import { TeMatchFormat, mapTeMatchFormat } from "./TeMatchFormat";

export type TeMatch = {
  formatKey: string | undefined;
  numTeams: number | undefined;
  startTimeMs: number | undefined;
};

export type TeMatchOrId = TeMatch | string;

export const newTeMatch = (): TeMatch => {
  return {
    formatKey: undefined,
    numTeams: undefined,
    startTimeMs: undefined,
  };
};

export const mapTeMatch = (
  teMatch: TeMatch,
  matchId: string,
  matchFormats: TeMatchFormat[]
): Match | undefined => {
  const matchFormat = matchFormats.find((f) => f.key === teMatch.formatKey);
  if (!matchFormat) {
    return undefined;
  }

  const fsMatch = mapTeMatchFormat(matchFormat);
  fsMatch.startTimeMs = teMatch.startTimeMs;
  if (!!teMatch.numTeams) {
    for (let i = 0; i < teMatch.numTeams; i++) {
      fsMatch.teams?.push("TBD");
    }
  }

  return {
    data: fsMatch,
    id: matchId,
  };
};

export const getMatchIdFromTeMatches = (
  match: TeMatchOrId,
  matchesWithIds: IdData<TeMatch>[]
) => {
  if (typeof match === "string") {
    return match;
  }

  return matchesWithIds.find((m) => m.data === match)?.id;
};

export const getMatchIdsFromTeMatches = (
  matches: TeMatchOrId[],
  matchesWithIds: IdData<TeMatch>[]
) => {
  return nonUndefined(
    matches.map((m) => getMatchIdFromTeMatches(m, matchesWithIds))
  );
};
