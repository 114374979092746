import {
  changeTournamentDraftPublishRequestFirebase,
  db,
  saveTournamentDraftFirebase,
} from "firebase-config";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import { store } from "redux/store";
import { removeTournamentDraft, updateTournamentDraft } from "redux/UserSlice";
import { FsTournamentDraft } from "./FsTournamentDraft";
import { removeTournamentDraftIdFromOrganizationAsync } from "database/tournamentOrganization/tournamentOrganizationInfo";
import { TeTournament } from "model/TournamentEditor/TeTournament";
import { removeNullUndefinedNaNFromObjectRecursive } from "Utility";
import { tournamentDraftCollectionName } from "db/info/collectionNames";

var tournamentDraftSubscriptions: { [key: string]: Unsubscribe } = {};

export const fetchTournamentDrafts = (ids: string[]) => {
  const existingIds = Object.keys(tournamentDraftSubscriptions);
  const newIds = ids.filter((id) => !existingIds.includes(id));

  for (var j = 0; j < newIds.length; j++) {
    const newId = newIds[j];
    const existingSubscription = tournamentDraftSubscriptions[newId];
    if (!!existingSubscription) {
      existingSubscription();
    }

    const docRef = doc(db, tournamentDraftCollectionName, newId);
    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        store.dispatch(
          updateTournamentDraft({
            id: docSnapshot.id,
            data: docSnapshot.data() as FsTournamentDraft,
          })
        );
      }
    });

    tournamentDraftSubscriptions[newId] = unsubscribe;
  }
};

export const fetchAllTournamentDrafts = () => {
  const queryRef = collection(db, tournamentDraftCollectionName);
  const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
    querySnapshot.docChanges().forEach((change) => {
      if (change.type === "removed") {
        store.dispatch(
          removeTournamentDraft({
            id: change.doc.id,
          })
        );
      } else {
        store.dispatch(
          updateTournamentDraft({
            id: change.doc.id,
            data: change.doc.data() as FsTournamentDraft,
          })
        );
      }
    });
  });

  tournamentDraftSubscriptions[tournamentDraftCollectionName] = unsubscribe;
};

export const unsubscribeAllTournamentDrafts = () => {
  const unsubscribes = Object.values(tournamentDraftSubscriptions);
  for (var i = 0; i < unsubscribes.length; i++) {
    unsubscribes[i]();
  }

  tournamentDraftSubscriptions = {};
};

export const saveTournamentDraftAsync = async (
  draft: TeTournament,
  organizationId: string,
  existingDraftId: string | undefined,
  existingTournamentId: string | undefined
): Promise<string> => {
  const sanitizedDraft = removeNullUndefinedNaNFromObjectRecursive(draft);
  const result: any = await saveTournamentDraftFirebase({
    draft: sanitizedDraft,
    existingDraftId,
    organizationId,
    existingTournamentId,
  });

  return result.data.message;
};

export const changeTournamentDraftPublishRequestAsync = async (
  draftId: string,
  organizationId: string,
  publishRequest: boolean
) => {
  try {
    const result: any = await changeTournamentDraftPublishRequestFirebase({
      draftId,
      organizationId,
      publishRequest,
    });

    return result.data.message;
  } catch (error: any) {
    return error.message;
  }
};

export const deleteDraftAsync = async (
  draftId: string,
  organizationId: string | undefined
) => {
  const draftRef = doc(db, tournamentDraftCollectionName, draftId);
  await deleteDoc(draftRef);
  store.dispatch(removeTournamentDraft({ id: draftId }));
  if (!!organizationId) {
    await removeTournamentDraftIdFromOrganizationAsync(organizationId, draftId);
  }
};
