import styled from "styled-components";

export const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
`;
