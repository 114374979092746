import { IStore } from "redux/store";

export const selectOrg = (state: IStore, id: string | undefined) => {
  if (!id) {
    return undefined;
  }
  return state.org.orgs[id];
};

export const selectAllOrgs = (state: IStore) => {
  return Object.values(state.org.orgs);
};
