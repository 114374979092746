import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUsernamesToFetch } from "selectors/Username";
import { AppDispatch, IStore } from "redux/store";
import { fetchUsernames } from "redux/UsersSlice";

export const useFetchUsernames = (uids: string[]) => {
  const dispatch = useDispatch<AppDispatch>();
  const usernamesToFetch = useSelector((state: IStore) =>
    selectUsernamesToFetch(state, uids)
  );

  useEffect(() => {
    if (usernamesToFetch.length > 0) {
      dispatch(fetchUsernames(usernamesToFetch));
    }
  }, [usernamesToFetch]);
};
