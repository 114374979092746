import { IStore } from "redux/store";
import { selectIsEcmStaff } from "../User";
import { mapIdDataToOptions } from "./core";
import { selectMultiple } from "selectors/Core";
import { selectDriver } from "selectors/Driver";
import { selectTeam } from "selectors/Team";
import { getTeamActiveDriverIds } from "fsModel/Team/Team";

export const selectDriverOptions = (state: IStore, driverIds: string[]) => {
  return mapIdDataToOptions(
    selectMultiple(state, selectDriver, driverIds),
    (driver) => driver.data.nickname ?? "",
    selectIsEcmStaff(state)
  );
};

export const selectDriverOptionsFromTeamId = (
  state: IStore,
  teamId: string,
  orgTime: number | undefined
) => {
  const team = selectTeam(state, teamId);
  if (!team) {
    return undefined;
  }

  return selectDriverOptions(state, getTeamActiveDriverIds(team.data, orgTime));
};
