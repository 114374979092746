import { FsSingleSegment } from "db/model/tournament/Segment/Single/FsSingle";
import { IdData } from "model/IdData";
import { TeMatch } from "model/TournamentEditor/TeMatch";
import {
  mapTeTournamentSegmentBaseToFsData,
  newTeTournamentSegmentBase,
  TeTournamentSegmentBase,
} from "../TeTournamentSegment";
import {
  mapTeSingleGroupsToFsData,
  newTeSingleGroup,
  TeSingleGroup,
} from "./TeSingleGroup";

export type TeSingleSegment = TeTournamentSegmentBase<TeSingleGroup> & {
  type: "Single";
};

export const newTeSingleSegment = (): TeSingleSegment => {
  return {
    ...newTeTournamentSegmentBase(),
    groups: [newTeSingleGroup()],
    type: "Single",
  };
};

export const mapTeSingleSegmentToFsData = (
  teSegment: TeSingleSegment,
  teMatchesWithIds: IdData<TeMatch>[]
): FsSingleSegment => {
  return {
    ...mapTeTournamentSegmentBaseToFsData(
      teSegment,
      teMatchesWithIds,
      mapTeSingleGroupsToFsData
    ),
    type: "Single",
  };
};
