import { newFsMatchSetBase } from "../FsMatchSet";
import { newFsWorldTourFormat } from "./FsWorldTourFormat";
import { FsWorldTourSet } from "db/model/match/Set/WorldTour/FsWorldTourSet";

export const newFsWorldTourSet = (): FsWorldTourSet => {
  return {
    ...newFsMatchSetBase(),
    format: newFsWorldTourFormat(),
    tracks: [],
    type: "WorldTour",
  };
};
