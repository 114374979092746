import { FsTournamentSegmentGroupBase } from "db/model/tournament/Segment/FsTournamentSegmentGroup";
import { TeBracketGroup } from "./Bracket/TeBracketGroup";
import { TeQualifierGroup } from "./Qualifier/TeQualifierGroup";
import { TeRoundsGroup } from "./Rounds/TeRoundsGroup";
import { TeSingleGroup } from "./Single/TeSingleGroup";

export type TeSegmentGroupBase = {
  groupId: string | undefined;
  name: string | undefined;
};

export type TeSegmentGroup =
  | TeBracketGroup
  | TeQualifierGroup
  | TeRoundsGroup
  | TeSingleGroup;

export const newTeSegmentGroupBase = (): TeSegmentGroupBase => {
  return {
    groupId: undefined,
    name: undefined,
  };
};

export const mapTeSegmentGroupBaseToFsData = (
  teGroup: TeSegmentGroupBase
): FsTournamentSegmentGroupBase => {
  return {
    groupId: teGroup.groupId,
    name: teGroup.name,
  };
};
