import { newFsMatchSetBase } from "../../FsMatchSet";
import { FsForwardCupModeLongSet } from "db/model/match/Set/CupModeLong/Forward/FsForwardCupModeLongSet";
import { newFsForwardCupModeLongFormat } from "./FsForwardCupModeFormat";

export const newFsForwardCupModeLongSet = (): FsForwardCupModeLongSet => {
  return {
    ...newFsMatchSetBase(),
    format: newFsForwardCupModeLongFormat(),
    tracks: [],
    type: "ForwardCupModeLong",
  };
};
