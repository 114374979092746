import { TeMatchOrId } from "model/TournamentEditor/TeMatch";

export type TeRound = {
  matches: TeMatchOrId[];
  roundNum: number | undefined;
};

export const newTeRound = (roundNum?: number): TeRound => {
  return {
    matches: [],
    roundNum,
  };
};
