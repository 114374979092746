import { getTimeMs } from "TimeHelper";
import { ListMatchTeam } from "./ListMatchTeam";
import { MatchTeamsContainer } from "./styles";
import { hasMatchStarted } from "fsModel/Match/MatchStatus";
import { getTeams } from "fsModel/Match/Match";
import { FsMatch } from "db/model/match/FsMatch";
import { getMatchTeamDisplayScores } from "fsModel/Match/MatchScore";

interface Props {
  match: FsMatch;
  orgTime: number;
}

export const MatchTeamsList = ({ match, orgTime }: Props) => {
  // todo - extract to reusable method, e.g. logic to show scores is used in other places too
  const showScores =
    !match.isDelayed &&
    ((match.startTimeMs ?? 0) < getTimeMs() ||
      hasMatchStarted(match) ||
      match.overrideComplete);

  const scores = showScores ? getMatchTeamDisplayScores(match) : undefined;
  return (
    <MatchTeamsContainer>
      {getTeams(match).map((team, i) => (
        <ListMatchTeam
          key={`${i} - ${team.teamId ?? "?"}`}
          team={team}
          teamScore={
            !!team.teamId && !!scores ? scores[team.teamId] : undefined
          }
          orgTime={orgTime}
        />
      ))}
    </MatchTeamsContainer>
  );
};
