import {
  FsTournamentSegment,
  FsTournamentSegmentBase,
} from "db/model/tournament/Segment/FsTournamentSegment";
import { FsTournamentSegmentGroup } from "db/model/tournament/Segment/FsTournamentSegmentGroup";
import { getBracketMatchIds } from "fsModel/Bracket/Bracket";
import { checkTypeExhausted, nonUndefined } from "Utility";

export const getSegmentMatchIds = (segment: FsTournamentSegment) => {
  switch (segment.type) {
    case "Bracket":
      return segment.groups?.flatMap(getBracketMatchIds) ?? [];
    case "Qualifier":
      return segment.groups?.flatMap((g) => g.matchIds ?? []) ?? [];
    case "Season":
    case "Swiss":
      return (
        segment.groups?.flatMap(
          (g) => g.rounds?.flatMap((r) => r.matchIds ?? []) ?? []
        ) ?? []
      );
    case "Single":
      return nonUndefined(segment.groups?.map((g) => g.matchId) ?? []) ?? [];
    default:
      return checkTypeExhausted(segment);
  }
};

export const getSegmentGroupByName = <
  TGroup extends FsTournamentSegmentGroup,
  TSegment extends FsTournamentSegmentBase<TGroup>
>(
  segment: TSegment,
  groupName: string
): TGroup | undefined => {
  return segment.groups?.length === 1
    ? segment.groups[0]
    : segment.groups?.find((g) => g.name === groupName);
};
