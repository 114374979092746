import { IStore } from "redux/store";
import { selectTournamentFromMatchId } from "./Match";

export const selectAllTrackIds = (state: IStore) => {
  return Object.keys(state.track.tracks);
};

export const selectTrack = (state: IStore, trackId: string | undefined) => {
  if (!trackId) {
    return undefined;
  }

  return state.track.tracks[trackId];
};

export const selectAllTracks = (state: IStore) => {
  return Object.values(state.track.tracks);
};

export const selectTrackName = (state: IStore, trackId: string | undefined) => {
  return selectTrack(state, trackId)?.data.name;
};

export const selectTrackIdsByMatchId = (
  state: IStore,
  matchId: string | undefined
) => {
  if (!matchId) {
    return undefined;
  }

  const tournament = selectTournamentFromMatchId(state, matchId);
  return tournament?.data.trackIds;
};
