import {
  driversPath,
  homePath,
  loginPath,
  tournamentsPath,
  orgsPath,
  tracksPath,
  manualPath,
  pickemPath,
} from "pages/PageConfigs";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountDropdown } from "./AccountDropdown/AccountDropdown";
import {
  NavBarContainer,
  NavOptions,
  NavBarAccountContainer,
  LoginButton,
  HomeNavItem,
  LogoImg,
  NavItem,
} from "./styles";
import { selectUserUid } from "selectors/User";
import { isEcmStaff } from "eCM";
import { MaxWidth } from "styles/layout";

export const NavBar = () => {
  const navigate = useNavigate();
  const prevLocation = useLocation();
  const uid = useSelector(selectUserUid);
  const toLogin = () => {
    navigate(loginPath, {
      replace: true,
      state: { prev: prevLocation.pathname },
    });
  };

  const userIsEcmStaff = isEcmStaff(uid);
  return (
    <NavBarContainer>
      <MaxWidth>
        <NavOptions>
          <HomeNavItem to={homePath}>
            <LogoImg src={`/images/ecm/logo.png`} />
          </HomeNavItem>
          {userIsEcmStaff && <NavItem to={driversPath}>Drivers</NavItem>}
          {userIsEcmStaff && <NavItem to={orgsPath}>Orgs</NavItem>}
          {userIsEcmStaff && <NavItem to={tracksPath}>Tracks</NavItem>}
          <NavItem to={tournamentsPath}>Tournaments</NavItem>
          <NavItem to={manualPath}>Manual</NavItem>
          {userIsEcmStaff && <NavItem to={pickemPath}>Pickem</NavItem>}
          <NavBarAccountContainer>
            {!uid ? (
              <LoginButton onClick={toLogin}>Login</LoginButton>
            ) : (
              <AccountDropdown />
            )}
          </NavBarAccountContainer>
        </NavOptions>
      </MaxWidth>
    </NavBarContainer>
  );
};
