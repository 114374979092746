import { IdData } from "model/IdData";
import { TournamentDraft } from "model/TournamentDraft/TournamentDraft";
import { selectMatch } from "selectors/Match";
import { selectAllTournamentDrafts } from "selectors/TournamentDraft";
import { selectUserIsOrganizer } from "selectors/TournamentOrganization";
import { selectIsEcmStaff } from "selectors/User";
import { IStore } from "redux/store";
import { hasMatchStarted, isMatchComplete } from "fsModel/Match/MatchStatus";
import { getTournamentMatchIds } from "fsModel/Tournament/Tournament";
import { FsTournament } from "db/model/tournament/FsTournament";
import { selectMultiple } from "selectors/Core";

export type TournamentStatus =
  | "Upcoming"
  | "Ongoing"
  | "Completed"
  | "Draft"
  | "Publish Request";

export const statusIsDraft = (status: TournamentStatus) => {
  return status === "Draft" || status === "Publish Request";
};

export type TournamentPageItem = {
  id: string;
  name: string | undefined;
  organizationId: string | undefined;
  status: TournamentStatus;
  lastModified: number | undefined;
  isOrganizer: boolean;
};

const mapDraft = (
  state: IStore,
  draft: IdData<TournamentDraft>
): TournamentPageItem => {
  return {
    id: draft.id,
    name: draft.data.draft.name,
    organizationId: draft.data.organizationId,
    status: draft.data.publishRequest ? "Publish Request" : "Draft",
    lastModified: draft.data.lastModified,
    isOrganizer:
      selectIsEcmStaff(state) ||
      (selectUserIsOrganizer(state, draft.data.organizationId) ?? false),
  };
};

const mapTournament = (
  state: IStore,
  tournamentId: string,
  tournamentName: string | undefined,
  organizationId: string | undefined,
  status: TournamentStatus
): TournamentPageItem => {
  return {
    id: tournamentId,
    name: tournamentName,
    organizationId,
    status,
    lastModified: undefined,
    isOrganizer:
      selectIsEcmStaff(state) ||
      (selectUserIsOrganizer(state, organizationId) ?? false),
  };
};

export const selectTournamentsPageUpcomingOngoing = (state: IStore) => {
  const uid = state.user.uid;
  if (!uid) {
    return;
  }

  const drafts = selectAllTournamentDrafts(state);
  const tournaments = selectUpcomingOngoingTournaments(state);
  return drafts
    .map((d) => mapDraft(state, d))
    .concat(
      tournaments.map((t) =>
        mapTournament(
          state,
          t.id,
          t.data.name,
          selectTournamentOrganizationId(state, t.id),
          selectTournamentStatus(state, t.data)
        )
      )
    );
};

export const selectTournamentsPageCompleted = (state: IStore) => {
  const uid = state.user.uid;
  if (!uid) {
    return;
  }

  const tournaments = selectCompletedTournaments(state);
  return tournaments.map((t) =>
    mapTournament(
      state,
      t.id,
      t.data.name,
      selectTournamentOrganizationId(state, t.id),
      selectTournamentStatus(state, t.data)
    )
  );
};

export const selectUpcomingOngoingTournaments = (state: IStore) => {
  return Object.values(state.user.tournaments).filter((t) => {
    const status = selectTournamentStatus(state, t.data);
    return status === "Ongoing" || status === "Upcoming";
  });
};

export const selectCompletedTournaments = (state: IStore) => {
  return Object.values(state.user.tournaments).filter((t) => {
    const status = selectTournamentStatus(state, t.data);
    return status === "Completed";
  });
};

export const selectTournamentStatus = (
  state: IStore,
  tournament: FsTournament
): TournamentStatus => {
  const matchIds = getTournamentMatchIds(tournament);
  const matches = selectMultiple(state, selectMatch, matchIds).map(
    (m) => m.data
  );

  if (!tournament.segments || matchIds.length === 0) {
    return "Upcoming";
  }

  if (matches.every(isMatchComplete)) {
    return "Completed";
  }

  if (matches.every((m) => !hasMatchStarted(m))) {
    return "Upcoming";
  }

  return "Ongoing";
};

export const selectTournamentOrganizationId = (
  state: IStore,
  tournamentId: string
) => {
  return Object.values(state.user.tournamentOrganizations).find((o) =>
    o.data.tournamentIds.includes(tournamentId)
  )?.id;
};
