import { FsTrack } from "db/model/track/FsTrack";
import { TournamentDraft } from "model/TournamentDraft/TournamentDraft";
import { TeTournamentSegment } from "model/TournamentEditor/Segment/TeTournamentSegment";
import { TeMatchFormat } from "model/TournamentEditor/TeMatchFormat";
import { TeTeam } from "model/TournamentEditor/TeTeam";
import { TeTournament } from "model/TournamentEditor/TeTournament";

export type FsTournamentDraft = {
  draft: FsTeTournament | undefined;
  existingTournamentId: string | undefined;
  lastModified: number | undefined;
  organizationId: string | undefined;
  publishRequest: boolean | undefined;
  publishRequestTime: number | undefined;
};

export const mapFsTournamentDraft = (
  fsModel: FsTournamentDraft
): TournamentDraft | undefined => {
  if (!fsModel.draft) {
    return undefined;
  }

  return {
    draft: mapFsTeTournament(fsModel.draft),
    existingTournamentId: fsModel.existingTournamentId,
    lastModified: fsModel.lastModified,
    organizationId: fsModel.organizationId,
    publishRequest: fsModel.publishRequest ?? false,
    publishRequestTime: fsModel.publishRequestTime,
  };
};

type FsTeTournament = {
  id: string | undefined;
  description: string | undefined;
  filterName: string | undefined;
  name: string | undefined;
  segments: TeTournamentSegment[] | undefined;
  teams: TeTeam[] | undefined;
  trackIds: (string | undefined)[];
  trigram: string | undefined;
  imageIdOverride: string | undefined;
  startTimeOverrideMs: number | undefined;
  endTimeOverrideMs: number | undefined;

  newTracks: FsTrack[] | undefined;
  matchFormats: TeMatchFormat[] | undefined;
};

const mapFsTeTournament = (fsModel: FsTeTournament): TeTournament => {
  return {
    id: fsModel.id,
    description: fsModel.description,
    filterName: fsModel.filterName,
    name: fsModel.name,
    segments: fsModel.segments ?? [],
    teams: fsModel.teams ?? [],
    trackIds: fsModel.trackIds ?? [],
    trigram: fsModel.trigram,
    imageIdOverride: fsModel.imageIdOverride,
    newTracks: fsModel.newTracks ?? [],
    matchFormats: fsModel.matchFormats ?? [],
  };
};
