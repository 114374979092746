import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentChange, DocumentData } from "firebase/firestore";
import { IFetchSlice, initialFetchState } from "./FetchSlice";
import { Driver } from "model/IdData";
import { FsDriver } from "db/model/driver/FsDriver";

export interface IDriverSlice extends IFetchSlice {
  drivers: { [key: string]: Driver };
}

const initialState: IDriverSlice = {
  ...initialFetchState,
  drivers: {},
};

export const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    updateDrivers: (
      state,
      action: PayloadAction<{
        docChanges: DocumentChange<DocumentData>[];
      }>
    ) => {
      const { docChanges } = action.payload;
      for (let i = 0; i < docChanges.length; i++) {
        const { doc } = docChanges[i];
        state.drivers[doc.id] = { id: doc.id, data: doc.data() as FsDriver };
      }

      if (!state.initialFetchComplete) {
        state.initialFetchComplete = true;
      }
    },
  },
});

export const { updateDrivers } = driverSlice.actions;
export const driverReducer = driverSlice.reducer;
