import { ReactNode, useState } from "react";
import { Header } from "./styles";
import { FlexColumn } from "styles/layout";

interface Props {
  header: string;
  children: ReactNode;
  initiallyExpanded?: boolean;
}

// todo - combinable with the collapse functionality in panel?
export const ExpandableHeader = ({
  header,
  children,
  initiallyExpanded,
}: Props) => {
  const [expanded, setExpanded] = useState(initiallyExpanded ?? false);

  return (
    <FlexColumn>
      <Header onClick={() => setExpanded(!expanded)}>{`${header} ${
        expanded ? "▲" : "▼"
      }`}</Header>
      {expanded && children}
    </FlexColumn>
  );
};
