import { Panel } from "components/Panel/Panel";
import { DISCORD_LINK } from "eCM";
import { PageContent, ColumnContainer, GrowColumn } from "pages/styles";
import { useSelector } from "react-redux";
import { selectTournamentName } from "selectors/Tournament";
import { selectUsername } from "selectors/Username";
import { IStore } from "redux/store";
import { FlexColumn, FlexRow, MaxWidth } from "styles/layout";
import { EmptyGrowPanelText } from "styles/text";
import { onlyUnique } from "Utility";
import { useFetchUsernames } from "hooks/useFetchUsernames";

export const HomePage = () => {
  const tournamentOrganizations = useSelector((state: IStore) =>
    Object.values(state.user.tournamentOrganizations)
  );
  const adminTournamentIds = useSelector(
    (state: IStore) => state.user.adminTournamentIds
  );

  const uidsToFetch = tournamentOrganizations
    .flatMap((o) => o.data.organizerUids)
    .filter(onlyUnique);
  useFetchUsernames(uidsToFetch);

  return (
    <PageContent>
      <MaxWidth>
        <ColumnContainer>
          <GrowColumn>
            <Panel header="Tournament Organizations" padding="padded">
              {tournamentOrganizations.length > 0 ? (
                <FlexColumn style={{ rowGap: "5px" }}>
                  {tournamentOrganizations.map((o) => (
                    <FlexRow
                      key={o.id}
                      style={{
                        fontSize: "1.1em",
                        fontWeight: 500,
                      }}
                    >
                      {o.data.name}
                      <FlexRow
                        style={{
                          paddingLeft: "10px",
                          columnGap: "8px",
                          fontSize: "0.8em",
                          color: "gray",
                          fontWeight: "normal",
                        }}
                      >
                        <span>Organizers:</span>
                        {o.data.organizerUids.map((uid) => (
                          <Username key={uid} id={uid} />
                        ))}
                      </FlexRow>
                    </FlexRow>
                  ))}
                </FlexColumn>
              ) : (
                <EmptyGrowPanelText>
                  Account not linked to any tournament organizations.
                  <br />
                  To get started join our{" "}
                  <a href={DISCORD_LINK} target="_blank">
                    Discord
                  </a>
                  , select the "Organizer" role in the "role-select" channel,
                  and make a post in the "request-access" channel.
                </EmptyGrowPanelText>
              )}
            </Panel>
            {adminTournamentIds.length > 0 ||
            tournamentOrganizations.length === 0 ? (
              <Panel header="Admin" padding="padded">
                {adminTournamentIds.length > 0 ? (
                  <FlexColumn>
                    {adminTournamentIds.map((id) => (
                      <TournamentName key={id} id={id} />
                    ))}
                  </FlexColumn>
                ) : (
                  <EmptyGrowPanelText>
                    Account not linked to any tournaments as an admin
                  </EmptyGrowPanelText>
                )}
              </Panel>
            ) : (
              <></>
            )}
          </GrowColumn>
        </ColumnContainer>
      </MaxWidth>
    </PageContent>
  );
};

const TournamentName = ({ id }: { id: string }) => {
  const tournamentName = useSelector((state: IStore) =>
    selectTournamentName(state, id)
  );
  return <span>{tournamentName}</span>;
};

const Username = ({ id }: { id: string }) => {
  const username = useSelector((state: IStore) => selectUsername(state, id));
  return <span>{username ?? "..."}</span>;
};
