import { Panel } from "components/Panel/Panel";
import { useSelector } from "react-redux";
import { selectCompletedMatches } from "selectors/Match";
import { IStore } from "redux/store";
import { EmptyGrowPanelText } from "styles/text";
import { RecentMatches } from "./RecentMatches";

interface Props {
  matchIds: string[];
}

export const RecentMatchesIds = ({ matchIds }: Props) => {
  const recentMatches = useSelector((state: IStore) =>
    selectCompletedMatches(state, matchIds)
  );

  if (recentMatches.length === 0) {
    return (
      <Panel>
        <EmptyGrowPanelText>No Recent Matches</EmptyGrowPanelText>
      </Panel>
    );
  }

  return <RecentMatches matches={recentMatches} />;
};
