import { FsMatch } from "db/model/match/FsMatch";
import { FormatContainer } from "./styles";
import { getMatchFormatKeys } from "fsModel/Match/MatchFormat";

interface Props {
  match: FsMatch;
}

export const MatchFormat = ({ match }: Props) => {
  return (
    <FormatContainer>
      {getMatchFormatKeys(match.format, match.sets ?? []).map((x, i) => (
        <span key={`${i}: ${x}`}>{x}</span>
      ))}
    </FormatContainer>
  );
};
