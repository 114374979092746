import { ReactNode, useState } from "react";
import { Button } from "styles/elements";
import { ButtonSpinner } from "styles/components";

interface Props {
  children: ReactNode;
  onClickAsync: () => Promise<void>;
  disabled?: boolean;
}

export const AsyncButton = ({
  children,
  onClickAsync,
  disabled = false,
}: Props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const onClickInternal = async () => {
    setShowSpinner(true);
    await onClickAsync();
    setShowSpinner(false);
  };

  return (
    <Button onClick={onClickInternal} disabled={showSpinner || disabled}>
      {showSpinner ? <ButtonSpinner /> : children}
    </Button>
  );
};
