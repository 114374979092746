import { TournamentOrganization } from "model/TournamentOrganization/TournamentOrganization";

export type FsTournamentOrganization = {
  name: string | undefined;
  organizerUids: string[] | undefined;
  tournamentDraftIds: string[] | undefined;
  tournamentIds: string[] | undefined;
};

export const mapFsTournamentOrganization = (
  fsModel: FsTournamentOrganization
): TournamentOrganization => {
  return {
    name: fsModel.name ?? "-",
    organizerUids: fsModel.organizerUids ?? [],
    tournamentDraftIds: fsModel.tournamentDraftIds ?? [],
    tournamentIds: fsModel.tournamentIds ?? [],
  };
};
