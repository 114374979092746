import { nonEmpty } from "Utility";
import { InputFieldArgs } from "./Core";
import { StyledInput } from "./styles";

type TextFieldArgs = InputFieldArgs & {};

interface Props {
  value: string | undefined;
  update: (newVal: string | undefined) => void; // todo - rename to onChange, and on similar Fields
  onBlur?: () => void;
  args?: TextFieldArgs;
}

export const TextField = ({ value, update, onBlur, args }: Props) => {
  return (
    <StyledInput
      type="text"
      disabled={args?.disabled}
      placeholder={args?.placeholder}
      value={value}
      onChange={(e) => {
        update(nonEmpty(e.target.value));
      }}
      onBlur={onBlur}
      style={{ width: args?.width ? args.width : "100px" }}
    />
  );
};
