import { nonUndefined } from "Utility";
import { FsTimeAttackRoundWeighting } from "db/model/match/Set/TimeAttack/Scoring/FsTimeAttackRoundWeighting";
import { FsTimeAttackScoring } from "db/model/match/Set/TimeAttack/Scoring/FsTimeAttackScoring";
import {
  FsTimeAttackScoringAverage,
  isFsTimeAttackScoringAverage,
} from "db/model/match/Set/TimeAttack/Scoring/FsTimeAttackScoringAverage";
import { isFsTimeAttackScoringSum } from "db/model/match/Set/TimeAttack/Scoring/FsTimeAttackScoringSum";
import { FsTimeAttackTimes } from "db/model/match/Set/TimeAttack/Track/Round/FsTimeAttackRound";
import { AdjustedTime, combineAdjustedTimes } from "fsModel/Match/AdjustedTime";

export type TimeAttackRoundWeightingOrder = "Best" | "Serial";

export type TimeAttackScore = {
  score: number;
  orderedTimes: (AdjustedTime | undefined)[];
};

export const getTimeAttackRoundBestTime = (times: FsTimeAttackTimes) => {
  const finishTimes = nonUndefined(Object.values(times)).filter(
    (t) => !!t && t > 0
  );
  if (finishTimes.length === 0) {
    return undefined;
  }

  return Math.min(...finishTimes);
};

export const calculateTimeAttackTeamScoring = (
  teamRoundTimes: (AdjustedTime | undefined)[][],
  scoring: FsTimeAttackScoring
) => {
  const combinedTimes = teamRoundTimes.map((roundTimes) => {
    const finishTimes = nonUndefined(roundTimes);
    if (finishTimes.length > 0) {
      return combineAdjustedTimes(finishTimes);
    }

    return undefined;
  });

  return calculateTimeAttackScoring(combinedTimes, scoring);
};

const calculateTimeAttackScoring = (
  roundTimes: (AdjustedTime | undefined)[],
  scoring: FsTimeAttackScoring
) => {
  if (isFsTimeAttackScoringSum(scoring)) {
    return sumTimeAttackScore(roundTimes);
  }

  if (isFsTimeAttackScoringAverage(scoring)) {
    return averageTimeAttackScore(roundTimes, scoring);
  }
};

const sumTimeAttackScore = (
  times: (AdjustedTime | undefined)[]
): TimeAttackScore => {
  const score = times.reduce((a, b) => a + (b?.time ?? 0), 0);
  return { score, orderedTimes: times };
};

export const averageTimeAttackScore = (
  times: (AdjustedTime | undefined)[],
  scoring: FsTimeAttackScoringAverage
): TimeAttackScore => {
  if (!scoring.roundWeighting) {
    return { score: 0, orderedTimes: [] };
  }

  const timesWithWeighting = scoring.roundWeighting.map((roundWeighting) =>
    getTimeAttackRoundTimesWithWeighting(times, roundWeighting)
  );

  const score = timesWithWeighting
    .map((times) =>
      times.reduce(
        (a, b) =>
          a + (!!b.roundTime ? (b.roundTime.time * b.weighting) / 100 : 0),
        0
      )
    )
    .reduce((a, b) => a + b, 0);

  const orderedTimes = timesWithWeighting.flatMap((times) =>
    times.flatMap((t) => t.roundTime)
  );

  return { score: roundScore(score), orderedTimes };
};

const roundScore = (score: number) => {
  return Math.round((score + Number.EPSILON) * 1) / 1;
};

export const getTimeAttackRoundTimesWithWeighting = (
  times: (AdjustedTime | undefined)[],
  weighting: FsTimeAttackRoundWeighting
): { weighting: number; roundTime: AdjustedTime | undefined }[] => {
  const weightingTimes = getRelevantRoundTimes(weighting, times);
  const orderedTimes = orderTimes(weightingTimes, weighting.order ?? "Best");

  return (
    weighting.weightings?.map((w, idx) => {
      return { weighting: w, roundTime: orderedTimes[idx] };
    }) ?? []
  );
};

const getRelevantRoundTimes = (
  weighting: FsTimeAttackRoundWeighting,
  roundTimes: (AdjustedTime | undefined)[]
) => {
  if (!weighting.startRoundNum) {
    return [];
  }

  const startIdx = weighting.startRoundNum - 1;
  const endRoundNum = weighting.endRoundNum ?? weighting.startRoundNum;
  const endIdx = endRoundNum;
  return roundTimes.slice(startIdx, endIdx);
};

const orderTimes = (
  times: (AdjustedTime | undefined)[],
  order: TimeAttackRoundWeightingOrder
) => {
  if (order === "Best") {
    return times.sort((a, b) => {
      if (!a) {
        return 1;
      }

      if (!b) {
        return -1;
      }

      return a.time - b.time;
    });
  }

  return times;
};
