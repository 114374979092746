import styled from "styled-components";
import { CenterFlexColumn, FlexRow } from "styles/layout";
import { Theme } from "theme";

export const ModalWrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: ${Theme.modalBackground};
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

export const ModalContent = styled.div`
  border-radius: 6px;
  position: fixed;
  background: ${Theme.modalContentBackground};
  max-width: 95%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DataModalContainer = styled(CenterFlexColumn)`
  position: relative;
`;

export const ModalButtonsContainer = styled(FlexRow)`
  column-gap: 5px;
  margin: 5px;
`;

export const ModalSpinnerWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${Theme.modalSpinnerBackground};
  display: ${({ visible }) => (visible ? "block" : "none")};
`;
