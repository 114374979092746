import { Panel } from "components/Panel/Panel";
import { FsBracket } from "db/model/tournament/Segment/Bracket/FsBracket";
import { GrowColumn } from "pages/styles";
import { EmptyGrowPanelText } from "styles/text";

interface Props {
  bracket: FsBracket;
  orgTime: number | undefined;
}

export const BracketTabContent = ({ bracket, orgTime }: Props) => {
  return (
    <GrowColumn>
      <Panel type="information">
        <EmptyGrowPanelText>
          This tab is a work in progress. You can view this segments matches
          under the 'Matches' tab.
        </EmptyGrowPanelText>
      </Panel>
    </GrowColumn>
  );
};
