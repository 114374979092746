import styled from "styled-components";
import { Button } from "styles/elements";
import { FlexRow } from "styles/layout";

export const TracksContainer = styled(FlexRow)`
  flex-wrap: wrap;
  gap: 10px;
`;

export const TrackContainer = styled(FlexRow)``;

export const AddButton = styled(Button)``;

export const RemoveButton = styled(Button)`
  width: 20px;
  height: 20px;
  padding: 0px;
`;
