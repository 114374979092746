import styled from "styled-components";
import { Theme } from "theme";

export const PrivacyAndTermsContainer = styled.div`
  padding: ${Theme.bigPanelPadding};
`;

export const PrivacyAndTermsHeader = styled.h3`
  margin: 20px 0 0px 0;
`;

export const PrivacyAndTermsSpan = styled.span`
  display: block;
`;
