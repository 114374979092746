import { useSelector } from "react-redux";
import { IStore } from "redux/store";
import { MatchesViewerMatch } from "./MatchesViewerMatch";
import { selectMultiple } from "selectors/Core";
import { selectMatch } from "selectors/Match";

interface Props {
  matchIds: string[];
  orgTime: number | undefined;
}

export const MatchesViewer = ({ matchIds, orgTime }: Props) => {
  const matches = useSelector((state: IStore) =>
    selectMultiple(state, selectMatch, matchIds)
  );
  const sortedMatches = matches.sort(
    (a, b) => (a.data.startTimeMs ?? 0) - (b.data.startTimeMs ?? 0)
  );

  return (
    <div>
      {sortedMatches.map((m) => (
        <MatchesViewerMatch
          key={m.id}
          matchId={m.id}
          match={m.data}
          orgTime={orgTime}
        />
      ))}
    </div>
  );
};
