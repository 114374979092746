import { AsyncButton } from "components/Core/AsyncButton/AsyncButton";
import { HideableText } from "components/Core/HideableText/HideableText";
import { getMatchApiKeyFirebase, rescindApiKeyFirebase } from "firebase-config";
import { useState } from "react";
import { CenterFlexColumn } from "styles/layout";
import { ErrorText } from "styles/text";

interface Props {
  matchId: string;
}

export const MatchApiKey = ({ matchId }: Props) => {
  const [errorText, setErrorText] = useState("");
  const [apiKey, setApiKey] = useState("");

  const getApiKeyAsync = async () => {
    try {
      // todo - proper way to get result?
      const result = await getMatchApiKeyFirebase({ matchId });
      setApiKey((result.data as any).key);
    } catch (e: any) {
      setErrorText(e.message);
    }
  };

  const rescindApiKeyAsync = async () => {
    try {
      // todo - proper way to get result?
      await rescindApiKeyFirebase({ matchId });
      setApiKey("");
    } catch (e: any) {
      setErrorText(e.message);
    }
  };

  return (
    <CenterFlexColumn style={{ rowGap: "10px" }}>
      {!apiKey ? (
        <AsyncButton onClickAsync={getApiKeyAsync}>Get Api Key</AsyncButton>
      ) : (
        <HideableText text={apiKey} />
      )}

      {!!apiKey && (
        <AsyncButton onClickAsync={rescindApiKeyAsync}>
          Rescind Api Key
        </AsyncButton>
      )}

      {!!errorText && <ErrorText>{errorText}</ErrorText>}
    </CenterFlexColumn>
  );
};
