import {
  FsPositionSetPointRules,
  FsSetPointRules,
  FsWDLSetPointRules,
  isFsPositionSetPointRules,
} from "db/model/match/Set/FsSetPointRules";
import { Positions } from "./Positions";
import { getTeamResult } from "./Results";

const DEFAULT_POINTS: FsWDLSetPointRules = { win: 1, draw: 0, loss: 0 };

export const getTeamSetPoints = (
  teamId: string,
  pointRules: FsSetPointRules | undefined,
  positions: Positions,
  numWinners: number
) => {
  if (!pointRules) {
    return getTeamWDLSetPoints(teamId, DEFAULT_POINTS, positions, numWinners);
  }

  return isFsPositionSetPointRules(pointRules)
    ? getTeamPositionSetPoints(teamId, pointRules, positions)
    : getTeamWDLSetPoints(teamId, pointRules, positions, numWinners);
};

export const getTeamPositionSetPoints = (
  teamId: string,
  points: FsPositionSetPointRules,
  positions: Positions
) => {
  const teamPosition = positions[teamId];
  if (!points.position || !teamPosition) {
    return 0;
  }

  return points.position[teamPosition - 1] ?? 0;
};

export const getTeamWDLSetPoints = (
  teamId: string,
  points: FsWDLSetPointRules,
  positions: Positions,
  numWinners: number
) => {
  const teamResult = getTeamResult(teamId, positions, numWinners);
  switch (teamResult) {
    case "W":
      return points.win;
    case "D":
      return points.draw;
    case "L":
      return points.loss;
    default:
      return 0;
  }
};

export const getBestPointsDifferential = (
  points: FsSetPointRules | undefined
) => {
  if (!points) {
    return 1; // 1 for Win, 0 for Loss
  }

  return isFsPositionSetPointRules(points)
    ? Math.max(...(points.position ?? []), 0)
    : (points.win ?? 1) - (points.loss ?? 0);
};
