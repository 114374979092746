import { Action, ctorActionColumn } from "components/DataGrid/Columns/Action";
import { ctorDateTimeColumn } from "components/DataGrid/Columns/DateTime";
import { ctorTextColumn } from "components/DataGrid/Columns/Text";
import { DataGrid } from "components/DataGrid/DataGrid";
import { DataGridColumn } from "components/DataGrid/DataGridColumn";
import { Panel } from "components/Panel/Panel";
import {
  buildEditDraftPath,
  buildEditTournamentPath,
  buildManageTournamentPath,
  createDraftPath,
} from "pages/PageConfigs";
import { PageContent, ColumnContainer, GrowColumn } from "pages/styles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectTournamentOrganizationNameLookup } from "selectors/TournamentOrganization";
import { Button } from "styles/elements";
import { MaxWidth } from "styles/layout";
import { anyStringContains } from "Utility";
import {
  selectTournamentsPageCompleted,
  selectTournamentsPageUpcomingOngoing,
  statusIsDraft,
  TournamentPageItem,
} from "./selector";
import { changeTournamentDraftPublishRequestAsync } from "database/tournamentDraft/tournamentDraftInfo";
import { DISCORD_LINK } from "eCM";
import { EmptyGrowPanelText } from "styles/text";

// todo - needs to be reworked
export const TournamentsPage = () => {
  const navigate = useNavigate();
  const tournamentOrganizationNameLookup = useSelector(
    selectTournamentOrganizationNameLookup
  );
  const upcomingOngoing =
    useSelector(selectTournamentsPageUpcomingOngoing) ?? [];
  const completed = useSelector(selectTournamentsPageCompleted) ?? [];

  const onCreateNewClick = () => {
    navigate(createDraftPath);
  };

  const onEditClick = (tournamentId: string) => {
    navigate(buildEditTournamentPath(tournamentId));
  };

  const onDraftEditClick = (draftId: string) => {
    navigate(buildEditDraftPath(draftId));
  };

  const onManageClick = (draftId: string) => {
    navigate(buildManageTournamentPath(draftId));
  };

  return (
    <PageContent>
      <MaxWidth>
        <ColumnContainer>
          <GrowColumn>
            {Object.keys(tournamentOrganizationNameLookup).length > 0 ? (
              <Button
                onClick={onCreateNewClick}
              >{`Create New Tournament`}</Button>
            ) : (
              <Panel padding="padded">
                <EmptyGrowPanelText>
                  To create a tournament you need to be part of a tournament
                  organization.
                  <br />
                  To get started join our{" "}
                  <a href={DISCORD_LINK} target="_blank">
                    Discord
                  </a>
                  , select the "Organizer" role in the "role-select" channel,
                  and make a post in the "request-access" channel.
                </EmptyGrowPanelText>
              </Panel>
            )}
            {(!!upcomingOngoing.length || !!completed.length) && (
              <>
                <Panel header="Ongoing / Upcoming">
                  <DataGrid
                    data={upcomingOngoing}
                    columns={getTournamentColumns(
                      onEditClick,
                      onDraftEditClick,
                      onManageClick,
                      tournamentOrganizationNameLookup
                    )}
                    keyGetter={(arg) => arg.id}
                    initialSortColumnIdx={0}
                  />
                </Panel>
                <Panel header="Completed">
                  <DataGrid
                    data={completed}
                    columns={getTournamentColumns(
                      onEditClick,
                      onDraftEditClick,
                      onManageClick,
                      tournamentOrganizationNameLookup
                    )}
                    keyGetter={(arg) => arg.id}
                    initialSortColumnIdx={0}
                    paginationAmount={50}
                    searchFunction={tournamentSearch}
                  />
                </Panel>
              </>
            )}
          </GrowColumn>
        </ColumnContainer>
      </MaxWidth>
    </PageContent>
  );
};

const getTournamentColumns = (
  onEditClick: (tournamentId: string) => void,
  onDraftEditClick: (draftId: string) => void,
  onManageClick: (tournamentId: string) => void,
  organizationNameLookup: { [id: string]: string }
): DataGridColumn<TournamentPageItem>[] => {
  return [
    ctorTextColumn({
      textGetter: (arg: TournamentPageItem) => arg.id,
      sortable: true,
      numberSort: true,
      header: "ID",
    }),
    ctorTextColumn({
      textGetter: (arg: TournamentPageItem) => arg.name,
      sortable: true,
      header: "Name",
    }),
    ctorTextColumn({
      textGetter: (arg: TournamentPageItem) =>
        !arg.organizationId
          ? "-"
          : organizationNameLookup[arg.organizationId] ?? "-",
      sortable: true,
      header: "Organization",
    }),
    ctorDateTimeColumn({
      dateTimeFormat: "dd/MM/yy, HH:mm",
      timeMsGetter: (arg: TournamentPageItem) => arg.lastModified,
      sortable: true,
      header: "Last Modified",
    }),
    ctorTextColumn({
      textGetter: (arg: TournamentPageItem) => arg.status,
      sortable: true,
      header: "Status",
    }),
    ctorActionColumn({
      actionsGetter: (arg: TournamentPageItem) =>
        statusIsDraft(arg.status)
          ? getDraftActions(arg, onDraftEditClick)
          : getTournamentActions(arg, onEditClick, onManageClick),
      header: "Actions",
    }),
  ];
};

const getDraftActions = (
  arg: TournamentPageItem,
  onDraftEditClick: (draftId: string) => void
): Action<TournamentPageItem>[] => {
  const actions = [
    {
      onAction: (arg: TournamentPageItem) => {
        onDraftEditClick(arg.id);
        return Promise.resolve();
      },
      textGetter: () => "Edit",
    },
  ];

  if (!arg.organizationId) {
    return actions;
  }

  return [
    ...actions,
    {
      onAction: (arg: TournamentPageItem) =>
        changeTournamentDraftPublishRequestAsync(
          arg.id,
          arg.organizationId!,
          arg.status !== "Publish Request"
        ),
      textGetter: (arg: TournamentPageItem) =>
        `${
          arg.status === "Publish Request"
            ? "Cancel Publish Request"
            : "Request Publish"
        }`,
    },
  ];
};

const getTournamentActions = (
  arg: TournamentPageItem,
  onEditClick: (tournamentId: string) => void,
  onManageClick: (tournamentId: string) => void
): Action<TournamentPageItem>[] => {
  const actions = [
    {
      onAction: (arg: TournamentPageItem) => {
        onManageClick(arg.id);
        return Promise.resolve();
      },
      textGetter: () => "Manage",
    },
  ];

  if (!arg.isOrganizer) {
    return actions;
  }

  return [
    ...actions,
    {
      onAction: (arg: TournamentPageItem) => {
        onEditClick(arg.id);
        return Promise.resolve();
      },
      textGetter: () => "Edit",
    },
  ];
};

const tournamentSearch = (arg: TournamentPageItem, searchText: string) => {
  return anyStringContains(searchText, [arg.id, arg.name]);
};
