import { TournamentLogo } from "components/TournamentLogo/TournamentLogo";
import { format } from "date-fns";
import { FlexRow } from "styles/layout";
import {
  DataContainer,
  HeaderContainer,
  LogoContainer,
  TabsContainer,
  TournamentDates,
  TournamentDescription,
  TournamentName,
} from "./styles";
import { FsTournament } from "db/model/tournament/FsTournament";
import { TabBar } from "components/TabBar/TabBar";
import { Filter } from "model/Filter";

interface Props {
  tournamentId: string;
  tournament: FsTournament;
  tabFilter: Filter<string>;
}

export const TournamentHeader = ({
  tournamentId,
  tournament,
  tabFilter,
}: Props) => {
  const startDate = !tournament.startTimeMs
    ? ""
    : format(new Date(tournament.startTimeMs), "do MMM");
  const endDate = !tournament.endTimeMs
    ? ""
    : format(new Date(tournament.endTimeMs), "do MMM");
  const endDateYear = !tournament.endTimeMs
    ? ""
    : format(new Date(tournament.endTimeMs), "do MMM yyyy");
  const dateString =
    startDate === endDate ? endDateYear : `${startDate} - ${endDateYear}`;

  return (
    <HeaderContainer>
      <FlexRow>
        <LogoContainer>
          <TournamentLogo
            imageId={tournament.imageIdOverride ?? tournamentId}
            title={tournament.name}
          />
        </LogoContainer>
        <DataContainer>
          <TournamentName>{tournament.name}</TournamentName>
          <TournamentDescription>
            {tournament.description ?? ""}
          </TournamentDescription>
          <TournamentDates>{dateString}</TournamentDates>
        </DataContainer>
      </FlexRow>
      <TabsContainer>
        <TabBar filter={tabFilter} />
      </TabsContainer>
    </HeaderContainer>
  );
};
