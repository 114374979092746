import { PayloadAction } from "@reduxjs/toolkit";
import { createFetchSlice, IFetchSlice, initialFetchState } from "./FetchSlice";
import { DocumentChange, DocumentData } from "firebase/firestore";
import { FsTour } from "db/model/tour/FsTour";
import { Tour } from "model/IdData";

export interface ITourSlice extends IFetchSlice {
  tours: { [key: string]: Tour };
}

const initialState: ITourSlice = {
  ...initialFetchState,
  tours: {},
};

// todo - should the FSModel -> Model mapping be done in the slice ??
export const tourSlice = createFetchSlice({
  name: "tour",
  initialState,
  reducers: {
    updateTours: (
      state,
      action: PayloadAction<{
        docChanges: DocumentChange<DocumentData>[];
      }>
    ) => {
      const { docChanges } = action.payload;
      for (let i = 0; i < docChanges.length; i++) {
        const { doc } = docChanges[i];
        state.tours[doc.id] = { id: doc.id, data: doc.data() as FsTour };
      }

      if (!state.initialFetchComplete) {
        state.initialFetchComplete = true;
      }
    },
  },
});

export const { updateTours } = tourSlice.actions;
export const tourReducer = tourSlice.reducer;
