import styled from "styled-components";
import { FlexColumn, FlexRow } from "styles/layout";
import { Theme } from "theme";

export const MatchesContainer = styled(FlexColumn)``;

// todo - extract style for this border bottom, hover background, stackable ting
export const MatchContainer = styled(FlexRow)`
  border-bottom: ${Theme.panelContentBorder};
  line-height: 100%;
  padding: 4px ${Theme.bigPanelPaddingHorizontal};
  column-gap: 20px;
  @media (max-width: 1000px) {
    padding: 4px 18px;
  }

  @media (max-width: 800px) {
    padding: 4px ${Theme.smallPanelPadding};
    column-gap: 6px;
  }

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    background: ${Theme.panelHoverBackground};
  }
`;

export const MatchTimeContainer = styled.div`
  margin-right: 8px;
`;

export const MatchTeamsContainer = styled.div`
  flex-grow: 1;
`;

export const TournamentLogoContainer = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;

export const TournamentDetailsContainer = styled.div``;

export const MatchFormatContainer = styled.div`
  margin: 0 2px;
  @media (max-width: 650px) {
    display: none;
  }
`;

export const StatusPillContainer = styled.div`
  @media (max-width: 750px) {
    display: none;
  }
`;
