import {
  ctorDataGridColumnBase,
  DataGridColumn,
  DataGridColumnBase,
  DataGridColumnBaseCtorArgs,
} from "../DataGridColumn";

export type DateTimeColumn<T> = DataGridColumnBase & {
  dateTimeFormat: string;
  timeMsGetter: (arg: T) => number | undefined;
};

interface CtorArgs<T> extends DataGridColumnBaseCtorArgs {
  dateTimeFormat: string;
  timeMsGetter: (arg: T) => number | undefined;
}

export function ctorDateTimeColumn<T>(args: CtorArgs<T>): DateTimeColumn<T> {
  return {
    ...ctorDataGridColumnBase(args),
    dateTimeFormat: args.dateTimeFormat,
    timeMsGetter: args.timeMsGetter,
  };
}

export function isDateTimeColumn<T>(
  column: DataGridColumn<T>
): column is DateTimeColumn<T> {
  return (column as DateTimeColumn<T>).timeMsGetter !== undefined;
}
