import { buildEditMatchPath } from "pages/PageConfigs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IStore } from "redux/store";
import { MatchFormat } from "./Parts/MatchFormat/MatchFormat";
import { MatchTeams } from "./Parts/MatchTeams/MatchTeams";
import { MatchTime } from "./Parts/MatchTime/MatchTime";
import { StatusPill } from "./Parts/StatusPill/StatusPill";
import {
  MatchContainer,
  MatchFormatContainer,
  MatchTeamsContainer,
  MatchTimeContainer,
  StatusPillContainer,
} from "./styles";
import { FsMatch } from "db/model/match/FsMatch";
import { selectOrgTimeFromMatchId } from "selectors/OrgTime";

interface Props {
  matchId: string;
  match: FsMatch;
}

export const MatchListItem = ({ matchId, match }: Props) => {
  const navigate = useNavigate();
  const toEditMatch = () => {
    navigate(buildEditMatchPath(matchId));
  };

  const orgTime =
    useSelector((state: IStore) => selectOrgTimeFromMatchId(state, matchId)) ??
    match.startTimeMs ??
    -1;

  return (
    <MatchContainer onClick={toEditMatch}>
      <MatchTimeContainer>
        <MatchTime timeMs={match.startTimeMs ?? 0} />
      </MatchTimeContainer>

      <MatchTeamsContainer>
        <MatchTeams match={match} orgTime={orgTime} />
      </MatchTeamsContainer>

      <MatchFormatContainer>
        <MatchFormat match={match} />
      </MatchFormatContainer>

      <StatusPillContainer>
        <StatusPill match={match} />
      </StatusPillContainer>
    </MatchContainer>
  );
};
