import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import { FlexColumn } from "styles/layout";
import { BracketMatchList } from "../MatchList/BracketMatchList";
import { TeBracketMatch } from "model/TournamentEditor/Segment/Bracket/TeBracketMatch";
import { useEffect } from "react";
import { BracketType, bracketTypes } from "fsModel/Bracket/Bracket";
import { TeMatchFormat } from "model/TournamentEditor/TeMatchFormat";
import { StyledSelect } from "components/Fields/styles";
import { TeBracketGroup } from "model/TournamentEditor/Segment/Bracket/TeBracketGroup";
import { updateTeBracketMatchSourceStructures } from "model/TournamentEditor/Segment/Bracket/TeBracketSegment";

interface Props {
  matchFormats: TeMatchFormat[];
  bracket: TeBracketGroup;
  update: (bracket: TeBracketGroup) => void;
}

export const BracketBuilder = ({ matchFormats, bracket, update }: Props) => {
  useEffect(() => {
    update({
      ...bracket,
      matches: updateTeBracketMatchSourceStructures(
        bracket.matches,
        bracket.bracketType,
        matchFormats
      ),
    });
  }, [matchFormats]);

  return (
    <FlexColumn>
      <LabelledField label="Type: ">
        <StyledSelect
          value={bracket.bracketType ?? ""}
          onChange={(e) => {
            const newType = !!e.target.value
              ? (e.target.value as BracketType)
              : undefined;

            update({
              ...bracket,
              matches: updateTeBracketMatchSourceStructures(
                bracket.matches,
                newType,
                matchFormats
              ),
              bracketType: newType,
            });
          }}
        >
          {bracketTypes.map((type) => (
            <option value={type}>{type}</option>
          ))}
        </StyledSelect>
      </LabelledField>
      <BracketMatchList
        matchFormats={matchFormats}
        bracketMatches={bracket.matches}
        updateBracketMatches={(matches: TeBracketMatch[]) =>
          update({
            ...bracket,
            matches: updateTeBracketMatchSourceStructures(
              matches,
              bracket.bracketType,
              matchFormats
            ),
          })
        }
        bracketType={bracket.bracketType}
      />
    </FlexColumn>
  );
};
