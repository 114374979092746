import { FsPointsDistribution } from "db/model/match/Set/FsPointsDistribution";
import { isDnf } from "../DriverTime";

export const getPoints = (
  pointsDist: FsPointsDistribution,
  position: number | undefined,
  finishTimeMs: number | undefined
) => {
  if (isDnf(finishTimeMs) && pointsDist.dnfPoints !== undefined) {
    return pointsDist.dnfPoints;
  }

  if (!pointsDist.distribution || !position || position <= 0) {
    return 0;
  }

  return pointsDist.distribution.at(position - 1) ?? 0;
};
