import { FsSetFormatBase } from "db/model/match/Set/FsSetFormat";
import { TeSetFormatArgsBase, newTeSetFormatArgsBase } from "../TeSetFormat";
import { FsTimeAttackFormat } from "db/model/match/Set/TimeAttack/FsTimeAttackFormat";

export type TeTimeAttackSetFormat = TeSetFormatArgsBase & {
  type: "TimeAttack";
};

export const newTeTimeAttackSetFormat = (): TeTimeAttackSetFormat => {
  return {
    ...newTeSetFormatArgsBase(),
    type: "TimeAttack",
  };
};

export const mapTeTimeAttackMatchToFsData = (
  base: FsSetFormatBase,
  format: TeTimeAttackSetFormat
): FsTimeAttackFormat => {
  return {
    ...base,
    scoring: undefined,
  };
};
