import { checkTypeExhausted } from "Utility";
import {
  newTeForwardCupModeSetFormat,
  TeForwardCupModeSetFormat,
} from "./CupMode/Forward/TeForwardCupModeSetFormat";
import {
  newTeReverseCupModeSetFormat,
  TeReverseCupModeSetFormat,
} from "./CupMode/Reverse/TeReverseCupModeSetFormat";
import {
  newTeMatchmakingSetFormat,
  TeMatchmakingSetFormat,
} from "./Matchmaking/TeMatchmakingSetFormat";
import {
  newTeRoundsSetFormat,
  TeRoundsSetFormat,
} from "./Rounds/TeRoundsSetFormat";
import {
  newTeWorldTourSetFormat,
  TeWorldTourSetFormat,
} from "./WorldTour/TeWorldTourSetFormat";
import {
  newTeTimeAttackSetFormat,
  TeTimeAttackSetFormat,
} from "./TimeAttack/TeTimeAttackSetFormat";
import { FsSetFormatBase } from "db/model/match/Set/FsSetFormat";
import {
  newTeForwardCupModeLongSetFormat,
  TeForwardCupModeLongSetFormat,
} from "./CupModeLong/Forward/TeForwardCupModeLongSetFormat";
import {
  newTeKncokoutSetFormat,
  TeKnockoutSetFormat,
} from "./Knockout/TeKnockoutSetFormat";

export type TeSetType =
  | "CupMode"
  | "CupModeLong"
  | "Knockout"
  | "Matchmaking"
  | "ReverseCupMode"
  | "Rounds"
  | "TimeAttack"
  | "WorldTour";

export const teSetTypes: string[] = [
  "CupMode",
  "CupModeLong",
  "Knockout",
  "Matchmaking",
  "ReverseCupMode",
  "Rounds",
  "TimeAttack",
  "WorldTour",
];

export const isTeSetType = (value: string | undefined): value is TeSetType => {
  if (!value) {
    return false;
  }

  return teSetTypes.includes(value);
};

export type TeSetFormatArgsBase = {
  driversPerTeam: number | undefined;
  numTracks: number | undefined;
  numWinners: number | undefined;
};

export type TeSetFormatArgs =
  | TeForwardCupModeSetFormat
  | TeForwardCupModeLongSetFormat
  | TeKnockoutSetFormat
  | TeMatchmakingSetFormat
  | TeReverseCupModeSetFormat
  | TeRoundsSetFormat
  | TeTimeAttackSetFormat
  | TeWorldTourSetFormat;

export const newTeSetFormatArgsBase = (): TeSetFormatArgsBase => {
  return {
    driversPerTeam: undefined,
    numTracks: undefined,
    numWinners: undefined,
  };
};

export const newTeSetFormatArgs = (type: TeSetType): TeSetFormatArgs => {
  switch (type) {
    case "CupMode":
      return newTeForwardCupModeSetFormat();
    case "CupModeLong":
      return newTeForwardCupModeLongSetFormat();
    case "Knockout":
      return newTeKncokoutSetFormat();
    case "Matchmaking":
      return newTeMatchmakingSetFormat();
    case "ReverseCupMode":
      return newTeReverseCupModeSetFormat();
    case "Rounds":
      return newTeRoundsSetFormat();
    case "TimeAttack":
      return newTeTimeAttackSetFormat();
    case "WorldTour":
      return newTeWorldTourSetFormat();
    default:
      return checkTypeExhausted(type);
  }
};

export const mapTeSetFormatArgsBase = (
  args: TeSetFormatArgsBase
): FsSetFormatBase => {
  return {
    driversPerTeam: args.driversPerTeam,
    numTracks: args.numTracks,
    numWinners: args.numWinners,
  };
};
