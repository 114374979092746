import { Panel } from "components/Panel/Panel";
import { DISCORD_LINK, TOURNAMENTS_URL } from "eCM";
import { PageContent } from "pages/styles";
import { MaxWidth } from "styles/layout";
import { Manual } from "./styles";

export const ManualPage = () => {
  return (
    <PageContent>
      <MaxWidth>
        <Panel padding="padded">
          <Manual>
            <h1>Introduction</h1>
            <p>
              TrackMania tournament organizers are challenged with organizing
              match data for participants and viewers when arranging events.
              eCircuitMania (“ECM”): Tournaments addresses this issue by
              providing intuitive tools and a centralized platform for
              tournament management and viewer engagement to collaborate with
              Tournament Organizers enhancing the tournament experience.
            </p>
            <p>
              eCircuitMania has established a comprehensive solution that
              includes:
              <ul>
                <li>
                  A user-friendly tool for tournament organizers to configure
                  their events directly on our website.
                </li>
                <li>Support for various tournament formats and match types.</li>
                <li>Viewer engagement features such as Pick'em predictions.</li>
                <li>Detailed player, track, and tournament statistics.</li>
                <li>
                  A centralized hub for TrackMania eSports, independent of any
                  specific tournament entity.
                </li>
                <li>
                  Integration plugins (OpenPlanet or ManiaControl) to automate
                  the transfer of match data to our database.
                </li>
                <li>A refined design with a strong brand identity.</li>
                <li>
                  Proven positive feedback from tournament organizers, viewers,
                  and players.
                </li>
              </ul>
            </p>
            <h1>1. Tournament Organization</h1>
            <p>
              A Tournament Organization refers to the people building and
              managing a tournament on {TOURNAMENTS_URL}. Before using the tool
              for the first time, an organization will need to be established
              with ECM. This can be done by making a post in the
              “Request-Access” Channel in the{" "}
              <a href={DISCORD_LINK} target="_blank">
                ECM Discord.
              </a>
            </p>
            <h1>2. Tournaments</h1>
            <p>
              An Organization’s Tournaments can be found in the “Tournament”
              tab.
            </p>
            <h2>2.1. Create New Tournament</h2>
            <p>
              Create a New Tournament by clicking the “Create New Tournament”
              button at the top of the page
            </p>
            <h3>2.1.1. Details</h3>
            <p>
              At the top of the page, the Tournament “Name”, “Trigram”, and
              “Description” can be entered. Trigrams in any usage may be 3-5
              characters long.
            </p>
            <h3>2.1.2. Teams</h3>
            <p>
              In the “Teams” tab, add a Team by clicking the “+” in the middle
              of the page. For solo tournaments, ECM still refers to
              participants as Teams of 1 Driver.
            </p>
            <p>
              <strong>Orgs:</strong> Use the top field dropdown.
              <ul>
                <li>Assign Org - Select the Org.</li>
                <li>
                  Org isn’t in the list – Select Add New and fill out the form.
                </li>
                <li>
                  Change Selection – First click the “x” and then select a new
                  choice.
                </li>
                <li>No Org – Select “Orgless”.</li>
              </ul>
            </p>
            <p>
              <strong>Drivers:</strong> Use the lower field dropdowns.
              <ul>
                <li>Assign Driver - Select the Driver.</li>
                <li>
                  Driver isn’t in the list – Select Add New and fill out the
                  form.
                </li>
                <li>
                  Change Selection – First click the “x” to reset the field and
                  then select the new choice.
                </li>
                <li>Remove Driver – Click the “-”.</li>
              </ul>
            </p>
            <p>
              Be sure all Teams have an Org (even if it’s “Orgless”) and a
              minimum number of Drivers for the Match Formats.
            </p>
            <h3>2.1.3. Segments</h3>
            <p>
              ECM refers to parts of a Tournament as Segments which will appear
              as the middle tabs at the top of the page.
            </p>
            <h4>2.1.3.1. Match Formats</h4>
            <p>
              Match Formats are assigned a letter reference on the left side and
              can be referenced by any Segment of the Tournament. If ECM doesn’t
              currently support your Tournament Match Format, make a post in the
              “HelpNeeded” Channel in the{" "}
              <a href={DISCORD_LINK} target="_blank">
                ECM Discord.
              </a>
            </p>
            <h5>2.1.3.1.1. CupMode</h5>
            <ul>
              <li>
                Drivers/Team: Number of possible Drivers per Team in a single
                Match.
              </li>
              <li>Teams: Number of Teams in a single Match.</li>
              <li>
                Winners: Number of Teams that will receive a win for this Match.
              </li>
              <li>
                Play Till Left: Number of Teams remaining when the Match is
                ended.
              </li>
              <li>
                Points Distribution: Points for each finish positions. This will
                be needed for each possible number of Drivers remaining in the
                Match.
              </li>
              <li>
                Rounds/Track: Number of Rounds played on each Track (do not
                count replaying).
              </li>
              <li>Tracks: Number of Tracks Played (do not count replaying).</li>
              <li>
                DNF Points: Number of Points a Driver receives if they do not
                finish a Round.
              </li>
              <li>Finalist: Check this if using Finalist Mode.</li>
              <li>
                Points Limit: Number of Points a Driver needs to reach Finalist
                or win a Match.
              </li>
            </ul>
            <h5>2.1.3.1.2. ReverseCupMode</h5>
            <ul>
              <li>
                Drivers/Team: Number of possible Drivers per Team in a single
                Match.
              </li>
              <li>Teams: Number of Teams in a single Match.</li>
              <li>
                Winners: Number of Teams that will receive a win for this Match.
              </li>
              <li>
                Play Till Left: Number of Teams remaining when the Match is
                ended.
              </li>
              <li>
                Points Distribution: Points for each finish positions. This will
                be needed for each possible number of Drivers remaining in the
                Match.
              </li>
              <li>
                Rounds/Track: Number of Rounds played on each Track (do not
                count replaying).
              </li>
              <li>Tracks: Number of Tracks Played (do not count replaying).</li>
              <li>
                DNF Points: Number of Points a Driver receives if they do not
                finish a Round.
              </li>
              <li>Last Chance: Check this if using Last Chance Mode.</li>
              <li>
                Starting Points: Number of Points a Driver has at the start of a
                Match.
              </li>
            </ul>
            <h5>2.1.3.1.3. Rounds</h5>
            <ul>
              <li>
                Drivers/Team: Number of possible Drivers per Team in a single
                Match.
              </li>
              <li>Teams: Number of Teams in a single Match.</li>
              <li>
                Winners: Number of Teams that will receive a win for this Match.
              </li>
              <li>
                Rounds/Track: Number of Rounds played on each Track (do not
                count replaying).
              </li>
              <li>Tracks: Number of Tracks Played (do not count replaying).</li>
              <li>
                DNF Points: Number of Points a Driver receives if they do not
                finish a Round.
              </li>
              <li>Points Distribution: Points for each finish positions.</li>
            </ul>
            <h5>2.1.3.1.4. Set</h5>
            <ul>
              <li>Sets: Number of Sets in a Match.</li>
              <li>Set BO: Best of Number of Tracks in a Set.</li>
              <li>Teams: Number of Teams in a single Match.</li>
              <li>Play All: Check this if all Sets are played.</li>
            </ul>
            <h5>2.1.3.1.5. TimeAttack</h5>
            <ul>
              <li>Tracks: Number of Tracks Played.</li>
            </ul>
            <h4>2.1.3.2. Segment Formats</h4>
            <p>
              ECM tries to support all of the unique Segment Formats that
              Tournaments could organize. All Segment Formats list the matches
              in the Segment with the following fields:
              <ul>
                <li>Format: Select the Match Format letter reference.</li>
                <li>
                  Start Time (ms): Match Datetime Epoch timestamp in
                  milliseconds https://www.epochconverter.com/
                </li>
              </ul>
            </p>
            <p>
              If ECM doesn’t currently support your Tournament Segment Format,
              make a post in the “Help-Needed” Channel in the{" "}
              <a href={DISCORD_LINK} target="_blank">
                ECM Discord.
              </a>
            </p>
            <h5>2.1.3.2.1. Bracket</h5>
            <p>
              All brackets use matches to build the bracket from the final
              matches to their source matches and continuing to the first round.
              <ul>
                <li>
                  SingleElim/DoubleElim: Brackets for Match Formats with only 2
                  Teams.
                </li>
                <li>
                  SingleElimShuffle/DoubleElimShuffle: Brackets for Match
                  Formats with more than 2 Teams.
                </li>
              </ul>
            </p>
            <p>
              Similar to Match Formats, Matches are assigned a letter reference
              on the left side and can be referenced as a source to another
              match.
              <ul>
                <li>
                  Src (Source): Select Match letter reference of the match that
                  feeds into this Match.
                </li>
                <ul>
                  <li>
                    Pos: Position from the Source Match that feeds into this
                    Match for Match Formats with more than 2 Teams.
                  </li>
                </ul>
                <li>
                  Is Final: Check this if the Match is a Final (cannot be a
                  Source for another Match)
                </li>
                <ul>
                  <li>
                    Is Upper Final: Check this if the Match is an Upper Bracket
                    Final (Only use for multiple Finals).
                  </li>
                </ul>
              </ul>
            </p>
            <h5>2.1.3.2.2. Multibracket</h5>
            <p>
              Multibracket uses the Bracket types for multiple brackets in the
              same segment.
            </p>
            <h5>2.1.3.2.3. Qualifier</h5>
            <p>
              Qualifier is a simple list of matches that are unrelated to each
              other in a segment.
            </p>
            <h5>2.1.3.2.4. Season</h5>
            <p>
              Season has Playdays to group Matches typically for a league
              format.
            </p>
            <h5>2.1.3.2.5. Swiss</h5>
            <p>
              Swiss has Rounds to group Matches for a large number of Teams.
            </p>
            <h5>2.1.3.2.6. TimeAttack</h5>
            <p>
              Time Attack includes a single Match that would include a
              TimeAttack Match Format.
            </p>
            <h3>2.1.4. Pickem (Coming Soon)</h3>
            <h3>2.1.5. Tracks</h3>
            <p>
              In the “Tracks” tab, add a Track by clicking the “+” in the middle
              of the page.
            </p>
            <p>
              Use the dropdown.
              <ul>
                <li>Assign Track - Select the Track.</li>
                <li>
                  Track isn’t in the list – Select Add New and fill out the
                  form.
                </li>
                <li>Change Selection – Select a new choice.</li>
              </ul>
            </p>
            <h2>2.2. Edit Tournament</h2>
            <p>
              Edit a Tournament by clicking the “Edit” button in the row of the
              Tournament. Editing a Tournament will recreate the Tournament
              Draft. A completed Tournament cannot be edited. The Tournament
              fields will be filled out with the Tournament Format. These fields
              can be edited as if making the Tournament for the first time.
              Changes can be disregarded by going back to the Tournaments Page
              by clicking the tab at the top of the page. Tournaments with
              changes will need to be republished.
            </p>
            <h2>2.3. Publish Tournament</h2>
            <p>
              ECM wants to verify that Tournaments are made by white-listed
              Organizers and that your Tournament is set up in the way that your
              Format intended.
            </p>
            <p>
              After building/editing a Tournament, click “Request Publish” and
              make a post in the “Publish-Request” Channel in the{" "}
              <a href={DISCORD_LINK} target="_blank">
                ECM Discord.
              </a>
            </p>
            <p>
              Alternatively, request a Tournament to be published by clicking
              the “Request Publish” button in the row of the Tournament Draft on
              the Tournaments Page.
            </p>
            <h2>2.4. Manage Tournament</h2>
            Manage a Tournament by clicking the “Manage” button in the row of
            the Tournament. A Tournament Draft must be published before it can
            be managed.
            <h3>2.4.1. Edit Matches</h3>
            Matches can be edited by selecting them in the segment tabs or the
            list in the “Matches” tab.
            <h4>2.4.1.1. Metadata</h4>
            <p>
              The Match Metadata includes the Match Format fields set up with
              the Tournament. Metadata includes some extra fields for more
              usability.
              <ul>
                <li>
                  Bye: Check this if the Match includes a Bye for the Team
                  participating.
                </li>
                <li>
                  Delayed: Check this if the Match is not going to take place at
                  the desired Start Time and the Start Time is unknown to let
                  viewers/participants know.
                </li>
                <ul>
                  <li>
                    When a new Start Time is determined the Start Time field can
                    be changed and Delayed can be unchecked.
                  </li>
                </ul>
                <li>
                  Override Complete: Check this if the Match is completed but
                  does not appear completed on the main site. Also, make a post
                  in the “Help-Needed” Channel in the{" "}
                  <a href={DISCORD_LINK} target="_blank">
                    ECM Discord.
                  </a>
                </li>
              </ul>
            </p>
            <h4>2.4.1.2. Teams</h4>
            <p>
              Click “Add Team” to add Teams to this Match.
              <ul>
                <li>
                  Dropdown: Select the Team (Org Name or alt Org Name, Driver
                  Name if Team only has 1 Driver).
                </li>
                <li>+/-: Add or Remove Drivers.</li>
                <ul>
                  <li>Creates dropdown to select a Driver.</li>
                  <ul>
                    <li>
                      Drivers can be assigned a letter reference for easier
                      Manual Match Data entry.
                    </li>
                  </ul>
                </ul>
                <li>Forfeited: Check this if this Team forfeits the Match.</li>
                <li>
                  Score Override: Enter the Teams score if the Match Data is
                  incomplete and cannot determine the proper
                </li>
                score.
                <li>Remove: Remove the Team.</li>
              </ul>
            </p>
            <h4>2.4.1.3. Match Data</h4>
            <h5>2.4.1.3.1. Add Pick</h5>
            <p>
              If a Match has any Pick/Ban, click “Add Pick” for each track that
              is either picked, banned, or randomly decided.
            </p>
            <p>
              These should be filled out in the order they are determined.
              <ul>
                <li>Team Dropdown: Select the Team.</li>
                <li>Pick Dropdown: Select the Pick type.</li>
                <ul>
                  <li>Pick: The Team picked this map to play.</li>
                  <li>Ban: The Team picked this map to not play.</li>
                  <li>
                    Random: The track will be played but was not picked by any
                    Team.
                  </li>
                </ul>
                <li>Track Dropdown: Select the Track.</li>
                <li>Remove: Remove the Pick/Ban.</li>
              </ul>
            </p>
            <h5>2.4.1.3.2. Add Track (Manual Match Data Entry)</h5>
            <p>
              If using the ECM plugin for automatic data entry, this section can
              be ignored unless something needs to be updated/fixed.
            </p>
            <p>
              If doing manual data entry, add Tracks to the Match by clicking
              “Add Track” for each Track that a round is played on.
              <ul>
                <li>Add Round: Add a round to the track.</li>
                <ul>
                  <li>
                    Driver Key Order: Enter the Driver letter references in the
                    order of finish position and click “Add From Keys”
                  </li>
                </ul>
                <li>
                  Driver Dropdown: Select the Driver in each column from first
                  to last (left to right).
                </li>
                <li>
                  Finish Time: Enter the respective Driver’s race time in
                  milliseconds.
                </li>
                <li>Add: Add a Driver to the Round.</li>
                <li>
                  Remove Last: Remove the last Driver from the Round (Useful for
                  Rounds that have less Drivers than other Rounds due to some
                  finishing/being eliminated).
                </li>
              </ul>
            </p>
            <h4>2.4.1.4. Update Match</h4>
            <p>
              After updating Metadata, Teams, and/or Match data, click “Update
              Match.”
            </p>
            <h3>2.4.2. Tournament Admins</h3>
            <p>
              A Tournament Organization can have many members and can restrict
              some members to specific Tournaments.
            </p>
            <h4>2.4.2.1. Organizers</h4>
            <p>
              Organizers can create new tournaments, edit tournaments, request
              publishing of tournaments, and manage tournaments. Organizers can
              only be added to a Tournament Organization by making a post in the
              “RequestAccess” Channel in the{" "}
              <a href={DISCORD_LINK} target="_blank">
                ECM Discord
              </a>{" "}
              and specify that the Organization already has access.
            </p>
            <h4>2.4.2.2. Admins</h4>
            <p>
              Admins can only manage specific tournaments. Add or remove Admins
              from the Tournament by entering their ECM user id on the “Admins”
              tab.{" "}
            </p>
          </Manual>
        </Panel>
      </MaxWidth>
    </PageContent>
  );
};
