import styled from "styled-components";
import { FlexRow } from "styles/layout";
import { Theme } from "theme";
import { StyledInput } from "../styles";

export const SearchSelectContainer = styled(FlexRow)`
  position: relative;
  align-items: stretch;
  width: 100%;
  border-width: 0 0 2px 0;
  border-color: gray;
  border-style: solid;
`;

// todo - pass in the Editor styling to keep SearchSelect abstract
export const Input = styled(StyledInput)`
  flex-grow: 1;
  border: 0;

  &:disabled {
    color: black;
    background: white;
  }
`;

export const InputButton = styled.button`
  font-size: 8.5pt;
  width: 30px;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

export const Options = styled.div<{ hide: boolean }>`
  position: absolute;
  top: 102%;
  left: 0;
  right: 0;
  display: none;

  background: white;
  row-gap: 5px;

  max-height: 150px;
  overflow-y: scroll;
  z-index: 99;

  border: 1px solid gray;

  ${Input}:focus ~ & {
    display: block;
  }

  &:hover {
    display: block;
    ${({ hide }) => hide && "display: none;"};
  }
`;

export const Option = styled.div`
  padding: 2px;
  cursor: pointer;
  font-size: 10pt;

  &:hover {
    background: ${Theme.buttonHoverBackground};
    color: ${Theme.buttonHoverForeground};
  }
`;
