import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { Scores, applyScoreOverrides } from "fsModel/Match/Scores";
import { applyForwardCupModeTrackScore } from "./Track";
import { FsForwardCupModeSet } from "db/model/match/Set/CupMode/Forward/FsForwardCupModeSet";
import { calculateFinalPositions } from "fsModel/Match/Positions";

export const getForwardCupModeSetScores = (
  set: FsForwardCupModeSet,
  teams: FsMatchTeam[],
  numTeams: number
): Scores => {
  const scores: Scores = {};
  const points = set.format?.points;
  const pointsLimit = set.format?.pointsLimit;
  const driversPerTeam = set.format?.driversPerTeam;
  if (!!set.tracks && !!points && !!pointsLimit && !!driversPerTeam) {
    const completedDriverIds: string[] = [];
    const completedTeamIds: string[] = [];
    set.tracks.forEach((t) =>
      applyForwardCupModeTrackScore(
        t,
        teams,
        scores,
        points,
        pointsLimit,
        driversPerTeam,
        completedDriverIds,
        completedTeamIds
      )
    );
  }

  const forfeitTeams = teams.filter((t) => t.forfeited);
  forfeitTeams.forEach((t) => {
    if (!!t.teamId) {
      scores[t.teamId] = numTeams * -1;
    }
  });

  applyScoreOverrides(scores, set.scoreOverrides);

  return scores;
};

export const isForwardCupModeSetComplete = (
  set: FsForwardCupModeSet,
  scores: Scores,
  numTeams: number
) => {
  if (set.overrideComplete) {
    return true;
  }

  const pointsLimit = set.format?.pointsLimit;
  const playTillLeft = set.format?.playTillLeft;
  if (!playTillLeft || !pointsLimit) {
    return false;
  }

  const numFinished = Object.values(scores).filter(
    (s) => s > pointsLimit
  ).length;

  return numTeams - numFinished <= playTillLeft;
};

export const getForwardCupModeSetFinalPositions = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
