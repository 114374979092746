import { perihperals } from "db/model/driver/FsDriver";
import { FormProps } from "../FormProps";
import { FormTable } from "../styles";
import { TeDriver } from "model/TournamentEditor/TeDriver";

// todo - is combinable with DriverForm ???
export const TeDriverForm = ({
  data: driver,
  setData: setDriver,
}: FormProps<TeDriver>) => {
  const update = () => {
    setDriver(driver);
  };

  return (
    <FormTable>
      <tbody>
        <tr>
          <td>Nickname</td>
          <td>
            <input
              value={driver.nickname}
              onChange={(e) => {
                driver.nickname = e.target.value;
                update();
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Home Country</td>
          <td>
            <input
              value={driver.country}
              onChange={(e) => {
                driver.country = e.target.value;
                update();
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Peripheral</td>
          <td>
            <select
              value={driver.peripheral}
              onChange={(e) => {
                driver.peripheral = e.target.value;
                update();
              }}
            >
              {perihperals.map((p) => (
                <option value={p}>{p}</option>
              ))}
            </select>
          </td>
        </tr>
        <tr>
          <td>Ubisoft Uid</td>
          <td>
            <input
              value={driver.ubisoftUid}
              onChange={(e) => {
                driver.ubisoftUid = e.target.value;
                update();
              }}
            />
          </td>
        </tr>
      </tbody>
    </FormTable>
  );
};
