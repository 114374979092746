import { MatchStatus } from "fsModel/Match/MatchStatus";
import styled from "styled-components";
import { Theme } from "theme";

export const PillContainer = styled.div`
  width: 90px;
  display: flex;
  justify-content: center;

  @media (max-width: 850px) {
    width: 75px;
  }
`;

export const Pill = styled.div<{ status: MatchStatus }>`
  border-radius: 8px;
  border: 1px solid black;
  padding: 4px;
  background: white;
  font-size: 11pt;

  ${({ status }) =>
    status === "Completed" &&
    `border-color:${Theme.matchStatusCompleteForeground};
    color:${Theme.matchStatusCompleteForeground};
    background:${Theme.matchStatusCompleteBackground}`}
  ${({ status }) =>
    status === "Live" &&
    `border-color:${Theme.matchStatusInProgressForeground};
    color:${Theme.matchStatusInProgressForeground};
    background:${Theme.matchStatusInProgressBackground};
    padding: 4px 12px;`}
  ${({ status }) =>
    status === "Unknown" &&
    `border-color:${Theme.matchStatusUpcomingForeground};
    color:${Theme.matchStatusUpcomingForeground};
    background:${Theme.matchStatusUpcomingBackground}`}
  ${({ status }) =>
    status === "Upcoming" &&
    `border-color:${Theme.matchStatusUpcomingForeground};
    color:${Theme.matchStatusUpcomingForeground};
    background:${Theme.matchStatusUpcomingBackground}`}
  ${({ status }) =>
    status === "Delayed" &&
    `border-color:${Theme.matchStatusDelayedForeground};
    color:${Theme.matchStatusDelayedForeground};
    background:${Theme.matchStatusDelayedBackground}`}
`;
