import { format } from "date-fns-tz";
import { useNavigate } from "react-router-dom";
import { MatchContainer, MatchTimeContainer } from "./styles";
import { MatchesViewerTeamList } from "./Teams/List/MatchesViewerTeamList";
import { FsMatch } from "db/model/match/FsMatch";
import { buildEditMatchPath } from "pages/PageConfigs";

interface Props {
  matchId: string;
  match: FsMatch;
  orgTime: number | undefined;
}

export const MatchesViewerMatch = ({ matchId, match, orgTime }: Props) => {
  const navigate = useNavigate();
  const toEditMatch = () => {
    navigate(buildEditMatchPath(matchId));
  };

  const startDate = new Date(match.startTimeMs ?? 0);
  return (
    <MatchContainer onClick={toEditMatch}>
      <MatchTimeContainer isDelayed={match.isDelayed ?? false}>
        <span>{format(startDate, "do MMM")}</span>
        <span>{format(startDate, "HH:mm")}</span>
        {match.isDelayed && <span>Delayed</span>}
      </MatchTimeContainer>
      <MatchesViewerTeamList match={match} orgTime={orgTime} />
    </MatchContainer>
  );
};
