import { checkTypeExhausted, onlyUnique } from "Utility";
import { getPlayConditionFormatKey } from "./PlayCondition";
import { FsMatchFormat } from "db/model/match/FsMatchFormat";
import { FsMatchSet } from "db/model/match/Set/FsMatchSet";

export type MultiSetMatchFormatType = "BestOf" | "FirstTo" | "PlayAll";
export type MatchFormatType = MultiSetMatchFormatType | "Single";

export const hasSetAdvantage = (format: FsMatchFormat) => {
  if (format.type === "Single") {
    return false;
  }

  return !!format.setAdvantageTeamIds?.length;
};

export const getMultiSetFormatString = (format: FsMatchFormat) => {
  switch (format.type) {
    case "BestOf":
      return `Best Of ${format.amount}`;
    case "FirstTo":
      return `First To ${format.amount}`;
    case "PlayAll":
      return `Play All ${format.amount}`;
    default:
      return "";
  }
};

export const getMatchFormatKeys = (
  format: FsMatchFormat | undefined,
  sets: FsMatchSet[]
) => {
  if (!format) {
    return ["Unknown"];
  }

  const nonTbSets = sets.filter((s) => !s.isTiebreaker);
  const setOne = sets.at(0);
  if (!setOne) {
    return ["Unknown"];
  }

  const setType = setOne.type;
  const allSetTypesSame = nonTbSets.every((s) => s.type === setType);
  if (!allSetTypesSame) {
    return ["Varies"];
  }

  const formatKeys = [getSetTypeKey(setType)];
  const formatAmountKey = getFormatAmountKey(format);
  if (!!formatAmountKey) {
    formatKeys.push(formatAmountKey);
  }

  const driversPerTeam = setOne.format?.driversPerTeam;
  const allDriversPerTeamSame =
    !!driversPerTeam ||
    nonTbSets.every((s) => s.format?.driversPerTeam === driversPerTeam);

  if (!allDriversPerTeamSame) {
    formatKeys.push("Varies");
    return formatKeys;
  }

  if (showDriversPerTeamKey(driversPerTeam!, setType)) {
    formatKeys.push(getDriversPerTeamKey(driversPerTeam!));
  }

  const setSpecificKeys = nonTbSets.map(getSetSpecificKey).filter(onlyUnique);
  if (setSpecificKeys.length !== 1) {
    formatKeys.push("Varies");
  } else {
    formatKeys.push(setSpecificKeys[0]);
  }

  return formatKeys;
};

const getSetTypeKey = (type: string) => {
  switch (type) {
    case "ForwardCupMode":
      return "CUP";
    case "ForwardCupModeLong":
      return "CUPL";
    case "Knockout":
      return "KO";
    case "Matchmaking":
      return "MM";
    case "ReverseCupMode":
      return "RCUP";
    case "Rounds":
      return "RND";
    case "TimeAttack":
      return "TA";
    case "WorldTour":
      return "WT";
    default:
      return "?";
  }
};

const getFormatAmountKey = (format: FsMatchFormat) => {
  switch (format.type) {
    case "Single":
      return undefined;
    case "BestOf":
      return `BO${format.amount}`;
    case "FirstTo":
      return `Ft${format.amount}`;
    case "PlayAll":
      return `PA${format.amount}`;
    default:
      return "?";
  }
};

const getDriversPerTeamKey = (driversPerTeam: number) => {
  switch (driversPerTeam) {
    case 1:
      return "SOLO";
    case 2:
      return "DUO";
    case 3:
      return "TRIO";
    case 4:
      return "QUAD";
    default:
      return `${driversPerTeam}s`;
  }
};

const showDriversPerTeamKey = (driversPerTeam: number, type: string) => {
  switch (type) {
    case "ForwardCupMode":
    case "ForwardCupModeLong":
    case "Knockout":
    case "ReverseCupMode":
    case "Rounds":
    case "TimeAttack":
      return driversPerTeam !== 1;
    case "WorldTour":
      return driversPerTeam !== 2;
    case "Matchmaking":
      return true;
    default:
      return false;
  }
};

const getSetSpecificKey = (set: FsMatchSet) => {
  switch (set.type) {
    case "ForwardCupMode":
      return set.format?.pointsLimit?.toString() ?? "?";
    case "ForwardCupModeLong":
      return `${getPlayConditionFormatKey(
        set.format?.playCondition,
        set.format?.numTracks
      )}-${set.format?.pointsLimit ?? "?"}`;
    case "Knockout":
      return set.format?.startingLives?.toString() ?? "?";
    case "ReverseCupMode":
      return set.format?.startingPoints?.toString() ?? "?";
    case "Rounds":
      return `${set.format?.numTracks ?? "?"} x ${
        set.format?.roundsPerTrack ?? "?"
      }`;
    case "TimeAttack":
      return ``;
    case "Matchmaking":
    case "WorldTour":
      return getPlayConditionFormatKey(
        set.format?.playCondition,
        set.format?.numTracks
      );
    default:
      return checkTypeExhausted(set);
  }
};
