export const Theme = {
  pageBackground: "lightgray",
  navBackground: "#3A3A3A",
  navForeground: "white",
  navHoverBackground: "rgba(100, 149, 237, 0.2)",
  navHoverForeground: "white",
  navHomeHover: "cornflowerblue",
  navActiveBackground: "rgba(100, 149, 237, 0.15)",
  navActiveForeground: "dodgerblue",
  navActiveHoverBackground: "rgba(100, 149, 237, 0.3)",
  burgerMenuBackground: "#3F3F3F",
  footerBackground: "#3A3A3A",
  footerForeground: "white",
  footerHoverForeground: "dodgerblue",
  panelBackground: "white",
  informationPanelBackground: "#d7ffd7",
  informationPanelBorder: "rgba(60, 200, 60, 1) 2px solid",
  warningPanelBackground: "rgba(255, 100, 100, 0.3)",
  warningPanelBorder: "rgba(255, 60, 60, 1) 2px solid",
  panelContentBorder: "1px solid darkgray",
  panelContentBorderStrong: "2px solid gray",
  panelBoxShadow:
    "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" /* https://getcssscan.com/css-box-shadow-examples */,
  panelContentBoxShadow:
    "rgba(100, 100, 111, 0.2) 0px 5px 10px 0px" /* https://getcssscan.com/css-box-shadow-examples */,
  panelContentBoxShadowFainter:
    "rgba(100, 100, 111, 0.15) 0px 2px 4px 0px" /* https://getcssscan.com/css-box-shadow-examples */,
  panelHoverBackground: "aliceblue",
  panelForegroundLight: "gray",
  panelForegroundLighter: "darkgray",
  smallPanelPadding: "10px",
  bigPanelPadding: "8px 28px",
  bigPanelPaddingHorizontal: "28px",
  bigPanelPaddingVertical: "8px",
  buttonBorder: "0.5px solid gray",
  buttonBackground: "gainsboro",
  buttonForeground: "black",
  buttonHoverBackground: "aliceblue",
  buttonHoverForeground: "dodgerblue",
  buttonDisabledBackground: "lightgray",
  buttonDisabledForeground: "gray",
  matchStatusCompleteBackground: "mintcream",
  matchStatusCompleteForeground: "yellowgreen",
  matchStatusDelayedBackground: "mistyrose",
  matchStatusDelayedForeground: "firebrick",
  matchStatusInProgressBackground: "peachpuff",
  matchStatusInProgressForeground: "brown",
  matchStatusUpcomingBackground: "whitesmoke",
  matchStatusUpcomingForeground: "gray",
  tabButtonForeground: "gray",
  tabBackground: "gainsboro",
  tabBorder: "1px solid darkgray",
  tabHoverBackground: "aliceblue",
  tabHoverForeground: "dodgerblue",
  tabSelectedBackground: "aliceblue",
  tabSelectedForeground: "dodgerblue",
  selectBorderColor: "darkgray",
  paginationButtonBackground: "aliceblue",
  paginationButtonForeground: "blue",
  multiSelectFilterSelectedBackground: "aliceblue",
  multiSelectFilterSelectedForeground: "blue",
  multiSelectFilterUnselectedBackground: "gainsboro",
  multiSelectFilterUnselectedForeground: "gray",
  multiSelectFilterBorder: "1px solid darkgray",
  multiSelectFilterBorderRadius: "5px",
  trackTimesBackground: "gainsboro",
  trackTimesAltBackground: "ghostwhite",
  spinnerForeground: "20px solid #666",
  spinnerBackground: "20px solid dodgerblue",
  spinnerSmallBorderWidth: "4px",
  errorTextColor: "crimson",
  successTextColor: "forestgreen",
  defaultOrgPrimaryColor: "gray",
  tdHighlightBackground: "aliceblue",
  tdHighlightForeground: "blue",
  thHighlightBackground: "silver",
  modalBackground: "rgba(0, 0, 0, 0.6)",
  modalSpinnerBackground: "rgba(0, 0, 0, 0.2)",
  modalContentBackground: "white",
  styledInputBorderColor: "darkgray",
  styledInputBorderFocusColor: "black",
  switcherSelectedForeground: "mediumseagreen",
  switcherNonSelectableForeground: "gray",
};

// todo - where belong?
export const PANEL_GAP = "20px";
export const COMPACT_COL_WIDTH = "200px";
