import { FormProps } from "components/AddEditForms/FormProps";
import { DataGrid } from "components/DataGrid/DataGrid";
import { DataGridColumn } from "components/DataGrid/DataGridColumn";
import { DataModal } from "components/Modal/DataModal";
import { Panel } from "components/Panel/Panel";
import { writeDataToDbAsync } from "db/info/core";
import { DocumentData, WithFieldValue } from "firebase/firestore";
import { idDataGetNextNumericalId, IdData } from "model/IdData";
import { Validated } from "model/validators/base";
import { PageContent, ColumnContainer, GrowColumn } from "pages/styles";
import { useState } from "react";
import { Button } from "styles/elements";
import { MaxWidth } from "styles/layout";

interface Props<T> {
  dataName: string;
  collectionName: string;
  data: IdData<T>[];
  columns: DataGridColumn<Validated<IdData<T>>>[];
  validated: Validated<IdData<T>>[];
  newData: () => T;
  searchFunction(arg: Validated<IdData<T>>, searchText: string): boolean;
  form: (props: FormProps<T>) => JSX.Element;
}

// todo remove data injection (only need validated)
export function DataPage<T extends WithFieldValue<DocumentData>>({
  dataName,
  collectionName,
  data,
  columns,
  validated,
  newData,
  searchFunction,
  form,
}: Props<T>) {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTarget, setModalTarget] = useState<T | undefined>();

  const onAddNewClick = () => {
    setModalTarget(newData());
    setModalVisible(true);
  };

  const onModalSave = async () => {
    if (!!modalTarget) {
      const newId = idDataGetNextNumericalId(data);
      writeDataToDbAsync(newId, modalTarget, collectionName, false)
        .then(() => {
          setModalVisible(false);
          setModalTarget(undefined);
        })
        .catch((e) => alert(e.message));
    }
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalTarget(undefined);
  };

  return (
    <PageContent>
      <MaxWidth>
        <ColumnContainer>
          <GrowColumn>
            <Panel>
              <Button onClick={onAddNewClick}>{`Add New ${dataName}`}</Button>
              <DataGrid
                data={validated}
                columns={columns}
                keyGetter={(arg) => arg.model.id}
                initialSortColumnIdx={0}
                paginationAmount={50}
                searchFunction={searchFunction}
              />
            </Panel>
            <DataModal
              visible={modalVisible}
              onSave={onModalSave}
              onCancel={onModalCancel}
            >
              {modalTarget &&
                form({ data: modalTarget, setData: setModalTarget })}
            </DataModal>
          </GrowColumn>
        </ColumnContainer>
      </MaxWidth>
    </PageContent>
  );
}
