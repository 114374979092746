import { newFsMatchSetBase } from "../../FsMatchSet";
import { FsReverseCupModeSet } from "db/model/match/Set/CupMode/Reverse/FsReverseCupModeSet";
import { newFsReverseCupModeFormat } from "./FsReverseCupModeFormat";

export const newFsReverseCupModeSet = (): FsReverseCupModeSet => {
  return {
    ...newFsMatchSetBase(),
    format: newFsReverseCupModeFormat(),
    tracks: [],
    type: "ReverseCupMode",
  };
};
