import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { redirect } from "react-router-dom";
import { auth } from "firebase-config";
import { homePath } from "pages/PageConfigs";
import { setUser } from "redux/UserSlice";
import {
  CheckPickButton,
  CheckSpinner,
  Container,
  UsernameInput,
} from "./styles";
import { CenterFlexDiv } from "styles/layout";
import { ErrorText } from "styles/text";
import { Panel } from "components/Panel/Panel";
import {
  MAX_USERNAME_LENGTH,
  MIN_USERNAME_LENGTH,
  isUsernameTaken,
  isValidUsername,
  saveUsernameToDb,
} from "db/info/userInfo";

interface Props {
  uid: string;
}

export const InitialUsernameSelector = ({ uid }: Props) => {
  const dispatch = useDispatch();

  const [inputUsername, setInputUsername] = useState<string>();
  const [errorText, setErrorText] = useState<string>();
  const [controlsDisabled, setControlsDisabled] = useState<boolean>(false);
  const [usernameChecked, setUsernameChecked] = useState<boolean>(false);
  useEffect(() => {
    setUsernameChecked(false);
  }, [inputUsername]);

  const handleCheck = async () => {
    setErrorText("");
    setInputUsername(inputUsername?.trim());
    setControlsDisabled(true);

    if (inputUsername === undefined) {
      setErrorText("Please enter a username");
      setControlsDisabled(false);
      return;
    }

    const { isValid, errorMessage } = isValidUsername(inputUsername);
    if (!isValid) {
      setErrorText(errorMessage);
      setControlsDisabled(false);
      return;
    }

    if (await isUsernameTaken(inputUsername)) {
      setErrorText("Username already taken");
      setControlsDisabled(false);
      return;
    }

    setUsernameChecked(true);
    setControlsDisabled(false);
  };

  const handlePick = async () => {
    await handleCheck();
    saveUsernameToDb(uid, inputUsername!)
      .then(() => {
        dispatch(
          setUser({
            uid: uid,
            username: inputUsername!,
            adminTournamentIds: [],
          })
        );
      })
      .catch((error) => {
        console.log(error);
        setErrorText("Unable to pick username");
        return;
      });
  };

  const handleCancel = async () => {
    await auth.signOut();
    redirect(homePath);
  };

  return (
    <CenterFlexDiv>
      <Panel padding="square">
        <Container>
          <span>Before continuing you must select a unique username</span>
          <ul>
            <li>
              Between {MIN_USERNAME_LENGTH} and {MAX_USERNAME_LENGTH} characters
              long
            </li>
            <li>Must start with a letter</li>
            <li>
              May include the following punctuation: Underscore (_), Dash (-),
              Period (.) and Spaces ( ))
            </li>
          </ul>
          <UsernameInput
            placeholder="Create a username"
            value={inputUsername}
            type="text"
            onChange={(e) => {
              setInputUsername(e.target.value);
              setErrorText("");
            }}
            onBlur={() => setInputUsername(inputUsername?.trim())}
            disabled={controlsDisabled}
            maxLength={15}
          />
          {usernameChecked ? (
            <CheckPickButton onClick={handlePick} disabled={controlsDisabled}>
              Pick Username
            </CheckPickButton>
          ) : (
            <CheckPickButton onClick={handleCheck} disabled={controlsDisabled}>
              {controlsDisabled ? <CheckSpinner /> : "Check Availability"}
            </CheckPickButton>
          )}
          <button onClick={handleCancel} disabled={controlsDisabled}>
            Cancel
          </button>
          <ErrorText>{errorText}</ErrorText>
        </Container>
      </Panel>
    </CenterFlexDiv>
  );
};
