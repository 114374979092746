import { Panel } from "components/Panel/Panel";
import { PageContent } from "pages/styles";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { MaxWidth } from "styles/layout";
import { privacyPath } from "../PageConfigs";
import {
  PrivacyAndTermsContainer,
  PrivacyAndTermsHeader,
  PrivacyAndTermsSpan,
} from "./styles";

export const TermsOfServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContent>
      <MaxWidth>
        <Panel>
          <PrivacyAndTermsContainer>
            <h2>Terms Of Service</h2>
            <PrivacyAndTermsHeader>OVERVIEW</PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              This website is operated by ECIRCUITMANIA Throughout the site, the
              terms “we”, “us” and “our” refer to ECIRCUITMANIA. ECIRCUITMANIA
              offers this website, including all information, tools and services
              available from this site to you, the user, conditioned upon your
              acceptance of all terms, conditions, policies and notices stated
              here.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              By visiting our site and/ or purchasing something from us, you
              engage in our “Service” and agree to be bound by the following
              terms and conditions (“Terms of Service”, “Terms”), including
              those additional terms and conditions and policies referenced
              herein and/or available by hyperlink. These Terms of Service apply
              to all users of the site, including without limitation users who
              are browsers, vendors, customers, merchants, and/ or contributors
              of content.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              Please read these Terms of Service carefully before accessing or
              using our website. By accessing or using any part of the site, you
              agree to be bound by these Terms of Service. If you do not agree
              to all the terms and conditions of this agreement, then you may
              not access the website or use any services. If these Terms of
              Service are considered an offer, acceptance is expressly limited
              to these Terms of Service.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              Any new features or tools which are added to the current store
              shall also be subject to the Terms of Service. You can review the
              most current version of the Terms of Service at any time on this
              page. We reserve the right to update, change or replace any part
              of these Terms of Service by posting updates and/or changes to our
              website. It is your responsibility to check this page periodically
              for changes. Your continued use of or access to the website
              following the posting of any changes constitutes acceptance of
              those changes.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 1 - ONLINE USE TERMS
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              This site is not intended for persons under the age of 13. If you
              are between the ages of 13 and the age of legal majority in your
              jurisdiction of residence, you may only use the Site under the
              supervision of a parent or legal guardian who agrees to be bound
              by these Terms of Service. By using this site, you represent that
              you are at least 13 years of age, you’re your parent or legal
              guardian agrees to be bound by these terms of service if you are
              between 13 and the age of legal majority in your jurisdiction of
              residence, and that you have not been previously removed from and
              are not prohibited from receiving our Services. You may not use
              our Services for any illegal or unauthorized purpose nor may you,
              in the use of the Service, violate any laws in your jurisdiction
              (including but not limited to copyright laws).
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              You must not transmit any worms or viruses or any code of a
              destructive nature. A breach or violation of any of the Terms will
              result in an immediate termination of your Services.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 2 - GENERAL CONDITIONS
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              We reserve the right to refuse service to anyone for any reason at
              any time. You understand that your content (not including credit
              card information), may be transferred unencrypted and involve (a)
              transmissions over various networks; and (b) changes to conform
              and adapt to technical requirements of connecting networks or
              devices. Credit card information is always encrypted during
              transfer over networks.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              You agree not to reproduce, duplicate, copy, sell, resell or
              exploit any portion of the Service, use of the Service, or access
              to the Service or any contact on the website through which the
              service is provided, without express written permission by us.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                The headings used in this agreement are included for convenience
                only and will not limit or otherwise affect these Terms.
              </PrivacyAndTermsSpan>
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 3 - LIMITED LICENSE
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              Unless otherwise stated, the trademarks, trade names, logos,
              product configurations, and merchandise templates displayed on
              this website and related pages are registered and unregistered
              trademarks of ECIRCUITMANIA. All intellectual property rights are
              reserved. You may view and/or print pages from
              http://www.ecircuitmania.com for your own personal use subject to
              restrictions set in these Terms of Service. You must not: (a)
              Republish material from http://www.ecircuitmania.com; (b) sell,
              rent or sub-license material from http://www.ecircuitmania.com;
              (c) reproduce, duplicate or copy material from
              http://www.ecircuitmania.com; or (d) redistribute content from
              ECIRCUITMANIA (unless content is specifically made for
              redistribution).
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 4 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              We are not responsible if information made available on this site
              is not accurate, complete or current. The material on this site is
              provided for general information only and should not be relied
              upon or used as the sole basis for making decisions without
              consulting primary, more accurate, more complete or more timely
              sources of information. Any reliance on the material on this site
              is at your own risk.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              This site may contain certain historical information. Historical
              information, necessarily, is not current and is provided for your
              reference only. We reserve the right to modify the contents of
              this site at any time, but we have no obligation to update any
              information on our site. You agree that it is your responsibility
              to monitor changes to our site.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 5 - MODIFICATIONS TO THE SERVICE
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              We reserve the right at any time to modify or discontinue the
              Service (or any part or content thereof) without notice at any
              time.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              We shall not be liable to you or to any third-party for any
              modification, suspension or freeasssdiscontinuance of the Service.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 6 - OPTIONAL TOOLS
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              We may provide you with access to third-party tools over which we
              neither monitor nor have any control nor input.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              You acknowledge and agree that we provide access to such tools ”as
              is” and “as available” without any warranties, representations or
              conditions of any kind and without any endorsement. We shall have
              no liability whatsoever arising from or relating to your use of
              optional third-party tools.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              Any use by you of optional tools offered through the site is
              entirely at your own risk and discretion and you should ensure
              that you are familiar with and approve of the terms on which tools
              are provided by the relevant third-party provider(s).
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              We may also, in the future, offer new services and/or features
              through the website (including, the release of new tools and
              resources). Such new features and/or services shall also be
              subject to these Terms of Service.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 7 - LINKING TO THE SITE
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              The following organizations may link to our Web site without prior
              written approval:
              <ul>
                <li> Government agencies;</li>
                <li> Search engines;</li>
                <li> News organizations;</li>
                <li>
                  Online directory distributors when they list us in the
                  directory may link to our Web site in the same manner as they
                  hyperlink to the Web sites of other listed businesses; and
                </li>
                <li>
                  Systemwide Accredited Businesses except soliciting non-profit
                  organizations, charity shopping malls, and charity fundraising
                  groups which may not hyperlink to our Web site.
                </li>
              </ul>
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              These organizations may link to our home page, to publications or
              to other Web site information so long as the link: (a) is not in
              any way misleading; (b) does not falsely imply sponsorship,
              endorsement or approval of the linking party and its products or
              services; and (c) fits within the context of the linking party's
              site.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              We may consider and approve in our sole discretion other link
              requests from the following types of organizations:
              <ul>
                <li>
                  commonly-known consumer and/or business information sources
                  such as Chambers of Commerce, American Automobile Association,
                  AARP and Consumers Union;
                </li>
                <li>dot.com community sites;</li>
                <li>
                  associations or other groups representing charities, including
                  charity giving sites, online directory distributors;
                </li>
                <li>internet portals;</li>
                <li>
                  accounting, law and consulting firms whose primary clients are
                  businesses; and
                </li>
                <li>educational institutions and trade associations.</li>
              </ul>
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              We will approve link requests from these organizations if we
              determine that: (a) the link would not reflect unfavorably on us
              or our accredited businesses (for example, trade associations or
              other organizations representing inherently suspect types of
              business, such as work-at-home opportunities, shall not be allowed
              to link); (b) the organization does not have an unsatisfactory
              record with us; (c) the benefit to us from the visibility
              associated with the hyperlink outweighs the absence of
              ECIRCUITMANIA; and (d) where the link is in the context of general
              resource information or is otherwise consistent with editorial
              content in a newsletter or similar product furthering the mission
              of the organization.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              These organizations may link to our home page, to publications or
              to other Web site information so long as the link: (a) is not in
              any way misleading; (b) does not falsely imply sponsorship,
              endorsement or approval of the linking party and its products or
              services; and (c) fits within the context of the linking party's
              site.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              If you are among the organizations listed in paragraph 2 above and
              are interested in linking to our website, you must notify us by
              sending an e-mail to contact@ecircuitmania.com. Please include
              your name, your organization name, contact information (such as a
              phone number and/or e-mail address) as well as the URL of your
              site, a list of any URLs from which you intend to link to our Web
              site, and a list of the URL(s) on our site to which you would like
              to link. Allow 2-3 weeks for a response.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              Approved organizations may hyperlink to our Web site as follows:
              <ul>
                <li>By use of our corporate name; or</li>
                <li>
                  By use of the uniform resource locator (Web address) being
                  linked to; or
                </li>
                <li>
                  By use of any other description of our Web site or material
                  being linked to that makes sense within the context and format
                  of content on the linking party's site.
                </li>
              </ul>
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              No use of ECIRCUITMANIA's logo or other artwork will be allowed
              for linking absent a trademark license agreement.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 8 – RESERVATION OF RIGHTS
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              We reserve the right at any time and in its sole discretion to
              request that you remove all links or any particular link to our
              Web site. You agree to immediately remove all links to our Web
              site upon such request. We also reserve the right to amend these
              Terms of Service and its linking policy at any time. By continuing
              to link to our Web site, you agree to be bound to and abide by
              these linking Terms of Service.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 9 - THIRD-PARTY LINKS
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              Certain content, products and services available via our Service
              may include materials from third parties.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              Certain content, products and services available via our Service
              may include materials from third parties.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              We are not liable for any harm or damages related to the purchase
              or use of goods, services, resources, content, or any other
              transactions made in connection with any third-party websites.
              Please review carefully the third-party's policies and practices
              and make sure you understand them before you engage in any
              transaction. Complaints, claims, concerns, or questions regarding
              third-party products should be directed to the third-party.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              If you find any link on our Web site or any linked web site
              objectionable for any reason, you may contact us about this. We
              will consider requests to remove links but will have no obligation
              to do so or to respond directly to you. Whilst we endeavor to
              ensure that the information on this website is correct, we do not
              warrant its completeness or accuracy; nor do we commit to ensuring
              that the website remains available or that the material on the
              website is kept up to date.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>SECTION 10 - IFRAMES</PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              Without prior approval and express written permission, you may not
              create frames around our Web pages or use other techniques that
              alter in any way the visual presentation or appearance of our Web
              site.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 11 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              If, at our request, you send certain specific submissions (for
              example contest entries) or without a request from us you send
              creative ideas, suggestions, proposals, plans, or other materials,
              whether online, by email, by postal mail, or otherwise
              (collectively, 'comments'), you agree that we may, at any time,
              without restriction, edit, copy, publish, distribute, translate
              and otherwise use in any medium any comments that you forward to
              us. We are and shall be under no obligation (1) to maintain any
              comments in confidence; (2) to pay compensation for any comments;
              or (3) to respond to any comments.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              We may, but have no obligation to, monitor, edit or remove content
              that we determine in our sole discretion are unlawful, offensive,
              threatening, libelous, defamatory, pornographic, obscene or
              otherwise objectionable or violates any party’s intellectual
              property or these Terms of Service.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              You agree that your comments will not violate any right of any
              third-party, including copyright, trademark, privacy, personality
              or other personal or proprietary right. You further agree that
              your comments will not contain libelous or otherwise unlawful,
              abusive or obscene material, or contain any computer virus or
              other malware that could in any way affect the operation of the
              Service or any related website. You may not use a false e mail
              address, pretend to be someone other than yourself, or otherwise
              mislead us or third-parties as to the origin of any comments. You
              are solely responsible for any comments you make and their
              accuracy. We take no responsibility and assume no liability for
              any comments posted by you or any third-party.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 12 - PERSONAL INFORMATION
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              Your submission of personal information through the Service is
              governed by our <NavLink to={privacyPath}>Privacy Policy</NavLink>
              .
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 13 - ERRORS, INACCURACIES AND OMISSIONS
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              Occasionally there may be information on our site or in the
              Service that contains typographical errors, inaccuracies or
              omissions that may relate to the Services we provide. We reserve
              the right to correct any errors, inaccuracies or omissions, and to
              change or update information if any information in the Service or
              on any related website is inaccurate at any time without prior
              notice.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              We undertake no obligation to update, amend or clarify information
              in the Service or on any related website as required by law. No
              specified update or refresh date applied in the Service or on any
              related website, should be taken to indicate that all information
              in the Service or on any related website has been modified or
              updated.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 14 - PROHIBITED USES
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              In addition to other prohibitions as set forth in the Terms of
              Service, you are prohibited from using the site or its content:
              (a) for any unlawful purpose; (b) to solicit others to perform or
              participate in any unlawful acts; (c) to violate any
              international, federal, provincial or state regulations, rules,
              laws, or local ordinances; (d) to infringe upon or violate our
              intellectual property rights or the intellectual property rights
              of others; (e) to harass, abuse, insult, harm, defame, slander,
              disparage, intimidate, or discriminate based on gender, sexual
              orientation, religion, ethnicity, race, age, national origin, or
              disability; (f) to submit false or misleading information; (g) to
              upload or transmit viruses or any other type of malicious code
              that will or may be used in any way that will affect the
              functionality or operation of the Service or of any related
              website, other websites, or the Internet; (h) to collect or track
              the personal information of others; (i) to spam, phish, pharm,
              pretext, spider, crawl, or scrape; (j) for any obscene or immoral
              purpose; or (k) to interfere with or circumvent the security
              features of the Service or any related website, other websites, or
              the Internet. We reserve the right to terminate your use of the
              Service or any related website for violating any of the prohibited
              uses.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 15 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              We do not guarantee, represent or warrant that your use of our
              service will be uninterrupted, timely, secure or error-free.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              We do not warrant that the results that may be obtained from the
              use of the service will be accurate or reliable.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              You agree that from time to time we may remove the service for
              indefinite periods of time or cancel the service at any time,
              without notice to you.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              You expressly agree that your use of, or inability to use, the
              service is at your sole risk. The service and all products and
              services delivered to you through the service are (except as
              expressly stated by us) provided 'as is' and 'as available' for
              your use, without any representation, warranties or conditions of
              any kind, either express or implied, including all implied
              warranties or conditions of merchantability, merchantable quality,
              fitness for a particular purpose, durability, title, and
              non-infringement.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              In no case shall ECIRCUITMANIA, our directors, officers,
              employees, affiliates, agents, contractors, interns, suppliers,
              service providers or licensors be liable for any injury, loss,
              claim, or any direct, indirect, incidental, punitive, special, or
              consequential damages of any kind, including, without limitation
              lost profits, lost revenue, lost savings, loss of data,
              replacement costs, or any similar damages, whether based in
              contract, tort (including negligence), strict liability or
              otherwise, arising from your use of any of the service or any
              products procured using the service, or for any other claim
              related in any way to your use of the service or any product,
              including, but not limited to, any errors or omissions in any
              content, or any loss or damage of any kind incurred as a result of
              the use of the service or any content (or product) posted,
              transmitted, or otherwise made available via the service, even if
              advised of their possibility. Because some states or jurisdictions
              do not allow the exclusion or the limitation of liability for
              consequential or incidental damages, in such states or
              jurisdictions, our liability shall be limited to the maximum
              extent permitted by law.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 16 - INDEMNIFICATION
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              You agree to indemnify, defend and hold harmless ECIRCUITMANIA and
              our parent, subsidiaries, affiliates, partners, officers,
              directors, agents, contractors, licensors, service providers,
              subcontractors, suppliers, interns and employees, harmless from
              any claim or demand, including reasonable attorneys’ fees, made by
              any third-party due to or arising out of your breach of these
              Terms of Service or the documents they incorporate by reference,
              or your violation of any law or the rights of a third-party.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 17 - SEVERABILITY
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              In the event that any provision of these Terms of Service is
              determined to be unlawful, void or unenforceable, such provision
              shall nonetheless be enforceable to the fullest extent permitted
              by applicable law, and the unenforceable portion shall be
              construed to reflect our original intent, such determination shall
              not affect the validity and enforceability of any other remaining
              provisions.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 18 - TERMINATION
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              The obligations and liabilities of the parties incurred prior to
              the termination date shall survive the termination of this
              agreement for all purposes.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              These Terms of Service are effective unless and until terminated
              by either you or us. You may terminate these Terms of Service at
              any time by notifying us that you no longer wish to use our
              Services, or when you cease using our site.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              If in our sole judgment you fail, or we suspect that you have
              failed, to comply with any term or provision of these Terms of
              Service, we also may terminate this agreement at any time without
              notice and you will remain liable for all amounts due up to and
              including the date of termination; and/or accordingly may deny you
              access to our Services (or any part thereof).
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 19 - ENTIRE AGREEMENT
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              The failure of us to exercise or enforce any right or provision of
              these Terms of Service shall not constitute a waiver of such right
              or provision.
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              These Terms of Service and any policies or operating rules posted
              by us on this site or in respect to The Service constitutes the
              entire agreement and understanding between you and us and govern
              your use of the Service, superseding any prior or contemporaneous
              agreements, communications and proposals, whether oral or written,
              between you and us (including, but not limited to, any prior
              versions of the Terms of Service).
            </PrivacyAndTermsSpan>
            <PrivacyAndTermsSpan>
              Any ambiguities in the interpretation of these Terms of Service
              shall not be construed against the drafting party.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 20 - GOVERNING LAW
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              These Terms of Service and any separate agreements whereby we
              provide you Services shall be governed by and construed in
              accordance with the laws of United States. The proper venue for
              any disputes arising out of or relating to the Terms and any
              access to or use of our Services will be the state and federal
              courts located in Madison County, Alabama.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 21 - CHANGES TO TERMS OF SERVICE
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              You can review the most current version of the Terms of Service at
              any time at this page. We reserve the right, at our sole
              discretion, to update, change or replace any part of these Terms
              of Service by posting updates and changes to our website. It is
              your responsibility to check our website periodically for changes.
              Your continued use of or access to our website or the Service
              following the posting of any changes to these Terms of Service
              constitutes acceptance of those changes.
            </PrivacyAndTermsSpan>

            <PrivacyAndTermsHeader>
              SECTION 22 - CONTACT INFORMATION
            </PrivacyAndTermsHeader>
            <PrivacyAndTermsSpan>
              Questions about the Terms of Service should be sent to us at
              terms@ecircuitmania.com.
            </PrivacyAndTermsSpan>
          </PrivacyAndTermsContainer>
        </Panel>
      </MaxWidth>
    </PageContent>
  );
};
