import { BracketBuilder } from "components/TournamentEditor/BracketBuilder/BracketBuilder";
import { TeMatchFormat } from "model/TournamentEditor/TeMatchFormat";
import { TeBracketSegment } from "model/TournamentEditor/Segment/Bracket/TeBracketSegment";
import { TeBracketGroup } from "model/TournamentEditor/Segment/Bracket/TeBracketGroup";
import { arrayReplaceAt } from "Utility";

interface Props {
  matchFormats: TeMatchFormat[];
  bracket: TeBracketSegment;
  update: (bracket: TeBracketSegment) => void;
}
export const Bracket = ({ matchFormats, bracket, update }: Props) => {
  const updateBracket = (group: TeBracketGroup, groupIdx: number) => {
    update({
      ...bracket,
      groups: arrayReplaceAt(bracket.groups, group, groupIdx),
    });
  };

  return (
    <>
      {bracket.groups.map((g, gIdx) => (
        <BracketBuilder
          matchFormats={matchFormats}
          bracket={g}
          update={(group) => updateBracket(group, gIdx)}
        />
      ))}
    </>
  );
};
