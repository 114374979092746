import { FsOrg } from "db/model/org/FsOrg";
import { getAllOrgDetails } from "fsModel/Org/Org";
import { teamIncludesOrg } from "fsModel/Team/Team";
import { Org, Team } from "model/IdData";
import {
  Validated,
  ValidationProblem,
  ValidationType,
  validateMandatoryField,
  validateUniqueValues,
} from "./base";

export const validateOrgs = (orgs: Org[], teams: Team[]) => {
  const validatedOrgs: Validated<Org>[] = [];
  for (var i = 0; i < orgs.length; i++) {
    const org = orgs[i];
    const otherOrgs = orgs.filter((d) => d !== org);
    validatedOrgs.push({
      model: org,
      problems: validateOrg(org, otherOrgs, teams),
    });
  }

  return validatedOrgs;
};

// todo - can these mandatory / unique be identified via Attributes on the FsModel? (not sure if this is possible in JS)
export const validateOrg = (org: Org, otherOrgs: Org[], teams: Team[]) => {
  const problems: ValidationProblem[] = [];
  if (!org.data.details) {
    problems.push({
      fieldName: "Details",
      message: "Mandatory Field Undefined",
      type: ValidationType.Error,
    });
    return problems;
  } else {
    org.data.details.forEach((d) =>
      validateMandatoryField(problems, "Name", d.name)
    );
    org.data.details.forEach((d) =>
      validateMandatoryField(problems, "Trigram", d.trigram)
    );
    validateOrgDetailsUniqueValues(problems, org, otherOrgs);
    validateOrgTeamHistory(problems, "Team History", org, teams);
    return problems;
  }
};

export const validateOrgDetailsUniqueValues = (
  problems: ValidationProblem[],
  org: Org,
  otherOrgs: Org[]
) => {
  const orgDetails = getAllOrgDetails(org.data);

  const orgNameInDetails = orgDetails
    .map((d) => d.name)
    .filter((name) => name !== undefined) as string[];
  const orgTrigramInDetails = orgDetails
    .map((d) => d.trigram)
    .filter((trigram) => trigram !== undefined) as string[];

  orgNameInDetails.forEach((d) => {
    validateUniqueValues(problems, "Name", org, otherOrgs, (model: FsOrg) => {
      const otherModelDetails = model.details?.flatMap((d) => d.name) ?? [];
      const otherModelAltDetails =
        model.altDetails?.flatMap((d) => d.name) ?? [];
      return [...otherModelDetails, ...otherModelAltDetails];
    });
  });
  orgDetails.forEach((d) => {
    validateUniqueValues(
      problems,
      "Trigram",
      org,
      otherOrgs,
      (model: FsOrg) => {
        const otherModelDetails =
          model.details?.flatMap((d) => d.trigram) ?? [];
        const otherModelAltDetails =
          model.altDetails?.flatMap((d) => d.trigram) ?? [];
        return [...otherModelDetails, ...otherModelAltDetails];
      }
    );
  });
};

export const validateOrgTeamHistory = (
  problems: ValidationProblem[],
  fieldName: string,
  org: Org,
  teams: Team[]
) => {
  org.data.teamHistory?.forEach((t) => {
    const teamRelevant = teams.find((team) => team.id === t);
    if (!teamRelevant) {
      problems.push({
        fieldName: fieldName,
        message: `Team DNE ${t}`,
        type: ValidationType.Warning,
      });
    } else if (!teamIncludesOrg(teamRelevant.data, org.id)) {
      problems.push({
        fieldName: fieldName,
        message: `Missing from Team ${t}`,
        type: ValidationType.Warning,
      });
    }
  });
  const teamsRelevant = teams.filter((team) =>
    teamIncludesOrg(team.data, org.id)
  );
  teamsRelevant.forEach((team) => {
    if (!org.data.teamHistory?.includes(team.id)) {
      problems.push({
        fieldName: fieldName,
        message: `Missing from Org ${team.id}`,
        type: ValidationType.Warning,
      });
    }
  });
  return problems;
};
