import { IdData } from "model/IdData";
import { TeMatch } from "model/TournamentEditor/TeMatch";
import {
  mapTeTournamentSegmentBaseToFsData,
  newTeTournamentSegmentBase,
  TeTournamentSegmentBase,
} from "../TeTournamentSegment";
import {
  mapTeBracketGroupsToFsData,
  newTeBracketGroup,
  TeBracketGroup,
} from "./TeBracketGroup";
import { FsBracketSegment } from "db/model/tournament/Segment/Bracket/FsBracket";
import { BracketType } from "fsModel/Bracket/Bracket";
import { TeMatchFormat } from "model/TournamentEditor/TeMatchFormat";
import {
  TeBracketMatch,
  updateTeBracketMatchSourceStructure,
} from "./TeBracketMatch";

export type TeBracketSegment = TeTournamentSegmentBase<TeBracketGroup> & {
  type: "Bracket";
};

export const newTeBracketSegment = (): TeBracketSegment => {
  return {
    ...newTeTournamentSegmentBase(),
    groups: [newTeBracketGroup()],
    type: "Bracket",
  };
};

export const mapTeBracketSegmentToFsData = (
  teSegment: TeBracketSegment,
  teMatchesWithIds: IdData<TeMatch>[]
): FsBracketSegment => {
  return {
    ...mapTeTournamentSegmentBaseToFsData(
      teSegment,
      teMatchesWithIds,
      mapTeBracketGroupsToFsData
    ),
    type: "Bracket",
  };
};

export const updateTeBracketMatchSourceStructures = (
  matches: TeBracketMatch[],
  bracketType: BracketType | undefined,
  matchFormats: TeMatchFormat[]
) => {
  return matches.map((m) => {
    const match = m.match;
    if (typeof match === "string") {
      return m;
    }
    return updateTeBracketMatchSourceStructure(
      m,
      bracketType,
      matchFormats.find((f) => f.key === match.formatKey)
    );
  });
};
