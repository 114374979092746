import { TeTournamentSegment } from "model/TournamentEditor/Segment/TeTournamentSegment";
import { Season } from "./Season/Season";
import { Qualifier } from "./Qualifier/Qualifier";
import { Swiss } from "./Swiss/Swiss";
import { Bracket } from "./Bracket/Bracket";
import { Single } from "./Single/Single";
import { TeMatchFormat } from "model/TournamentEditor/TeMatchFormat";
import { checkTypeExhausted } from "Utility";

interface Props {
  matchFormats: TeMatchFormat[];
  segment: TeTournamentSegment;
  update: (segment: TeTournamentSegment) => void;
}

export const SegmentArgs = ({ matchFormats, segment, update }: Props) => {
  const formatKeys = matchFormats.map((f) => f.key);

  switch (segment.type) {
    case "Bracket":
      return (
        <Bracket
          matchFormats={matchFormats}
          bracket={segment}
          update={update}
        />
      );
    case "Qualifier":
      return (
        <Qualifier
          formatKeys={formatKeys}
          qualifier={segment}
          update={update}
        />
      );
    case "Season":
      return (
        <Season formatKeys={formatKeys} season={segment} update={update} />
      );
    case "Single":
      return (
        <Single formatKeys={formatKeys} single={segment} update={update} />
      );
    case "Swiss":
      return <Swiss formatKeys={formatKeys} swiss={segment} update={update} />;
    default:
      checkTypeExhausted(segment);
  }

  return <></>;
};
