import { FsTrack } from "db/model/track/FsTrack";
import { FormProps } from "../FormProps";
import { FormTable } from "../styles";

export const TrackForm = ({
  data: track,
  setData: setTrack,
}: FormProps<FsTrack>) => {
  return (
    <FormTable>
      <tbody>
        <tr>
          <td>Name</td>
          <td>
            <input
              value={track.name}
              onChange={(e) => {
                track.name = e.target.value;
                setTrack(track);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Mapper Name</td>
          <td>
            <input
              placeholder="optional"
              value={track.mapperName}
              onChange={(e) => {
                track.mapperName = e.target.value;
                setTrack(track);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Link</td>
          <td>
            <input
              placeholder="preferable"
              value={track.link}
              onChange={(e) => {
                track.link = e.target.value;
                setTrack(track);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Map Id</td>
          <td>
            <input
              placeholder="required"
              value={track.mapId}
              onChange={(e) => {
                track.mapId = e.target.value;
                setTrack(track);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Image Id Override</td>
          <td>
            <input
              placeholder="optional"
              value={track.imageIdOverride}
              onChange={(e) => {
                track.imageIdOverride = e.target.value;
                setTrack(track);
              }}
            />
          </td>
        </tr>
      </tbody>
    </FormTable>
  );
};
