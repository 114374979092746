import { nonUndefined } from "Utility";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { getWinnerIds } from "fsModel/Match/Results";
import { Scores } from "fsModel/Match/Scores";
import { isPlayCondition } from "fsModel/Match/PlayCondition";
import { FsForwardCupModeLongSet } from "db/model/match/Set/CupModeLong/Forward/FsForwardCupModeLongSet";
import {
  getForwardCupModeLongTrackFinalPositions,
  getForwardCupModeLongTrackScores,
  isForwardCupModeLongTrackComplete,
} from "./Track";
import { isPlayConditionComplete } from "../../MatchSet";
import { calculateFinalPositions } from "fsModel/Match/Positions";

const NUM_WINNERS = 1;

export const getForwardCupModeLongSetScores = (
  set: FsForwardCupModeLongSet,
  teams: FsMatchTeam[]
): Scores => {
  const teamIds = nonUndefined(teams.map((t) => t.teamId));
  const scores: Scores = {};

  const points = set.format?.points;
  const pointsLimit = set.format?.pointsLimit;
  const driversPerTeam = set.format?.driversPerTeam;

  const trackWinnerIds: string[][] = [];
  if (!!points && !!pointsLimit && !!driversPerTeam) {
    set.tracks?.forEach((t) => {
      const scores = getForwardCupModeLongTrackScores(
        t,
        teams,
        points,
        pointsLimit,
        driversPerTeam
      );
      const isComplete = isForwardCupModeLongTrackComplete(
        t,
        scores,
        pointsLimit
      );
      const results = getForwardCupModeLongTrackFinalPositions(
        scores,
        isComplete
      );
      const winnerIds = getWinnerIds(results, NUM_WINNERS);
      if (!!winnerIds) {
        trackWinnerIds.push(winnerIds);
      }
    });
  }

  // Below logic same as Matchmaking equivalent
  for (var i = 0; i < teamIds.length; i++) {
    const teamId = teamIds[i];
    const scoreOverride = !!set.scoreOverrides
      ? set.scoreOverrides[teamId]
      : undefined;
    if (scoreOverride !== undefined) {
      scores[teamId] = scoreOverride;
    } else {
      scores[teamId] = trackWinnerIds.filter((winnerIds) =>
        winnerIds.includes(teamId)
      ).length;
    }
  }

  return scores;
};

export const isForwardCupModeLongSetComplete = (
  set: FsForwardCupModeLongSet,
  scores: Scores
) => {
  if (set.overrideComplete) {
    return true;
  }

  const playCondition = set.format?.playCondition;
  const numTracks = set.format?.numTracks;
  const pointsLimit = set.format?.pointsLimit;
  if (!pointsLimit || !numTracks || !isPlayCondition(playCondition)) {
    return false;
  }

  const numCompleteTracks =
    set.tracks?.filter((t) =>
      isForwardCupModeLongTrackComplete(t, scores, pointsLimit)
    ).length ?? 0;

  return isPlayConditionComplete(
    scores,
    playCondition,
    numTracks,
    numCompleteTracks
  );
};

export const getForwardCupModeLongSetFinalPositions = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

// todo - Add tiebreaker logic here
const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
