import { deleteDraftAsync } from "database/tournamentDraft/tournamentDraftInfo";
import { addTournamentIdToOrganizationAsync } from "database/tournamentOrganization/tournamentOrganizationInfo";
import { Driver, IdData, Match, Org, Team, Tournament } from "model/IdData";
import { getNextNumericalId, nonUndefined } from "Utility";
import {
  getTeTournamentSegmentMatches,
  mapTeTournamentSegmentsToFsData,
} from "./Segment/TeTournamentSegment";
import { TeMatch, mapTeMatch } from "./TeMatch";
import { mapTeTeam, TeTeam } from "./TeTeam";
import { TeTournament } from "./TeTournament";
import { FsTrack } from "db/model/track/FsTrack";
import { mapTeTrack } from "./TeTrack";
import { writeDataToDbAsync } from "db/info/core";
import {
  driverCollectionName,
  matchCollectionName,
  orgCollectionName,
  teamCollectionName,
  tournamentCollectionName,
  trackCollectionName,
} from "db/info/collectionNames";
import { isFsSingleDriverTeam } from "db/model/team/FsTeam";

// todo - cleanup needed
export const publishTournamentAsync = async (
  draftId: string | undefined,
  organizationId: string | undefined,
  teTournament: TeTournament,
  existingTournamentId: string | undefined,
  existingDriverIds: string[],
  existingMatchIds: string[],
  existingOrgIds: string[],
  existingTeamIds: string[],
  existingTrackIds: string[],
  existingOrgs: Org[],
  existingDrivers: Driver[],
  fsTeams: Team[],
  existingMatchesEarliestStartTimeMs: number | undefined
) => {
  if (!teTournament.id) {
    return undefined;
  }

  const teMatches = teTournament.segments
    .flatMap((s) => getTeTournamentSegmentMatches(s) ?? [])
    .filter((m) => typeof m !== "string") as TeMatch[];
  const existingMatchIdsCopy = [...existingMatchIds];
  const teMatchesWithIds: IdData<TeMatch>[] = nonUndefined(
    teMatches.map((m) => getTeMatchWithId(m, existingMatchIdsCopy))
  );

  const startTimes = nonUndefined([
    ...teMatches.map((m) => m.startTimeMs),
    existingMatchesEarliestStartTimeMs,
  ]);
  const tournamentStartTimeMs =
    startTimes.length > 0 ? Math.min(...startTimes) : undefined;
  const tournamentEndTimeMs =
    startTimes.length > 0 ? Math.max(...startTimes) : undefined;
  const tournament: Tournament = {
    id: teTournament.id,
    data: {
      description: teTournament.description,
      filterName: teTournament.filterName,
      name: teTournament.name,
      trigram: teTournament.trigram,

      imageIdOverride: teTournament.imageIdOverride,
      startTimeMs: tournamentStartTimeMs,
      endTimeMs: tournamentEndTimeMs,
      segmentLinks: undefined,

      prizes: undefined,
      rankingGroups: undefined,

      segments: mapTeTournamentSegmentsToFsData(
        teTournament.segments,
        teMatchesWithIds
      ),
      teamIds: [],
      theme: undefined,
      trackIds: nonUndefined(teTournament.trackIds),
    },
  };

  const newMatches: Match[] = nonUndefined(
    teMatchesWithIds.map((m) =>
      mapTeMatch(m.data, m.id, teTournament.matchFormats)
    )
  );

  let nextTeamId = getNextNumericalId(existingTeamIds);
  let nextDriverId = getNextNumericalId(existingDriverIds);
  let nextOrgId = getNextNumericalId(existingOrgIds);
  const { driversToWrite, orgsToWrite, teamsToWrite, tournamentTeamIds } =
    getTeamBasedDataToWrite(
      teTournament.teams,
      fsTeams,
      nextDriverId,
      nextOrgId,
      nextTeamId,
      existingDrivers,
      existingOrgs
    );

  tournament.data.teamIds = tournamentTeamIds;
  for (let d = 0; d < driversToWrite.length; d++) {
    const driverToWrite = driversToWrite[d];
    await writeDataToDbAsync(
      driverToWrite.driver.id,
      driverToWrite.driver.data,
      driverCollectionName,
      driverToWrite.overwrite
    );
  }

  for (let o = 0; o < orgsToWrite.length; o++) {
    const orgToWrite = orgsToWrite[o];
    await writeDataToDbAsync(
      orgToWrite.org.id,
      orgToWrite.org.data,
      orgCollectionName,
      orgToWrite.overwrite
    );
  }

  for (let t = 0; t < teamsToWrite.length; t++) {
    const teamToWrite = teamsToWrite[t];
    await writeDataToDbAsync(
      teamToWrite.team.id,
      teamToWrite.team.data,
      teamCollectionName,
      teamToWrite.overwrite
    );
  }

  if (teTournament.newTracks.length > 0) {
    const newTracks: FsTrack[] = teTournament.newTracks.map(mapTeTrack);
    let nextTrackId = getNextNumericalId(existingTrackIds);
    for (let k = 0; k < newTracks.length; k++) {
      const newTrackId = nextTrackId.toString();
      await writeDataToDbAsync(
        newTrackId,
        newTracks[k],
        trackCollectionName,
        false
      );
      tournament.data.trackIds?.push(newTrackId);
      nextTrackId++;
    }
  }

  for (let l = 0; l < newMatches.length; l++) {
    const newMatch = newMatches[l];
    await writeDataToDbAsync(
      newMatch.id,
      newMatch.data,
      matchCollectionName,
      false
    );
  }

  await writeDataToDbAsync(
    tournament.id,
    tournament.data,
    tournamentCollectionName,
    !!existingTournamentId && existingTournamentId === tournament.id
  );

  if (!!draftId) {
    await deleteDraftAsync(draftId, organizationId);
  }

  if (!!organizationId) {
    await addTournamentIdToOrganizationAsync(organizationId, tournament.id);
  }
};

const getNextMatchId = (startTimeMs: number, existingIds: string[]) => {
  const idPrefix = startTimeMs.toString();
  const matchingIds = existingIds.filter((id) => id.startsWith(idPrefix));
  const suffixNumbers = matchingIds
    .map((id) => parseInt(id.substring(id.length - 3)))
    .filter((n) => !isNaN(n));

  const idSuffix = Math.max(...suffixNumbers, 0) + 1;
  const matchId = `${idPrefix}x${
    idSuffix < 10 ? "00" : idSuffix < 100 ? "0" : ""
  }${idSuffix}`;
  return matchId;
};

const getTeMatchWithId = (
  match: TeMatch,
  existingIds: string[]
): IdData<TeMatch> | undefined => {
  if (!match.startTimeMs) {
    return undefined;
  }

  const matchId = getNextMatchId(match.startTimeMs, existingIds);
  existingIds.push(matchId);
  return { id: matchId, data: match };
};

const getTeamBasedDataToWrite = (
  teTeams: TeTeam[],
  fsTeams: Team[],
  nextDriverId: number,
  nextOrgId: number,
  nextTeamId: number,
  existingDrivers: Driver[],
  existingOrgs: Org[]
) => {
  const driversToWrite: {
    driver: Driver;
    overwrite: boolean;
  }[] = [];
  const orgsToWrite: { org: Org; overwrite: boolean }[] = [];
  const teamsToWrite: { team: Team; overwrite: boolean }[] = [];
  const tournamentTeamIds: string[] = [];

  let driverId = nextDriverId;
  let orgId = nextOrgId;
  let teamId = nextTeamId;
  for (let i = 0; i < teTeams.length; i++) {
    const teTeam = teTeams[i];

    // If team has existingTeamId, skip if nothing changed is an overwrite of an existing fsTeam
    if (!!teTeam.existingTeamId) {
      const fsTeam = fsTeams.find((t) => t.id === teTeam.existingTeamId);
      if (!!fsTeam) {
        const fsTeamOrg = fsTeam.data.orgId;
        const fsTeamDriverIds = nonUndefined(
          isFsSingleDriverTeam(fsTeam.data)
            ? [fsTeam.data.driverId]
            : fsTeam.data.drivers?.map((d) => d.id) ?? []
        );

        // If same org and drivers, don't want to write anything but store the teamId
        if (
          teTeam.org === fsTeamOrg &&
          teTeam.drivers.every(
            (d) => typeof d === "string" && fsTeamDriverIds.includes(d)
          )
        ) {
          tournamentTeamIds.push(teTeam.existingTeamId);
          continue;
        }
      }
    }

    const isExistingTeam = !!teTeam.existingTeamId;
    const { team, newDrivers, newOrg } = mapTeTeam(
      teTeam,
      teTeam.existingTeamId ?? teamId.toString(),
      orgId,
      driverId,
      existingOrgs,
      existingDrivers
    );

    newDrivers.forEach((newDriver) => {
      driversToWrite.push({ driver: newDriver, overwrite: false });
      driverId += 1;
    });

    if (!!newOrg) {
      orgsToWrite.push({ org: newOrg, overwrite: false });
      orgId += 1;
    }

    if (!isExistingTeam) {
      teamId += 1;
    }

    teamsToWrite.push({ team, overwrite: isExistingTeam });
    tournamentTeamIds.push(team.id);
  }

  return { driversToWrite, orgsToWrite, teamsToWrite, tournamentTeamIds };
};
