import styled from "styled-components";
import { Theme } from "theme";

export const Button = styled.button`
  padding: 4px 8px;
  background: ${Theme.buttonBackground};
  color: ${Theme.buttonForeground};
  border: ${Theme.buttonBorder};
  box-shadow: ${Theme.panelContentBoxShadow};
  text-decoration: none;
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background: ${Theme.buttonHoverBackground};
    color: ${Theme.buttonHoverForeground};
    border-color: ${Theme.buttonHoverForeground};
  }

  &:disabled {
    cursor: default;
    background: ${Theme.buttonDisabledBackground};
    color: ${Theme.buttonDisabledForeground};
    border-color: ${Theme.buttonDisabledForeground};
  }
`;

export const ClearButton = styled(Button)`
  background: transparent;
  border: none;
  box-shadow: none;

  &:hover {
    background: none;
  }
`;

export const SecondaryButton = styled.button`
  color: ${Theme.buttonForeground};
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${Theme.buttonHoverForeground};
  }

  &:disabled {
    cursor: default;
    color: ${Theme.buttonDisabledForeground};
  }
`;

export const A = Button.withComponent("a");

export const Select = styled.select`
  padding: 5px 5px 5px 1px;
  border-color: ${Theme.selectBorderColor};
  box-shadow: ${Theme.panelContentBoxShadow};
`;

export const CenterAlignHeader = styled.th`
  text-align: center;
`;

export const LeftAlignHeader = styled.th`
  text-align: left;
`;
