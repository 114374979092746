import {
  TeCupModeSetFormatArgs,
  newTeCupModeSetFormatBase,
  mapTeCupModeSetFormatBase,
} from "../TeCupModeMatchFormat";
import { FsSetFormatBase } from "db/model/match/Set/FsSetFormat";
import { FsReverseCupModeFormat } from "db/model/match/Set/CupMode/Reverse/FsReverseCupModeFormat";

export type TeReverseCupModeSetFormat = TeCupModeSetFormatArgs & {
  startingPoints: number | undefined;
  type: "ReverseCupMode";
};

export const newTeReverseCupModeSetFormat = (): TeReverseCupModeSetFormat => {
  return {
    ...newTeCupModeSetFormatBase(),
    startingPoints: undefined,
    type: "ReverseCupMode",
  };
};

export const mapTeReverseCupModeSetFormat = (
  base: FsSetFormatBase,
  format: TeReverseCupModeSetFormat
): FsReverseCupModeFormat => {
  return {
    ...mapTeCupModeSetFormatBase(base, format),
    lastChance: true,
    startingPoints: format.startingPoints,
  };
};
