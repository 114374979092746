import { FsSetFormatBase } from "db/model/match/Set/FsSetFormat";
import { TeSetFormatArgsBase, newTeSetFormatArgsBase } from "../TeSetFormat";
import { FsRoundsFormat } from "db/model/match/Set/Rounds/FsRoundsFormat";
import { mapStringToPointsDistribution } from "model/PointsDistribution";

export type TeRoundsSetFormat = TeSetFormatArgsBase & {
  dnfPoints: number | undefined;
  pointsDistribution: string | undefined;
  roundsPerTrack: number | undefined;
  type: "Rounds";
};

export const newTeRoundsSetFormat = (): TeRoundsSetFormat => {
  return {
    ...newTeSetFormatArgsBase(),
    dnfPoints: undefined,
    pointsDistribution: undefined,
    roundsPerTrack: undefined,
    type: "Rounds",
  };
};

export const mapTeRoundsSetFormat = (
  base: FsSetFormatBase,
  format: TeRoundsSetFormat
): FsRoundsFormat => {
  return {
    ...base,
    points: {
      distribution: mapStringToPointsDistribution(format.pointsDistribution),
      dnfPoints: format.dnfPoints,
    },
    roundsPerTrack: format.roundsPerTrack,
  };
};
