export type FsDriver = {
  country: string | undefined;
  name: string | undefined;
  nickname: string | undefined;
  peripheral: string | undefined;
  teamHistory: string[] | undefined;
  ubisoftUids: string[] | undefined;
};

export const perihperals: string[] = [
  "unknown",
  "controller",
  "keyboard",
  "mouse",
  "wheel",
  "other",
];
