import { Track, Tournament } from "model/IdData";
import {
  Validated,
  ValidationProblem,
  ValidationType,
  validateMandatoryField,
} from "./base";

export const validateTracks = (tracks: Track[], tournaments: Tournament[]) => {
  const validatedTracks: Validated<Track>[] = [];
  for (var i = 0; i < tracks.length; i++) {
    const track = tracks[i];
    validatedTracks.push({
      model: track,
      problems: validateTrack(track, tournaments),
    });
  }

  return validatedTracks;
};

// todo - can these mandatory / unique be identified via Attributes on the FsModel? (not sure if this is possible in JS)
export const validateTrack = (track: Track, tournaments: Tournament[]) => {
  const problems: ValidationProblem[] = [];
  validateMandatoryField(problems, "Mapper Name", track.data.mapperName);
  validateMandatoryField(problems, "Name", track.data.name);
  validateMandatoryField(problems, "Link", track.data.link);
  validateTrackInTournament(problems, "Tournament", track, tournaments);
  return problems;
};

export const validateTrackInTournament = (
  problems: ValidationProblem[],
  fieldName: string,
  track: Track,
  tournaments: Tournament[]
) => {
  const tournamentsRelevant = tournaments.find((tournament) =>
    tournament.data.trackIds?.includes(track.id)
  );
  if (!tournamentsRelevant) {
    problems.push({
      fieldName: fieldName,
      message: `Not used`,
      type: ValidationType.Message,
    });
  }
  return problems;
};
