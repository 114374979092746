import { FsSetFormatBase } from "db/model/match/Set/FsSetFormat";
import { TeSetFormatArgsBase, newTeSetFormatArgsBase } from "../TeSetFormat";
import { FsMatchmakingFormat } from "db/model/match/Set/Matchmaking/FsMatchmakingFormat";
import { mapStringToPointsDistribution } from "model/PointsDistribution";
import { PlayCondition } from "fsModel/Match/PlayCondition";

export type TeMatchmakingSetFormat = TeSetFormatArgsBase & {
  dnfPoints: number | undefined;
  playCondition: PlayCondition;
  pointsDistribution: string | undefined;
  roundsLimit: number | undefined;
  trackWinReq: number | undefined;
  type: "Matchmaking";
};

export const newTeMatchmakingSetFormat = (): TeMatchmakingSetFormat => {
  return {
    ...newTeSetFormatArgsBase(),
    dnfPoints: undefined,
    playCondition: "BestOf",
    pointsDistribution: undefined,
    roundsLimit: undefined,
    trackWinReq: undefined,
    type: "Matchmaking",
  };
};

export const mapTeMatchmakingSetFormat = (
  base: FsSetFormatBase,
  format: TeMatchmakingSetFormat
): FsMatchmakingFormat => {
  return {
    ...base,
    playCondition: format.playCondition,
    points: {
      distribution: mapStringToPointsDistribution(format.pointsDistribution),
      dnfPoints: format.dnfPoints,
    },
    roundsLimit: format.roundsLimit,
    trackWinReq: format.trackWinReq,
  };
};
