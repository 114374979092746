import styled from "styled-components";
import { CenterAlignHeader } from "styles/elements";
import { Theme } from "theme";

export const MatchViewerTeamsTable = styled.table`
  border-spacing: 0;
  padding: ${Theme.bigPanelPadding};

  th {
    font-weight: 500;
    font-size: 10pt;
  }

  @media (max-width: 800px) {
    padding: 3px ${Theme.smallPanelPadding};
  }

  @media (max-width: 700px) {
    th {
      font-size: 9pt;
    }
  }

  @media (max-width: 500px) {
    padding: 3px;
  }
`;

export const OrgNameCell = styled.td`
  text-align: left;

  span {
    width: 175px;
    padding-right: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: clip;
  }

  @media (max-width: 800px) {
    span {
      width: 160px;
      padding-right: 4px;
    }
  }

  @media (max-width: 700px) {
    font-size: 11pt;
    span {
      width: 145px;
      padding-right: 2px;
    }
  }

  @media (max-width: 500px) {
    font-size: 10.5pt;
    span {
      width: 120px;
    }
  }
`;

export const OrgLogoContainer = styled.div`
  width: 28px;
  height: 28px;
  padding-right: 8px;

  @media (max-width: 800px) {
    width: 25px;
    height: 25px;
    padding-right: 4px;
  }

  @media (max-width: 500px) {
    width: 21px;
    height: 21px;
    padding-right: 3px;
  }
`;

export const FinalHeader = styled(CenterAlignHeader)`
  width: 25px;
  padding-right: 25px;
  text-align: center;
  color: black;

  @media (max-width: 800px) {
    width: 21px;
  }

  @media (max-width: 600px) {
    width: 16px;
  }

  @media (max-width: 500px) {
    width: 12px;
  }
`;

export const SetHeader = styled(CenterAlignHeader)`
  color: black;
`;

export const TrackHeader = styled(CenterAlignHeader)`
  color: gray;
  font-size: 9pt;
`;

export const MatchScoreCell = styled.td`
  width: 25px;
  padding-right: 25px;
  text-align: center;

  @media (max-width: 800px) {
    width: 21px;
    font-size: 11.5pt;
  }

  @media (max-width: 600px) {
    width: 16px;
  }

  @media (max-width: 500px) {
    font-size: 10pt;
    width: 12px;
  }
`;

const PartScoreCell = styled.td<{ isWinner: boolean }>`
  width: 38px;
  text-align: center;
  color: gray;
  font-size: 0.85em;

  @media (max-width: 800px) {
    width: 30px;
  }

  @media (max-width: 600px) {
    width: 20px;
    font-size: 0.7em;
  }

  @media (max-width: 500px) {
    width: 18px;
  }

  ${({ isWinner }) => isWinner && "font-weight: bold"}
`;

export const SetPartScoreCell = styled(PartScoreCell)``;

export const TrackPartScoreCell = styled(PartScoreCell)`
  font-size: 0.7em;
`;
