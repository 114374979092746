import {
  createSlice,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";

export interface IFetchSlice {
  initialFetchComplete: boolean;
}

export const initialFetchState: IFetchSlice = {
  initialFetchComplete: false,
};

export const createFetchSlice = <
  T extends IFetchSlice,
  Reducers extends SliceCaseReducers<T>
>({
  name,
  initialState,
  reducers,
}: {
  name: string;
  initialState: T;
  reducers: ValidateSliceCaseReducers<T, Reducers>;
}) => {
  return createSlice({
    name,
    initialState,
    reducers,
  });
};
