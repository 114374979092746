import { Logo, LogoContainer } from "./styles";

interface Props {
  imageId: string;
  title?: string;
}

export const TournamentLogo = ({ imageId, title }: Props) => {
  return (
    <LogoContainer>
      <Logo src={`/images/tournaments/${imageId}/logo.png`} title={title} />
    </LogoContainer>
  );
};
