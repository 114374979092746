import { FsTrack } from "db/model/track/FsTrack";

export type TeTrack = {
  link: string | undefined;
  mapId: string | undefined;
  mapperName: string | undefined;
  name: string | undefined;
};

export const newTeTrack = (): TeTrack => {
  return {
    link: undefined,
    mapId: undefined,
    mapperName: undefined,
    name: undefined,
  };
};

export const mapTeTrack = (teTrack: TeTrack): FsTrack => {
  return {
    link: teTrack.link,
    mapId: teTrack.mapId,
    mapperName: teTrack.mapperName,
    name: teTrack.name,
    imageIdOverride: undefined,
  };
};
