import { checkTypeExhausted } from "Utility";
import {
  FsMatchSet,
  FsMatchSetBase,
  FsMatchSetType,
} from "db/model/match/Set/FsMatchSet";
import { newFsForwardCupModeSet } from "./CupMode/Forward/FsForwardCupModeSet";
import { newFsReverseCupModeSet } from "./CupMode/Reverse/FsReverseCupModeSet";
import { newFsMatchmakingSet } from "./Matchmaking/FsMatchmakingSet";
import { newFsRoundsSet } from "./Rounds/FsRoundsSet";
import { newFsTimeAttackSet } from "./TimeAttack/FsTimeAttackSet";
import { newFsWorldTourSet } from "./WorldTour/FsWorldTourSet";
import { newFsForwardCupModeLongSet } from "./CupModeLong/Forward/FsForwardCupModeSet";
import { newFsKnockoutSet } from "./Knockout/FsKnockoutSet";

export const newFsMatchSetBase = (): FsMatchSetBase => {
  return {
    isTiebreaker: undefined,
    overrideComplete: undefined,
    picks: [],
    points: undefined,
    scoreOverrides: {},
  };
};

export const newFsMatchSet = (type: FsMatchSetType): FsMatchSet => {
  switch (type) {
    case "ForwardCupMode":
      return newFsForwardCupModeSet();
    case "ForwardCupModeLong":
      return newFsForwardCupModeLongSet();
    case "Knockout":
      return newFsKnockoutSet();
    case "Matchmaking":
      return newFsMatchmakingSet();
    case "ReverseCupMode":
      return newFsReverseCupModeSet();
    case "Rounds":
      return newFsRoundsSet();
    case "TimeAttack":
      return newFsTimeAttackSet();
    case "WorldTour":
      return newFsWorldTourSet();
    default:
      return checkTypeExhausted(type);
  }
};
