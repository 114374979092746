import { TeOrg, newTeOrg } from "model/TournamentEditor/TeOrg";
import { useSelector } from "react-redux";
import { SearchSelect } from "components/Fields/SearchSelect/SearchSelect";
import { TeOrgForm } from "components/AddEditForms/Org/TeOrgForm";
import { selectOrgOptions } from "selectors/options/Org";
import { IStore } from "redux/store";

interface Props {
  org: string | TeOrg | undefined;
  updateOrg: (org: string | TeOrg | undefined) => void;
}

export const TeamOrg = ({ org, updateOrg }: Props) => {
  const orgOptions = useSelector((state: IStore) =>
    selectOrgOptions(state, Object.keys(state.org.orgs))
  );

  return (
    <SearchSelect
      items={orgOptions}
      selectedItem={org}
      setSelectedItem={updateOrg}
      newItem={newTeOrg}
      itemForm={TeOrgForm}
      itemDisplay={(org: TeOrg) => org.name ?? "unnamed"}
      searchPlaceholder="Org"
    />
  );
};
