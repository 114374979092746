import { IdData } from "model/IdData";
import { TeMatch } from "model/TournamentEditor/TeMatch";
import {
  mapTeTournamentSegmentBaseToFsData,
  newTeTournamentSegmentBase,
  TeTournamentSegmentBase,
} from "../TeTournamentSegment";
import {
  mapTeRoundsGroupsToFsData,
  newTeRoundsGroup,
  TeRoundsGroup,
} from "./TeRoundsGroup";
import { FsSeasonSegment } from "db/model/tournament/Segment/Season/FsSeason";

export type TeSeasonSegment = TeTournamentSegmentBase<TeRoundsGroup> & {
  type: "Season";
};

export const newTeSeasonSegment = (): TeSeasonSegment => {
  return {
    ...newTeTournamentSegmentBase(),
    groups: [newTeRoundsGroup()],
    type: "Season",
  };
};

export const mapTeSeasonSegmentToFsData = (
  teSegment: TeSeasonSegment,
  teMatchesWithIds: IdData<TeMatch>[]
): FsSeasonSegment => {
  return {
    ...mapTeTournamentSegmentBaseToFsData(
      teSegment,
      teMatchesWithIds,
      mapTeRoundsGroupsToFsData
    ),
    altPoints: undefined,
    points: undefined,
    type: "Season",
  };
};
