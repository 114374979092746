import { IStore } from "redux/store";
import { selectTournament } from "./Tournament";
import { getTournamentMatchIds } from "fsModel/Tournament/Tournament";
import { isMatchComplete } from "fsModel/Match/MatchStatus";
import { Match } from "model/IdData";

export const selectAllMatchIds = (state: IStore) => {
  return Object.keys(state.match.matches);
};

export const selectMatch = (state: IStore, id: string | undefined) => {
  if (!id) {
    return undefined;
  }

  return state.match.matches[id];
};

export const selectMatchStartTime = (state: IStore, matchId: string) => {
  return selectMatch(state, matchId)?.data.startTimeMs;
};

export const selectIncompleteMatches = (state: IStore, matchIds: string[]) => {
  const incompleteMatches: Match[] = [];
  matchIds.forEach((id) => {
    const match = selectMatch(state, id);
    if (!!match && !isMatchComplete(match.data)) {
      incompleteMatches.push(match);
    }
  });

  return incompleteMatches;
};

export const selectCompletedMatches = (state: IStore, matchIds: string[]) => {
  const completeMatches: Match[] = [];
  matchIds.forEach((id) => {
    const match = selectMatch(state, id);

    if (!!match && isMatchComplete(match.data)) {
      completeMatches.push(match);
    }
  });

  return completeMatches;
};

export const selectRecentMatches = (state: IStore, amount: number) => {
  const matchIds = selectAllMatchIds(state);
  const completeMatches = selectCompletedMatches(state, matchIds);
  const sortedMatches = completeMatches.sort(
    (a, b) => (b.data.startTimeMs ?? 0) - (a.data.startTimeMs ?? 0)
  );

  return {
    matches: sortedMatches.slice(0, amount),
    totalAmount: sortedMatches.length,
  };
};

export const selectUpcomingMatches = (state: IStore, amount: number) => {
  const matchIds = selectAllMatchIds(state);
  const incompleteMatches = selectIncompleteMatches(state, matchIds);
  const sortedMatches = incompleteMatches.sort(
    (a, b) => (a.data.startTimeMs ?? 0) - (b.data.startTimeMs ?? 0)
  );

  return {
    matches: sortedMatches.slice(0, amount),
    totalAmount: sortedMatches.length,
  };
};

export const selectTournamentIdFromMatchId = (
  state: IStore,
  matchId: string
) => {
  const tournaments = state.user.tournaments;
  const tournamentIds = Object.keys(tournaments);
  for (var i = 0; i < tournamentIds.length; i++) {
    const tournamentId = tournamentIds[i];
    const tournament = tournaments[tournamentId];
    const matchIds = getTournamentMatchIds(tournament.data);
    if (matchIds.includes(matchId)) {
      return tournamentId;
    }
  }
};

export const selectTournamentFromMatchId = (state: IStore, matchId: string) => {
  const tournamentId = selectTournamentIdFromMatchId(state, matchId);
  if (!tournamentId) {
    return undefined;
  }

  return selectTournament(state, tournamentId);
};
