import { FlexColumn } from "styles/layout";
import { TeMatchOrId } from "model/TournamentEditor/TeMatch";
import { MatchList } from "components/TournamentEditor/MatchList/MatchList";
import { TeQualifierSegment } from "model/TournamentEditor/Segment/Qualifier/TeQualifierSegment";
import { TeQualifierGroup } from "model/TournamentEditor/Segment/Qualifier/TeQualifierGroup";
import { arrayReplaceAt } from "Utility";

interface Props {
  formatKeys: string[];
  qualifier: TeQualifierSegment;
  update: (segment: TeQualifierSegment) => void;
}
export const Qualifier = ({ formatKeys, qualifier, update }: Props) => {
  const qualGroup = qualifier.groups[0];
  const updateQualifier = (group: TeQualifierGroup, groupIdx: number) => {
    update({
      ...qualifier,
      groups: arrayReplaceAt(qualifier.groups, group, groupIdx),
    });
  };

  return (
    <FlexColumn>
      <MatchList
        formatKeys={formatKeys}
        matches={qualGroup.matches}
        updateMatches={(matches: TeMatchOrId[]) =>
          updateQualifier({ ...qualGroup, matches }, 0)
        }
      />
    </FlexColumn>
  );
};
