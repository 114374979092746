import { IdData } from "model/IdData";

export type Validated<T> = {
  model: T;
  problems: ValidationProblem[];
};

export enum ValidationType {
  Error = "Error",
  Warning = "Warning",
  Message = "Message",
  OK = "OK",
}

export type ValidationProblem = {
  fieldName: string;
  message: string;
  type: ValidationType;
};

export const getPrimaryValidationType = (problems: ValidationProblem[]) => {
  if (problems.some((p) => p.type === ValidationType.Error)) {
    return ValidationType.Error;
  }

  if (problems.some((p) => p.type === ValidationType.Warning)) {
    return ValidationType.Warning;
  }

  if (problems.some((p) => p.type === ValidationType.Message)) {
    return ValidationType.Message;
  }

  return ValidationType.OK;
};

export const sortValidationType = (
  a: ValidationType,
  b: ValidationType,
  ascending: boolean
) => {
  if (a === b) {
    return 0;
  }

  if (b === ValidationType.OK) {
    return -1;
  }

  if (a === ValidationType.OK) {
    return 1;
  }

  if (a === ValidationType.Error) {
    return ascending ? -1 : 1;
  }

  return ascending ? 1 : -1;
};

export function validateModels<T>(
  models: T[],
  validator: (model: T, otherModels: T[]) => ValidationProblem[]
) {
  const validated: Validated<T>[] = [];
  for (var i = 0; i < models.length; i++) {
    const model = models[i];
    const otherModels = models.filter((m) => m !== model);
    validated.push({
      model,
      problems: validator(model, otherModels),
    });
  }

  return validated;
}

export function validateMandatoryField<T>(
  problems: ValidationProblem[],
  fieldName: string,
  value: T | undefined
) {
  if (value === undefined) {
    problems.push({
      fieldName,
      message: "Mandatory Field Undefined",
      type: ValidationType.Error,
    });
  }
}

export function validateUniqueValue<TModel, TValue>(
  problems: ValidationProblem[],
  fieldName: string,
  model: IdData<TModel>,
  otherModels: IdData<TModel>[],
  selector: (model: TModel) => TValue | undefined
) {
  const value = selector(model.data);
  if (value === undefined) {
    return;
  }

  for (var i = 0; i < otherModels.length; i++) {
    const otherModel = otherModels[i];
    const otherValue = selector(otherModel.data);
    if (value === otherValue) {
      problems.push({
        fieldName,
        message: `Value Shared With Id ${otherModel.id}`,
        type: ValidationType.Warning,
      });
    }
  }
}

export function validateUniqueValues<TModel, TValue>(
  problems: ValidationProblem[],
  fieldName: string,
  model: IdData<TModel>,
  otherModels: IdData<TModel>[],
  selector: (model: TModel) => TValue[] | undefined,
  validationType: ValidationType = ValidationType.Warning
) {
  const values = selector(model.data);
  if (values === undefined) {
    return;
  }

  for (var i = 0; i < otherModels.length; i++) {
    const otherModel = otherModels[i];
    const otherValues = selector(otherModel.data);
    if (otherValues === undefined) {
      continue;
    }

    for (var j = 0; j < values.length; j++) {
      const value = values[j];
      if (otherValues.includes(value)) {
        problems.push({
          fieldName,
          message: `Value ${value} Shared With Id ${otherModel.id}`,
          type: validationType,
        });
      }
    }
  }
}
