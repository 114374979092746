import { MatchesViewer } from "components/MatchesViewer/MatchesViewer";
import { Panel } from "components/Panel/Panel";
import { TabBar } from "components/TabBar/TabBar";
import { FsSwiss } from "db/model/tournament/Segment/Swiss/FsSwiss";
import { Filter } from "model/Filter";
import { GrowColumn } from "pages/styles";
import { useState } from "react";

interface Props {
  swiss: FsSwiss;
  orgTime: number | undefined;
}

// todo - lots of dupe code with Season equivalent
export const SwissTabContent = ({ swiss, orgTime }: Props) => {
  const swissOptions =
    swiss.rounds?.map((r) => `${swiss.roundPrefix} ${r.roundNum}`) ?? [];
  const [selectedTab, setSelectedTab] = useState(swissOptions[0]);
  const swissFilter: Filter<string> = {
    options: swissOptions,
    selectedValue: selectedTab,
    setSelectedValue: setSelectedTab,
  };

  const selectedRound = getRoundFromOption(swiss, swissFilter.selectedValue);
  return (
    <GrowColumn>
      <Panel>
        <TabBar filter={swissFilter} isSecondaryTabBar={true} />
        {!!selectedRound && (
          <MatchesViewer
            matchIds={selectedRound.matchIds ?? []}
            orgTime={orgTime}
          />
        )}
      </Panel>
    </GrowColumn>
  );
};

const getRoundFromOption = (swiss: FsSwiss, option: string | undefined) => {
  if (!option) {
    return undefined;
  }

  const roundNumString = option.slice(-1);
  const roundNum = parseInt(roundNumString);
  return swiss.rounds?.find((r) => r.roundNum === roundNum);
};
