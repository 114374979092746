import { FsTournament } from "db/model/tournament/FsTournament";
import { Team } from "model/IdData";
import {
  mapTournamentToTeTournament,
  TeTournament,
} from "model/TournamentEditor/TeTournament";

export type TournamentDraft = {
  draft: TeTournament;
  existingTournamentId: string | undefined;
  lastModified: number | undefined;
  organizationId: string | undefined;
  publishRequest: boolean;
  publishRequestTime: number | undefined;
};

export const mapTournamentToDraft = (
  tournamentId: string,
  tournament: FsTournament,
  teams: Team[]
): TournamentDraft => {
  return {
    draft: mapTournamentToTeTournament(tournamentId, tournament, teams),
    existingTournamentId: tournamentId,
    lastModified: undefined,
    organizationId: undefined,
    publishRequest: false,
    publishRequestTime: undefined,
  };
};
