import { IStore } from "redux/store";
import { selectIsEcmStaff } from "../User";
import { mapIdDataToOptions } from "./core";
import { selectTeam, selectTeamOrgDisplayName } from "../Team";
import { selectMultiple } from "selectors/Core";

export const selectTeamOptions = (
  state: IStore,
  teamIds: string[],
  orgTime: number | undefined
) => {
  return mapIdDataToOptions(
    selectMultiple(state, selectTeam, teamIds),
    (team) => selectTeamOrgDisplayName(state, team.id, orgTime) ?? "",
    selectIsEcmStaff(state)
  );
};
