import styled from "styled-components";
import { FlexColumn, FlexRow } from "styles/layout";
import { Theme } from "theme";

export const FieldContainer = styled(FlexRow)`
  column-gap: 40px;
  padding: 10px 15px;
  align-items: center;
`;

export const NameFields = styled(FlexColumn)`
  align-items: flex-end;
  row-gap: 2px;
`;

export const HeaderContainer = styled(FlexColumn)``;

export const TabsContainer = styled.div`
  border-top: ${Theme.panelContentBorder};
`;
