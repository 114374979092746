import { newTeSetFormatArgsBase, TeSetFormatArgsBase } from "../TeSetFormat";
import { TeForwardCupModeSetFormat } from "./Forward/TeForwardCupModeSetFormat";
import { TeReverseCupModeSetFormat } from "./Reverse/TeReverseCupModeSetFormat";
import {
  mapTeCupModePoints,
  TeCupModePoints,
} from "./TeCupModePointsDistribution";
import { FsCupModeFormatBase } from "db/model/match/Set/CupMode/FsCupModeFormat";
import { FsSetFormatBase } from "db/model/match/Set/FsSetFormat";

export type TeCupModeSetFormat =
  | TeForwardCupModeSetFormat
  | TeReverseCupModeSetFormat;

export type TeCupModeSetFormatArgs = TeSetFormatArgsBase & {
  dnfPoints: number | undefined;
  playTillLeft: number | undefined;
  points: TeCupModePoints;
  roundsPerTrack: number | undefined;
};

export const newTeCupModeSetFormatBase = (): TeCupModeSetFormatArgs => {
  return {
    ...newTeSetFormatArgsBase(),
    dnfPoints: undefined,
    playTillLeft: undefined,
    points: {},
    roundsPerTrack: undefined,
  };
};

export const mapTeCupModeSetFormatBase = (
  base: FsSetFormatBase,
  format: TeCupModeSetFormatArgs
): FsCupModeFormatBase => {
  return {
    ...base,
    playTillLeft: format.playTillLeft,
    points: mapTeCupModePoints(format.points, format.dnfPoints),
    requiredTeamWinners: undefined,
    roundsPerTrack: format.roundsPerTrack,
  };
};
