import { useEffect, useState } from "react";
import { CenterFlexRow } from "styles/layout";
import {
  matchingArrays,
  nonEmptyStrings,
  numberCompare,
  stringCompare,
} from "Utility";
import { DataGridColumn } from "./DataGridColumn";
import { isTextColumn } from "./Columns/Text";
import {
  PaddingTd,
  PaddingTh,
  SortArrowContainer,
  Data,
  DataCell,
  DataGridTd,
  DataGridDataTr,
  DataGridHeaderTr,
  DataGridTable,
  DataGridTh,
  PaginationContainer,
  FilterInputContainer,
} from "./styles";
import { PaginationSwitcher } from "components/Pagination/PaginationSwitcher";
import {
  getPrimaryValidationType,
  sortValidationType,
  ValidationProblem,
} from "model/validators/base";
import { isValidationColumn } from "./Columns/Validation";
import { isActionColumn } from "./Columns/Action";
import { Button } from "styles/elements";
import { isDateTimeColumn } from "./Columns/DateTime";
import { format } from "date-fns-tz";

interface Props<T> {
  data: T[];
  columns: DataGridColumn<T>[];
  keyGetter: (arg: T) => string | undefined;
  onRowClick?: (arg: T) => void;
  initialSortColumnIdx?: number;
  initialSortOrder?: SortOrder;
  thinner?: boolean;
  paginationAmount?: number;
  searchFunction?: (arg: T, searchText: string) => boolean;
  searchPlaceholder?: string;
}

type SortOrder = "ASC" | "DESC" | undefined;

function getSortColumn<T>(
  idx: number | undefined,
  columns: DataGridColumn<T>[]
) {
  if (idx === undefined) {
    return undefined;
  }

  return columns[idx];
}

function getSortedData<T>(
  data: T[],
  sortColumn: DataGridColumn<T> | undefined,
  sortOrder: SortOrder
) {
  return !!sortColumn ? sortData(data, sortColumn, sortOrder ?? "ASC") : data;
}

export function DataGrid<T>({
  data,
  columns,
  keyGetter,
  onRowClick,
  initialSortColumnIdx,
  initialSortOrder,
  thinner,
  paginationAmount,
  searchFunction,
  searchPlaceholder,
}: Props<T>) {
  const [actionExecuting, setActionExecuting] = useState(false);

  // 1. Sort Data
  var unmodifiedDataCopy = data.slice(0);
  var initialSortColumn = getSortColumn(initialSortColumnIdx, columns);
  var initialSortedData = getSortedData(
    unmodifiedDataCopy,
    initialSortColumn,
    initialSortOrder
  );

  // Initialised as undefined because doesn't count initial sort
  const [currentSortColumn, setCurrentSortColumn] = useState<
    DataGridColumn<T> | undefined
  >();
  const [currentSortOrder, setCurrentSortOrder] = useState<SortOrder>();
  const [sortedData, setSortedData] = useState<T[]>(initialSortedData);

  // todo - clean this up, required for track stats
  useEffect(() => {
    unmodifiedDataCopy = data.slice(0);
    initialSortColumn =
      initialSortColumnIdx !== undefined
        ? columns[initialSortColumnIdx]
        : undefined;

    initialSortedData = !!initialSortColumn
      ? sortData(
          unmodifiedDataCopy,
          initialSortColumn,
          initialSortOrder ?? "ASC"
        )
      : unmodifiedDataCopy;

    const resetColumnSort =
      !currentSortColumn || !columns.includes(currentSortColumn);
    const sortColumn = !resetColumnSort
      ? currentSortColumn
      : initialSortColumnIdx !== undefined
      ? columns[initialSortColumnIdx]
      : undefined;
    const sortOrder = !resetColumnSort ? currentSortOrder : initialSortOrder;
    setSortedData(
      !!sortColumn
        ? sortData(unmodifiedDataCopy, sortColumn, sortOrder ?? "ASC")
        : unmodifiedDataCopy
    );
  }, [data]);

  // If Columns or InitialSortColumnIdx change check if we need to resort the data
  useEffect(() => {
    initialSortColumn = getSortColumn(initialSortColumnIdx, columns);
    initialSortedData = sortData(
      unmodifiedDataCopy,
      initialSortColumn,
      initialSortOrder ?? "ASC"
    );

    // If the sorted data have changed, update state
    if (!matchingArrays(initialSortedData, sortedData)) {
      setSortedData(initialSortedData);
      setCurrentSortColumn(undefined);
      setCurrentSortOrder(undefined);
    }
  }, [initialSortColumnIdx, columns]);

  // 2. Filter Data
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState<T[]>(
    filterData(initialSortedData, searchFunction, searchText)
  );

  // If SearchText or SortedData state are updated, refilter the data
  useEffect(() => {
    const newFilteredData = filterData(sortedData, searchFunction, searchText);

    // If the filtered data have changed, update state
    if (!matchingArrays(newFilteredData, filteredData)) {
      setFilteredData(newFilteredData);
    }
  }, [searchText, sortedData]);

  // 3. Paginate Data
  const [selectedPage, setSelectedPage] = useState(1);
  var maxPage = !!paginationAmount
    ? Math.ceil(filteredData.length / paginationAmount)
    : undefined;

  useEffect(() => {
    if (!!maxPage && selectedPage > maxPage) {
      setSelectedPage(maxPage);
    }
  }, [maxPage]);

  const [paginatedData, setPaginatedData] = useState<T[]>(
    paginateData(filteredData, paginationAmount, selectedPage)
  );

  // Scroll to top of page on pagination switch
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedPage]);

  // If SelectedPage or FilteredData state are updated,
  useEffect(() => {
    const newPaginatedData = paginateData(
      filteredData,
      paginationAmount,
      selectedPage
    );
    // If the paginated data has changed, update state
    if (!matchingArrays(newPaginatedData, paginatedData)) {
      setPaginatedData(newPaginatedData);
    }
  }, [selectedPage, filteredData]);

  const onRowClickInternal = (e: any, arg: T) => {
    if (e.target.id !== "link_button") {
      if (!!onRowClick) {
        onRowClick(arg);
      }
    }
  };

  return (
    <>
      {!!searchFunction && (
        <FilterInputContainer>
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value ?? "")}
            placeholder={searchPlaceholder}
            spellCheck={false}
          />
        </FilterInputContainer>
      )}
      <DataGridTable>
        <thead>
          <DataGridHeaderTr thinner={thinner === true}>
            <PaddingTh />
            {columns.map((col, i) => (
              <DataGridTh
                key={`${i} ${col.title}`}
                highlight={col.highlight}
                highlightSort={col === currentSortColumn}
                title={col.title}
                clickable={col.sortable}
                onClick={
                  !!col.sortable
                    ? () =>
                        setSortedData(
                          sortByColumn(
                            unmodifiedDataCopy,
                            initialSortedData,
                            col,
                            col === currentSortColumn,
                            setCurrentSortColumn,
                            currentSortOrder,
                            setCurrentSortOrder
                          )
                        )
                    : undefined
                }
              >
                <CenterFlexRow>
                  <SortArrowContainer />
                  {col.header}
                  <SortArrowContainer
                    style={!col.header ? { marginLeft: "auto" } : {}}
                  >
                    {currentSortColumn === col
                      ? getSortArrow(currentSortOrder)
                      : ""}
                  </SortArrowContainer>
                </CenterFlexRow>
              </DataGridTh>
            ))}
            <PaddingTh />
          </DataGridHeaderTr>
        </thead>
        <tbody>
          {paginatedData.map((stat) => (
            <DataGridDataTr
              key={keyGetter(stat)}
              thinner={thinner === true}
              clickable={!!onRowClick}
              onClick={
                !!onRowClick ? (e) => onRowClickInternal(e, stat) : undefined
              }
            >
              <PaddingTd />
              {columns.map((col, i) =>
                mapDataToColumn(
                  stat,
                  col,
                  i,
                  actionExecuting,
                  setActionExecuting
                )
              )}
              <PaddingTd />
            </DataGridDataTr>
          ))}
        </tbody>
      </DataGridTable>
      {!!maxPage && maxPage > 1 && (
        <PaginationContainer>
          <PaginationSwitcher
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            maxPage={maxPage}
          />
        </PaginationContainer>
      )}
    </>
  );
}

function mapDataToColumn<T>(
  data: T,
  column: DataGridColumn<T>,
  columnIdx: number,
  actionExecuting: boolean,
  setActionExecuting: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (isActionColumn(column)) {
    const actions = column.actionsGetter(data);
    return (
      <DataGridTd key={`${columnIdx} action`} highlight={column.highlight}>
        {actions.map((action, actionIdx) => {
          const actionText = action.textGetter(data);
          return (
            <Button
              disabled={actionExecuting}
              key={`${actionIdx} ${actionText}`}
              onClick={async () => {
                setActionExecuting(true);
                await action.onAction(data);
                setActionExecuting(false);
              }}
            >
              {actionText}
            </Button>
          );
        })}
      </DataGridTd>
    );
  }
  if (isDateTimeColumn(column)) {
    const timeMs = column.timeMsGetter(data);
    const dateTimeString = !!timeMs
      ? format(timeMs, column.dateTimeFormat)
      : "-";
    return (
      <DataCell
        key={`${columnIdx} dateTime ${timeMs ?? "undefined"}`}
        highlight={column.highlight}
      >
        <Data>{dateTimeString}</Data>
      </DataCell>
    );
  }
  if (isTextColumn(column)) {
    const text = column.textGetter(data);
    return (
      <DataCell key={`${columnIdx} text ${text}`} highlight={column.highlight}>
        <Data>{text}</Data>
      </DataCell>
    );
  }

  if (isValidationColumn(column)) {
    const problems = column.validationGetter(data);
    const title = problems
      .map((p) => `${p.fieldName}: ${p.message}`)
      .join(", ");
    return (
      <DataCell highlight={column.highlight}>
        <Data title={title}>{getPrimaryValidationType(problems)}</Data>
      </DataCell>
    );
  }
}

const getSortArrow = (sortOrder: SortOrder) => {
  if (sortOrder === "ASC") {
    return "▲";
  }

  if (sortOrder === "DESC") {
    return "▼";
  }
};

function sortData<T>(
  data: T[],
  column: DataGridColumn<T> | undefined,
  order: SortOrder
) {
  if (order === undefined || column === undefined) {
    return data;
  }

  const sortAscending = order === "ASC";

  if (isDateTimeColumn(column)) {
    sortByNumberGetter(data, column.timeMsGetter, sortAscending);
  }

  if (isTextColumn(column)) {
    sortByTextGetter(data, column.textGetter, sortAscending, column.numberSort);
  }

  if (isValidationColumn(column)) {
    sortByValidationGetter(data, column.validationGetter, sortAscending);
  }

  return data;
}

function sortByColumn<T>(
  data: T[],
  initialSortedData: T[],
  column: DataGridColumn<T>,
  isCurrentSortColumn: boolean,
  setCurrentSortColumn: React.Dispatch<
    React.SetStateAction<DataGridColumn<T> | undefined>
  >,
  currentSortOrder: SortOrder,
  setCurrentSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
) {
  var newSortOrder: SortOrder;
  if (!isCurrentSortColumn || currentSortOrder === undefined) {
    newSortOrder = "ASC";
  } else if (currentSortOrder === "ASC") {
    newSortOrder = "DESC";
  }

  const sortedData = !!newSortOrder
    ? sortData(data, column, newSortOrder)
    : initialSortedData;

  const newSortColumn = !!newSortOrder ? column : undefined;
  setCurrentSortOrder(newSortOrder);
  setCurrentSortColumn(newSortColumn);
  return sortedData;
}

function sortByNumberGetter<T>(
  data: T[],
  getter: (arg: T) => number | undefined,
  ascending: boolean
) {
  return data.sort((a, b) => numberCompare(getter(a), getter(b), ascending));
}

function sortByTextGetter<T>(
  data: T[],
  getter: (arg: T) => string | undefined,
  ascending: boolean,
  numberSort: boolean
) {
  return data.sort((a, b) =>
    stringCompare(getter(a), getter(b), ascending, numberSort)
  );
}

function sortByValidationGetter<T>(
  data: T[],
  getter: (arg: T) => ValidationProblem[],
  ascending: boolean
) {
  return data.sort((a, b) => {
    const primaryA = getPrimaryValidationType(getter(a));
    const primaryB = getPrimaryValidationType(getter(b));
    return sortValidationType(primaryA, primaryB, ascending);
  });
}

function filterData<T>(
  sortedData: T[],
  searchFunction: ((arg: T, searchText: string) => boolean) | undefined,
  searchText: string
) {
  if (!searchFunction || !searchText) {
    return sortedData;
  }

  var filteredData = [...sortedData];
  const splitSearchText = nonEmptyStrings(searchText.split(","));
  filteredData = filteredData.filter((data) =>
    splitSearchText.some((text) => searchFunction(data, text.trim()))
  );

  return filteredData;
}

function paginateData<T>(
  filteredData: T[],
  amountPerPage: number | undefined,
  selectedPage: number
) {
  if (!amountPerPage) {
    return filteredData;
  }

  const firstIdx = (selectedPage - 1) * amountPerPage;
  const lastIdx = firstIdx + amountPerPage;
  return filteredData.slice(firstIdx, lastIdx);
}
