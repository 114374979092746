import { TeMatch, newTeMatch } from "model/TournamentEditor/TeMatch";
import { Match } from "components/TournamentEditor/Match";
import { TeSingleSegment } from "model/TournamentEditor/Segment/Single/TeSingleSegment";
import { arrayReplaceAt } from "Utility";
import { TeSingleGroup } from "model/TournamentEditor/Segment/Single/TeSingleGroup";

interface Props {
  formatKeys: string[];
  single: TeSingleSegment;
  update: (segment: TeSingleSegment) => void;
}
export const Single = ({ formatKeys, single, update }: Props) => {
  const group = single.groups[0];
  const updateGroup = (group: TeSingleGroup, groupIdx: number) => {
    update({
      ...single,
      groups: arrayReplaceAt(single.groups, group, groupIdx),
    });
  };

  return (
    <Match
      formatKeys={formatKeys}
      match={group.match}
      updateMatch={(match: TeMatch) => updateGroup({ ...group, match }, 0)}
      removeExistingMatch={() =>
        updateGroup({ ...group, match: newTeMatch() }, 0)
      }
    />
  );
};
