import { arrayReplaceAt } from "Utility";
import {
  TeRound,
  newTeRound,
} from "model/TournamentEditor/Segment/Rounds/TeRound";
import { TeMatchOrId } from "model/TournamentEditor/TeMatch";
import { FlexColumn } from "styles/layout";
import { MatchList } from "../MatchList/MatchList";
import { useState } from "react";
import { TabBarOption, TabBarV2 } from "components/TabBarV2/TabBarV2";
import { Button } from "styles/elements";
import { TeRoundsGroup } from "model/TournamentEditor/Segment/Rounds/TeRoundsGroup";
import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import { StyledInput } from "components/Fields/styles";

interface Props {
  formatKeys: string[];
  group: TeRoundsGroup;
  update: (group: TeRoundsGroup) => void;
}

export const RoundTabs = ({ formatKeys, group, update }: Props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabOptions: TabBarOption[] = group.rounds.map((r, rIdx) => ({
    displayText: `${group.roundsPrefix} ${r.roundNum}`,
    item: rIdx,
  }));

  const onAddRoundClick = () => {
    const nextIdx = group.rounds.length;
    group.rounds = [...group.rounds, newTeRound(nextIdx + 1)];
    update(group);
    setSelectedTab(nextIdx);
  };

  tabOptions.push({
    displayText: `Add ${group.roundsPrefix}`,
    onClick: onAddRoundClick,
  });

  const onRemoveRoundClick = (round: TeRound) => {
    const roundIdx = group.rounds.indexOf(round) ?? 0;
    group.rounds = group.rounds
      .filter((r) => r !== round)
      .map((r, rIdx) => ({
        ...r,
        roundNum: rIdx + 1,
      }));

    update(group);
    setSelectedTab(roundIdx > 0 ? roundIdx - 1 : 0);
  };

  const updateRoundMatches = (round: TeRound, matches: TeMatchOrId[]) => {
    const newRound = {
      ...round,
      matches,
    };
    const idx = group.rounds.indexOf(round);
    group.rounds = arrayReplaceAt(group.rounds, newRound, idx);
    update(group);
  };

  const selectedRound =
    selectedTab !== undefined ? group.rounds.at(selectedTab) : undefined;

  return (
    <FlexColumn>
      <LabelledField label="Prefix: ">
        <StyledInput
          style={{ width: "120px" }}
          type="text"
          value={group.roundsPrefix}
          onChange={(e) => update({ ...group, roundsPrefix: e.target.value })}
        />
      </LabelledField>
      <br />
      <TabBarV2
        options={tabOptions}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isSecondaryTabBar={true}
      />
      {!!selectedRound && (
        <FlexColumn key={selectedRound.roundNum}>
          <Button
            style={{ marginLeft: "auto" }}
            onClick={() => onRemoveRoundClick(selectedRound)}
          >
            Remove Round
          </Button>
          <MatchList
            formatKeys={formatKeys}
            matches={selectedRound.matches}
            updateMatches={(matches: TeMatchOrId[]) =>
              updateRoundMatches(selectedRound, matches)
            }
          />
        </FlexColumn>
      )}
    </FlexColumn>
  );
};
