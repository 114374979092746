import { FsQualifier } from "db/model/tournament/Segment/Qualifier/FsQualifier";
import { IdData } from "model/IdData";
import {
  TeMatchOrId,
  TeMatch,
  getMatchIdsFromTeMatches,
} from "model/TournamentEditor/TeMatch";
import {
  mapTeSegmentGroupBaseToFsData,
  newTeSegmentGroupBase,
  TeSegmentGroupBase,
} from "../TeSegmentGroup";

export type TeQualifierGroup = TeSegmentGroupBase & {
  matches: TeMatchOrId[];
};

export const newTeQualifierGroup = (): TeQualifierGroup => {
  return {
    ...newTeSegmentGroupBase(),
    matches: [],
  };
};

export const mapTeQualifierGroupsToFsData = (
  teGroups: TeQualifierGroup[],
  teMatchesWithIds: IdData<TeMatch>[]
): FsQualifier[] => {
  return teGroups.map((g) => mapTeQualifierGroupToFsData(g, teMatchesWithIds));
};

const mapTeQualifierGroupToFsData = (
  teGroup: TeQualifierGroup,
  teMatchesWithIds: IdData<TeMatch>[]
): FsQualifier => {
  return {
    ...mapTeSegmentGroupBaseToFsData(teGroup),
    matchIds: getMatchIdsFromTeMatches(teGroup.matches, teMatchesWithIds),
  };
};

export const mapFsQualifierToTeQualifierGroup = (
  fsQualifier: FsQualifier
): TeQualifierGroup => {
  return {
    groupId: fsQualifier.groupId,
    name: fsQualifier.name,
    matches: fsQualifier.matchIds ?? [],
  };
};
