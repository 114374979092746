import { FsForwardCupModeLongFormat } from "db/model/match/Set/CupModeLong/Forward/FsForwardCupModeLongFormat";
import { newFsSetFormatBase } from "../../FsSetFormat";

export const newFsForwardCupModeLongFormat = (): FsForwardCupModeLongFormat => {
  return {
    ...newFsSetFormatBase(),
    finalist: true,
    playCondition: "BestOf",
    points: undefined,
    pointsLimit: undefined,
    requiredTeamWinners: undefined,
  };
};
