import { MatchViewerTeamsTable } from "./styles";
import { MatchesViewerListTeam } from "./MatchesViewerListTeam";
import { FsMatch } from "db/model/match/FsMatch";
import { getNumTeams, getTeams } from "fsModel/Match/Match";
import { getMatchTeamDisplayScores } from "fsModel/Match/MatchScore";

interface Props {
  match: FsMatch;
  orgTime: number | undefined;
}

export const MatchesViewerTeamList = ({ match, orgTime }: Props) => {
  if (getNumTeams(match) > 10) {
    return <span>Lots of teams!</span>;
  }

  const scores = getMatchTeamDisplayScores(match);
  return (
    <MatchViewerTeamsTable>
      {getTeams(match).map((team, i) => {
        const teamId = typeof team !== "string" ? team.teamId : undefined;
        return (
          <MatchesViewerListTeam
            key={typeof team === "string" ? `${team}${i}` : team.teamId}
            team={team}
            teamScoreString={!!teamId ? scores[teamId] : undefined}
            orgTime={orgTime}
          />
        );
      })}
    </MatchViewerTeamsTable>
  );
};
