export const driverCollectionName = "driver";
export const leaderboardCollectionName = "leaderboard";
export const matchCollectionName = "newMatch";
export const orgCollectionName = "org";
export const pickemCollectionName = "pickem";
export const pickemDetailsCollectionName = "pickemDetails";
export const teamCollectionName = "newTeam";
export const tourCollectionName = "tour";
export const tournamentCollectionName = "newTournament";
export const tournamentAdminCollectionName = "tournamentAdmin";
export const tournamentDraftCollectionName = "tournamentDraft";
export const tournamentOrganizationCollectionName = "tournamentOrganization";
export const tourPointsCollectionName = "tourPoints";
export const trackCollectionName = "track";
export const userCollectionName = "user";
export const usernameCollectionName = "username";
