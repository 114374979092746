import { mapTeWorldTourSetFormat } from "./SetFormat/WorldTour/TeWorldTourSetFormat";
import {
  TeSetFormatArgs,
  mapTeSetFormatArgsBase,
} from "./SetFormat/TeSetFormat";
import { checkTypeExhausted } from "Utility";
import { mapTeForwardCupModeSetFormat } from "./SetFormat/CupMode/Forward/TeForwardCupModeSetFormat";
import { mapTeReverseCupModeSetFormat } from "./SetFormat/CupMode/Reverse/TeReverseCupModeSetFormat";
import { mapTeTimeAttackMatchToFsData } from "./SetFormat/TimeAttack/TeTimeAttackSetFormat";
import { mapTeRoundsSetFormat } from "./SetFormat/Rounds/TeRoundsSetFormat";
import { mapTeMatchmakingSetFormat } from "./SetFormat/Matchmaking/TeMatchmakingSetFormat";
import { FsMatch } from "db/model/match/FsMatch";
import { FsMatchSet, FsMatchSetBase } from "db/model/match/Set/FsMatchSet";
import { mapTeForwardCupModeLongSetFormat } from "./SetFormat/CupModeLong/Forward/TeForwardCupModeLongSetFormat";
import { mapTeKnockoutSetFormat } from "./SetFormat/Knockout/TeKnockoutSetFormat";

export type TeMatchFormat = {
  readonly key: string;
  numTeams: number | undefined;
  numWinners: number | undefined;
  setFormat: TeSetFormatArgs | undefined;
};

export const newTeMatchFormat = (key: string): TeMatchFormat => {
  return {
    key,
    numTeams: undefined,
    numWinners: undefined,
    setFormat: undefined,
  };
};

export const mapTeMatchFormat = (format: TeMatchFormat) => {
  const fsMatch: FsMatch = {
    isDelayed: undefined,
    format: {
      setAdvantageTeamIds: undefined,
      tiebreakerSet: undefined,
      type: "Single",
      amount: undefined,
    },
    numWinners: format.numWinners,
    overrideComplete: undefined,
    scoreOverrides: undefined,
    sets: [],
    startTimeMs: undefined,
    teams: [],
    dqForceLast: undefined,
    ffForceLast: undefined,
    ignore: undefined,
  };

  if (!!format.numTeams) {
    for (let i = 0; i < format.numTeams; i++) {
      fsMatch.teams?.push("TBD");
    }
  }

  if (!!format.setFormat) {
    fsMatch.sets?.push(mapMatchSet(format.setFormat));
  }

  return fsMatch;
};

const matchSetBase: FsMatchSetBase = {
  isTiebreaker: undefined,
  overrideComplete: undefined,
  picks: undefined,
  points: undefined,
  scoreOverrides: undefined,
};

const mapMatchSet = (formatArgs: TeSetFormatArgs): FsMatchSet => {
  const formatBase = mapTeSetFormatArgsBase(formatArgs);
  switch (formatArgs.type) {
    case "CupMode":
      return {
        ...matchSetBase,
        format: mapTeForwardCupModeSetFormat(formatBase, formatArgs),
        tracks: undefined,
        type: "ForwardCupMode",
      };
    case "CupModeLong":
      return {
        ...matchSetBase,
        format: mapTeForwardCupModeLongSetFormat(formatBase, formatArgs),
        tracks: undefined,
        type: "ForwardCupModeLong",
      };
    case "Knockout":
      return {
        ...matchSetBase,
        format: mapTeKnockoutSetFormat(formatBase, formatArgs),
        tracks: undefined,
        type: "Knockout",
      };
    case "Matchmaking":
      return {
        ...matchSetBase,
        format: mapTeMatchmakingSetFormat(formatBase, formatArgs),
        tracks: undefined,
        type: "Matchmaking",
      };
    case "ReverseCupMode":
      return {
        ...matchSetBase,
        format: mapTeReverseCupModeSetFormat(formatBase, formatArgs),
        tracks: undefined,
        type: "ReverseCupMode",
      };
    case "Rounds":
      return {
        ...matchSetBase,
        format: mapTeRoundsSetFormat(formatBase, formatArgs),
        tracks: undefined,
        type: "Rounds",
      };
    case "TimeAttack":
      return {
        ...matchSetBase,
        format: mapTeTimeAttackMatchToFsData(formatBase, formatArgs),
        tracks: undefined,
        type: "TimeAttack",
      };
    case "WorldTour":
      return {
        ...matchSetBase,
        format: mapTeWorldTourSetFormat(formatBase, formatArgs),
        tracks: undefined,
        type: "WorldTour",
      };
    default:
      return checkTypeExhausted(formatArgs);
  }
};
