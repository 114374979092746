import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsMatchTrack } from "db/model/match/Track/FsMatchTrack";
import { Scores } from "fsModel/Match/Scores";
import { getWorldTourRoundResults } from "./Round";
import { nonUndefined } from "Utility";
import { FsWorldTourScoring } from "db/model/match/Set/WorldTour/FsWorldTourScoring";
import { calculateFinalPositions } from "fsModel/Match/Positions";

export const getWorldTourTrackScores = (
  track: FsMatchTrack,
  teams: FsMatchTeam[],
  scoring: FsWorldTourScoring
): Scores => {
  const teamIds = nonUndefined(teams.flatMap((t) => t.teamId));
  const results = (track.rounds ?? []).flatMap((round) =>
    getWorldTourRoundResults(scoring, round.times ?? [], teams)
  );

  const scores: Scores = {};
  for (var i = 0; i < teamIds.length; i++) {
    const teamId = teamIds[i];
    const scoreOverride = !!track.scoreOverrides
      ? track.scoreOverrides[teamId]
      : undefined;
    if (scoreOverride !== undefined) {
      scores[teamId] = scoreOverride;
    } else {
      const teamResults = results.filter((r) => r.teamId === teamId);
      scores[teamId] = teamResults.reduce((a, b) => a + b.points, 0);
    }
  }

  return scores;
};

export const isWorldTourTrackComplete = (
  track: FsMatchTrack,
  scores: Scores,
  winReq: number
) => {
  if (track.overrideComplete) {
    return true;
  }

  const scoreValues = Object.values(scores);
  const bestScore = Math.max(...scoreValues, 0);
  if (bestScore < winReq) {
    return false;
  }

  return scoreValues.filter((s) => s === bestScore).length === 1;
};

export const getWorldTourTrackFinalPositions = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
