import { PageContent, ColumnContainer, GrowColumn } from "pages/styles";
import { useEffect, useState } from "react";
import {
  deepCopyObject,
  nonUndefined,
  onlyUnique,
  removeNullUndefinedNaNFromObjectRecursive,
} from "Utility";
import { MetadataPanel } from "./MetadataPanel";
import { TeamsPanel } from "./Teams/TeamsPanel";
import { useSelector } from "react-redux";
import { IStore } from "redux/store";
import { selectOrgTimeFromMatchId } from "selectors/OrgTime";
import { DriverKeys } from "./Teams/DriverKeyEditor";
import { SetsPanel } from "./Sets/SetsPanel";
import { Panel } from "components/Panel/Panel";
import { CenterFlexColumn, MaxWidth } from "styles/layout";
import { AsyncButton } from "components/Core/AsyncButton/AsyncButton";
import { Button } from "styles/elements";
import { Modal } from "components/Modal/Modal";
import { MatchApiKey } from "components/MatchApiKey/MatchApiKey";
import { FsMatch } from "db/model/match/FsMatch";
import { writeDataToDbAsync } from "db/info/core";
import { matchCollectionName } from "db/info/collectionNames";
import { getTeams } from "fsModel/Match/Match";
import { updateMatchFirebase } from "firebase-config";

interface Props {
  matchId: string;
  match: FsMatch;
}

export const MatchEditor = ({ matchId, match }: Props) => {
  const [matchCopy, setMatchCopy] = useState<FsMatch>(deepCopyObject(match));
  // useEffect(() => {
  //   if (
  //     match.type === "Set" ||
  //     match.type === "TimeAttack" ||
  //     match.type === "MultiMm"
  //   ) {
  //     return;
  //   }

  //   const latestTrack = match.tracks?.at(-1);
  //   if (!latestTrack) {
  //     return;
  //   }

  //   const latestCopyTrack = matchCopy.tracks?.at(-1);
  //   if (!latestCopyTrack || latestCopyTrack.trackId !== latestTrack.trackId) {
  //     const newTrack = deepCopyObject(latestTrack);
  //     const newCopy = {
  //       ...matchCopy,
  //       tracks: [
  //         ...(matchCopy.tracks ?? []),
  //         { ...newTrack, rounds: newTrack.rounds?.slice(0, 1) ?? [] },
  //       ],
  //     };
  //     setMatchCopy(newCopy);
  //   } else {
  //     const latestTrackRound = latestTrack.rounds?.at(-1);
  //     if (!latestTrackRound) {
  //       return;
  //     }

  //     const latestCopyRound = latestCopyTrack.rounds?.at(-1);
  //     var roundMatches = true;
  //     if (!latestCopyRound) {
  //       roundMatches = false;
  //     } else {
  //       latestCopyRound.times?.forEach((time) => {
  //         if (
  //           time.finishTimeMs !==
  //           latestTrackRound?.times?.find((t) => t.position === time.position)
  //             ?.finishTimeMs
  //         ) {
  //           roundMatches = false;
  //         }
  //       });
  //     }

  //     if (!roundMatches) {
  //       latestCopyTrack.rounds = [
  //         ...(latestCopyTrack.rounds ?? []),
  //         latestTrackRound,
  //       ];

  //       setMatchCopy(deepCopyObject(matchCopy));
  //     }
  //   }
  // }, [match]);

  const [driverKeys, setDriverKeys] = useState<DriverKeys>({});

  // Keep Driver Keys up to date with the driverIds associated with match
  // todo - does this logic belong here?
  useEffect(() => {
    const allDriverIds = nonUndefined(
      getTeams(matchCopy).flatMap((t) => t.driverIds)
    ).filter(onlyUnique);
    const existingDriverKeyIds = Object.keys(driverKeys);

    const addedDriverIds = allDriverIds.filter(
      (id) => !existingDriverKeyIds.includes(id)
    );
    const removedDriverIds = existingDriverKeyIds.filter(
      (id) => !allDriverIds.includes(id)
    );

    if (!addedDriverIds.length && !removedDriverIds.length) {
      return;
    }

    const newDriverKeys: DriverKeys = {};
    existingDriverKeyIds.forEach((id) => {
      if (!removedDriverIds.includes(id)) {
        newDriverKeys[id] = driverKeys[id];
      }
    });

    addedDriverIds.forEach((id) => {
      newDriverKeys[id] = undefined;
    });

    setDriverKeys(newDriverKeys);
  }, [matchCopy]);

  const orgTime = useSelector((state: IStore) =>
    selectOrgTimeFromMatchId(state, matchId)
  );

  const [resultText, setResultText] = useState("");
  const [apiKeyModalVisible, setApiKeyModalVisible] = useState(false);
  const updateMatchAsync = async () => {
    setResultText("");
    const result = await updateMatchInDbAsync(matchId, matchCopy);
    setResultText(result);
  };

  return (
    <PageContent>
      <MaxWidth>
        <ColumnContainer>
          <GrowColumn>
            <Button
              style={{ marginLeft: "auto" }}
              onClick={() => setApiKeyModalVisible(true)}
            >
              Match API Key
            </Button>
            <Modal
              visible={apiKeyModalVisible}
              onCloseClick={() => setApiKeyModalVisible(false)}
            >
              <div style={{ padding: "10px 20px" }}>
                <MatchApiKey matchId={matchId} />
              </div>
            </Modal>
            <MetadataPanel match={matchCopy} update={setMatchCopy} />
            <TeamsPanel
              matchId={matchId}
              match={matchCopy}
              update={setMatchCopy}
              driverKeys={driverKeys}
              updateDriverKeys={setDriverKeys}
              orgTime={orgTime}
            />
            <SetsPanel
              matchId={matchId}
              match={matchCopy}
              update={setMatchCopy}
              driverKeys={driverKeys}
              orgTime={orgTime}
            />
            <Panel padding="padded">
              <CenterFlexColumn>
                <AsyncButton onClickAsync={updateMatchAsync}>
                  Update Match
                </AsyncButton>
                <span>{resultText}</span>
              </CenterFlexColumn>
            </Panel>
          </GrowColumn>
        </ColumnContainer>
      </MaxWidth>
    </PageContent>
  );
};

// todo - move this to some db file
const updateMatchInDbAsync = async (matchId: string, matchData: FsMatch) => {
  try {
    const result: any = await updateMatchFirebase({
      matchId,
      matchData: removeNullUndefinedNaNFromObjectRecursive(matchData),
    });

    return result.data.message;
  } catch (error: any) {
    return error.message;
  }
};
