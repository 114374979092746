import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Theme } from "theme";

export const FooterContainer = styled.div`
  background-color: ${Theme.footerBackground};
  min-height: 50px;
  clear: both;
`;

export const FooterContentContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  justify-content: center;
  font-size: 12pt;
  a {
    font-size: 12pt;
  }

  @media (max-width: 800px) {
    column-gap: 16px;
    font-size: 11pt;

    a {
      font-size: 10pt;
    }
  }

  @media (max-width: 600px) {
    column-gap: 6px;
    font-size: 10pt;

    a {
      font-size: 10pt;
    }
  }
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Theme.footerForeground};
  row-gap: 12px;
`;

export const NavItem = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
  font-size: 12pt;
  color: ${Theme.footerForeground};

  &:hover {
    color: ${Theme.footerHoverForeground};
    text-decoration: underline;
  }

  @media (max-width: 600px) {
    font-size: 10pt;
  }
`;

export const FooterLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-size: 12pt;
  color: ${Theme.footerForeground};

  &:hover {
    color: ${Theme.footerHoverForeground};
    text-decoration: underline;
  }

  @media (max-width: 600px) {
    font-size: 10pt;
  }
`;

export const FooterLogoContainer = styled.div`
  /* display: flex; */
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 180px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 800px) {
    width: 150px;
    margin-top: 10px;
  }

  @media (max-width: 700px) {
    width: 100px;
    margin-top: 20px;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
`;
