import { LoginOptions } from "components/Login/LoginOptions";
import { Panel } from "components/Panel/Panel";
import { PageContent } from "pages/styles";
import { CenterFlexDiv } from "styles/layout";

export const LoginPage = () => {
  return (
    <CenterFlexDiv>
      <PageContent>
        <Panel padding="square">
          <LoginOptions />
        </Panel>
      </PageContent>
    </CenterFlexDiv>
  );
};
