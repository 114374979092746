import { FsForwardCupModeFormat } from "db/model/match/Set/CupMode/Forward/FsForwardCupModeFormat";
import { newFsCupModeFormat } from "../FsCupModeFormat";

export const newFsForwardCupModeFormat = (): FsForwardCupModeFormat => {
  return {
    ...newFsCupModeFormat(),
    finalist: true,
    pointsLimit: undefined,
  };
};
