import styled from "styled-components";
import { FlexRow } from "styles/layout";
import { Theme } from "theme";

export const Header = styled(FlexRow)`
  margin-right: auto;
  padding: 5px 10px 5px 5px;
  &:hover {
    cursor: pointer;
    color: ${Theme.buttonHoverForeground};
  }
`;
