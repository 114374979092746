import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsCupModePoints } from "db/model/match/Set/CupMode/FsCupModePoints";
import { Scores } from "fsModel/Match/Scores";
import { getPointsDistribution } from "../Points";
import { getPoints } from "../../PointsDistribution";
import { FsRoundTime } from "db/model/match/Track/FsRoundTime";

export const applyForwardCupModeRoundScore = (
  times: FsRoundTime[],
  teams: FsMatchTeam[],
  runningScores: Scores,
  points: FsCupModePoints,
  pointsLimit: number,
  driversPerTeam: number,
  completedDriverIds: string[],
  completedTeamIds: string[]
) => {
  const numDriversAtStart = teams.length * driversPerTeam;
  const numDriversLeft = numDriversAtStart - completedDriverIds.length;
  const numTeamsLeft = teams.length - completedTeamIds.length;
  const pointsDist = getPointsDistribution(points, numDriversLeft);
  if (numDriversLeft <= 1 || !pointsDist) {
    return;
  }

  for (let i = 0; i < teams.length; i++) {
    const { teamId, driverIds: teamDriverIds } = teams[i];
    if (!teamId || !teamDriverIds) {
      continue;
    }

    const teamTimes = times.filter(
      (t) => !!t.driverId && teamDriverIds.includes(t.driverId)
    );
    if (teamTimes.length === 0) {
      continue;
    }

    const teamPointsAtStart = runningScores[teamId] ?? 0;
    const teamPointsDelta = teamTimes.reduce(
      (a, b) => a + getPoints(pointsDist, b.position, b.finishTimeMs),
      0
    );

    if (teamPointsAtStart >= pointsLimit) {
      const teamFirstPlace = teamTimes.find((t) => t.position === 1);
      if (!!teamFirstPlace && !!teamFirstPlace.driverId) {
        completedDriverIds.push(teamFirstPlace.driverId);
      }
    }

    var teamPointsAtEnd: number;
    const teamCompletedDriverIds = completedDriverIds.filter((id) =>
      teamDriverIds.includes(id)
    );
    if (teamCompletedDriverIds.length >= driversPerTeam) {
      completedTeamIds.push(teamId);
      teamPointsAtEnd = pointsLimit + numTeamsLeft;
    } else {
      teamPointsAtEnd = Math.min(
        teamPointsAtStart + teamPointsDelta,
        pointsLimit
      );
    }

    runningScores[teamId] = teamPointsAtEnd;
  }
};
