import { Flag } from "model/Flag";
import { toggleTrueOrUndefined } from "Utility";
import { FieldArgs } from "./Core";

export type CheckboxFieldArgs = FieldArgs & {
  falseAsUndefined?: Flag;
};

interface Props {
  value: boolean | undefined;
  update: (newVal: boolean | undefined) => void;
  args?: CheckboxFieldArgs;
}

export const CheckboxField = ({ value, update, args }: Props) => {
  return (
    <input
      type="checkbox"
      disabled={args?.disabled}
      checked={value ?? false}
      onChange={() => {
        const newValue = args?.falseAsUndefined
          ? toggleTrueOrUndefined(value)
          : !(value ?? false);
        update(newValue);
      }}
    />
  );
};
