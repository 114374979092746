import { checkTypeExhausted, deepCopyObject } from "Utility";
import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import { NumberField } from "components/Fields/Number";
import { TypedSelectField } from "components/Fields/Select";
import { TextField } from "components/Fields/Text";
import {
  FieldColumn,
  FieldRow,
  FieldsContainer,
} from "components/Fields/styles";
import { isPlayCondition, playConditions } from "fsModel/Match/PlayCondition";
import { TeForwardCupModeSetFormat } from "model/TournamentEditor/SetFormat/CupMode/Forward/TeForwardCupModeSetFormat";
import { TeReverseCupModeSetFormat } from "model/TournamentEditor/SetFormat/CupMode/Reverse/TeReverseCupModeSetFormat";
import { TeCupModeSetFormat } from "model/TournamentEditor/SetFormat/CupMode/TeCupModeMatchFormat";
import { getAdjustedTeCupModePoints } from "model/TournamentEditor/SetFormat/CupMode/TeCupModePointsDistribution";
import { TeForwardCupModeLongSetFormat } from "model/TournamentEditor/SetFormat/CupModeLong/Forward/TeForwardCupModeLongSetFormat";
import { TeMatchmakingSetFormat } from "model/TournamentEditor/SetFormat/Matchmaking/TeMatchmakingSetFormat";
import { TeRoundsSetFormat } from "model/TournamentEditor/SetFormat/Rounds/TeRoundsSetFormat";
import {
  TeSetFormatArgs,
  isTeSetType,
  newTeSetFormatArgs,
  teSetTypes,
} from "model/TournamentEditor/SetFormat/TeSetFormat";
import { TeWorldTourSetFormat } from "model/TournamentEditor/SetFormat/WorldTour/TeWorldTourSetFormat";
import { TeMatchFormat } from "model/TournamentEditor/TeMatchFormat";
import { Button } from "styles/elements";
import { TeKnockoutSetFormat } from "model/TournamentEditor/SetFormat/Knockout/TeKnockoutSetFormat";

interface Props {
  matchFormat: TeMatchFormat;
  removeMatchFormat: () => void;
  update: (matchFormat: TeMatchFormat) => void;
}

const canAdjustCupModePoints = (
  args: TeSetFormatArgs | undefined
): args is TeCupModeSetFormat => {
  return (
    args?.type === "CupMode" ||
    args?.type === "CupModeLong" ||
    args?.type === "ReverseCupMode"
  );
};

const getAdjustedCupModePoints = (
  args: TeCupModeSetFormat | TeForwardCupModeLongSetFormat,
  numTeams: number | undefined
) => {
  if (args.type === "CupModeLong") {
    return getAdjustedTeCupModePoints(
      args.points,
      numTeams,
      args.driversPerTeam,
      !!numTeams ? numTeams - 1 : undefined
    );
  }

  return getAdjustedTeCupModePoints(
    args.points,
    numTeams,
    args.driversPerTeam,
    args.playTillLeft
  );
};

export const MatchFormatEditor = ({
  matchFormat,
  removeMatchFormat,
  update,
}: Props) => {
  const updateSetFormatArgs = (args: TeSetFormatArgs | undefined) => {
    matchFormat.setFormat = canAdjustCupModePoints(args)
      ? {
          ...args,
          points: getAdjustedCupModePoints(args, matchFormat.numTeams),
        }
      : args;

    update(matchFormat);
  };

  const updateNumTeams = (numTeams: number | undefined) => {
    update({
      ...matchFormat,
      numTeams,
      setFormat: canAdjustCupModePoints(matchFormat.setFormat)
        ? {
            ...matchFormat.setFormat,
            points: getAdjustedCupModePoints(matchFormat.setFormat, numTeams),
          }
        : matchFormat.setFormat,
    });
  };

  return (
    <FieldsContainer>
      <FieldColumn>
        <span>{matchFormat.key}</span>
      </FieldColumn>
      <FieldColumn>
        <LabelledField label="Teams">
          <NumberField value={matchFormat.numTeams} update={updateNumTeams} />
        </LabelledField>
        <LabelledField label="Winners">
          <NumberField
            value={matchFormat.numWinners}
            update={(numWinners) => update({ ...matchFormat, numWinners })}
          />
        </LabelledField>
      </FieldColumn>
      <FieldColumn>
        <TypedSelectField
          value={matchFormat.setFormat?.type}
          options={teSetTypes}
          isType={isTeSetType}
          update={(type) =>
            !!type ? updateSetFormatArgs(newTeSetFormatArgs(type)) : undefined
          }
        />
      </FieldColumn>
      <FieldColumn>
        {getSetFormatFields(matchFormat.setFormat, updateSetFormatArgs)}
      </FieldColumn>
      <FieldColumn>
        <Button onClick={removeMatchFormat}>-</Button>
      </FieldColumn>
    </FieldsContainer>
  );
};

const getSetFormatFields = (
  format: TeSetFormatArgs | undefined,
  update: (args: TeSetFormatArgs | undefined) => void
) => {
  if (!format) {
    return <></>;
  }

  switch (format.type) {
    case "CupMode":
    case "ReverseCupMode":
      return (
        <>
          <FieldRow>
            {NumTracksField(format, update)}
            {RoundsPerTrackField(format, update)}
            {NumWinnersField(format, update)}
            {DriversPerTeamField(format, update)}
            {PlayTillLeftField(format, update)}
          </FieldRow>
          <FieldRow>
            {format.type === "CupMode"
              ? PointsLimitField(format, update)
              : StartingPointsField(format, update)}
            {PointsDistributionsFields(format, update)}
            {DnfPointsField(format, update)}
          </FieldRow>
        </>
      );
    case "CupModeLong":
      return (
        <>
          <FieldRow>
            {NumTracksField(format, update)}
            {PlayConditionField(format, update)}
            {NumWinnersField(format, update)}
            {DriversPerTeamField(format, update)}
          </FieldRow>
          <FieldRow>
            {PointsLimitField(format, update)}
            {PointsDistributionsFields(format, update)}
            {DnfPointsField(format, update)}
          </FieldRow>
        </>
      );
    case "Knockout":
      return (
        <>
          <FieldRow>
            {NumTracksField(format, update)}
            {RoundsPerTrackField(format, update)}
            {NumWinnersField(format, update)}
            {DriversPerTeamField(format, update)}
            {PlayTillLeftField(format, update)}
            {StartingLivesField(format, update)}
          </FieldRow>
        </>
      );
    case "Matchmaking":
      return (
        <>
          <FieldRow>
            {NumTracksField(format, update)}
            {PlayConditionField(format, update)}
            {TrackWinReqField(format, update)}
            {RoundsLimitField(format, update)}
            {NumWinnersField(format, update)}
            {DriversPerTeamField(format, update)}
            <FieldRow>
              {PointsDistributionSingleField(format, update)}
              {DnfPointsField(format, update)}
            </FieldRow>
          </FieldRow>
        </>
      );
    case "Rounds":
      return (
        <>
          <FieldRow>
            {NumTracksField(format, update)}
            {RoundsPerTrackField(format, update)}
            {NumWinnersField(format, update)}
            {DriversPerTeamField(format, update)}
          </FieldRow>
          <FieldRow>
            {PointsDistributionSingleField(format, update)}
            {DnfPointsField(format, update)}
          </FieldRow>
        </>
      );
    case "TimeAttack":
      return (
        <FieldRow>
          {NumTracksField(format, update)}
          {NumWinnersField(format, update)}
          {DriversPerTeamField(format, update)}
        </FieldRow>
      );
    case "WorldTour":
      return (
        <FieldRow>
          {NumTracksField(format, update)}
          {PlayConditionField(format, update)}
          {TrackWinReqField(format, update)}
          {NumWinnersField(format, update)}
          {DriversPerTeamField(format, update)}
        </FieldRow>
      );
    default:
      return checkTypeExhausted(format);
  }
};

const DnfPointsField = (
  format:
    | TeCupModeSetFormat
    | TeForwardCupModeLongSetFormat
    | TeMatchmakingSetFormat
    | TeRoundsSetFormat,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="DNF Points:">
      <NumberField
        value={format.dnfPoints}
        update={(dnfPoints) => update({ ...format, dnfPoints })}
        args={{ allowNegative: true }}
      />
    </LabelledField>
  );
};

const DriversPerTeamField = (
  format: TeSetFormatArgs,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="Drivers/Team:">
      <NumberField
        value={format.driversPerTeam}
        update={(driversPerTeam) => update({ ...format, driversPerTeam })}
      />
    </LabelledField>
  );
};

const NumTracksField = (
  format: TeSetFormatArgs,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="Tracks:">
      <NumberField
        value={format.numTracks}
        update={(numTracks) => update({ ...format, numTracks })}
      />
    </LabelledField>
  );
};

const NumWinnersField = (
  format: TeSetFormatArgs,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="Winners:">
      <NumberField
        value={format.numWinners}
        update={(numWinners) => update({ ...format, numWinners })}
      />
    </LabelledField>
  );
};

const PlayConditionField = (
  format:
    | TeForwardCupModeLongSetFormat
    | TeMatchmakingSetFormat
    | TeWorldTourSetFormat,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="Play Condition:">
      <TypedSelectField
        value={format.playCondition}
        options={playConditions}
        isType={isPlayCondition}
        update={(playCondition) =>
          update({ ...format, playCondition: playCondition ?? "BestOf" })
        }
      />
    </LabelledField>
  );
};

const PlayTillLeftField = (
  format: TeCupModeSetFormat | TeKnockoutSetFormat,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="Play Till Left:">
      <NumberField
        value={format.playTillLeft}
        update={(playTillLeft) => update({ ...format, playTillLeft })}
      />
    </LabelledField>
  );
};

const PointsDistributionField = (
  label: string,
  value: string | undefined,
  update: (newVal: string | undefined) => void
) => {
  return (
    <LabelledField label={label}>
      <TextField
        value={value}
        update={update}
        args={{ placeholder: "e.g 8,6,4,2", width: "125px" }}
      />
    </LabelledField>
  );
};

const PointsDistributionSingleField = (
  format: TeMatchmakingSetFormat | TeRoundsSetFormat,
  update: (args: TeSetFormatArgs) => void
) => {
  return PointsDistributionField(
    "Points Distribution:",
    format.pointsDistribution,
    (pointsDistribution) => update({ ...format, pointsDistribution })
  );
};

const PointsDistributionsFields = (
  format: TeCupModeSetFormat | TeForwardCupModeLongSetFormat,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <>
      {Object.keys(format.points).map((key) => {
        const pointsDistString = format.points[key];
        return PointsDistributionField(
          `${key} left`,
          pointsDistString,
          (distString) => {
            const newPoints = deepCopyObject(format.points);
            newPoints[key] = distString;
            update({ ...format, points: newPoints });
          }
        );
      })}
    </>
  );
};

const PointsLimitField = (
  format: TeForwardCupModeSetFormat | TeForwardCupModeLongSetFormat,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="Points Limit:">
      <NumberField
        value={format.pointsLimit}
        update={(pointsLimit) => update({ ...format, pointsLimit })}
      />
    </LabelledField>
  );
};

const RoundsLimitField = (
  format: TeMatchmakingSetFormat,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="Rounds Limit:">
      <NumberField
        value={format.roundsLimit}
        update={(roundsLimit) => update({ ...format, roundsLimit })}
      />
    </LabelledField>
  );
};

const RoundsPerTrackField = (
  format: TeCupModeSetFormat | TeKnockoutSetFormat | TeRoundsSetFormat,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="Rounds/Track:">
      <NumberField
        value={format.roundsPerTrack}
        update={(roundsPerTrack) => update({ ...format, roundsPerTrack })}
      />
    </LabelledField>
  );
};

const StartingLivesField = (
  format: TeKnockoutSetFormat,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="Starting Lives:">
      <NumberField
        value={format.startingLives}
        update={(startingLives) => update({ ...format, startingLives })}
      />
    </LabelledField>
  );
};

const StartingPointsField = (
  format: TeReverseCupModeSetFormat,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="Starting Points:">
      <NumberField
        value={format.startingPoints}
        update={(startingPoints) => update({ ...format, startingPoints })}
      />
    </LabelledField>
  );
};

const TrackWinReqField = (
  format: TeMatchmakingSetFormat | TeWorldTourSetFormat,
  update: (args: TeSetFormatArgs) => void
) => {
  return (
    <LabelledField label="Track Win Req:">
      <NumberField
        value={format.trackWinReq}
        update={(trackWinReq) => update({ ...format, trackWinReq })}
      />
    </LabelledField>
  );
};
