import {
  PaginationButton,
  PaginationSeperator,
  PaginationContainer,
} from "./styles";

interface Props {
  selectedPage: number;
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>;
  maxPage: number;
}

export const PaginationSwitcher = ({
  selectedPage,
  setSelectedPage,
  maxPage,
}: Props) => {
  const startButtons = [1];
  const midButtons = [];
  const endButtons = [];

  if (maxPage <= 5) {
    for (var i = 2; i <= maxPage; i++) {
      startButtons.push(i);
    }
  } else if (selectedPage <= 3) {
    startButtons.push(2, 3, 4);
    endButtons.push(maxPage);
  } else if (selectedPage >= maxPage - 2) {
    endButtons.push(maxPage - 3, maxPage - 2, maxPage - 1, maxPage);
  } else {
    endButtons.push(maxPage);
    midButtons.push(selectedPage - 1, selectedPage, selectedPage + 1);
  }

  return (
    <PaginationContainer>
      {startButtons.map((s) => createButton(s, selectedPage, setSelectedPage))}
      {midButtons.length > 0 && (
        <>
          <PaginationSeperator>⋯</PaginationSeperator>
          {midButtons.map((m) =>
            createButton(m, selectedPage, setSelectedPage)
          )}
        </>
      )}
      {endButtons.length > 0 && (
        <>
          <PaginationSeperator>⋯</PaginationSeperator>
          {endButtons.map((e) =>
            createButton(e, selectedPage, setSelectedPage)
          )}
        </>
      )}
    </PaginationContainer>
  );
};

const createButton = (
  pageNum: number,
  selectedPage: number,
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>
) => {
  return (
    <PaginationButton
      key={pageNum}
      selected={pageNum === selectedPage}
      onClick={() => setSelectedPage(pageNum)}
    >
      {pageNum}
    </PaginationButton>
  );
};
