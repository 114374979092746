import { FormProps } from "../FormProps";
import { FormTable } from "../styles";
import { TeOrg } from "model/TournamentEditor/TeOrg";

// todo - is combinable with OrgForm ???
export const TeOrgForm = ({ data: org, setData: setOrg }: FormProps<TeOrg>) => {
  const update = () => {
    setOrg(org);
  };

  return (
    <FormTable>
      <tbody>
        <tr>
          <td>Name</td>
          <td>
            <input
              value={org.name}
              onChange={(e) => {
                org.name = e.target.value;
                update();
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Trigram</td>
          <td>
            <input
              value={org.trigram}
              onChange={(e) => {
                org.trigram = e.target.value;
                update();
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Primary Color</td>
          <td>
            <input
              value={org.primaryColor}
              onChange={(e) => {
                org.primaryColor = e.target.value;
                update();
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Country</td>
          <td>
            <input
              value={org.country}
              onChange={(e) => {
                org.country = e.target.value;
                update();
              }}
            />
          </td>
        </tr>
      </tbody>
    </FormTable>
  );
};
