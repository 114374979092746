import { IStore } from "redux/store";
import { selectUserUid } from "./User";

export const selectAllTournamentOrganizations = (state: IStore) => {
  return Object.values(state.user.tournamentOrganizations);
};

export const selectTournamentOrganization = (
  state: IStore,
  organizationId: string | undefined
) => {
  if (!organizationId) {
    return undefined;
  }

  return state.user.tournamentOrganizations[organizationId];
};

export const selectTournamentOrganizationNameLookup = (state: IStore) => {
  const nameLookup: { [id: string]: string } = {};
  Object.keys(state.user.tournamentOrganizations).forEach((id) => {
    nameLookup[id] = state.user.tournamentOrganizations[id].data.name;
  });

  return nameLookup;
};

export const selectTournamentOrganizationFromTournamentId = (
  state: IStore,
  tournamentId: string
) => {
  const organizations = selectAllTournamentOrganizations(state);
  return organizations.find((o) => o.data.tournamentIds.includes(tournamentId));
};

export const selectUserIsOrganizer = (
  state: IStore,
  organizationId: string | undefined
) => {
  const uid = selectUserUid(state);
  const organization = selectTournamentOrganization(state, organizationId);
  if (!organization) {
    return undefined;
  }

  return organization.data.organizerUids.includes(uid);
};
