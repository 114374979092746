import { MatchesViewer } from "components/MatchesViewer/MatchesViewer";
import { Panel } from "components/Panel/Panel";
import { TabBar } from "components/TabBar/TabBar";
import { GrowColumn } from "pages/styles";
import { useState } from "react";
import { FsSeason } from "db/model/tournament/Segment/Season/FsSeason";
import { Filter } from "model/Filter";

interface Props {
  season: FsSeason;
  orgTime: number | undefined;
}

export const SeasonTabContent = ({ season, orgTime }: Props) => {
  const seasonOptions =
    season.rounds?.map((r) => `${season.roundPrefix} ${r.roundNum}`) ?? [];
  const [selectedTab, setSelectedTab] = useState(seasonOptions[0]);
  const seasonFilter: Filter<string> = {
    options: seasonOptions,
    selectedValue: selectedTab,
    setSelectedValue: setSelectedTab,
  };

  const selectedRound = getRoundFromOption(season, seasonFilter.selectedValue);
  return (
    <GrowColumn>
      <Panel>
        <TabBar filter={seasonFilter} isSecondaryTabBar={true} />
        {!!selectedRound && (
          <MatchesViewer
            matchIds={selectedRound?.matchIds ?? []}
            orgTime={orgTime}
          />
        )}
      </Panel>
    </GrowColumn>
  );
};

const getRoundFromOption = (season: FsSeason, option: string | undefined) => {
  if (!option) {
    return undefined;
  }

  const roundNumString = option.slice(-1);
  const roundNum = parseInt(roundNumString);
  return season.rounds?.find((r) => r.roundNum === roundNum);
};
