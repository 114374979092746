import { db } from "firebase-config";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  onSnapshot,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";
import { store } from "redux/store";
import { updateTournamentOrganization } from "redux/UserSlice";
import { FsTournamentOrganization } from "./FsTournamentOrganization";
import { tournamentOrganizationCollectionName } from "db/info/collectionNames";

var tournamentOrganizationSubscriptions: { [key: string]: Unsubscribe } = {};

export const fetchTournamentOrganizations = (ids: string[]) => {
  const existingIds = Object.keys(tournamentOrganizationSubscriptions);
  const newIds = ids.filter((id) => !existingIds.includes(id));

  for (var j = 0; j < newIds.length; j++) {
    const newId = newIds[j];
    const existingSubscription = tournamentOrganizationSubscriptions[newId];
    if (!!existingSubscription) {
      existingSubscription();
    }

    const docRef = doc(db, tournamentOrganizationCollectionName, newId);
    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        store.dispatch(
          updateTournamentOrganization({
            id: docSnapshot.id,
            data: docSnapshot.data() as FsTournamentOrganization,
          })
        );
      }
    });

    tournamentOrganizationSubscriptions[newId] = unsubscribe;
  }
};

export const fetchAllTournamentOrganizations = () => {
  const queryRef = collection(db, tournamentOrganizationCollectionName);
  const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
    querySnapshot.docChanges().forEach((change) => {
      store.dispatch(
        updateTournamentOrganization({
          id: change.doc.id,
          data: change.doc.data() as FsTournamentOrganization,
        })
      );
    });
  });

  tournamentOrganizationSubscriptions[tournamentOrganizationCollectionName] =
    unsubscribe;
};

export const unsubscribeAllTournamentOrganizations = () => {
  const unsubscribes = Object.values(tournamentOrganizationSubscriptions);
  for (var i = 0; i < unsubscribes.length; i++) {
    unsubscribes[i]();
  }

  tournamentOrganizationSubscriptions = {};
};

export const removeTournamentDraftIdFromOrganizationAsync = async (
  organizationId: string,
  draftId: string
) => {
  const orgRef = doc(db, tournamentOrganizationCollectionName, organizationId);
  await updateDoc(orgRef, { tournamentDraftIds: arrayRemove(draftId) });
};

export const addTournamentIdToOrganizationAsync = async (
  organizationId: string,
  tournamentId: string
) => {
  const orgRef = doc(db, tournamentOrganizationCollectionName, organizationId);
  await updateDoc(orgRef, { tournamentIds: arrayUnion(tournamentId) });
};
