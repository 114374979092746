import { FsMatch } from "db/model/match/FsMatch";
import { Pill, PillContainer } from "./styles";
import { getMatchStatus } from "fsModel/Match/MatchStatus";

interface Props {
  match: FsMatch;
}

export const StatusPill = ({ match }: Props) => {
  const matchStatus = getMatchStatus(match);
  return (
    <PillContainer>
      <Pill status={matchStatus}>{matchStatus}</Pill>
    </PillContainer>
  );
};
