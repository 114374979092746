import { nonUndefined } from "Utility";
import { IStore } from "redux/store";

export function selectMultiple<T>(
  state: IStore,
  selector: (state: IStore, id: string) => T | undefined,
  ids: string[]
) {
  return nonUndefined(ids.map((id) => selector(state, id)));
}
