import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsCupModePoints } from "db/model/match/Set/CupMode/FsCupModePoints";
import { FsMatchTrack } from "db/model/match/Track/FsMatchTrack";
import { Scores } from "fsModel/Match/Scores";
import { applyForwardCupModeRoundScore } from "./Round";

export const applyForwardCupModeTrackScore = (
  track: FsMatchTrack,
  teams: FsMatchTeam[],
  runningScores: Scores,
  points: FsCupModePoints,
  pointsLimit: number,
  driversPerTeam: number,
  completedDriverIds: string[],
  completedTeamIds: string[]
) => {
  if (!track.rounds) {
    return;
  }

  for (let i = 0; i < track.rounds.length; i++) {
    const round = track.rounds[i];
    if (!round.times) {
      continue;
    }

    applyForwardCupModeRoundScore(
      round.times,
      teams,
      runningScores,
      points,
      pointsLimit,
      driversPerTeam,
      completedDriverIds,
      completedTeamIds
    );
  }
};
