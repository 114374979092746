import { arrayReplaceAt, nonEmpty } from "Utility";
import { Button } from "styles/elements";
import { FlexRow } from "styles/layout";
import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import {
  FieldsContainer,
  StyledInput,
  StyledSelect,
} from "components/Fields/styles";
import { useSelector } from "react-redux";
import { selectMatch } from "selectors/Match";
import { IStore } from "redux/store";
import { TeBracketMatch } from "model/TournamentEditor/Segment/Bracket/TeBracketMatch";
import { TeMatchFormat } from "model/TournamentEditor/TeMatchFormat";

interface Props {
  matchFormats: TeMatchFormat[];
  bracketMatch: TeBracketMatch;
  bracketMatchKeys: string[];
  updateMatch: (newMatch: TeBracketMatch) => void;
  removeMatch?: () => void;
}
export const BracketMatch = ({
  matchFormats,
  bracketMatch,
  bracketMatchKeys,
  updateMatch,
  removeMatch,
}: Props) => {
  const match = bracketMatch.match;
  const existingMatch = useSelector((state: IStore) =>
    typeof match === "string" ? selectMatch(state, match) : undefined
  );

  if (typeof match === "string") {
    return (
      <FlexRow>
        <span>{`Existing match. ID: ${
          existingMatch?.id ?? "NOT FOUND"
        } Start Time (ms): ${existingMatch?.data.startTimeMs}`}</span>
        {removeMatch && <Button onClick={removeMatch}>-</Button>}
      </FlexRow>
    );
  }

  // todo - extract shared fields with MatchList?
  return (
    <FieldsContainer>
      <LabelledField label="">
        <span>{bracketMatch.matchKey}</span>
      </LabelledField>
      <LabelledField label="Format: ">
        <StyledSelect
          style={{ width: "50px" }}
          value={match.formatKey}
          onChange={(e) =>
            updateMatch({
              ...bracketMatch,
              match: { ...match, formatKey: e.target.value },
            })
          }
        >
          <option value="" />
          {matchFormats
            .map((f) => f.key)
            .map((k) => (
              <option value={k}>{k}</option>
            ))}
        </StyledSelect>
      </LabelledField>
      <LabelledField label="Start Time (ms): ">
        <StyledInput
          style={{ width: "100px" }}
          type="number"
          value={match.startTimeMs}
          onChange={(e) =>
            updateMatch({
              ...bracketMatch,
              match: {
                ...match,
                startTimeMs: e.target.valueAsNumber,
              },
            })
          }
        />
      </LabelledField>
      {!!bracketMatch.teamSources ? (
        <>
          {bracketMatch.teamSources.map((source, sourceIdx) => (
            <>
              <LabelledField label={`Src: `}>
                <StyledSelect
                  value={source.matchKey ?? ""}
                  onChange={(e) => {
                    const newMatchKey = nonEmpty(e.target.value);
                    updateMatch({
                      ...bracketMatch,
                      teamSources: arrayReplaceAt(
                        bracketMatch.teamSources!,
                        { ...source, matchKey: newMatchKey },
                        sourceIdx
                      ),
                    });
                  }}
                >
                  <option value="">Seed</option>
                  {bracketMatchKeys.map((key) => (
                    <option value={key}>{key}</option>
                  ))}
                </StyledSelect>
              </LabelledField>
              <LabelledField label={`Pos: `}>
                <StyledInput
                  type="number"
                  value={source.position}
                  style={{ width: "25px" }}
                  onChange={(e) => {
                    updateMatch({
                      ...bracketMatch,
                      teamSources: arrayReplaceAt(
                        bracketMatch.teamSources!,
                        { ...source, position: e.target.valueAsNumber },
                        sourceIdx
                      ),
                    });
                  }}
                />
              </LabelledField>
            </>
          ))}
        </>
      ) : (
        <>
          <LabelledField label="Src: ">
            <StyledSelect
              value={bracketMatch.teamOneSourceKey ?? ""}
              onChange={(e) => {
                const newMatchKey = nonEmpty(e.target.value);
                updateMatch({ ...bracketMatch, teamOneSourceKey: newMatchKey });
              }}
            >
              <option value="">Seed</option>
              {bracketMatchKeys.map((key) => (
                <option value={key}>{key}</option>
              ))}
            </StyledSelect>
          </LabelledField>
          <LabelledField label="Src">
            <StyledSelect
              value={bracketMatch.teamTwoSourceKey ?? ""}
              onChange={(e) => {
                const newMatchKey = nonEmpty(e.target.value);
                updateMatch({ ...bracketMatch, teamTwoSourceKey: newMatchKey });
              }}
            >
              <option value="">Seed</option>
              {bracketMatchKeys.map((key) => (
                <option value={key}>{key}</option>
              ))}
            </StyledSelect>
          </LabelledField>
        </>
      )}
      <LabelledField label="Is Final: ">
        <StyledInput
          type="checkbox"
          checked={bracketMatch.isFinal}
          onChange={() =>
            updateMatch({
              ...bracketMatch,
              isFinal: !bracketMatch.isFinal,
            })
          }
        />
      </LabelledField>

      {bracketMatch.isFinal && (
        <LabelledField label="Is Upper Final: ">
          <StyledInput
            type="checkbox"
            checked={bracketMatch.isUpperFinal}
            onChange={() =>
              updateMatch({
                ...bracketMatch,
                isUpperFinal: !bracketMatch.isUpperFinal,
              })
            }
          />
        </LabelledField>
      )}
      <Button onClick={removeMatch}>-</Button>
    </FieldsContainer>
  );
};
