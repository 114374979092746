import { Timestamp } from "firebase/firestore";

export const getTimeMs = () => {
  return Timestamp.now().toMillis();
};

export const convertMsToTimeString = (msTime: number) => {
  const milliSeconds = msTime % 1000;
  const seconds = Math.floor((msTime / 1000) % 60);
  const minutes = Math.floor((msTime / 1000 / 60) % 60);

  return `${minutes.toString()}:${seconds
    .toString()
    .padStart(2, "0")}.${milliSeconds.toString().padStart(3, "0")}`;
};

export const convertMsToSecondsString = (msTime: number, prefix: string) => {
  const milliSeconds = msTime % 1000;
  const seconds = Math.floor((msTime / 1000) % 60);
  return `${prefix}${seconds.toString().padStart(1, "0")}.${milliSeconds
    .toString()
    .padStart(3, "0")}`;
};

export const daysToMs = (days: number) => {
  return days * 86400000;
};

export const dateIsToday = (date: Date) => {
  const current = new Date();
  if (
    date.getDate() !== current.getDate() ||
    date.getMonth() !== current.getMonth() ||
    date.getFullYear() !== current.getFullYear()
  ) {
    return false;
  }

  return true;
};
