import { newFsSetFormatBase } from "../FsSetFormat";
import { FsKnockoutFormat } from "db/model/match/Set/Knockout/FsKnockoutFormat";

export const newFsKnockoutFormat = (): FsKnockoutFormat => {
  return {
    ...newFsSetFormatBase(),
    lives: undefined,
    playTillLeft: undefined,
    roundsPerTrack: undefined,
    startingLives: undefined,
  };
};
