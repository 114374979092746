import { TournamentDraftEditor } from "components/TournamentEditor/TournamentDraftEditor";
import { PageContent, ColumnContainer, GrowColumn } from "pages/styles";
import { MaxWidth } from "styles/layout";

export const CreateDraftPage = () => {
  return (
    <PageContent>
      <MaxWidth>
        <ColumnContainer>
          <GrowColumn>
            <TournamentDraftEditor />
          </GrowColumn>
        </ColumnContainer>
      </MaxWidth>
    </PageContent>
  );
};
