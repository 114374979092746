import { IStore } from "redux/store";
import { nonUndefined } from "Utility";

export const selectTournament = (
  state: IStore,
  tournamentId: string | undefined
) => {
  if (!tournamentId) {
    return undefined;
  }

  return state.user.tournaments[tournamentId];
};

export const selectAllTournamentIds = (state: IStore) => {
  return Object.keys(state.user.tournaments);
};

export const selectAllTournaments = (state: IStore) => {
  return Object.values(state.user.tournaments);
};

export const selectTournamentName = (
  state: IStore,
  tournamentId: string | undefined
) => {
  if (!tournamentId) {
    return undefined;
  }

  return selectTournament(state, tournamentId)?.data.name;
};

export const selectTournamentSegmentNames = (
  state: IStore,
  tournamentId: string
) => {
  return nonUndefined(
    (selectTournament(state, tournamentId)?.data.segments ?? []).flatMap(
      (s) => s.name
    )
  );
};

export const selectTournamentSegments = (
  state: IStore,
  tournamentId: string,
  segmentIds: string[]
) => {
  const tournament = selectTournament(state, tournamentId);
  if (!tournament) {
    return undefined;
  }

  return tournament.data.segments?.filter(
    (s) => !!s.segmentId && segmentIds.includes(s.segmentId)
  );
};
