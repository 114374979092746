import { TournamentDraftEditor } from "components/TournamentEditor/TournamentDraftEditor";
import { mapTournamentToDraft } from "model/TournamentDraft/TournamentDraft";
import { PageContent, ColumnContainer, GrowColumn } from "pages/styles";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectAllTeams } from "selectors/Team";
import { selectTournament } from "selectors/Tournament";
import { IStore } from "redux/store";
import { MaxWidth } from "styles/layout";
import { EmptyGrowPanelText, ErrorText } from "styles/text";

export const EditTournamentPage = () => {
  const { tournamentId } = useParams();
  const tournament = useSelector((state: IStore) =>
    selectTournament(state, tournamentId)
  );

  const teams = useSelector(selectAllTeams);

  return (
    <PageContent>
      <MaxWidth>
        <ColumnContainer>
          <GrowColumn>
            {!!tournament ? (
              <TournamentDraftEditor
                existing={mapTournamentToDraft(
                  tournament.id,
                  tournament.data,
                  teams
                )}
              />
            ) : (
              <EmptyGrowPanelText>
                <ErrorText>Tournament could not be found</ErrorText>
              </EmptyGrowPanelText>
            )}
          </GrowColumn>
        </ColumnContainer>
      </MaxWidth>
    </PageContent>
  );
};
