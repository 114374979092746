import { arrayRemoveAt, arrayReplaceAt } from "Utility";
import { Panel } from "components/Panel/Panel";
import { CenterFlexColumn, CenterFlexRow } from "styles/layout";
import { TeamEditor } from "./TeamEditor";
import { DriverKeyEditor, DriverKeys } from "./DriverKeyEditor";
import { Button } from "styles/elements";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsMatch } from "db/model/match/FsMatch";

interface Props {
  matchId: string;
  match: FsMatch;
  update: (match: FsMatch) => void;
  driverKeys: DriverKeys;
  updateDriverKeys: (keys: DriverKeys) => void;
  orgTime: number | undefined; // todo orgtime type
}

export const TeamsPanel = ({
  matchId,
  match,
  update,
  driverKeys,
  updateDriverKeys,
  orgTime,
}: Props) => {
  const addTeam = () => {
    update({
      ...match,
      teams: [...(match.teams ?? []), "TBD"],
    });
  };

  const updateScoreOverride = (
    teamId: string | undefined,
    scoreOverride: number | undefined
  ) => {
    if (!teamId) {
      return;
    }

    const newScoreOverrides = match.scoreOverrides ?? {};
    if (
      scoreOverride === undefined &&
      newScoreOverrides[teamId] !== undefined
    ) {
      delete newScoreOverrides[teamId];
    } else if (scoreOverride !== undefined) {
      newScoreOverrides[teamId] = scoreOverride;
    }

    update({ ...match, scoreOverrides: newScoreOverrides });
  };

  return (
    <Panel header="Teams" padding="padded" collapsedContent="Teams Collapsed">
      <CenterFlexColumn>
        <Button onClick={addTeam}>Add Team</Button>
        <CenterFlexRow style={{ margin: "auto" }}>
          {match.teams?.map((team, teamIdx) => (
            <TeamEditor
              key={`${teamIdx} - ${
                typeof team !== "string" ? team.teamId : team
              }`}
              matchId={matchId}
              team={team}
              updateTeam={(team: FsMatchTeam | string) =>
                update({
                  ...match,
                  teams: arrayReplaceAt(match.teams ?? [], team, teamIdx),
                })
              }
              removeTeam={() =>
                update({
                  ...match,
                  teams: arrayRemoveAt(match.teams ?? [], teamIdx),
                })
              }
              scoreOverride={
                typeof team !== "string" &&
                !!team.teamId &&
                !!match.scoreOverrides
                  ? match.scoreOverrides[team.teamId]
                  : undefined
              }
              updateScoreOverride={updateScoreOverride}
              orgTime={orgTime}
            />
          ))}
        </CenterFlexRow>

        {!!Object.keys(driverKeys).length && (
          <>
            <br />
            <span style={{ fontSize: "9pt" }}>
              Assign driver keys below to assist manual data entry
            </span>
            <CenterFlexRow>
              <DriverKeyEditor
                driverKeys={driverKeys}
                updateDriverKeys={updateDriverKeys}
              />
            </CenterFlexRow>
          </>
        )}
      </CenterFlexColumn>
    </Panel>
  );
};
