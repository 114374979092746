import styled from "styled-components";
import { Button } from "styles/elements";
import { FlexColumn } from "styles/layout";
import { PANEL_GAP } from "theme";

export const MatchesContainer = styled(FlexColumn)`
  row-gap: ${PANEL_GAP};
`;

export const ShowMoreButton = styled(Button)`
  width: 150px;
  padding: 6px;
  margin: auto;
  font-size: 11pt;
  border-radius: 20px;

  @media (max-width: 700px) {
    width: 120px;
    font-size: 10pt;
    padding: 5px;
  }
`;
