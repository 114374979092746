import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { Scores } from "fsModel/Match/Scores";
import { FsPointsDistribution } from "db/model/match/Set/FsPointsDistribution";
import { FsRoundTime } from "db/model/match/Track/FsRoundTime";
import { getPoints } from "../PointsDistribution";

export const applyRoundsRoundScore = (
  times: FsRoundTime[],
  teams: FsMatchTeam[],
  runningScores: Scores,
  pointsDist: FsPointsDistribution
) => {
  for (let i = 0; i < teams.length; i++) {
    const { teamId, driverIds } = teams[i];
    if (!teamId || !driverIds) {
      continue;
    }

    const pointsAtStart = runningScores[teamId] ?? 0;
    const teamTimes = times.filter(
      (t) => !!t.driverId && driverIds.includes(t.driverId)
    );

    let pointsDelta = 0;
    teamTimes.forEach(({ finishTimeMs, position }) => {
      if (!!position) {
        pointsDelta += getPoints(pointsDist, position, finishTimeMs);
      }
    });

    const runningScore = pointsAtStart + pointsDelta;
    runningScores[teamId] = runningScore;
  }
};
