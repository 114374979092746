import { TypedSelectField, IdSelectField } from "components/Fields/Select";
import {
  FsTrackPick,
  PickType,
  isPickType,
  pickTypes,
} from "db/model/match/Track/FsTrackPick";
import { IdDisplay } from "model/IdDisplay";
import { Button } from "styles/elements";
import { FlexRow } from "styles/layout";

interface Props {
  pick: FsTrackPick;
  update: (pick: FsTrackPick) => void;
  remove: () => void;
  teamOptions: IdDisplay[];
  trackOptions: IdDisplay[];
}

export const SetPick = ({
  pick,
  update,
  remove,
  teamOptions,
  trackOptions,
}: Props) => {
  const updatePickType = (
    pick: FsTrackPick,
    pickType: PickType | undefined
  ) => {
    const pickingTeamId =
      !!pickType && pickType !== "Random" ? pick.pickingTeamId : undefined;
    update({
      ...pick,
      pickType,
      pickingTeamId,
    });
  };

  return (
    <FlexRow style={{ columnGap: "8px", marginLeft: "8px" }}>
      <IdSelectField
        value={pick.trackId}
        options={trackOptions}
        update={(trackId) => update({ ...pick, trackId })}
      />
      <TypedSelectField
        value={pick.pickType}
        options={pickTypes}
        isType={isPickType}
        update={(type) => updatePickType(pick, type)}
      />
      {(pick.pickType === "Pick" || pick.pickType === "Ban") && (
        <IdSelectField
          value={pick.pickingTeamId}
          options={teamOptions}
          update={(pickingTeamId) => update({ ...pick, pickingTeamId })}
        />
      )}
      <Button style={{ fontSize: "7pt" }} onClick={remove}>
        Remove
      </Button>
    </FlexRow>
  );
};
