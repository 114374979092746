import styled from "styled-components";
import { Spinner } from "styles/components";
import { ClearButton } from "styles/elements";
import { FlexRow } from "styles/layout";
import { PANEL_GAP, Theme } from "theme";
import { PanelContentType, PanelPaddingType } from "./Panel";

export const PanelContainer = styled.div<{ bottomMargin: boolean }>`
  margin-bottom: ${({ bottomMargin }) => (bottomMargin ? PANEL_GAP : 0)};
`;

export const PanelHeader = styled.div`
  font-size: 12pt;
  font-weight: 500;
  text-align: left;
  padding-left: 6px;
  margin-bottom: 4px;

  @media (max-width: 600px) {
    font-size: 11pt;
    padding-left: 4px;
  }
`;

const handlePanelType = (type: PanelContentType) => {
  switch (type) {
    case "normal":
      return `background: ${Theme.panelBackground};`;
    case "information":
      return `background: ${Theme.informationPanelBackground}; border: ${Theme.informationPanelBorder};`;
    case "warning":
      return `background: ${Theme.warningPanelBackground}; border: ${Theme.warningPanelBorder};`;
  }
};

const handlePanelPadding = (padding: PanelPaddingType) => {
  switch (padding) {
    case "none":
      return `;`;
    case "padded":
      return `padding: ${Theme.bigPanelPadding};

      @media (max-width: 700px) {
        padding: ${Theme.smallPanelPadding};
      }`;
    case "square":
      return `padding: ${Theme.bigPanelPaddingHorizontal};

      @media (max-width: 700px) {
        padding: ${Theme.smallPanelPadding};
      }`;
  }
};

export const PanelContent = styled.div<{
  type: PanelContentType;
  padding: PanelPaddingType;
  allowOverflow: boolean;
}>`
  background: ${Theme.panelBackground};
  box-shadow: ${Theme.panelBoxShadow};
  border-radius: 6px;
  overflow-x: auto;
  ${({ allowOverflow }) => allowOverflow && `overflow-x: visible;`}
  ${({ type }) => handlePanelType(type)};
  ${({ padding }) => handlePanelPadding(padding)};
`;

export const PanelSpinner = styled(Spinner)`
  border-width: ${Theme.spinnerSmallBorderWidth};
  width: 20px;
  height: 20px;
  margin: ${Theme.bigPanelPaddingVertical} auto;
  @media (max-width: 700px) {
    margin: ${Theme.smallPanelPadding} auto;
  }
`;

export const CollapsableHeaderRow = styled(FlexRow)`
  cursor: pointer;
  align-items: center;
  &:hover {
    color: ${Theme.buttonHoverForeground};
  }
`;

export const ExpandCollapseButton = styled.span`
  padding-left: 5px;
  padding-bottom: 2px;
  padding-right: 5px;
  font-size: 8pt;
  line-height: 100%;
`;
