import { Panel } from "components/Panel/Panel";
import { TeTournamentEditor } from "components/TournamentEditor/TeTournamentEditor";
import { TournamentDraft } from "model/TournamentDraft/TournamentDraft";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsEcmStaff } from "selectors/User";
import { Button } from "styles/elements";
import { ActionsContainer } from "./styles";
import { newTeTournament } from "model/TournamentEditor/TeTournament";
import {
  selectExistingDriverIds,
  selectExistingMatchIds,
  selectExistingOrgIds,
  selectExistingTeamIds,
  selectExistingTrackIds,
} from "selectors/Ids";
import { CenterFlexColumn } from "styles/layout";
import { deepCopyObject, nonUndefined } from "Utility";
import { selectTournamentOrganizationNameLookup } from "selectors/TournamentOrganization";
import { publishTournamentAsync } from "model/TournamentEditor/Publish";
import { useNavigate } from "react-router-dom";
import { tournamentsPath } from "pages/PageConfigs";
import { saveTournamentDraftAsync } from "database/tournamentDraft/tournamentDraftInfo";
import { getTeTournamentSegmentMatches } from "model/TournamentEditor/Segment/TeTournamentSegment";
import { IStore } from "redux/store";
import { selectAllTeams } from "selectors/Team";
import { EmptyGrowPanelText } from "styles/text";
import { selectMultiple } from "selectors/Core";
import { StyledSelect } from "components/Fields/styles";
import { selectMatch } from "selectors/Match";
import { selectAllDrivers } from "selectors/Driver";
import { selectAllOrgs } from "selectors/Org";

interface Props {
  existing?: TournamentDraft;
  existingId?: string;
}

export const TournamentDraftEditor = ({ existing, existingId }: Props) => {
  const navigate = useNavigate();
  const [draft, setDraft] = useState(
    !!existing ? deepCopyObject(existing.draft) : newTeTournament()
  );
  const [savingDraft, setSavingDraft] = useState(false);
  const [resultText, setResultText] = useState("");
  const [newDraftTournamentOrgId, setNewDraftTournamentOrgId] = useState("");
  const tournamentOrgNameLookup = useSelector(
    selectTournamentOrganizationNameLookup
  );

  const isEcmStaff = useSelector(selectIsEcmStaff);

  const onSaveDraftClick = async () => {
    setSavingDraft(true);
    try {
      await saveTournamentDraftAsync(
        draft,
        existing?.organizationId ?? newDraftTournamentOrgId,
        existingId,
        existing?.existingTournamentId
      );
      navigate(tournamentsPath);
    } catch (error: any) {
      setResultText(error.message);
      setSavingDraft(false);
    }
  };

  const existingDriverIds = useSelector(selectExistingDriverIds);
  const existingMatchIds = useSelector(selectExistingMatchIds);
  const existingOrgIds = useSelector(selectExistingOrgIds);
  const existingTeamIds = useSelector(selectExistingTeamIds);
  const existingTrackIds = useSelector(selectExistingTrackIds);
  const existingTeams = useSelector(selectAllTeams);
  const existingDrivers = useSelector(selectAllDrivers);
  const existingOrgs = useSelector(selectAllOrgs);
  const draftMatchIds = draft.segments
    .flatMap((s) => getTeTournamentSegmentMatches(s) ?? [])
    .filter((m) => typeof m === "string") as string[];

  const existingMatchStartTimes = useSelector((state: IStore) =>
    nonUndefined(
      selectMultiple(state, selectMatch, draftMatchIds).map(
        (m) => m?.data.startTimeMs
      )
    )
  );

  const onPublishTournamentClick = async () => {
    setSavingDraft(true);
    try {
      await publishTournamentAsync(
        existingId,
        existing?.organizationId ?? newDraftTournamentOrgId,
        draft,
        existing?.existingTournamentId,
        existingDriverIds,
        existingMatchIds,
        existingOrgIds,
        existingTeamIds,
        existingTrackIds,
        existingOrgs,
        existingDrivers,
        existingTeams,
        existingMatchStartTimes.length > 0
          ? Math.min(...existingMatchStartTimes)
          : undefined
      );
      navigate(tournamentsPath);
    } catch (e: any) {
      setResultText(e.message);
    } finally {
      setSavingDraft(false);
    }
  };

  return (
    <>
      {Object.keys(tournamentOrgNameLookup).length === 0 && (
        <Panel type="warning">
          <EmptyGrowPanelText>
            Your account is not linked to any tournament organizations, so you
            will be unable to save this draft. <br /> This means all changes
            will be lost!
          </EmptyGrowPanelText>
        </Panel>
      )}
      <TeTournamentEditor tournament={draft} setTournament={setDraft} />
      <Panel padding="padded">
        <CenterFlexColumn style={{ rowGap: "5px" }}>
          {!existing?.organizationId && (
            <StyledSelect
              value={newDraftTournamentOrgId}
              onChange={(e) => {
                setNewDraftTournamentOrgId(e.target.value ?? "");
              }}
            >
              <option key="" value="">
                select tournament org
              </option>
              {Object.keys(tournamentOrgNameLookup).map((tournamentOrgId) => (
                <option key={tournamentOrgId} value={tournamentOrgId}>
                  {tournamentOrgNameLookup[tournamentOrgId]}
                </option>
              ))}
            </StyledSelect>
          )}
          <ActionsContainer>
            {isEcmStaff && (
              <Button
                onClick={onPublishTournamentClick}
                disabled={
                  savingDraft ||
                  (!existing?.organizationId && !newDraftTournamentOrgId) ||
                  !draft.id
                }
              >
                Publish Tournament
              </Button>
            )}
            <Button
              onClick={onSaveDraftClick}
              disabled={savingDraft || (!existing && !newDraftTournamentOrgId)}
            >
              {!!existing ? "Update" : "Save"} Draft
            </Button>
          </ActionsContainer>
          {resultText}
        </CenterFlexColumn>
      </Panel>
    </>
  );
};
