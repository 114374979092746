import { TrackForm } from "components/AddEditForms/TrackForm/TrackForm";
import { ctorTextColumn } from "components/DataGrid/Columns/Text";
import { ctorValidationColumn } from "components/DataGrid/Columns/Validation";
import { DataGridColumn } from "components/DataGrid/DataGridColumn";
import { trackCollectionName } from "db/info/collectionNames";
import { Track } from "model/IdData";
import { Validated } from "model/validators/base";
import { validateTracks } from "model/validators/TrackValidator";
import { DataPage } from "pages/Data/DataPage";
import { useSelector } from "react-redux";
import { selectAllTournaments } from "selectors/Tournament";
import { selectAllTracks } from "selectors/Track";
import { anyStringContains } from "Utility";

export const TracksPage = () => {
  const tracks = useSelector(selectAllTracks);

  const tournaments = useSelector(selectAllTournaments);
  const validatedTracks = validateTracks(tracks, tournaments);
  return (
    <DataPage
      dataName="Track"
      collectionName={trackCollectionName}
      data={tracks}
      columns={tracksColumns}
      validated={validatedTracks}
      newData={() => ({
        imageIdOverride: undefined,
        link: undefined,
        mapId: undefined,
        mapperName: undefined,
        name: undefined,
      })}
      searchFunction={trackSearch}
      form={TrackForm}
    />
  );
};
const tracksColumns: DataGridColumn<Validated<Track>>[] = [
  ctorTextColumn({
    textGetter: (arg: Validated<Track>) => arg.model.id,
    sortable: true,
    numberSort: true,
    header: "ID",
  }),
  ctorTextColumn({
    textGetter: (arg: Validated<Track>) => arg.model.data.name,
    sortable: true,
    header: "Name",
  }),
  ctorValidationColumn({
    validationGetter: (arg: Validated<Track>) => arg.problems,
    sortable: true,
    header: "Validation",
  }),
];

const trackSearch = (arg: Validated<Track>, searchText: string) => {
  return anyStringContains(searchText, [arg.model.id, arg.model.data.name]);
};
