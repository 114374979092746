import { FsOrg } from "db/model/org/FsOrg";
import { FormProps } from "../FormProps";
import { StringArrayField } from "../StringArrayField/StringArrayField";
import { FormTable } from "../styles";
import { FsOrgDetails } from "db/model/org/FsOrgDetails";

export const OrgForm = ({ data: org, setData: setOrg }: FormProps<FsOrg>) => {
  const update = () => {
    setOrg(org);
  };

  return (
    <FormTable>
      <tbody>
        <tr>
          <td>Name</td>
          <td>
            <input
              value={org.details?.at(0)?.name}
              onChange={(e) => {
                if (!org.details?.at(0)) {
                  org.details = [newOrgDetails()];
                }
                org.details[0].name = e.target.value;
                update();
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Trigram</td>
          <td>
            <input
              value={org.details?.at(0)?.trigram}
              onChange={(e) => {
                if (!org.details?.at(0)) {
                  org.details = [newOrgDetails()];
                }
                org.details[0].trigram = e.target.value;
                update();
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Primary Color</td>
          <td>
            <input
              value={org.details?.at(0)?.primaryColor}
              onChange={(e) => {
                if (!org.details?.at(0)) {
                  org.details = [newOrgDetails()];
                }
                org.details[0].primaryColor = e.target.value;
                update();
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Country</td>
          <td>
            <input
              value={org.details?.at(0)?.country}
              onChange={(e) => {
                if (!org.details?.at(0)) {
                  org.details = [newOrgDetails()];
                }
                org.details[0].country = e.target.value;
                update();
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Team History</td>
          <td>
            <StringArrayField
              values={org.teamHistory ?? []}
              update={(teamHistory) => setOrg({ ...org, teamHistory })}
            />
          </td>
        </tr>
      </tbody>
    </FormTable>
  );
};

const newOrgDetails = (): FsOrgDetails => {
  return {
    country: undefined,
    name: undefined,
    primaryColor: undefined,
    trigram: undefined,
    endTimeMs: undefined,
  };
};
