import { SwitchOption, Switcher } from "components/Switcher/Switcher";
import { newFsMatchSet } from "db/ctor/match/Set/FsMatchSet";
import { FsMatchSet } from "db/model/match/Set/FsMatchSet";

interface Props {
  sets: FsMatchSet[];
  selectedSetIdx: number | undefined;
  setSelectedSetIdx: React.Dispatch<React.SetStateAction<number | undefined>>;
  updateSets: (newSets: FsMatchSet[]) => void;
}

export const SetSwitcher = ({
  sets,
  selectedSetIdx,
  setSelectedSetIdx,
  updateSets,
}: Props) => {
  const options: SwitchOption<number>[] = [
    ...sets.map((_, setIdx) => ({
      display: <span>Set {setIdx + 1}</span>,
      selectable: true,
      value: setIdx,
    })),
    {
      display: <span>Add Set</span>,
      selectable: false,
      onClick: () => {
        const lastSetType = sets.at(-1)?.type;
        updateSets([...sets, newFsMatchSet(lastSetType ?? "ForwardCupMode")]);
      },
    },
  ];

  return (
    <Switcher
      options={options}
      selectedValue={selectedSetIdx}
      setSelectedValue={setSelectedSetIdx}
    />
  );
};
