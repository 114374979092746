import { checkTypeExhausted } from "Utility";
import { PlayCondition } from "../PlayCondition";
import { Scores } from "../Scores";
import { FsMatchSet } from "db/model/match/Set/FsMatchSet";
import {
  getForwardCupModeSetFinalPositions,
  getForwardCupModeSetScores,
  isForwardCupModeSetComplete,
} from "./CupMode/Forward/Set";
import {
  getReverseCupModeSetFinalPositions,
  getReverseCupModeSetScores,
  isReverseCupModeSetComplete,
} from "./CupMode/Reverse/Set";
import {
  getMatchmakingSetFinalPositions,
  getMatchmakingSetScores,
  isMatchmakingSetComplete,
} from "./Matchmaking/Set";
import {
  getRoundSetScores,
  getRoundsSetFinalPositions,
  isRoundsSetComplete,
} from "./Rounds/Set";
import {
  getTimeAttackSetFinalPositions,
  getTimeAttackSetScores,
  isTimeAttackSetComplete,
} from "./TimeAttack/Set";
import {
  getWorldTourSetFinalPositions,
  getWorldTourSetScores,
  isWorldTourSetComplete,
} from "./WorldTour/Set";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import {
  getForwardCupModeLongSetFinalPositions,
  getForwardCupModeLongSetScores,
  isForwardCupModeLongSetComplete,
} from "./CupModeLong/Forward/Set";
import {
  getKnockoutSetFinalPositions,
  getKnockoutSetScores,
  isKnockoutSetComplete,
} from "./Knockout/Set";

export const getMatchSetFinalPositions = (
  set: FsMatchSet,
  scores: Scores,
  isComplete: boolean
) => {
  switch (set.type) {
    case "ForwardCupMode":
      return getForwardCupModeSetFinalPositions(scores, isComplete);
    case "ForwardCupModeLong":
      return getForwardCupModeLongSetFinalPositions(scores, isComplete);
    case "Knockout":
      return getKnockoutSetFinalPositions(scores, isComplete);
    case "Matchmaking":
      return getMatchmakingSetFinalPositions(scores, isComplete);
    case "ReverseCupMode":
      return getReverseCupModeSetFinalPositions(scores, isComplete);
    case "Rounds":
      return getRoundsSetFinalPositions(scores, isComplete);
    case "TimeAttack":
      return getTimeAttackSetFinalPositions(scores, isComplete);
    case "WorldTour":
      return getWorldTourSetFinalPositions(scores, isComplete);
    default:
      return checkTypeExhausted(set);
  }
};

export const getMatchSetScores = (
  set: FsMatchSet,
  teams: FsMatchTeam[],
  numTeams: number
) => {
  switch (set.type) {
    case "ForwardCupMode":
      return getForwardCupModeSetScores(set, teams, numTeams);
    case "ForwardCupModeLong":
      return getForwardCupModeLongSetScores(set, teams);
    case "Knockout":
      return getKnockoutSetScores(set, teams);
    case "Matchmaking":
      return getMatchmakingSetScores(set, teams);
    case "ReverseCupMode":
      return getReverseCupModeSetScores(set, teams, numTeams);
    case "Rounds":
      return getRoundSetScores(set, teams);
    case "TimeAttack":
      return getTimeAttackSetScores(set, teams);
    case "WorldTour":
      return getWorldTourSetScores(set, teams);
    default:
      return checkTypeExhausted(set);
  }
};

export const matchSetIsComplete = (
  set: FsMatchSet,
  scores: Scores,
  numTeams: number
) => {
  switch (set.type) {
    case "ForwardCupMode":
      return isForwardCupModeSetComplete(set, scores, numTeams);
    case "ForwardCupModeLong":
      return isForwardCupModeLongSetComplete(set, scores);
    case "Knockout":
      return isKnockoutSetComplete(set, scores, numTeams);
    case "Matchmaking":
      return isMatchmakingSetComplete(set, scores);
    case "ReverseCupMode":
      return isReverseCupModeSetComplete(set, scores, numTeams);
    case "Rounds":
      return isRoundsSetComplete(set);
    case "TimeAttack":
      return isTimeAttackSetComplete(set);
    case "WorldTour":
      return isWorldTourSetComplete(set, scores);
    default:
      return checkTypeExhausted(set);
  }
};

export const isPlayConditionComplete = (
  scores: Scores,
  playCondition: PlayCondition,
  numTracks: number,
  numCompleteTracks: number
) => {
  const totalPoints = numTracks;
  const totalScore = Object.values(scores).reduce((a, b) => a + b, 0);
  const remainingPoints = totalPoints - totalScore;

  switch (playCondition) {
    case "BestOf": {
      if (numCompleteTracks === numTracks) {
        return true;
      }

      const scoreValues = Object.values(scores);
      if (scoreValues.length <= 1) {
        return false;
      }

      const bestScore = Math.max(...scoreValues, 0);
      const tiedBest = scoreValues.filter((s) => s === bestScore).length > 1;
      if (tiedBest) {
        return false;
      }

      const otherScoreValues = scoreValues.filter((s) => s < bestScore);
      const nextBestScore = Math.max(...otherScoreValues, 0);
      return bestScore > nextBestScore + remainingPoints;
    }
    case "FirstTo": {
      return Math.max(...Object.values(scores), 0) >= numTracks;
    }
    case "PlayAll":
      return numCompleteTracks === numTracks || totalPoints === totalScore;
    default:
      return checkTypeExhausted(playCondition);
  }
};

export const matchSetHasData = (set: FsMatchSet) => {
  if (!!Object.keys(set.scoreOverrides ?? {}).length) {
    return true;
  }

  return set.tracks?.some(
    (t) => !!Object.keys(t.scoreOverrides ?? {}).length || !!t.rounds?.length
  );
};

export const matchSetHasDriverData = (set: FsMatchSet) => {
  return false;
};
