import { IdData } from "model/IdData";
import { TeMatch } from "model/TournamentEditor/TeMatch";
import {
  mapTeTournamentSegmentBaseToFsData,
  newTeTournamentSegmentBase,
  TeTournamentSegmentBase,
} from "../TeTournamentSegment";
import { FsQualifierSegment } from "db/model/tournament/Segment/Qualifier/FsQualifier";
import {
  mapTeQualifierGroupsToFsData,
  newTeQualifierGroup,
  TeQualifierGroup,
} from "./TeQualifierGroup";

export type TeQualifierSegment = TeTournamentSegmentBase<TeQualifierGroup> & {
  type: "Qualifier";
};

export const newTeQualfierSegment = (): TeQualifierSegment => {
  return {
    ...newTeTournamentSegmentBase(),
    groups: [newTeQualifierGroup()],
    type: "Qualifier",
  };
};

export const mapTeQualifierSegmentToFsData = (
  teSegment: TeQualifierSegment,
  teMatchesWithIds: IdData<TeMatch>[]
): FsQualifierSegment => {
  return {
    ...mapTeTournamentSegmentBaseToFsData(
      teSegment,
      teMatchesWithIds,
      mapTeQualifierGroupsToFsData
    ),
    type: "Qualifier",
  };
};
