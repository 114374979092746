import { FsRoundTime, newFsRoundTime } from "./FsRoundTime";

export type FsTrackRound = {
  times: FsRoundTime[] | undefined;
};

export const newFsTrackRound = (
  numDrivers: number,
  driverIdsByPosition?: (string | undefined)[]
): FsTrackRound => {
  const times: FsRoundTime[] = [];
  for (var i = 0; i < numDrivers; i++) {
    times.push(newFsRoundTime(driverIdsByPosition?.at(i), i + 1, 0));
  }

  return {
    times,
  };
};
