import { IStore } from "redux/store";
import { selectMatchStartTime, selectTournamentIdFromMatchId } from "./Match";
import { selectTournament } from "./Tournament";

export const selectOrgTimeFromMatchId = (
  state: IStore,
  matchId: string | undefined
) => {
  if (!matchId) {
    return undefined;
  }

  const tournamentId = selectTournamentIdFromMatchId(state, matchId);
  const tournament = selectTournament(state, tournamentId);
  const tournamentEndTime = !!tournament
    ? tournament.data.endTimeMs
    : undefined;
  return tournamentEndTime ?? selectMatchStartTime(state, matchId);
};
