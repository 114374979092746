export const DISCORD_LINK = "https://discord.gg/rcVkdw2CMD";
export const TWITTER_LINK = "https://twitter.com/eCircuitMania";

export const EMAIL_CONTACT = "contact@ecircuitmania.com";

export const TOURNAMENTS_URL = " https://tournaments.ecircuitmania.com/";

const JACOBS = "HO2vz43gBagdHuuoWHDBBoP9mtu1";
const UNANIMOUS = "C5leh1bEi8aDdXjwA9Y5Q970UQm2";
const ecmStaff = [JACOBS, UNANIMOUS];

export const isEcmStaff = (uid: string) => ecmStaff.includes(uid);
