import { useState } from "react";
import { Button } from "styles/elements";
import { FlexRow } from "styles/layout";

interface Props {
  text: string;
}

export const HideableText = ({ text }: Props) => {
  const [textVisible, setTextVisible] = useState(false);

  return (
    <FlexRow>
      <span>{textVisible ? text : "Hidden"}</span>
      <Button onClick={() => setTextVisible(!textVisible)}>
        {textVisible ? "Hide" : "Show"}
      </Button>
    </FlexRow>
  );
};
