import { IStore } from "redux/store";
import { selectIsEcmStaff } from "../User";
import { mapIdDataToOptions } from "./core";
import { selectOrg } from "selectors/Org";
import { selectMultiple } from "selectors/Core";

export const selectOrgOptions = (state: IStore, orgIds: string[]) => {
  return mapIdDataToOptions(
    selectMultiple(state, selectOrg, orgIds),
    (org) => org.data.details?.at(-1)?.name ?? "",
    selectIsEcmStaff(state)
  );
};
