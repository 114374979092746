import { FlexColumn } from "styles/layout";
import { arrayRemoveAt, arrayReplaceAt } from "Utility";
import { AddButton } from "./styles";
import { TeamDriver } from "./TeamDriver";
import { TeDriver } from "model/TournamentEditor/TeDriver";

interface Props {
  drivers: (string | TeDriver | undefined)[];
  updateDrivers: (drivers: (string | TeDriver | undefined)[]) => void;
}

export const TeamDrivers = ({ drivers, updateDrivers }: Props) => {
  const onAddDriverClick = () => {
    updateDrivers([...drivers, undefined]);
  };

  const removeDriver = (driverIdx: number) => {
    updateDrivers(arrayRemoveAt(drivers, driverIdx));
  };

  return (
    <>
      {drivers.map((driver, driverIdx) => (
        <TeamDriver
          driver={driver}
          updateDriver={(driver: string | TeDriver | undefined) =>
            updateDrivers(arrayReplaceAt(drivers, driver, driverIdx))
          }
          removeDriver={() => removeDriver(driverIdx)}
        />
      ))}
      <AddButton onClick={onAddDriverClick}>Add Driver</AddButton>
    </>
  );
};
