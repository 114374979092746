import { SignInWithGoogle } from "./SignInWithGoogle";
import { SignInOptions } from "./styles";

export const LoginOptions = () => {
  return (
    <SignInOptions>
      <SignInWithGoogle />
    </SignInOptions>
  );
};
