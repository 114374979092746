import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsMatchTrack } from "db/model/match/Track/FsMatchTrack";
import { Scores } from "fsModel/Match/Scores";
import { applyKnockoutRoundScore } from "./Round";
import { FsLivesRules } from "db/model/match/Set/Knockout/FsLivesRules";

export const applyKnockoutTrackScore = (
  track: FsMatchTrack,
  teams: FsMatchTeam[],
  runningScores: Scores,
  livesRules: FsLivesRules
) => {
  if (!track.rounds) {
    return;
  }

  for (let i = 0; i < track.rounds.length; i++) {
    const round = track.rounds[i];
    if (!round.times) {
      continue;
    }

    applyKnockoutRoundScore(round.times, teams, runningScores, livesRules);
  }
};

export const isKnockoutTrackComplete = (
  track: FsMatchTrack,
  roundsPerTrack: number
) => {
  if (track.overrideComplete) {
    return true;
  }

  const completeRounds =
    track.rounds?.filter((r) => !!r.times?.length).length ?? 0;
  return completeRounds >= roundsPerTrack;
};
