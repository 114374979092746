import { FsOrg } from "db/model/org/FsOrg";

export type TeOrg = {
  country: string | undefined;
  name: string | undefined;
  trigram: string | undefined;
  primaryColor: string | undefined;
};

export const newTeOrg = (): TeOrg => {
  return {
    country: undefined,
    name: undefined,
    trigram: undefined,
    primaryColor: undefined,
  };
};

export const mapTeOrgToFsData = (org: TeOrg, teamId: string): FsOrg => {
  return {
    altDetails: undefined,
    details: [
      {
        country: org.country,
        endTimeMs: undefined,
        name: org.name,
        primaryColor: org.primaryColor,
        trigram: org.trigram,
      },
    ],
    teamHistory: [teamId],
  };
};
