import { IStore } from "redux/store";
import { getTeamDisplayName, getTeamOrgId } from "fsModel/Team/Team";

export const selectTeam = (state: IStore, teamId: string | undefined) => {
  if (!teamId) {
    return undefined;
  }

  return state.team.teams[teamId];
};

export const selectAllTeams = (state: IStore) => {
  return Object.values(state.team.teams);
};

export const selectTeamOrgId = (
  state: IStore,
  teamId: string | undefined,
  time: number | undefined
) => {
  const team = selectTeam(state, teamId);
  if (!team) {
    return undefined;
  }

  return getTeamOrgId(team.data, time);
};

export const selectTeamOrgDisplayName = (
  state: IStore,
  teamId: string | undefined,
  orgTime: number | undefined
) => {
  const team = selectTeam(state, teamId);
  if (!team) {
    return undefined;
  }

  return getTeamDisplayName(team.data, orgTime);
};
