import { newFsMatchSetBase } from "../FsMatchSet";
import { newFsMatchmakingFormat } from "./FsMatchmakingFormat";
import { FsMatchmakingSet } from "db/model/match/Set/Matchmaking/FsMatchmakingSet";

export const newFsMatchmakingSet = (): FsMatchmakingSet => {
  return {
    ...newFsMatchSetBase(),
    format: newFsMatchmakingFormat(),
    tracks: [],
    type: "Matchmaking",
  };
};
