import { Panel } from "components/Panel/Panel";
import { TabBar } from "components/TabBar/TabBar";
import { FsBracket } from "db/model/tournament/Segment/Bracket/FsBracket";
import { FsTournamentSegment } from "db/model/tournament/Segment/FsTournamentSegment";
import { FsQualifier } from "db/model/tournament/Segment/Qualifier/FsQualifier";
import { FsSeason } from "db/model/tournament/Segment/Season/FsSeason";
import { FsSingle } from "db/model/tournament/Segment/Single/FsSingle";
import { FsSwiss } from "db/model/tournament/Segment/Swiss/FsSwiss";
import { getSegmentGroupByName } from "fsModel/Tournament/Segment/TournamentSegment";
import { Filter } from "model/Filter";
import { GrowColumn } from "pages/styles";
import { useState } from "react";
import { EmptyGrowPanelText } from "styles/text";
import { checkTypeExhausted } from "Utility";
import { BracketTabContent } from "./Group/BracketTabContent";
import { QualifierTabContent } from "./Group/QualifierTabContent";
import { SeasonTabContent } from "./Group/SeasonTabContent";
import { SwissTabContent } from "./Group/SwissTabContent";
import { SingleTabContent } from "./Group/SingleTabContent";

interface Props {
  segment: FsTournamentSegment;
  orgTime: number | undefined;
}

export const TournamentSegmentTabContent = ({ segment, orgTime }: Props) => {
  // todo - move this filter logic into a reusable hook?
  const groupTabOptions = segment.groups?.map((g) => g.name ?? "?") ?? [];
  const [selectedGroupTab, setSelectedSegmentGroupTab] = useState(
    groupTabOptions[0]
  );

  const groupTabFilter: Filter<string> = {
    options: groupTabOptions,
    selectedValue: selectedGroupTab,
    setSelectedValue: setSelectedSegmentGroupTab,
  };

  return (
    <GrowColumn>
      {groupTabOptions.length > 1 && (
        <Panel>
          <TabBar filter={groupTabFilter} isSecondaryTabBar={true} />
        </Panel>
      )}
      <GroupContent
        key={`${segment.name}_${selectedGroupTab}`}
        segment={segment}
        groupName={selectedGroupTab}
        orgTime={orgTime}
      />
    </GrowColumn>
  );
};

interface GroupContentProps {
  segment: FsTournamentSegment;
  groupName: string;
  orgTime: number | undefined;
}

const GroupContent = ({ segment, groupName, orgTime }: GroupContentProps) => {
  switch (segment.type) {
    case "Bracket":
      const bracket: FsBracket | undefined = getSegmentGroupByName(
        segment,
        groupName
      );
      return !!bracket ? (
        <BracketTabContent bracket={bracket} orgTime={orgTime} />
      ) : (
        unableToFindMessage
      );
    case "Qualifier":
      const qualifier: FsQualifier | undefined = getSegmentGroupByName(
        segment,
        groupName
      );
      return !!qualifier ? (
        <QualifierTabContent qualifier={qualifier} orgTime={orgTime} />
      ) : (
        unableToFindMessage
      );
    case "Season":
      const season: FsSeason | undefined = getSegmentGroupByName(
        segment,
        groupName
      );
      return !!season ? (
        <SeasonTabContent season={season} orgTime={orgTime} />
      ) : (
        unableToFindMessage
      );
    case "Single":
      const single: FsSingle | undefined = getSegmentGroupByName(
        segment,
        groupName
      );
      return !!single ? (
        <SingleTabContent single={single} />
      ) : (
        unableToFindMessage
      );
    case "Swiss":
      const swiss: FsSwiss | undefined = getSegmentGroupByName(
        segment,
        groupName
      );
      return !!swiss ? (
        <SwissTabContent swiss={swiss} orgTime={orgTime} />
      ) : (
        unableToFindMessage
      );

    default:
      checkTypeExhausted(segment);
  }

  return <></>;
};

const unableToFindMessage = (
  <GrowColumn>
    <Panel>
      <EmptyGrowPanelText>Unable to find group</EmptyGrowPanelText>
    </Panel>
  </GrowColumn>
);
