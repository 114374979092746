import { IStore } from "redux/store";

export const selectAllTournamentDrafts = (state: IStore) => {
  return Object.values(state.user.tournamentDrafts);
};

export const selectTournamentDraft = (
  state: IStore,
  id: string | undefined
) => {
  if (!id) {
    return undefined;
  }

  return state.user.tournamentDrafts[id];
};
