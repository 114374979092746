import { PayloadAction } from "@reduxjs/toolkit";
import { createFetchSlice, IFetchSlice, initialFetchState } from "./FetchSlice";
import { DocumentChange, DocumentData } from "firebase/firestore";
import { FsOrg } from "db/model/org/FsOrg";
import { Org } from "model/IdData";

export interface IOrgSlice extends IFetchSlice {
  orgs: { [key: string]: Org };
}

const initialState: IOrgSlice = {
  ...initialFetchState,
  orgs: {},
};

// todo - should the FSModel -> Model mapping be done in the slice ??
export const orgSlice = createFetchSlice({
  name: "org",
  initialState,
  reducers: {
    updateOrgs: (
      state,
      action: PayloadAction<{
        docChanges: DocumentChange<DocumentData>[];
      }>
    ) => {
      const { docChanges } = action.payload;
      for (let i = 0; i < docChanges.length; i++) {
        const { doc } = docChanges[i];
        state.orgs[doc.id] = { id: doc.id, data: doc.data() as FsOrg };
      }

      if (!state.initialFetchComplete) {
        state.initialFetchComplete = true;
      }
    },
  },
});

export const { updateOrgs } = orgSlice.actions;
export const orgReducer = orgSlice.reducer;
