import { checkNaN, nonEmpty } from "Utility";
import { ExpandableHeader } from "components/Core/ExpandableHeader/ExpandableHeader";
import { DriverKeys } from "components/MatchEditor/Teams/DriverKeyEditor";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectTrackName } from "selectors/Track";
import { IStore } from "redux/store";
import { FlexRow } from "styles/layout";
import { selectTeamOrgDisplayName } from "selectors/Team";
import { IdDisplay } from "model/IdDisplay";
import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import { FsMatchTrack } from "db/model/match/Track/FsMatchTrack";
import {
  FsTrackRound,
  newFsTrackRound,
} from "db/model/match/Track/FsTrackRound";
import { FsRoundTime } from "db/model/match/Track/FsRoundTime";

interface Props {
  track: FsMatchTrack;
  trackOptions: IdDisplay[];
  teamIds: string[];
  update: (track: FsMatchTrack) => void;
  remove: () => void;
  driverKeys: DriverKeys;
  driverIds: string[];
}

// todo - old code, rework
export const SetTrack = ({
  track,
  trackOptions,
  teamIds,
  update,
  remove,
  driverKeys,
  driverIds,
}: Props) => {
  const [driverKeyOrder, setDriverKeyOrder] = useState<string>("");
  const drivers = useSelector((state: IStore) => state.driver.drivers);
  const trackName = useSelector((state: IStore) =>
    selectTrackName(state, track.trackId)
  );

  const addRound = () => {
    update({
      ...track,
      rounds: [...(track.rounds ?? []), newFsTrackRound(4)],
    });
  };

  const addTime = (round: FsTrackRound) => {
    if (!round.times) {
      round.times = [];
    }
    round.times!.push({
      driverId: undefined,
      finishTimeMs: 0,
      position: round.times!.length + 1,
    });
    update({ ...track });
  };

  const removeTime = (round: FsTrackRound) => {
    if (!track.rounds || !round.times) {
      return;
    }

    const newRoundTimes = round.times.slice(0, -1);
    const roundIdx = track.rounds.indexOf(round);
    track.rounds[roundIdx].times = newRoundTimes;
    update({ ...track });
  };

  const addRoundFromKeys = () => {
    if (driverKeyOrder.length === 0) {
      return;
    }

    const newRound: FsTrackRound = { times: [] };
    for (var i = 0; i < driverKeyOrder.length; i++) {
      const driverId = Object.keys(driverKeys).find(
        (k) => driverKeys[k] === driverKeyOrder[i]
      );
      newRound.times!.push({
        driverId: driverId ?? undefined,
        finishTimeMs: 0,
        position: i + 1,
      });
    }

    const newRounds = [...(track.rounds ?? []), newRound];
    update({ ...track, rounds: newRounds });
  };

  const removeRound = (round: FsTrackRound) => {
    const newRounds = track.rounds?.filter((r) => r !== round);
    update({ ...track, rounds: newRounds });
  };

  const changeRoundTimeDriverId = (
    driver: FsRoundTime,
    newDriverId: string | undefined
  ) => {
    driver.driverId = newDriverId;
    update({ ...track });
  };

  const changeRoundTimeFinishTimeMs = (
    driver: FsRoundTime,
    newFinishTimeMs: number
  ) => {
    if (isNaN(newFinishTimeMs)) {
      return;
    } else {
      driver.finishTimeMs = newFinishTimeMs;
    }

    update({ ...track });
  };

  return (
    <ExpandableHeader header={trackName ?? ""} initiallyExpanded={false}>
      <div style={{ margin: "5px 0 0 10px" }}>
        <LabelledField label="Track">
          <select
            value={track.trackId}
            onChange={(e) => {
              const newValue = !!e.target.value ? e.target.value : undefined;
              update({ ...track, trackId: newValue });
            }}
          >
            <option value="">-</option>
            {trackOptions.map(({ id, display }) => (
              <option value={id}>{display}</option>
            ))}
          </select>
        </LabelledField>
        <table style={{ marginTop: "10px" }}>
          {track.rounds?.map((round) => (
            <>
              <tr>
                {round.times
                  ?.sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
                  .map((roundTime) => (
                    <>
                      <select
                        style={{ width: "110px", margin: "0 4px" }}
                        value={roundTime.driverId}
                        onChange={(e) => {
                          changeRoundTimeDriverId(
                            roundTime,
                            nonEmpty(e.target.value)
                          );
                        }}
                      >
                        <option value={""}>---</option>
                        {driverIds.map((driverId) => (
                          <option key={driverId} value={driverId}>
                            {drivers[driverId]?.data.nickname} ({driverId})
                          </option>
                        ))}
                      </select>
                    </>
                  ))}
                <td>
                  <FlexRow>
                    <button
                      style={{ fontSize: "7pt" }}
                      onClick={() => addTime(round)}
                    >
                      add
                    </button>
                    <button
                      style={{ fontSize: "7pt" }}
                      onClick={() => removeTime(round)}
                    >
                      remove last
                    </button>
                  </FlexRow>
                </td>
              </tr>
              <tr>
                {round.times
                  ?.sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
                  .map((roundTime) => (
                    <input
                      style={{ width: "102px", margin: "0 4px" }}
                      type="text"
                      placeholder={roundTime.finishTimeMs?.toString()}
                      onChange={(e) =>
                        changeRoundTimeFinishTimeMs(
                          roundTime,
                          parseInt(e.target.value)
                        )
                      }
                      onBlur={(e) => {
                        if (e.target.value[0] === "/") {
                          const value = parseInt(e.target.value.substring(1));
                          e.target.value = "";
                          if (isNaN(value)) {
                            return;
                          }

                          changeRoundTimeFinishTimeMs(roundTime, value);
                          return;
                        }

                        const value = parseInt(e.target.value);
                        if (isNaN(value)) {
                          e.target.value = "";
                          return;
                        }

                        var finishTimeMs = value;
                        // if less than 0, is a DNF, aka -1
                        if (value < 0) {
                          finishTimeMs = -1;
                        }
                        // else if not 1st add time as entered to first places recorded time
                        else if ((roundTime.position ?? 0) > 1) {
                          const firstPlaceTime =
                            round.times?.find((pd) => pd?.position === 1)
                              ?.finishTimeMs ?? 0;
                          finishTimeMs += firstPlaceTime;
                        }

                        changeRoundTimeFinishTimeMs(roundTime, finishTimeMs);
                        e.target.value = "";
                      }}
                    />
                  ))}
              </tr>
              <button
                style={{ fontSize: "7pt", margin: "0 0 15px 5px" }}
                onClick={(e) => removeRound(round)}
              >
                Remove
              </button>
            </>
          ))}
        </table>
        <FlexRow>
          <button
            style={{ fontSize: "7pt", width: "150px" }}
            onClick={addRound}
          >
            Add Round
          </button>
          <button
            style={{ fontSize: "6pt", width: "80px", marginLeft: "auto" }}
            onClick={remove}
          >
            Remove Track
          </button>
        </FlexRow>
        <br />
        <FlexRow>
          <span style={{ fontSize: "10pt" }}>Driver Key Order</span>
          <input
            style={{ fontSize: "10pt", width: "56px", margin: "0 6px" }}
            type="text"
            maxLength={driverIds.length}
            value={driverKeyOrder}
            onChange={(e) => setDriverKeyOrder(e.target.value.toUpperCase())}
          />
          <button onClick={addRoundFromKeys}>Add From Keys</button>
        </FlexRow>

        <br />
        <span>Track Score Overrides</span>
        <FlexRow style={{ columnGap: "5px" }}>
          {teamIds.map((teamId) => (
            <>
              <TeamDisplayName teamId={teamId} />
              <input
                value={
                  !!track.scoreOverrides ? track.scoreOverrides[teamId] : ""
                }
                type="number"
                style={{ width: "30px" }}
                onChange={(e) => {
                  const newValue = checkNaN(e.target.valueAsNumber);
                  const newScoreOverrides = { ...(track.scoreOverrides ?? {}) };
                  if (newValue === undefined) {
                    delete newScoreOverrides[teamId];
                  } else {
                    newScoreOverrides[teamId] = newValue;
                  }

                  update({
                    ...track,
                    scoreOverrides:
                      Object.keys(newScoreOverrides).length > 0
                        ? newScoreOverrides
                        : undefined,
                  });
                }}
              />
            </>
          ))}
        </FlexRow>
        <br />
      </div>
    </ExpandableHeader>
  );
};

export const TeamDisplayName = ({ teamId }: { teamId: string | undefined }) => {
  const displayName = useSelector((state: IStore) =>
    selectTeamOrgDisplayName(state, teamId, undefined)
  );

  return <span>{displayName}</span>;
};
