import { TeTeam } from "./TeTeam";
import {
  newTeTournamentSegment,
  TeTournamentSegment,
} from "./Segment/TeTournamentSegment";
import { checkTypeExhausted, nonUndefined } from "Utility";
import { FsTournamentSegment } from "db/model/tournament/Segment/FsTournamentSegment";
import { Team } from "model/IdData";
import { FsTournament } from "db/model/tournament/FsTournament";
import { TeTrack } from "./TeTrack";
import { TeMatchFormat } from "./TeMatchFormat";
import { mapFsBracketToTeBracketGroup } from "./Segment/Bracket/TeBracketGroup";
import { mapFsQualifierToTeQualifierGroup } from "./Segment/Qualifier/TeQualifierGroup";
import { mapFsRoundsGroupToTeRoundsGroup } from "./Segment/Rounds/TeRoundsGroup";
import { mapFsSingleToTeSingleGroup } from "./Segment/Single/TeSingleGroup";
import { isFsSingleDriverTeam } from "db/model/team/FsTeam";

export type TeTournament = {
  id: string | undefined;
  description: string | undefined;
  filterName: string | undefined;
  name: string | undefined;
  segments: TeTournamentSegment[];
  teams: TeTeam[];
  trackIds: (string | undefined)[];
  trigram: string | undefined;
  imageIdOverride: string | undefined;

  newTracks: TeTrack[];
  matchFormats: TeMatchFormat[];
};

export const newTeTournament = (): TeTournament => {
  return {
    id: undefined,
    description: undefined,
    filterName: undefined,
    name: undefined,
    segments: [newTeTournamentSegment("Bracket")],
    teams: [],
    trackIds: [],
    trigram: undefined,
    imageIdOverride: undefined,
    newTracks: [],
    matchFormats: [],
  };
};

export const mapTournamentToTeTournament = (
  tournamentId: string,
  tournament: FsTournament,
  teams: Team[]
): TeTournament => {
  return {
    id: tournamentId,
    description: tournament.description,
    filterName: tournament.filterName,
    name: tournament.name,
    trigram: tournament.trigram,
    imageIdOverride: tournament.imageIdOverride,
    trackIds: tournament.trackIds ?? [],

    segments: mapFsSegmentsToTeSegments(tournament.segments ?? []),
    teams: mapTeamsToTeTeams(tournament.teamIds ?? [], teams),

    newTracks: [],
    matchFormats: [],
  };
};

const mapFsSegmentsToTeSegments = (segments: FsTournamentSegment[]) => {
  return segments.map(mapFsSegmentToTeSegment);
};

const mapFsSegmentToTeSegment = (
  segment: FsTournamentSegment
): TeTournamentSegment => {
  const base = {
    name: segment.name,
    segmentId: segment.segmentId,
  };

  switch (segment.type) {
    case "Bracket":
      return {
        ...base,
        groups: segment.groups?.map(mapFsBracketToTeBracketGroup) ?? [],
        type: "Bracket",
      };
    case "Qualifier":
      return {
        ...base,
        groups: segment.groups?.map(mapFsQualifierToTeQualifierGroup) ?? [],
        type: "Qualifier",
      };
    case "Season":
      return {
        ...base,
        groups: segment.groups?.map(mapFsRoundsGroupToTeRoundsGroup) ?? [],
        type: "Season",
      };
    case "Single":
      return {
        ...base,
        groups: segment.groups?.map(mapFsSingleToTeSingleGroup) ?? [],
        type: "Single",
      };
    case "Swiss":
      return {
        ...base,
        groups: segment.groups?.map(mapFsRoundsGroupToTeRoundsGroup) ?? [],
        type: "Swiss",
      };
    default:
      return checkTypeExhausted(segment);
  }
};

const mapTeamsToTeTeams = (teamIds: string[], teams: Team[]): TeTeam[] => {
  return nonUndefined(
    teamIds.map((teamId) => {
      const team = teams.find((t) => t.id === teamId);
      if (!team) {
        return undefined;
      }
      return mapTeamToTeTeam(team);
    })
  );
};

const mapTeamToTeTeam = (team: Team): TeTeam => {
  return {
    existingTeamId: team.id,
    org: team.data.orgId,
    drivers: isFsSingleDriverTeam(team.data)
      ? [team.data.driverId]
      : team.data.drivers?.map((d) => d.id) ?? [],
  };
};
