import { CreateDraftPage } from "./CreateDraft/CreateDraftPage";
import { DriversPage } from "./Drivers/DriversPage";
import { EditMatchPage } from "./EditMatch/EditMatchPage";
import { EditDraftPage } from "./EditDraft/EditDraftPage";
import { HomePage } from "./Home/HomePage";
import { LoginPage } from "./Login/LoginPage";
import { ManageTournamentPage } from "./ManageTournament/ManageTournamentPage";
import { OrgsPage } from "./Orgs/OrgsPage";
import { PrivacyPage } from "./Privacy/PrivacyPage";
import { TermsOfServicePage } from "./TermsOfService/TermsOfServicePage";
import { TournamentsPage } from "./Tournaments/TournamentsPage";
import { TracksPage } from "./Tracks/TracksPage";
import { EditTournamentPage } from "./EditTournament/EditTournamentPage";
import { ManualPage } from "./Manual/ManualPage";
import { PickemPage } from "./Pickem/PickemPage";

export enum PageRestriction {
  NOT_LOGGED_IN,
  LOGGED_IN,
  STAFF,
}

export type PageConfig = {
  path: string;
  page: JSX.Element;
  showNavBarAndFooter?: boolean;
  restriction?: PageRestriction;
};

export const createDraftPath = "/create-draft";
export const driversPath = "/drivers";
export const editMatchPath = `/edit-match`;
export const editMatchPathWithParams = `${editMatchPath}/:matchId`;
export const editDraftPath = `/edit-draft`;
export const editDraftPathWithParams = `${editDraftPath}/:draftId`;
export const editTournamentPath = `/edit-tournament`;
export const editTournamentPathWithParams = `${editTournamentPath}/:tournamentId`;
export const homePath = "/";
export const loginPath = "/login";
export const manageTournamentPath = "/manage-tournament";
export const manageTournamentPathWithParams = `${manageTournamentPath}/:tournamentId`;
export const manualPath = "/manual";
export const orgsPath = "/orgs";
export const pickemPath = "/pickem";
export const privacyPath = "/privacy";
export const termsOfServicePath = "/terms-of-service";
export const tournamentsPath = "/tournaments";
export const tracksPath = "/tracks";

export const buildEditMatchPath = (matchId: string) => {
  return `${editMatchPath}/${matchId}`;
};

export const buildEditDraftPath = (draftId: string) => {
  return `${editDraftPath}/${draftId}`;
};

export const buildEditTournamentPath = (tournamentId: string) => {
  return `${editTournamentPath}/${tournamentId}`;
};

export const buildManageTournamentPath = (tournamentId: string) => {
  return `${manageTournamentPath}/${tournamentId}`;
};

export const PAGE_CONFIGS: PageConfig[] = [
  {
    path: createDraftPath,
    page: <CreateDraftPage />,
    showNavBarAndFooter: true,
    restriction: PageRestriction.LOGGED_IN,
  },
  {
    path: driversPath,
    page: <DriversPage />,
    showNavBarAndFooter: true,
    restriction: PageRestriction.STAFF,
  },
  {
    path: editDraftPathWithParams,
    page: <EditDraftPage />,
    showNavBarAndFooter: true,
    restriction: PageRestriction.LOGGED_IN,
  },
  {
    path: editMatchPathWithParams,
    page: <EditMatchPage />,
    showNavBarAndFooter: true,
    restriction: PageRestriction.LOGGED_IN,
  },
  {
    path: editTournamentPathWithParams,
    page: <EditTournamentPage />,
    showNavBarAndFooter: true,
    restriction: PageRestriction.LOGGED_IN,
  },
  {
    path: manageTournamentPathWithParams,
    page: <ManageTournamentPage />,
    showNavBarAndFooter: true,
    restriction: PageRestriction.LOGGED_IN,
  },
  {
    path: homePath,
    page: <HomePage />,
    showNavBarAndFooter: true,
    restriction: PageRestriction.LOGGED_IN,
  },
  {
    path: loginPath,
    page: <LoginPage />,
    restriction: PageRestriction.NOT_LOGGED_IN,
  },
  {
    path: manualPath,
    showNavBarAndFooter: true,
    page: <ManualPage />,
  },
  {
    path: orgsPath,
    page: <OrgsPage />,
    showNavBarAndFooter: true,
    restriction: PageRestriction.STAFF,
  },
  {
    path: pickemPath,
    page: <PickemPage />,
    showNavBarAndFooter: true,
    restriction: PageRestriction.STAFF,
  },
  {
    path: privacyPath,
    showNavBarAndFooter: true,
    page: <PrivacyPage />,
  },
  {
    path: termsOfServicePath,
    showNavBarAndFooter: true,
    page: <TermsOfServicePage />,
  },
  {
    path: tournamentsPath,
    page: <TournamentsPage />,
    showNavBarAndFooter: true,
    restriction: PageRestriction.LOGGED_IN,
  },
  {
    path: tracksPath,
    page: <TracksPage />,
    showNavBarAndFooter: true,
    restriction: PageRestriction.STAFF,
  },
];
