import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsRoundTime } from "db/model/match/Track/FsRoundTime";
import { FsWorldTourScoring } from "db/model/match/Set/WorldTour/FsWorldTourScoring";
import { isDnf } from "fsModel/Match/DriverTime";
import { nonUndefined } from "Utility";

export type WorldTourRoundResult = {
  points: number;
  teamId: string;
};

export const getWorldTourRoundResults = (
  scoring: FsWorldTourScoring,
  times: FsRoundTime[],
  teams: FsMatchTeam[]
) => {
  const roundPoints = getWorldTourRoundPoints(scoring, times, teams);
  const results: WorldTourRoundResult[] = [];
  Object.keys(roundPoints).forEach((teamId) =>
    results.push({ teamId, points: roundPoints[teamId] })
  );

  return results;
};

export const getWorldTourRoundPoints = (
  scoring: FsWorldTourScoring,
  times: FsRoundTime[],
  teams: FsMatchTeam[]
) => {
  const teamPositions: { [teamId: string]: number[] } = {};
  for (var i = 0; i < teams.length; i++) {
    const { teamId, driverIds } = teams[i];
    if (!teamId || !driverIds) {
      continue;
    }

    const teamTimes = times.filter(
      (t) => !!t.driverId && driverIds.includes(t.driverId)
    );
    const nonDnfPositions = nonUndefined(
      teamTimes.filter((t) => !isDnf(t.finishTimeMs)).map((t) => t.position)
    );

    teamPositions[teamId] = nonDnfPositions.sort((a, b) => a - b);
  }

  const sortedTeamIds = getSortedTeamIdsByBestPosition(teamPositions);
  const scoringKey = getWorldTourScoringKey(
    sortedTeamIds.map((teamId) => teamPositions[teamId])
  );

  const points = scoring[scoringKey];
  const teamPoints: { [teamId: string]: number } = {};
  sortedTeamIds.forEach(
    (teamId, idx) => (teamPoints[teamId] = points?.at(idx) ?? 0)
  );

  return teamPoints;
};

const getWorldTourScoringKey = (sortedPositions: number[][]) => {
  return sortedPositions.map((p) => p.join(",")).join("-");
};

const getSortedTeamIdsByBestPosition = (teamPositions: {
  [teamId: string]: number[];
}) => {
  return Object.keys(teamPositions).sort((a, b) => {
    const aBestPos = teamPositions[a].at(0);
    const bBestPos = teamPositions[b].at(0);
    if (!aBestPos && !bBestPos) {
      return 0;
    }

    if (!aBestPos) {
      return 1;
    }

    if (!bBestPos) {
      return -1;
    }

    return aBestPos < bBestPos ? -1 : 1;
  });
};
