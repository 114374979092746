import { FsTeamDriver } from "./FsTeamDriver";
import { FsOrgHistory } from "./FsOrgHistory";

export type FsTeam = FsMultiDriverTeam | FsSingleDriverTeam;

export type FsTeamBase = {
  orgHistory: FsOrgHistory[] | undefined;
  orgId: string | undefined;
  orgName: string | undefined;
  orgTrigram: string | undefined;
};

export type FsMultiDriverTeam = FsTeamBase & {
  drivers: FsTeamDriver[] | undefined;
};

export type FsSingleDriverTeam = FsTeamBase & {
  driverId: string | undefined;
  driverNickname: string | undefined;
};

export const isFsSingleDriverTeam = (
  team: FsTeam
): team is FsSingleDriverTeam => {
  return (team as FsSingleDriverTeam).driverId !== undefined;
};
