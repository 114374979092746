import { format } from "date-fns";
import { MatchHour } from "./styles";

interface Props {
  timeMs: number;
}

export const MatchTime = ({ timeMs }: Props) => {
  const date = new Date(timeMs);
  const hour = format(date, "HH:mm");

  return <MatchHour>{hour}</MatchHour>;
};
