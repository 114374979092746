import styled from "styled-components";
import { FlexColumn } from "styles/layout";

export const Manual = styled(FlexColumn)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0;
  }

  p {
    margin: 0 0 8px 0;
  }

  ul {
    margin: 0;
  }
`;
