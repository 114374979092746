import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
`;

export const MaxWidth = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0;
`;

export const CenterFlexDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CenterFlexColumn = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CenterFlexRow = styled(FlexRow)`
  justify-content: center;
`;
