import { FsMatch } from "db/model/match/FsMatch";
import { MatchTeamsList } from "./List/MatchTeamsList";
import { getNumTeams } from "fsModel/Match/Match";

interface Props {
  match: FsMatch;
  orgTime: number;
}

export const MatchTeams = ({ match, orgTime }: Props) => {
  if (getNumTeams(match) <= 10) {
    return <MatchTeamsList match={match} orgTime={orgTime} />;
  }

  return <></>;
};
