import { Flag } from "model/Flag";
import { checkNaN } from "Utility";
import { InputFieldArgs } from "./Core";
import { StyledInput } from "./styles";

type NumberFieldArgs = InputFieldArgs & {
  allowNegative?: Flag;
};

interface Props {
  value: number | undefined;
  update: (newVal: number | undefined) => void;
  args?: NumberFieldArgs;
}

export const NumberField = ({ value, update, args }: Props) => {
  return (
    <StyledInput
      type="number"
      disabled={args?.disabled}
      placeholder={args?.placeholder}
      value={value}
      min={args?.allowNegative ? undefined : 0}
      onChange={(e) => {
        const nonNaN = checkNaN(e.target.valueAsNumber);
        const negativeAdjusted =
          !args?.allowNegative && !!nonNaN && nonNaN < 0 ? undefined : nonNaN;
        update(negativeAdjusted);
      }}
      style={{ width: args?.width ? args.width : "40px" }}
    />
  );
};
