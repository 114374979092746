export type Scores = { [teamId: string]: number };

export const hasScore = (scores: Scores | undefined) => {
  if (!scores) {
    return false;
  }

  return Object.values(scores).some((s) => s > 0);
};

export const applyScoreOverrides = (
  scores: Scores,
  scoreOverrides: Scores | undefined
) => {
  if (!scoreOverrides) {
    return scores;
  }

  Object.keys(scoreOverrides).forEach((teamId) => {
    const scoreOverride = scoreOverrides[teamId];
    if (scoreOverride !== undefined) {
      scores[teamId] = scoreOverride;
    }
  });

  return scores;
};
