import { TournamentDraftEditor } from "components/TournamentEditor/TournamentDraftEditor";
import { PageContent, ColumnContainer, GrowColumn } from "pages/styles";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectTournamentDraft } from "selectors/TournamentDraft";
import { IStore } from "redux/store";
import { MaxWidth } from "styles/layout";
import { EmptyGrowPanelText, ErrorText } from "styles/text";

export const EditDraftPage = () => {
  const { draftId } = useParams();
  const draft = useSelector((state: IStore) =>
    selectTournamentDraft(state, draftId)
  );

  return (
    <PageContent>
      <MaxWidth>
        <ColumnContainer>
          <GrowColumn>
            {!!draft ? (
              <TournamentDraftEditor
                existing={draft.data}
                existingId={draft.id}
              />
            ) : (
              <EmptyGrowPanelText>
                <ErrorText>Draft could not be found</ErrorText>
              </EmptyGrowPanelText>
            )}
          </GrowColumn>
        </ColumnContainer>
      </MaxWidth>
    </PageContent>
  );
};
