import { newFsCupModeFormat } from "../FsCupModeFormat";
import { FsReverseCupModeFormat } from "db/model/match/Set/CupMode/Reverse/FsReverseCupModeFormat";

export const newFsReverseCupModeFormat = (): FsReverseCupModeFormat => {
  return {
    ...newFsCupModeFormat(),
    lastChance: true,
    startingPoints: undefined,
  };
};
