export type FsRoundTime = {
  driverId: string | undefined;
  finishTimeMs: number | undefined;
  position: number | undefined;
};

export const newFsRoundTime = (
  driverId?: string,
  position?: number,
  finishTimeMs?: number
): FsRoundTime => {
  return {
    driverId,
    position,
    finishTimeMs,
  };
};
