import { arrayRemoveAt, arrayReplaceAt } from "Utility";
import {
  AddButton,
  TracksContainer,
  TrackContainer,
  RemoveButton,
} from "./styles";
import { useSelector } from "react-redux";
import { TeTournament } from "model/TournamentEditor/TeTournament";
import { useState } from "react";
import { DataModal } from "components/Modal/DataModal";
import { selectTrackOptions } from "selectors/options/Track";
import { selectAllTrackIds } from "selectors/Track";
import { IStore } from "redux/store";
import { TeTrackForm } from "components/AddEditForms/TrackForm/TeTrackForm";
import { TeTrack, newTeTrack } from "model/TournamentEditor/TeTrack";
import { StyledSelect } from "components/Fields/styles";

interface Props {
  tournament: TeTournament;
  update: () => void;
}

export const Tracks = ({ tournament, update }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTarget, setModalTarget] = useState<TeTrack | undefined>();

  const onAddTrackClick = () => {
    tournament.trackIds = [...tournament.trackIds, undefined];
    update();
  };

  const onAddNewTrackClick = () => {
    setModalTarget(newTeTrack());
    setModalVisible(true);
  };

  const onAddNewTrackSave = async () => {
    if (!!modalTarget) {
      tournament.newTracks = [...tournament.newTracks, modalTarget];
      update();
      setModalVisible(false);
      setModalTarget(undefined);
    }
  };

  const onAddNewTrackCancel = () => {
    setModalVisible(false);
    setModalTarget(undefined);
  };

  const trackIds = useSelector(selectAllTrackIds);
  const trackOptions = useSelector((state: IStore) =>
    selectTrackOptions(state, trackIds)
  );

  return (
    <TracksContainer>
      {tournament.newTracks.map((track, idx) => (
        <TrackContainer key={`new${idx} - ${track.name}`}>
          <span>{track.name}</span>
          <RemoveButton
            onClick={() => {
              tournament.newTracks = arrayRemoveAt(tournament.newTracks, idx);
              update();
            }}
          >
            -
          </RemoveButton>
        </TrackContainer>
      ))}
      {tournament.trackIds.map((trackId, idx) => (
        <TrackContainer key={`${idx} ${trackId}`}>
          <StyledSelect
            value={trackId}
            onChange={(e) => {
              tournament.trackIds = arrayReplaceAt(
                tournament.trackIds,
                e.target.value,
                idx
              );
              update();
            }}
          >
            <option key="" value="">
              -
            </option>
            {trackOptions.map((t) => (
              <option key={t.id} value={t.id}>
                {t.display}
              </option>
            ))}
          </StyledSelect>
          <RemoveButton
            onClick={() => {
              tournament.trackIds = arrayRemoveAt(tournament.trackIds, idx);
              update();
            }}
          >
            -
          </RemoveButton>
        </TrackContainer>
      ))}
      <AddButton onClick={onAddTrackClick}>+</AddButton>
      <AddButton onClick={onAddNewTrackClick}>New</AddButton>
      <DataModal
        visible={modalVisible}
        onSave={onAddNewTrackSave}
        onCancel={onAddNewTrackCancel}
      >
        {modalTarget && (
          <TeTrackForm data={modalTarget} setData={setModalTarget} />
        )}
      </DataModal>
    </TracksContainer>
  );
};
