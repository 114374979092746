import { TournamentAdmin } from "model/TournamentAdmin/TournamentAdmin";

export type FsTournamentAdmin = {
  adminUids: string[] | undefined;
};

export const mapFsTournamentAdmin = (
  fsModel: FsTournamentAdmin
): TournamentAdmin | undefined => {
  return {
    adminUids: fsModel.adminUids ?? [],
  };
};
