import { FsTrackPick } from "../Track/FsTrackPick";
import { FsWorldTourSet } from "./WorldTour/FsWorldTourSet";
import { FsForwardCupModeSet } from "./CupMode/Forward/FsForwardCupModeSet";
import { FsReverseCupModeSet } from "./CupMode/Reverse/FsReverseCupModeSet";
import { FsForwardCupModeLongSet } from "./CupModeLong/Forward/FsForwardCupModeLongSet";
import { FsRoundsSet } from "./Rounds/FsRoundsSet";
import { FsTimeAttackSet } from "./TimeAttack/FsTimeAttackSet";
import { FsMatchmakingSet } from "./Matchmaking/FsMatchmakingSet";
import { FsSetPointRules } from "db/model/match/Set/FsSetPointRules";
import { FsKnockoutSet } from "./Knockout/FsKnockoutSet";
import { Scores } from "fsModel/Match/Scores";

export type FsMatchSetBase = {
  isTiebreaker: boolean | undefined;
  overrideComplete: boolean | undefined;
  picks: FsTrackPick[] | undefined;
  points: FsSetPointRules | undefined;
  scoreOverrides: Scores | undefined;
};

export type FsMatchSet =
  | FsForwardCupModeSet
  | FsForwardCupModeLongSet
  | FsKnockoutSet
  | FsMatchmakingSet
  | FsReverseCupModeSet
  | FsRoundsSet
  | FsTimeAttackSet
  | FsWorldTourSet;

export type FsMatchSetType =
  | "ForwardCupMode"
  | "ForwardCupModeLong"
  | "Knockout"
  | "Matchmaking"
  | "ReverseCupMode"
  | "Rounds"
  | "TimeAttack"
  | "WorldTour";

export const matchSetTypes = [
  "ForwardCupMode",
  "ForwardCupModeLong",
  "Matchmaking",
  "ReverseCupMode",
  "Rounds",
  "TimeAttack",
  "WorldTour",
];

export const isMatchSetType = (
  value: string | undefined
): value is FsMatchSetType => {
  return !!value && matchSetTypes.includes(value);
};
