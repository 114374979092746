import { arrayRemoveAt, arrayReplaceAt } from "Utility";
import { IdDisplay } from "model/IdDisplay";
import { Button } from "styles/elements";
import { SetPick } from "./SetPick";
import { FlexColumn } from "styles/layout";
import { FsTrackPick } from "db/model/match/Track/FsTrackPick";

interface Props {
  picks: FsTrackPick[] | undefined;
  update: (picks: FsTrackPick[]) => void;
  teamOptions: IdDisplay[];
  trackOptions: IdDisplay[];
}

const NEW_PICK_TYPE = "Pick";
export const SetPicks = ({
  picks,
  update,
  teamOptions,
  trackOptions,
}: Props) => {
  return (
    <FlexColumn style={{ rowGap: "5px" }}>
      {picks?.map((pick, pickIdx) => (
        <SetPick
          pick={pick}
          update={(pick) => {
            const newPicks = arrayReplaceAt(picks, pick, pickIdx);
            update(newPicks);
          }}
          remove={() => {
            const newPicks = arrayRemoveAt(picks, pickIdx);
            update(newPicks);
          }}
          teamOptions={teamOptions}
          trackOptions={trackOptions}
        />
      ))}
      <Button
        style={{ width: "120px", fontSize: "9pt", margin: "5px 0 0 10px" }}
        onClick={() => {
          const newPicks = [
            ...(picks ?? []),
            {
              pickingTeamId: undefined,
              pickType: NEW_PICK_TYPE,
              trackId: undefined,
            },
          ];
          update(newPicks);
        }}
      >
        Add Pick
      </Button>
    </FlexColumn>
  );
};
