import {
  FsTimeAttackScoringBase,
  FsTimeAttackScoring,
} from "./FsTimeAttackScoring";

export type FsTimeAttackScoringSum = FsTimeAttackScoringBase & {};

export const isFsTimeAttackScoringSum = (
  scoring: FsTimeAttackScoring
): scoring is FsTimeAttackScoringSum => {
  return scoring.type === "Sum";
};
