import { FsPointsDistribution } from "../FsPointsDistribution";

export type FsStaticCupModePoints = FsPointsDistribution;
export type FsDynamicCupModePoints = {
  [numLeft: string]: FsPointsDistribution | undefined;
};
export type FsCupModePoints = FsStaticCupModePoints | FsDynamicCupModePoints;

export const isFsStaticCupModePoints = (
  points: FsCupModePoints
): points is FsStaticCupModePoints => {
  return (points as FsStaticCupModePoints).distribution !== undefined;
};
