import { db } from "firebase-config";
import { setDoc, doc, getDoc, onSnapshot } from "firebase/firestore";
import { store } from "redux/store";
import { setUser } from "redux/UserSlice";
import { Unsubscribe } from "firebase/auth";
import { userCollectionName, usernameCollectionName } from "./collectionNames";
import { fetchAllTournamentAdmins } from "database/tournamentAdmin/tournamentAdminInfo";
import { fetchAllTournamentDrafts } from "database/tournamentDraft/tournamentDraftInfo";
import {
  fetchAllTournamentOrganizations,
  fetchTournamentOrganizations,
} from "database/tournamentOrganization/tournamentOrganizationInfo";
import { isEcmStaff } from "eCM";
import { fetchAllTournaments } from "database/tournament/tournamentInfo";

// todo - extract these elsewhere?
export const MIN_USERNAME_LENGTH = 3;
export const MAX_USERNAME_LENGTH = 15;
export const VALID_FIRST_USERNAME_CHAR = /^[a-zA-Z]$/;
export const VALID_USERNAME_CHARS = /^[a-zA-Z0-9_\\. -]+$/;

export const usernameSubscriptions: { [key: string]: Function } = {};
export var userSubscription: Unsubscribe | undefined;

export const saveUsernameToDb = async (id: string, username: string) => {
  const docRef = doc(db, userCollectionName, id);
  await setDoc(docRef, { username: username });

  const usernameDocRef = doc(
    db,
    usernameCollectionName,
    username.toLowerCase()
  );
  await setDoc(usernameDocRef, {});
};

export const setUserInfoFromDb = async (uid: string) => {
  const unsubscribe = onSnapshot(
    doc(db, userCollectionName, uid),
    (docSnapshot) => {
      const data = docSnapshot.data();
      if (!!data?.username) {
        store.dispatch(
          setUser({
            uid,
            username: data.username,
            adminTournamentIds: data.adminTournamentIds ?? [],
          })
        );

        if (!isEcmStaff(uid)) {
          // tournaments and drafts fetched as organizations read-in for non staff
          fetchTournamentOrganizations(data.tournamentOrganizations ?? []);
        }
      }
    }
  );

  if (isEcmStaff(uid)) {
    fetchAllTournamentOrganizations();
    fetchAllTournamentAdmins();
    fetchAllTournamentDrafts();
    fetchAllTournaments();
  }

  userSubscription = unsubscribe;
  const docRef = doc(db, userCollectionName, uid);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() && docSnap.data().username !== undefined;
};

export const isUsernameTaken = async (username: string) => {
  const lowerUsername = username.toLowerCase();
  const docRef = doc(db, usernameCollectionName, lowerUsername);
  const docSnap = await getDoc(docRef);
  return docSnap.exists();
};

export const isValidUsername = (
  username: string
): { isValid: boolean; errorMessage: string } => {
  if (username.length < MIN_USERNAME_LENGTH) {
    return { isValid: false, errorMessage: "Username Too Short" };
  }

  if (username.length > MAX_USERNAME_LENGTH) {
    return { isValid: false, errorMessage: "Username Too Long" };
  }

  if (!VALID_USERNAME_CHARS.test(username)) {
    return {
      isValid: false,
      errorMessage: "Username Contains An Invalid Character",
    };
  }

  if (!VALID_FIRST_USERNAME_CHAR.test(username[0])) {
    return {
      isValid: false,
      errorMessage: "Username Must Start With A Letter",
    };
  }

  // todo - check characters
  return { isValid: true, errorMessage: "" };
};
