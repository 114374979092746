import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { Scores } from "fsModel/Match/Scores";
import { FsRoundTime } from "db/model/match/Track/FsRoundTime";
import { FsLivesRules } from "db/model/match/Set/Knockout/FsLivesRules";
import { getLivesLost } from "./Lives";

export const applyKnockoutRoundScore = (
  times: FsRoundTime[],
  teams: FsMatchTeam[],
  runningLives: Scores,
  livesRules: FsLivesRules
) => {
  const numDriversLeft = Object.keys(runningLives).filter(
    (l) => runningLives[l] > 0
  ).length;

  for (let i = 0; i < teams.length; i++) {
    const { teamId, driverIds } = teams[i];
    if (!teamId || !driverIds) {
      continue;
    }

    const livesAtStart = runningLives[teamId];
    const teamTimes = times.filter(
      (t) => !!t.driverId && driverIds.includes(t.driverId)
    );
    const worstPos = Math.max(0, ...teamTimes.map((t) => t.position ?? 0));
    const livesLost = getLivesLost(livesRules, numDriversLeft, worstPos);

    runningLives[teamId] = livesAtStart - livesLost;
  }
};
