import { onlyUnique } from "Utility";
import { Positions } from "./Positions";

export type Result = "W" | "D" | "L";
export type Results = { [teamId: string]: Result };

export const getResults = (
  finalPositions: Positions | undefined,
  numWinners: number
) => {
  if (!finalPositions) {
    return undefined;
  }

  const results: Results = {};
  const teamIds = Object.keys(finalPositions);
  const draw = isDraw(finalPositions);
  teamIds.forEach((id) => {
    if (draw) {
      results[id] = "D";
    } else if (isWin(finalPositions[id], numWinners)) {
      results[id] = "W";
    } else {
      results[id] = "L";
    }
  });

  return results;
};

export const getWinnerIds = (
  finalPositions: Positions | undefined,
  numWinners: number
) => {
  const results = getResults(finalPositions, numWinners);
  if (!results) {
    return undefined;
  }

  return Object.keys(results).filter((id) => results[id] === "W");
};

export const getTeamResult = (
  teamId: string,
  finalPositions: Positions | undefined,
  numWinners: number
): Result | undefined => {
  if (!finalPositions) {
    return undefined;
  }

  const teamPos = finalPositions[teamId];
  if (!teamPos) {
    return undefined;
  }

  if (isDraw(finalPositions)) {
    return "D";
  }

  return isWin(finalPositions[teamId], numWinners) ? "W" : "L";
};

const isDraw = (finalPositions: Positions) => {
  return Object.values(finalPositions).filter(onlyUnique).length === 1;
};

const isWin = (position: number, numWinners: number) => position <= numWinners;
