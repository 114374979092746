import { db } from "firebase-config";
import {
  DocumentChange,
  DocumentData,
  WithFieldValue,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { store } from "redux/store";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

export function setupSnapshot<T extends string>(
  collectionName: string,
  update: ActionCreatorWithPayload<
    {
      docChanges: DocumentChange<DocumentData>[];
    },
    T
  >
) {
  onSnapshot(collection(db, collectionName), (querySnapshot) => {
    store.dispatch(
      update({
        docChanges: querySnapshot.docChanges(),
      })
    );
  });
}

export async function writeDataToDbAsync<
  T extends WithFieldValue<DocumentData>
>(id: string, data: T, collectionName: string, overwrite: boolean = false) {
  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (!overwrite && docSnap.exists()) {
    throw new Error(`${collectionName} document already exists for id ${id}`);
  } else {
    await setDoc(docRef, data);
  }
}
