import { PanelSpinner } from "components/Panel/styles";
import { ReactNode, useState } from "react";
import { Modal } from "./Modal";
import {
  DataModalContainer,
  ModalButtonsContainer,
  ModalSpinnerWrapper,
} from "./styles";
import { Button } from "styles/elements";

interface Props {
  children: ReactNode;
  visible: boolean;
  onSave: () => Promise<void>;
  onCancel: () => void;
}

export const DataModal = ({ children, visible, onSave, onCancel }: Props) => {
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const onSaveWithSpinner = async () => {
    setSpinnerVisible(true);
    onSave().then(() => {
      setSpinnerVisible(false);
    });
  };
  return (
    <Modal visible={visible}>
      <DataModalContainer>
        {children}
        <ModalButtonsContainer>
          <Button onClick={onSaveWithSpinner} disabled={spinnerVisible}>
            Save
          </Button>
          <Button onClick={onCancel} disabled={spinnerVisible}>
            Cancel
          </Button>
        </ModalButtonsContainer>
        <ModalSpinnerWrapper visible={spinnerVisible}>
          <PanelSpinner />
        </ModalSpinnerWrapper>
      </DataModalContainer>
    </Modal>
  );
};
