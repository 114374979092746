import { OrgLogo } from "components/OrgLogo/OrgLogo";
import { useSelector } from "react-redux";
import { selectTeamOrgDisplayName, selectTeamOrgId } from "selectors/Team";
import { IStore } from "redux/store";
import { FlexRow } from "styles/layout";
import { MatchScoreCell, OrgLogoContainer, OrgNameCell } from "./styles";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";

interface Props {
  team: FsMatchTeam | "BYE" | "TBD";
  teamScoreString: string | undefined;
  orgTime: number | undefined;
}

export const MatchesViewerListTeam = ({
  team,
  teamScoreString,
  orgTime,
}: Props) => {
  const teamId = typeof team !== "string" ? team.teamId : undefined;
  const orgId = useSelector((state: IStore) =>
    selectTeamOrgId(state, teamId, orgTime)
  );

  const displayName = useSelector((state: IStore) => {
    if (typeof team === "string") {
      return team;
    }

    return selectTeamOrgDisplayName(state, team.teamId, orgTime);
  });

  return (
    <tr>
      <OrgNameCell>
        <FlexRow>
          <OrgLogoContainer>
            {!!displayName && <OrgLogo orgId={orgId} />}
          </OrgLogoContainer>
          <span>{displayName ?? "???"}</span>
        </FlexRow>
      </OrgNameCell>
      <MatchScoreCell>{teamScoreString ?? "-"}</MatchScoreCell>
    </tr>
  );
};
