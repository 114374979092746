import { TeDriverForm } from "components/AddEditForms/Driver/TeDriverForm";
import { SearchSelect } from "components/Fields/SearchSelect/SearchSelect";
import { TournamentEditorInlineButton } from "components/TournamentEditor/styles";
import { TeDriver, newTeDriver } from "model/TournamentEditor/TeDriver";
import { useSelector } from "react-redux";
import { TeamDriverContainer } from "./styles";
import { selectDriverOptions } from "selectors/options/Driver";
import { IStore } from "redux/store";

interface Props {
  driver: string | TeDriver | undefined;
  updateDriver: (driver: string | TeDriver | undefined) => void;
  removeDriver: () => void;
}

export const TeamDriver = ({ driver, updateDriver, removeDriver }: Props) => {
  const driverOptions = useSelector((state: IStore) =>
    selectDriverOptions(state, Object.keys(state.driver.drivers))
  );

  return (
    <TeamDriverContainer>
      <SearchSelect
        items={driverOptions}
        selectedItem={driver}
        setSelectedItem={updateDriver}
        newItem={newTeDriver}
        itemForm={TeDriverForm}
        itemDisplay={(driver: TeDriver) => driver.nickname ?? "unnamed"}
        searchPlaceholder="Driver"
      />
      <TournamentEditorInlineButton onClick={removeDriver}>
        -
      </TournamentEditorInlineButton>
    </TeamDriverContainer>
  );
};
