import { Button } from "styles/elements";
import { FlexRow } from "styles/layout";
import { TeMatch, TeMatchOrId } from "model/TournamentEditor/TeMatch";
import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import { useSelector } from "react-redux";
import { IStore } from "redux/store";
import { selectMatch } from "selectors/Match";
import { StyledInput, StyledSelect } from "components/Fields/styles";

interface Props {
  formatKeys: string[];
  match: TeMatchOrId;
  updateMatch: (newMatch: TeMatch) => void;
  removeExistingMatch?: () => void;
  removeMatch?: () => void;
}
export const Match = ({
  formatKeys,
  match,
  updateMatch,
  removeExistingMatch,
  removeMatch,
}: Props) => {
  const existingMatch = useSelector((state: IStore) =>
    typeof match === "string" ? selectMatch(state, match) : undefined
  );

  if (typeof match === "string") {
    return (
      <FlexRow>
        <span>{`Existing match. ID: ${
          existingMatch?.id ?? "NOT FOUND"
        } Start Time (ms): ${existingMatch?.data.startTimeMs}`}</span>
        {removeExistingMatch && (
          <Button onClick={removeExistingMatch}>-</Button>
        )}
      </FlexRow>
    );
  }

  return (
    <FlexRow>
      <LabelledField label="Format: ">
        <StyledSelect
          style={{ width: "50px" }}
          value={match.formatKey}
          onChange={(e) => updateMatch({ ...match, formatKey: e.target.value })}
        >
          <option value="" />
          {formatKeys.map((k) => (
            <option value={k}>{k}</option>
          ))}
        </StyledSelect>
      </LabelledField>
      <LabelledField label="Start Time (ms): ">
        <StyledInput
          style={{ width: "120px" }}
          type="number"
          value={match.startTimeMs}
          onChange={(e) =>
            updateMatch({ ...match, startTimeMs: e.target.valueAsNumber })
          }
        />
      </LabelledField>
      {removeMatch && <Button onClick={removeMatch}>-</Button>}
    </FlexRow>
  );
};
