import { PayloadAction } from "@reduxjs/toolkit";
import { createFetchSlice, IFetchSlice, initialFetchState } from "./FetchSlice";
import { DocumentChange, DocumentData } from "firebase/firestore";
import { FsTournamentPickemDetails } from "db/model/pickemDetails/FsTournamentPickemDetails";

export interface IPickemDetailsSlice extends IFetchSlice {
  pickemDetails: { [key: string]: FsTournamentPickemDetails };
}

const initialState: IPickemDetailsSlice = {
  ...initialFetchState,
  pickemDetails: {},
};

// todo - should the FSModel -> Model mapping be done in the slice ??
export const pickemDetailsSlice = createFetchSlice({
  name: "pickemDetails",
  initialState,
  reducers: {
    updatePickemDetails: (
      state,
      action: PayloadAction<{
        docChanges: DocumentChange<DocumentData>[];
      }>
    ) => {
      const { docChanges } = action.payload;
      for (let i = 0; i < docChanges.length; i++) {
        const { doc } = docChanges[i];
        state.pickemDetails[doc.id] = doc.data() as FsTournamentPickemDetails;
      }
      if (!state.initialFetchComplete) {
        state.initialFetchComplete = true;
      }
    },
  },
});

export const { updatePickemDetails } = pickemDetailsSlice.actions;
export const pickemDetailsReducer = pickemDetailsSlice.reducer;
