import styled from "styled-components";
import { Button } from "styles/elements";
import { CenterFlexRow } from "styles/layout";
import { Theme } from "theme";

export const TournamentEditorTextArea = styled.textarea`
  border-width: 2px;
  border-color: ${Theme.styledInputBorderColor};
  outline: none;
  &:focus {
    border-color: ${Theme.styledInputBorderFocusColor};
  }
`;

export const ActionsContainer = styled(CenterFlexRow)`
  column-gap: 10px;
`;

export const TournamentEditorInlineButton = styled(Button)`
  padding: 2px 6px;
`;
