import { AsyncButton } from "components/Core/AsyncButton/AsyncButton";
import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import { Panel } from "components/Panel/Panel";
import {
  addTournamentAdminAsync,
  removeTournamentAdminAsync,
} from "database/tournamentAdmin/tournamentAdminInfo";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectTournamentAdmin } from "selectors/TournamentAdmin";
import { selectUsername } from "selectors/Username";
import { IStore } from "redux/store";
import { CenterFlexColumn, FlexRow } from "styles/layout";
import { onlyUnique } from "Utility";
import { useFetchUsernames } from "hooks/useFetchUsernames";

interface Props {
  tournamentId: string;
}
export const TournamentAdminsTab = ({ tournamentId }: Props) => {
  const [newAdminUid, setNewAdminUid] = useState("");
  const [resultText, setResultText] = useState("");
  const admins = useSelector((state: IStore) =>
    selectTournamentAdmin(state, tournamentId)
  );

  const uidsToFetch = admins?.data.adminUids.filter(onlyUnique) ?? [];
  useFetchUsernames(uidsToFetch);

  const addAdminAsync = async () => {
    setResultText("");
    const result = await addTournamentAdminAsync(tournamentId, newAdminUid);
    setResultText(result);
  };

  return (
    <Panel padding="padded">
      <CenterFlexColumn>
        <FlexRow>
          <LabelledField label="Add Admin (from ecm user id):">
            <input
              value={newAdminUid}
              onChange={(e) => setNewAdminUid(e.target.value)}
              placeholder="eCM user id"
            />
          </LabelledField>
          <AsyncButton onClickAsync={addAdminAsync} disabled={!newAdminUid}>
            Add Admin
          </AsyncButton>
          <span>{resultText}</span>
        </FlexRow>
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            {admins?.data.adminUids.map((adminUid) => (
              <tr>
                <td>{adminUid}</td>
                <td>
                  <UsernameSpan uid={adminUid} />
                </td>
                <td>
                  <AsyncButton
                    onClickAsync={() =>
                      removeTournamentAdminAsync(tournamentId, adminUid)
                    }
                  >
                    Remove Admin
                  </AsyncButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CenterFlexColumn>
    </Panel>
  );
};

// todo - extract this kinda ting?
interface UsernameProps {
  uid: string;
}

const UsernameSpan = ({ uid }: UsernameProps) => {
  const username = useSelector((state: IStore) => selectUsername(state, uid));
  return <span>{username ?? "..."}</span>;
};
