import { FsCupModePoints } from "db/model/match/Set/CupMode/FsCupModePoints";
import { mapStringToPointsDistribution } from "model/PointsDistribution";

export type TeCupModePoints = { [numLeft: string]: string | undefined };

export const mapTeCupModePoints = (
  tePoints: TeCupModePoints,
  dnfPoints: number | undefined
): FsCupModePoints => {
  const teKeys = Object.keys(tePoints);
  const points: FsCupModePoints = {};
  for (let i = 0; i < teKeys.length; i++) {
    const key = teKeys[i];
    const distString = tePoints[key];
    if (!!distString) {
      points[key] = {
        distribution: mapStringToPointsDistribution(distString),
        dnfPoints,
      };
    }
  }
  return points;
};

export const getAdjustedTeCupModePoints = (
  oldDistribution: TeCupModePoints,
  numTeams: number | undefined,
  driversPerTeam: number | undefined,
  playTillLeft: number | undefined
) => {
  if (!numTeams || !driversPerTeam || !playTillLeft) {
    return {};
  }

  const adjustedNumTeams = Math.max(numTeams, 0);
  const adjustedDriversPerTeam = Math.max(driversPerTeam, 0);
  const adjustedPlayTillLeft = Math.max(playTillLeft, 0);

  const numDrivers = adjustedNumTeams * adjustedDriversPerTeam;
  const newDistribution: TeCupModePoints = {};

  for (var i = numDrivers; i > adjustedPlayTillLeft; i--) {
    newDistribution[i] = oldDistribution[i] ?? "";
  }

  return newDistribution;
};
