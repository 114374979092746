import styled from "styled-components";
import { Button } from "styles/elements";
import { CenterFlexRow } from "styles/layout";
import { Theme } from "theme";

export const PaginationContainer = styled(CenterFlexRow)``;

export const PaginationButton = styled(Button)<{ selected: boolean }>`
  margin: 5px 5px;
  padding: 0;
  width: 34px;
  height: 32px;
  font-size: 10.5pt;

  ${({ selected }) =>
    selected &&
    `background: ${Theme.paginationButtonBackground};  color: ${Theme.paginationButtonForeground}`}
`;

export const PaginationSeperator = styled.div`
  margin: 0 5px;
`;
