import styled from "styled-components";
import { CenterFlexColumn, FlexRow } from "styles/layout";
import { Theme } from "theme";

export const MatchContainer = styled(FlexRow)`
  border-top: ${Theme.panelContentBorder};

  cursor: pointer;
  &:hover {
    background: ${Theme.panelHoverBackground};
  }
`;

export const MatchTimeContainer = styled(CenterFlexColumn)<{
  isDelayed: boolean;
}>`
  width: 120px;
  font-size: 11pt;

  @media (max-width: 1000px) {
    width: 105px;
  }

  @media (max-width: 800px) {
    width: 80px;
  }

  @media (max-width: 700px) {
    font-size: 10pt;
    width: 75px;
  }

  @media (max-width: 500px) {
    width: 65px;
  }

  ${({ isDelayed }) =>
    isDelayed && `color: ${Theme.matchStatusDelayedForeground}`}
`;
