import { Panel } from "components/Panel/Panel";
import { TabBar } from "components/TabBar/TabBar";
import { Filter } from "model/Filter";
import { GrowColumn } from "pages/styles";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "redux/store";
import { selectTournamentSegments } from "selectors/Tournament";
import { EmptyGrowPanelText } from "styles/text";
import { TournamentSegmentTabContent } from "./TournamentSegmentTabContent";

interface Props {
  tournamentId: string;
  segmentIds: string[];
  orgTime: number | undefined;
}
export const TournamentSegmentTab = ({
  tournamentId,
  segmentIds,
  orgTime,
}: Props) => {
  const segments = useSelector(
    (state: IStore) =>
      selectTournamentSegments(state, tournamentId, segmentIds),
    shallowEqual
  );

  // todo - move this filter logic into a reusable hook?
  const segmentTabOptions = segments?.map((s) => s.name ?? "???") ?? [];
  const [selectedSegmentTab, setSelectedSegmentTab] = useState(
    segmentTabOptions[0]
  );

  const segmentTabFilter: Filter<string> = {
    options: segmentTabOptions,
    selectedValue: selectedSegmentTab,
    setSelectedValue: setSelectedSegmentTab,
  };

  const selectedSegment = !!segments
    ? segments.length === 1
      ? segments[0]
      : segments.find((s) => s.name === selectedSegmentTab)
    : undefined;

  if (!selectedSegment) {
    return (
      <GrowColumn>
        <Panel>
          <EmptyGrowPanelText>Unable to find segment</EmptyGrowPanelText>
        </Panel>
      </GrowColumn>
    );
  }

  return (
    <GrowColumn>
      {segmentTabOptions.length > 1 && (
        <Panel>
          <TabBar filter={segmentTabFilter} isSecondaryTabBar={true} />
        </Panel>
      )}
      <TournamentSegmentTabContent
        segment={selectedSegment}
        orgTime={orgTime}
      />
    </GrowColumn>
  );
};
