import { nonUndefined } from "Utility";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { Scores } from "fsModel/Match/Scores";
import {
  getTimeAttackTrackFinalPositions,
  getTimeAttackTrackScores,
  isTimeAttackTrackComplete,
} from "./Track";
import { FsTimeAttackSet } from "db/model/match/Set/TimeAttack/FsTimeAttackSet";
import { calculateFinalPositions, Positions } from "fsModel/Match/Positions";

export const getTimeAttackSetScores = (
  set: FsTimeAttackSet,
  teams: FsMatchTeam[]
): Scores => {
  const teamIds = nonUndefined(teams.flatMap((t) => t.teamId));
  const scores: Scores = {};
  const scoring = set.format?.scoring;

  const trackResults: Positions[] = [];
  if (!!scoring) {
    set.tracks?.forEach((t) => {
      const scores = getTimeAttackTrackScores(t, teams, scoring);
      const isComplete = isTimeAttackTrackComplete(t);
      const finalPositions = getTimeAttackTrackFinalPositions(
        scores,
        isComplete
      );
      if (!!finalPositions) {
        trackResults.push(finalPositions);
      }
    });
  }

  for (var i = 0; i < teamIds.length; i++) {
    const teamId = teamIds[i];
    const scoreOverride = !!set.scoreOverrides
      ? set.scoreOverrides[teamId]
      : undefined;
    if (scoreOverride !== undefined) {
      scores[teamId] = scoreOverride;
    } else {
      const teamResults = trackResults.map((r) => r[teamId]).filter((r) => !!r);
      if (teamResults.length === 0) {
        continue;
      }

      scores[teamId] =
        teamResults.reduce((a, b) => a + b, 0) / teamResults.length;
    }
  }

  return scores;
};

export const isTimeAttackSetComplete = (set: FsTimeAttackSet) => {
  if (set.overrideComplete) {
    return true;
  }

  const numTracks = set.format?.numTracks;
  if (!numTracks || !set.tracks?.length) {
    return false;
  }

  return (
    set.tracks.filter((t) => isTimeAttackTrackComplete(t)).length >= numTracks
  );
};

export const getTimeAttackSetFinalPositions = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

// todo - Add tiebreaker logic here
const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
