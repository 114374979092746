import { FsMatch } from "db/model/match/FsMatch";
import { getNumTeams, getTeams, matchCompleteByByesOrForfeits } from "./Match";
import {
  getMatchSetFinalPositions,
  getMatchSetScores,
  matchSetIsComplete,
} from "./Set/MatchSet";
import { Scores, applyScoreOverrides } from "./Scores";
import { getWinnerIds } from "./Results";
import { getTeamSetPoints } from "./SetPoints";
import { nonUndefined } from "Utility";

export const getMatchScores = (match: FsMatch) => {
  const teams = getTeams(match);
  const numTeams = getNumTeams(match);
  const matchScores: Scores = {};
  const teamIds = nonUndefined(teams.flatMap((t) => t.teamId));
  teamIds.forEach((teamId) => (matchScores[teamId] = 0));

  for (let i = 0; i < (match.sets?.length ?? 0); i++) {
    const set = match.sets?.at(i);
    if (!set) {
      continue;
    }

    const setScores = getMatchSetScores(set, teams, numTeams);
    const isSetComplete = matchSetIsComplete(set, setScores, numTeams);
    if (!isSetComplete) {
      continue;
    }

    const finalPositions = getMatchSetFinalPositions(
      set,
      setScores,
      isSetComplete
    );
    if (!finalPositions) {
      continue;
    }

    for (let t = 0; t < teamIds.length; t++) {
      const teamId = teamIds[t];
      const teamPoints =
        getTeamSetPoints(
          teamId,
          set.points,
          finalPositions,
          set.format?.numWinners ?? 1
        ) ?? 0;
      matchScores[teamId] = matchScores[teamId] ?? 0 + teamPoints;
    }
  }

  if (!!match.scoreOverrides) {
    applyScoreOverrides(matchScores, match.scoreOverrides);
  }

  return matchScores;
};

export const getMatchTeamDisplayScores = (match: FsMatch) => {
  const teams = getTeams(match);
  const numTeams = getNumTeams(match);

  var teamScores: Scores | undefined;
  if (match.format?.type === "Single") {
    const setOne = match.sets?.at(0);
    if (!!setOne) {
      teamScores = getMatchSetScores(setOne, teams, numTeams);
    }
  } else {
    teamScores = getMatchScores(match);
  }

  const matchCompleteViaDefault = matchCompleteByByesOrForfeits(match);
  const teamDisplayScores: { [teamId: string]: string } = {};
  for (let i = 0; i < teams.length; i++) {
    const team = teams[i];
    if (!team.teamId) {
      continue;
    }

    if (team.forfeited) {
      teamDisplayScores[team.teamId] = "F";
      continue;
    }

    if (matchCompleteViaDefault) {
      teamDisplayScores[team.teamId] = "W";
      continue;
    }

    if (!!teamScores) {
      teamDisplayScores[team.teamId] =
        teamScores[team.teamId]?.toString() ?? "-";
    }
  }

  return teamDisplayScores;
};
