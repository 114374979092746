import { tournamentCollectionName } from "db/info/collectionNames";
import { FsTournament } from "db/model/tournament/FsTournament";
import { db } from "firebase-config";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { store } from "redux/store";
import { updateTournament } from "redux/UserSlice";

var tournamentSubscriptions: { [key: string]: Unsubscribe } = {};

export const fetchTournaments = (ids: string[]) => {
  const existingIds = Object.keys(tournamentSubscriptions);
  const newIds = ids.filter((id) => !existingIds.includes(id));
  for (var j = 0; j < newIds.length; j++) {
    const newId = newIds[j];
    const existingSubscription = tournamentSubscriptions[newId];
    if (!!existingSubscription) {
      existingSubscription();
    }

    const docRef = doc(db, tournamentCollectionName, newId);
    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        store.dispatch(
          updateTournament({
            id: docSnapshot.id,
            data: docSnapshot.data() as FsTournament,
          })
        );
      }
    });

    tournamentSubscriptions[newId] = unsubscribe;
  }
};

export const fetchAllTournaments = () => {
  const queryRef = collection(db, tournamentCollectionName);
  const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
    querySnapshot.docChanges().forEach((change) => {
      store.dispatch(
        updateTournament({
          id: change.doc.id,
          data: change.doc.data() as FsTournament,
        })
      );
    });
  });

  tournamentSubscriptions[tournamentCollectionName] = unsubscribe;
};

// export const fetchAllTournaments = () => {
//   const queryRef = collection(db, tournamentCollectionName);
//   const unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
//     querySnapshot.docChanges().forEach((change) => {
//       store.dispatch(
//         updateTournament({
//           id: change.doc.id,
//           data: change.doc.data() as FsTournament,
//         })
//       );
//     });
//   });

//   tournamentSubscriptions[tournamentCollectionName] = unsubscribe;
// };

export const unsubscribeAllTournaments = () => {
  const unsubscribes = Object.values(tournamentSubscriptions);
  for (var i = 0; i < unsubscribes.length; i++) {
    unsubscribes[i]();
  }

  tournamentSubscriptions = {};
};
