import { FsWorldTourFormat } from "db/model/match/Set/WorldTour/FsWorldTourFormat";
import { TeSetFormatArgsBase, newTeSetFormatArgsBase } from "../TeSetFormat";
import { PlayCondition } from "fsModel/Match/PlayCondition";
import { FsSetFormatBase } from "db/model/match/Set/FsSetFormat";
import { newFsWorldTourScoring } from "db/ctor/match/Set/WorldTour/FsWorldTourFormat";

export type TeWorldTourSetFormat = TeSetFormatArgsBase & {
  playCondition: PlayCondition;
  trackWinReq: number | undefined;
  type: "WorldTour";
};

export const newTeWorldTourSetFormat = (): TeWorldTourSetFormat => {
  return {
    ...newTeSetFormatArgsBase(),
    playCondition: "BestOf",
    trackWinReq: undefined,
    type: "WorldTour",
  };
};

export const mapTeWorldTourSetFormat = (
  base: FsSetFormatBase,
  format: TeWorldTourSetFormat
): FsWorldTourFormat => {
  return {
    ...base,
    playCondition: format.playCondition,
    scoring: newFsWorldTourScoring(),
    trackWinReq: format.trackWinReq,
  };
};
