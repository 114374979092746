import { arrayReplaceAt } from "Utility";
import { Button } from "styles/elements";
import { FlexColumn } from "styles/layout";
import {
  newTeMatch,
  TeMatch,
  TeMatchOrId,
} from "model/TournamentEditor/TeMatch";
import { Match } from "../Match";

interface Props {
  formatKeys: string[];
  matches: TeMatchOrId[];
  updateMatches: (matches: TeMatchOrId[]) => void;
}
export const MatchList = ({ formatKeys, matches, updateMatches }: Props) => {
  const onAddMatchClick = () => {
    updateMatches([...matches, newTeMatch()]);
  };

  const onRemoveMatchClick = (match: TeMatchOrId) => {
    updateMatches(matches.filter((m) => m !== match));
  };

  const updateMatch = (newMatch: TeMatch, matchIdx: number) => {
    updateMatches(arrayReplaceAt(matches, newMatch, matchIdx));
  };

  return (
    <FlexColumn>
      {matches.map((match, matchIdx) => (
        <Match
          formatKeys={formatKeys}
          match={match}
          updateMatch={(newMatch: TeMatch) => updateMatch(newMatch, matchIdx)}
          removeMatch={() => onRemoveMatchClick(match)}
          removeExistingMatch={() => onRemoveMatchClick(match)}
        />
      ))}
      <Button onClick={onAddMatchClick}>Add Match</Button>
    </FlexColumn>
  );
};
