import { FsOrg } from "db/model/org/FsOrg";

export const getAltOrgDetails = (org: FsOrg, teamId: string | undefined) => {
  if (!teamId) {
    return undefined;
  }

  return org.altDetails?.find((d) => d.teamId === teamId);
};

export const getOrgDetails = (org: FsOrg, timeMs: number | undefined) => {
  if (!org.details) {
    return undefined;
  }

  if (!timeMs || org.details.length === 0) {
    return org.details.at(-1);
  }

  for (var i = 0; i < org.details.length; i++) {
    const details = org.details[i];
    if (!details.endTimeMs || details.endTimeMs >= timeMs) {
      return details;
    }
  }

  return org.details.at(-1);
};
