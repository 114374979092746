import { ReactNode } from "react";
import { Container } from "./styles";

interface Props {
  label: string;
  children: ReactNode;
}
export const LabelledField = ({ label, children }: Props) => {
  return (
    <Container>
      <span style={{ lineHeight: "100%", paddingBottom: "2px" }}>{label}</span>
      {children}
    </Container>
  );
};
