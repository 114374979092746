export type PlayCondition = "BestOf" | "FirstTo" | "PlayAll";

export const playConditions = ["BestOf", "FirstTo", "PlayAll"];

export const isPlayCondition = (
  value: string | undefined
): value is PlayCondition => {
  if (!value) {
    return false;
  }

  return ["BestOf", "FirstTo", "PlayAll"].includes(value);
};

export const getPlayConditionAcronym = (condition: PlayCondition) => {
  switch (condition) {
    case "BestOf":
      return "BO";
    case "FirstTo":
      return "Ft";
    case "PlayAll":
      return "PA";
  }
};

export const getPlayConditionFormatKey = (
  condition: string | undefined,
  amount: number | undefined
) => {
  return `${
    isPlayCondition(condition) ? getPlayConditionAcronym(condition) : "?"
  }${amount ?? "?"}`;
};
