import { IdDisplay } from "model/IdDisplay";
import { nonEmpty } from "Utility";
import { FieldArgs } from "./Core";
import { StyledSelect } from "./styles";

export type SelectArgs = FieldArgs & {
  includeEmptyOption?: boolean;
};

interface BaseProps {
  value: string | undefined;
  args?: SelectArgs;
}

interface StringSelectProps extends BaseProps {
  options: string[];
  update: (newVal: string | undefined) => void;
}

export const StringSelectField = ({
  value,
  options,
  update,
  args,
}: StringSelectProps) => {
  return (
    <StyledSelect
      disabled={args?.disabled}
      value={value ?? ""}
      onChange={(e) => update(nonEmpty(e.target.value))}
    >
      {(args?.includeEmptyOption ?? true) && <option key={""} value={""} />}
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </StyledSelect>
  );
};

interface IdSelectProps extends BaseProps {
  options: IdDisplay[];
  update: (newVal: string | undefined) => void;
}

export const IdSelectField = ({
  value,
  options,
  update,
  args,
}: IdSelectProps) => {
  return (
    <StyledSelect
      disabled={args?.disabled}
      value={value ?? ""}
      onChange={(e) => update(nonEmpty(e.target.value))}
    >
      {(args?.includeEmptyOption ?? true) && <option key={""} value={""} />}
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.display}
        </option>
      ))}
    </StyledSelect>
  );
};

interface TypedSelectProps<T> extends BaseProps {
  options: string[];
  isType: (val: any) => val is T;
  update: (newVal: T | undefined) => void;
}

export function TypedSelectField<T>({
  value,
  options,
  isType,
  update,
  args,
}: TypedSelectProps<T>) {
  return (
    <StyledSelect
      disabled={args?.disabled}
      value={value ?? ""}
      onChange={(e) =>
        update(isType(e.target.value) ? e.target.value : undefined)
      }
    >
      {(args?.includeEmptyOption ?? true) && <option key={""} value={""} />}
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </StyledSelect>
  );
}
