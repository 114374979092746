import { LabelledField } from "components/Fields/LabelledField/LabelledField";
import { useSelector } from "react-redux";
import { selectDriverNickname } from "selectors/Driver";
import { IStore } from "redux/store";

export type DriverKeys = { [driverId: string]: string | undefined };

interface Props {
  driverKeys: DriverKeys;
  updateDriverKeys: (keys: DriverKeys) => void;
}

export const DriverKeyEditor = ({ driverKeys, updateDriverKeys }: Props) => {
  const driverIds = Object.keys(driverKeys);

  const updateDriverKey = (driverId: string, newKey: string | undefined) => {
    updateDriverKeys({ ...driverKeys, [driverId]: newKey });
  };

  return (
    <>
      {driverIds?.map((driverId) => (
        <DriverKey
          key={driverId}
          driverId={driverId}
          driverKey={driverKeys[driverId]}
          updateDriverKey={(newKey) => updateDriverKey(driverId, newKey)}
        />
      ))}
    </>
  );
};

interface DriverKeyProps {
  driverId: string;
  driverKey: string | undefined;
  updateDriverKey: (newKey: string | undefined) => void;
}

const DriverKey = ({
  driverId,
  driverKey,
  updateDriverKey,
}: DriverKeyProps) => {
  const nickname = useSelector((state: IStore) =>
    selectDriverNickname(state, driverId)
  );

  return (
    <LabelledField label={nickname ?? ""}>
      <input
        style={{ width: "18px", margin: "4px" }}
        type="text"
        maxLength={1}
        value={driverKey}
        onChange={(e) => updateDriverKey(e.target.value?.toUpperCase())}
      />
    </LabelledField>
  );
};
