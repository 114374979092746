import styled from "styled-components";
import { FlexColumn } from "styles/layout";
import { Theme } from "theme";

export const HeaderContainer = styled(FlexColumn)`
  row-gap: 15px;
`;

export const LogoContainer = styled.div`
  height: 120px;
  max-width: 110px;
  min-width: 110px;
  padding-left: ${Theme.bigPanelPaddingHorizontal};
  padding-top: ${Theme.smallPanelPadding};

  @media (max-width: 800px) {
    padding-left: ${Theme.smallPanelPadding};
  }

  @media (max-width: 700px) {
    max-width: 80px;
    min-width: 80px;
  }
`;

export const DataContainer = styled(FlexColumn)`
  row-gap: 12px;
  padding: ${Theme.bigPanelPadding};
  @media (max-width: 800px) {
    padding: ${Theme.smallPanelPadding};
  }
`;

export const TournamentName = styled.div`
  font-size: 14pt;
  font-weight: 500;
`;

export const TournamentDescription = styled.div``;

export const TournamentDates = styled.div`
  margin-top: 10px;
`;

export const TabsContainer = styled.div`
  border-top: ${Theme.panelContentBorder};
`;
