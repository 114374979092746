import { FsDriver } from "db/model/driver/FsDriver";
import { FsMatch } from "db/model/match/FsMatch";
import { FsOrg } from "db/model/org/FsOrg";
import { FsTeam } from "db/model/team/FsTeam";
import { FsTour } from "db/model/tour/FsTour";
import { FsTournament } from "db/model/tournament/FsTournament";
import { FsTrack } from "db/model/track/FsTrack";

export type Driver = IdData<FsDriver>;
export type Match = IdData<FsMatch>;
export type Org = IdData<FsOrg>;
export type Team = IdData<FsTeam>;
export type Tour = IdData<FsTour>;
export type Tournament = IdData<FsTournament>;
export type Track = IdData<FsTrack>;

export type IdData<T> = {
  data: T;
  id: string;
};

export function newIdData<T>(id: string, newData: () => T): IdData<T> {
  return {
    id,
    data: newData(),
  };
}

export function idDataGetNextNumericalId<T>(existing: IdData<T>[]) {
  var largestId = 0;
  for (var i = 0; i < existing.length; i++) {
    const numberId = parseInt(existing[i].id);
    if (isNaN(numberId)) {
      continue;
    }

    if (numberId > largestId) {
      largestId = numberId;
    }
  }

  return `${largestId + 1}`;
}
