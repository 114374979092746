import { FsOrgHistory } from "db/model/team/FsOrgHistory";
import { FsTeam, isFsSingleDriverTeam } from "db/model/team/FsTeam";
import { FsTeamDriver } from "db/model/team/FsTeamDriver";
import { nonUndefined } from "Utility";

export const teamContainsDriver = (team: FsTeam, driverId: string) => {
  if (isFsSingleDriverTeam(team)) {
    return team.driverId === driverId;
  }

  return !!team.drivers?.find((d) => d.id === driverId);
};

export const teamIncludesOrg = (team: FsTeam, orgId: string) => {
  if (team.orgId === orgId) {
    return true;
  }

  return team.orgHistory?.some((h) => h.id === orgId);
};

export const isActiveDriver = (
  driver: FsTeamDriver,
  timeMs: number | undefined
) => {
  if (!timeMs) {
    return !!driver.end;
  }

  if (!!driver.start && driver.start > timeMs) {
    return false;
  }

  if (!!driver.end && driver.end < timeMs) {
    return false;
  }

  return true;
};

export const getRelevantOrgHistory = (
  orgHistory: FsOrgHistory[] | undefined,
  time: number | undefined
) => {
  if (!orgHistory || !time) {
    return undefined;
  }

  const sorted = [...orgHistory].sort((a, b) => (a.end ?? 0) - (b.end ?? 0));
  for (let i = 0; i < sorted.length; i++) {
    const hist = sorted[i];
    if (!hist.end) {
      continue;
    }

    if (hist.end >= time) {
      return hist;
    }
  }
};

export const getTeamOrgId = (team: FsTeam, time: number | undefined) => {
  const orgHist = getRelevantOrgHistory(team.orgHistory, time);
  return !!orgHist ? orgHist.id : team.orgId;
};

export const getTeamOrgName = (team: FsTeam, time: number | undefined) => {
  const orgHist = getRelevantOrgHistory(team.orgHistory, time);
  const orgName = !!orgHist ? orgHist.name : team.orgName;
  return orgName ?? "Orgless";
};

export const getTeamOrgTrigram = (team: FsTeam, time: number | undefined) => {
  const orgHist = getRelevantOrgHistory(team.orgHistory, time);
  const orgName = !!orgHist ? orgHist.trigram : team.orgTrigram;
  return orgName ?? "ORG";
};

export const getTeamDisplayName = (team: FsTeam, time: number | undefined) => {
  return isFsSingleDriverTeam(team)
    ? team.driverNickname
    : getTeamOrgName(team, time);
};

export const getTeamDisplayTrigram = (
  team: FsTeam,
  time: number | undefined
) => {
  return isFsSingleDriverTeam(team)
    ? team.driverNickname?.slice(0, 3)
    : getTeamOrgName(team, time);
};

export const getTeamDriverNickname = (team: FsTeam, driverId: string) => {
  return isFsSingleDriverTeam(team)
    ? team.driverId === driverId
      ? team.driverNickname
      : undefined
    : team.drivers?.find((d) => d.id === driverId)?.nickname;
};

export const getTeamActiveDriverIds = (
  team: FsTeam,
  timeMs: number | undefined
) => {
  if (isFsSingleDriverTeam(team)) {
    return !!team.driverId ? [team.driverId] : [];
  }

  return nonUndefined(
    team.drivers?.filter((d) => isActiveDriver(d, timeMs))?.map((d) => d.id) ??
      []
  );
};
