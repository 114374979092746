import { Panel } from "components/Panel/Panel";
import { useState } from "react";
import { Header } from "./Header/Header";
import { Teams } from "./Teams/Teams";
import { Tracks } from "./Tracks/Tracks";
import { TeTournament } from "model/TournamentEditor/TeTournament";
import {
  newTeTournamentSegment,
  TeTournamentSegment,
} from "model/TournamentEditor/Segment/TeTournamentSegment";
import { Segment } from "./Segment/Segment";
import { arrayReplaceAt } from "Utility";
import { TabBarOption } from "components/TabBarV2/TabBarV2";
import { TeMatchFormat } from "model/TournamentEditor/TeMatchFormat";

interface Props {
  tournament: TeTournament;
  setTournament: React.Dispatch<React.SetStateAction<TeTournament>>;
}

const INITIAL_TAB = "Teams";

export const TeTournamentEditor = ({ tournament, setTournament }: Props) => {
  // todo - should pass in a new tournament object (does state want to be immutable?)
  const update = () => {
    setTournament({ ...tournament });
  };

  const [selectedTab, setSelectedTab] = useState<any>(INITIAL_TAB);
  const addNewSegment = () => {
    tournament.segments = [
      ...tournament.segments,
      newTeTournamentSegment("Bracket"),
    ];
    update();
    setSelectedTab(tournament.segments.length - 1);
  };

  const tabOptions = getTabOptions(tournament, addNewSegment) ?? [];
  const removeSegment = (segment: TeTournamentSegment) => {
    const segmentIdx = tournament.segments.indexOf(segment);
    tournament.segments = tournament.segments.filter((s) => s !== segment);
    update();
    if (selectedTab === segmentIdx) {
      setSelectedTab(segmentIdx > 0 ? segmentIdx - 1 : INITIAL_TAB);
    }
  };

  return (
    <>
      <Panel>
        <Header
          tournament={tournament}
          tabOptions={tabOptions}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          update={update}
        />
      </Panel>
      {getTabContent(selectedTab, tournament, removeSegment, update)}
    </>
  );
};

const getTabOptions = (tournament: TeTournament, addNewSegment: () => void) => {
  const tabOptions: TabBarOption[] = [
    {
      displayText: "Teams",
      item: "Teams",
    },
  ];
  tournament.segments?.forEach((s, sIdx) => {
    tabOptions.push({
      displayText: !!s.name ? s.name : `Segment ${sIdx + 1}`,
      item: sIdx,
    });
  });
  tabOptions.push({
    displayText: "New Segment",
    onClick: () => {
      addNewSegment();
    },
  });
  tabOptions.push({
    displayText: "Tracks",
    item: "Tracks",
  });

  return tabOptions;
};

// todo - improve TabBarV2 and it's selection logic to avoid weird idx offsets
const getTabContent = (
  selectedTab: any,
  tournament: TeTournament,
  removeSegment: (segment: TeTournamentSegment) => void,
  update: () => void
) => {
  switch (selectedTab) {
    case "Teams":
      return (
        <Panel padding={"padded"} allowOverflow={true}>
          <Teams tournament={tournament} update={update} />
        </Panel>
      );
    case "Tracks":
      return (
        <Panel padding={"padded"} allowOverflow={true}>
          <Tracks tournament={tournament} update={update} />
        </Panel>
      );
    default:
      const segment = tournament.segments.at(selectedTab);
      return (
        !!segment && (
          <Segment
            key={selectedTab}
            segment={segment}
            matchFormats={tournament.matchFormats}
            setMatchFormats={(formats: TeMatchFormat[]) => {
              tournament.matchFormats = formats;
              update();
            }}
            removeSegment={() => removeSegment(segment)}
            update={(newSegment: TeTournamentSegment) => {
              const index = tournament.segments.indexOf(segment);
              tournament.segments = arrayReplaceAt(
                tournament.segments,
                newSegment,
                index
              );
              update();
            }}
          />
        )
      );
  }
};
