import styled from "styled-components";
import { FlexRow, CenterFlexColumn } from "styles/layout";
import { Theme } from "theme";

const BORDER_WIDTH = "3px";
export const TabContainer = styled(FlexRow)`
  align-items: stretch;
`;

export const Tab = styled.div<{ selected: boolean; secondary: boolean }>`
  flex: 1;
  font-weight: 500;
  text-align: center;
  padding: 0.5em 0;
  cursor: pointer;
  background: ${Theme.tabBackground};
  border-bottom: ${BORDER_WIDTH} solid transparent;
  border-right: ${Theme.tabBorder};
  &:last-child {
    border-right: 0;
  }

  @media (max-width: 700px) {
    padding: 0.35em 0;
    font-size: 11pt;
    ${({ secondary }) => secondary && `font-size: 9pt`}
  }

  &:hover {
    ${({ selected }) =>
      !selected &&
      `background: ${Theme.tabHoverBackground}; border-bottom: ${BORDER_WIDTH} solid ${Theme.tabHoverForeground}; color: ${Theme.tabHoverForeground};`}
  }

  ${({ selected }) =>
    selected &&
    `background: ${Theme.tabSelectedBackground}; border-bottom: ${BORDER_WIDTH} solid ${Theme.tabSelectedForeground}; color: ${Theme.tabSelectedForeground};`}

  ${({ secondary }) => secondary && `font-size: 10pt`}
`;

export const TabText = styled(CenterFlexColumn)`
  height: 100%;
`;
