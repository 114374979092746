import { MatchEditor } from "components/MatchEditor/MatchEditor";
import { PageContent, ColumnContainer, GrowColumn } from "pages/styles";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectMatch } from "selectors/Match";
import { IStore } from "redux/store";
import { MaxWidth } from "styles/layout";
import { EmptyGrowPanelText, ErrorText } from "styles/text";

export const EditMatchPage = () => {
  const { matchId } = useParams();
  const match = useSelector((state: IStore) => selectMatch(state, matchId));

  return (
    <PageContent>
      <MaxWidth>
        <ColumnContainer>
          <GrowColumn>
            {!!match ? (
              <MatchEditor matchId={match.id} match={match.data} />
            ) : (
              <EmptyGrowPanelText>
                <ErrorText>Match could not be found</ErrorText>
              </EmptyGrowPanelText>
            )}
          </GrowColumn>
        </ColumnContainer>
      </MaxWidth>
    </PageContent>
  );
};
