import { createContext, useContext } from "react";

export type GlobalContent = {
  isLoadingOverlayVisible: boolean;
  setIsLoadingOverlayVisible: (b: boolean) => void;
};

export const GlobalContext = createContext<GlobalContent>({
  isLoadingOverlayVisible: false,
  setIsLoadingOverlayVisible: () => {},
});

export const useGlobalContext = () => useContext(GlobalContext);
