import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchTournaments,
  unsubscribeAllTournaments,
} from "database/tournament/tournamentInfo";
import {
  FsTournamentAdmin,
  mapFsTournamentAdmin,
} from "database/tournamentAdmin/FsTournamentAdmin";
import {
  fetchTournamentAdmins,
  unsubscribeAllTournamentAdmins,
} from "database/tournamentAdmin/tournamentAdminInfo";
import {
  FsTournamentDraft,
  mapFsTournamentDraft,
} from "database/tournamentDraft/FsTournamentDraft";
import {
  fetchTournamentDrafts,
  unsubscribeAllTournamentDrafts,
} from "database/tournamentDraft/tournamentDraftInfo";
import {
  FsTournamentOrganization,
  mapFsTournamentOrganization,
} from "database/tournamentOrganization/FsTournamentOrganization";
import { unsubscribeAllTournamentOrganizations } from "database/tournamentOrganization/tournamentOrganizationInfo";
import { FsTournament } from "db/model/tournament/FsTournament";
import { isEcmStaff } from "eCM";
import { IdData, Tournament } from "model/IdData";
import { TournamentAdmin } from "model/TournamentAdmin/TournamentAdmin";
import { TournamentDraft } from "model/TournamentDraft/TournamentDraft";
import { TournamentOrganization } from "model/TournamentOrganization/TournamentOrganization";

export interface IUserSlice {
  adminTournamentIds: string[];
  tournamentAdmins: { [key: string]: IdData<TournamentAdmin> };
  tournamentDrafts: { [key: string]: IdData<TournamentDraft> };
  tournamentOrganizations: { [key: string]: IdData<TournamentOrganization> };
  tournaments: { [key: string]: Tournament };
  uid: string;
  username: string;
}

const initialState: IUserSlice = {
  adminTournamentIds: [],
  tournamentAdmins: {},
  tournamentDrafts: {},
  tournamentOrganizations: {},
  tournaments: {},
  uid: "",
  username: "",
};

// todo - deal with clearing data and subscriptions properly, e.g. if change user can probably access old pickems and see changes still
export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{
        uid: string;
        username: string;
        adminTournamentIds: string[];
      }>
    ) => {
      const { uid, username, adminTournamentIds } = action.payload;
      state.uid = uid;
      state.username = username;
      state.adminTournamentIds = adminTournamentIds ?? [];
      fetchTournaments(adminTournamentIds);
    },
    resetUser: (state) => {
      unsubscribeAllTournamentAdmins();
      unsubscribeAllTournamentDrafts();
      unsubscribeAllTournamentOrganizations();
      unsubscribeAllTournaments();
      state.adminTournamentIds = [];
      state.tournamentAdmins = {};
      state.tournamentDrafts = {};
      state.tournamentOrganizations = {};
      state.tournaments = {};
      state.uid = "";
      state.username = "";
    },
    updateTournament: (
      state,
      action: PayloadAction<{ id: string; data: FsTournament }>
    ) => {
      const { id, data } = action.payload;
      state.tournaments[id] = { id, data };
    },
    updateTournamentAdmin: (
      state,
      action: PayloadAction<{ id: string; data: FsTournamentAdmin }>
    ) => {
      const { id, data } = action.payload;
      const mappedData = mapFsTournamentAdmin(data);
      if (!!mappedData) {
        state.tournamentAdmins[id] = { id, data: mappedData };
      }
    },
    updateTournamentDraft: (
      state,
      action: PayloadAction<{ id: string; data: FsTournamentDraft }>
    ) => {
      const { id, data } = action.payload;
      const mappedData = mapFsTournamentDraft(data);
      if (!!mappedData) {
        state.tournamentDrafts[id] = { id, data: mappedData };
      }
    },
    removeTournamentDraft: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      delete state.tournamentDrafts[id];
    },
    updateTournamentOrganization: (
      state,
      action: PayloadAction<{ id: string; data: FsTournamentOrganization }>
    ) => {
      const { id, data } = action.payload;
      const mappedData = mapFsTournamentOrganization(data);
      if (!!mappedData) {
        state.tournamentOrganizations[id] = { id, data: mappedData };
        if (
          !isEcmStaff(state.uid) &&
          mappedData.organizerUids.includes(state.uid)
        ) {
          fetchTournamentAdmins(mappedData.tournamentIds);
          fetchTournamentDrafts(mappedData.tournamentDraftIds);
          fetchTournaments(mappedData.tournamentIds);
        }
      }
    },
  },
});

export const {
  setUser,
  resetUser,
  updateTournament,
  updateTournamentAdmin,
  updateTournamentDraft,
  removeTournamentDraft,
  updateTournamentOrganization,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
