import { Match } from "model/IdData";
import { MatchListItem } from "./MatchListItem";
import { MatchesContainer } from "./styles";

interface Props {
  matches: Match[];
  ascending: boolean;
}

export const MatchList = ({ matches, ascending }: Props) => {
  const sortedMatches = matches.sort((a, b) =>
    ascending
      ? (a.data.startTimeMs ?? 0) - (b.data.startTimeMs ?? 0)
      : (b.data.startTimeMs ?? 0) - (a.data.startTimeMs ?? 0)
  );

  return (
    <MatchesContainer>
      {sortedMatches.map((m) => (
        <MatchListItem matchId={m.id} match={m.data} key={m.id} />
      ))}
    </MatchesContainer>
  );
};
