import { isEcmStaff } from "eCM";
import { IStore } from "redux/store";
import { selectTournamentOrganizationFromTournamentId } from "./TournamentOrganization";

export const selectUserUid = (state: IStore) => {
  return state.user.uid;
};

export const selectIsEcmStaff = (state: IStore) => {
  return isEcmStaff(selectUserUid(state));
};

export const selectIsTournamentOrganizer = (
  state: IStore,
  tournamentId: string
) => {
  const uid = selectUserUid(state);
  if (!uid) {
    return false;
  }

  if (isEcmStaff(uid)) {
    return true;
  }

  const organization = selectTournamentOrganizationFromTournamentId(
    state,
    tournamentId
  );
  if (!organization) {
    return undefined;
  }

  return organization.data.organizerUids.includes(uid);
};
