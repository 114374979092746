import { Panel } from "components/Panel/Panel";
import { useSelector } from "react-redux";
import { selectIncompleteMatches } from "selectors/Match";
import { IStore } from "redux/store";
import { EmptyGrowPanelText } from "styles/text";
import { UpcomingMatches } from "./UpcomingMatches";

interface Props {
  matchIds: string[];
}

export const UpcomingMatchesIds = ({ matchIds }: Props) => {
  const upcomingMatches = useSelector((state: IStore) =>
    selectIncompleteMatches(state, matchIds)
  );

  if (upcomingMatches.length === 0) {
    return (
      <Panel>
        <EmptyGrowPanelText>No Upcoming Matches</EmptyGrowPanelText>
      </Panel>
    );
  }

  return <UpcomingMatches matches={upcomingMatches} />;
};
