import { FsMatchTrack } from "db/model/match/Track/FsMatchTrack";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { applyForwardCupModeTrackScore } from "../../CupMode/Forward/Track";
import { FsCupModePoints } from "db/model/match/Set/CupMode/FsCupModePoints";
import { Scores, applyScoreOverrides } from "fsModel/Match/Scores";
import { calculateFinalPositions } from "fsModel/Match/Positions";

export const getForwardCupModeLongTrackScores = (
  track: FsMatchTrack,
  teams: FsMatchTeam[],
  points: FsCupModePoints,
  pointsLimit: number,
  driversPerTeam: number
): Scores => {
  const scores: Scores = {};
  applyForwardCupModeTrackScore(
    track,
    teams,
    scores,
    points,
    pointsLimit,
    driversPerTeam,
    [],
    []
  );

  applyScoreOverrides(scores, track.scoreOverrides);
  return scores;
};

export const isForwardCupModeLongTrackComplete = (
  track: FsMatchTrack,
  scores: Scores,
  pointsLimit: number
) => {
  if (track.overrideComplete) {
    return true;
  }

  return Object.values(scores).some((v) => v > pointsLimit);
};

export const getForwardCupModeLongTrackFinalPositions = (
  scores: Scores,
  isComplete: boolean
) => {
  return calculateFinalPositions(isComplete, scores, compareScores);
};

const compareScores = (scores: Scores, a: string, b: string) => {
  return scores[b] - scores[a];
};
