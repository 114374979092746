import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userCollectionName } from "db/info/collectionNames";
import { db } from "firebase-config";
import {
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
} from "firebase/firestore";

export interface IUsersSlice {
  usernamesBeingFetched: string[];
  usernames: { [key: string]: string };
}

const initialState: IUsersSlice = {
  usernamesBeingFetched: [],
  usernames: {},
};

export const fetchUsernames = createAsyncThunk(
  "users/fetchUsernames",
  async (uids: string[]) => {
    const promises: Promise<DocumentSnapshot<DocumentData>>[] = [];
    const usernames = new Map<string, string | undefined>();
    for (var i = 0; i < uids.length; i++) {
      promises.push(getDoc(doc(db, userCollectionName, uids[i])));
    }

    const users = await Promise.all(promises);
    users.forEach((user) => {
      usernames.set(user.id, user.data()?.username ?? "Unknown User");
    });

    return usernames;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateUsername: (
      state,
      action: PayloadAction<{ id: string; data: string }>
    ) => {
      const { id, data } = action.payload;
      state.usernames[id] = data;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsernames.pending, (state, action) => {
        const uids = action.meta.arg;
        uids.forEach((uid) => state.usernamesBeingFetched.push(uid));
      })
      .addCase(fetchUsernames.fulfilled, (state, action) => {
        const usernames = action.payload;
        Array.from(usernames.keys()).forEach(
          (uid) => (state.usernames[uid] = usernames.get(uid) ?? "Unknown User")
        );

        const uids = action.meta.arg;
        state.usernamesBeingFetched = state.usernamesBeingFetched.filter(
          (u) => !uids.includes(u)
        );
      })
      // todo - rejected case
      .addCase(fetchUsernames.rejected, (state, action) => {
        console.log(action.error.message);
      });
  },
});

export const { updateUsername } = usersSlice.actions;

export const usersReducer = usersSlice.reducer;
