import { FsDriver } from "db/model/driver/FsDriver";
import { Driver, Team } from "model/IdData";
import {
  Validated,
  validateMandatoryField,
  validateUniqueValue,
  validateUniqueValues,
  ValidationProblem,
  ValidationType,
} from "./base";
import { teamContainsDriver } from "fsModel/Team/Team";

export const validateDrivers = (drivers: Driver[], teams: Team[]) => {
  const validatedDrivers: Validated<Driver>[] = [];
  for (var i = 0; i < drivers.length; i++) {
    const driver = drivers[i];
    const otherDrivers = drivers.filter((d) => d !== driver);
    validatedDrivers.push({
      model: driver,
      problems: validateDriver(driver, otherDrivers, teams),
    });
  }

  return validatedDrivers;
};

// todo - can these mandatory / unique be identified via Attributes on the FsModel? (not sure if this is possible in JS)
export const validateDriver = (
  driver: Driver,
  otherDrivers: Driver[],
  teams: Team[]
) => {
  const problems: ValidationProblem[] = [];
  validateMandatoryField(problems, "Country", driver.data.country);
  validateMandatoryField(problems, "Nickname", driver.data.nickname);
  validateMandatoryField(problems, "Account ID", driver.data.ubisoftUids);
  validateUniqueValue(
    problems,
    "Nickname",
    driver,
    otherDrivers,
    (model: FsDriver) => model.nickname
  );
  validateUniqueValues(
    problems,
    "Account ID",
    driver,
    otherDrivers,
    (model: FsDriver) => model.ubisoftUids,
    ValidationType.Error
  );
  validateDriverTeamHistory(problems, "Team History", driver, teams);
  return problems;
};

export const validateDriverTeamHistory = (
  problems: ValidationProblem[],
  fieldName: string,
  driver: Driver,
  teams: Team[]
) => {
  driver.data.teamHistory?.forEach((t) => {
    const teamRelevant = teams.find((team) => team.id === t);
    if (!teamRelevant) {
      problems.push({
        fieldName: fieldName,
        message: `Team DNE ${t}`,
        type: ValidationType.Warning,
      });
    } else if (!teamContainsDriver(teamRelevant.data, driver.id)) {
      problems.push({
        fieldName: fieldName,
        message: `Missing from Team ${t}`,
        type: ValidationType.Warning,
      });
    }
  });
  const teamsRelevant = teams.filter((team) =>
    teamContainsDriver(team.data, driver.id)
  );
  teamsRelevant.forEach((team) => {
    if (!driver.data.teamHistory?.includes(team.id)) {
      problems.push({
        fieldName: fieldName,
        message: `Missing from Driver ${team.id}`,
        type: ValidationType.Warning,
      });
    }
  });
  return problems;
};
