import { Panel } from "components/Panel/Panel";
import { EmptyGrowPanelText } from "styles/text";

interface Props {}

export const TournamentOverviewTab = ({}: Props) => {
  return (
    <Panel>
      <EmptyGrowPanelText>Tournament Overview</EmptyGrowPanelText>
    </Panel>
  );
};
