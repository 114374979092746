import { Panel } from "components/Panel/Panel";
import { PageContent } from "pages/styles";
import { useEffect } from "react";
import { MaxWidth } from "styles/layout";
import {
  PrivacyAndTermsContainer,
  PrivacyAndTermsHeader,
  PrivacyAndTermsSpan,
} from "./styles";

export const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContent>
      <MaxWidth>
        <Panel>
          <PrivacyAndTermsContainer>
            <h2>Privacy Policy</h2>
            <p>
              <PrivacyAndTermsSpan>
                This Privacy Policy describes how your personal information is
                collected, used, and shared when you visit or make a purchase
                from http://www.ecircuitmania.com (the “Site”).
              </PrivacyAndTermsSpan>

              <PrivacyAndTermsHeader>
                Personal information we collect
              </PrivacyAndTermsHeader>
              <PrivacyAndTermsSpan>
                When you visit the Site, we automatically collect certain
                information about your device, including information about your
                web browser, IP address, time zone, and some of the cookies that
                are installed on your device. Additionally, as you browse the
                Site, we collect information about the individual web pages or
                products that you view, what websites or search terms referred
                you to the Site, and information about how you interact with the
                Site. We refer to this automatically-collected information as
                “Device Information”.
              </PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                We collect Device Information using the following technologies:
                <ul>
                  <li>
                    “Cookies” are data files that are placed on your device or
                    computer and often include an anonymous unique identifier.
                    For more information about cookies, and how to disable
                    cookies, visit http://www.allaboutcookies.org.
                  </li>
                  <li>
                    “Log files” track actions occurring on the Site, and collect
                    data including your IP address, browser type, Internet
                    service provider, referring/exit pages, and date/time
                    stamps.
                  </li>
                  <li>
                    “Web beacons”, “tags”, and “pixels” are electronic files
                    used to record information about how you browse the Site.
                  </li>
                </ul>
              </PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                When we talk about “Personal Information” in this Privacy
                Policy, we are talking about Device Information as well.
              </PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                Your data is stored through Firebase’s data storage, databases
                and the general Firebase application. They store your data on a
                secure server behind a firewall.
              </PrivacyAndTermsSpan>

              <PrivacyAndTermsHeader>
                How do we use your personal information?
              </PrivacyAndTermsHeader>
              <PrivacyAndTermsSpan>
                We use this Personal Information to:
                <ul>
                  <li> Communicate with you; and</li>
                  <li>
                    When in line with the preferences you have shared with us,
                    provide you with information or advertising relating to our
                    products or services.
                  </li>
                </ul>
              </PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                We use the Device Information that we collect to help us screen
                for potential risk and fraud (in particular, your IP address),
                and more generally to improve and optimize our Site (for
                example, by generating analytics about how our customers browse
                and interact with the Site, and to assess the success of our
                marketing and advertising campaigns).
              </PrivacyAndTermsSpan>

              <PrivacyAndTermsHeader>
                Sharing your personal Information
              </PrivacyAndTermsHeader>
              <PrivacyAndTermsSpan>
                We share your Personal Information with third parties to help us
                use your Personal Information, as described above. For example,
                we use Firebase to handle our database--you can read more about
                how Firebase uses your Personal Information here:
                https://policies.google.com/privacy. We also use Google
                Analytics to help us understand how our users use the Site --
                you can read more about how Google uses your Personal
                Information here:
                https://www.google.com/intl/en/policies/privacy/. You can also
                opt-out of Google Analytics here:
                https://tools.google.com/dlpage/gaoptout.
              </PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                Finally, we may also share your Personal Information to comply
                with applicable laws and regulations, to respond to a subpoena,
                search warrant or other lawful request for information we
                receive, or to otherwise protect our rights.
              </PrivacyAndTermsSpan>

              <PrivacyAndTermsHeader>
                Behavioral advertising
              </PrivacyAndTermsHeader>
              <PrivacyAndTermsSpan>
                As described above, we use your Personal Information to provide
                you with targeted advertisements or marketing communications we
                believe may be of interest to you. For more information about
                how targeted advertising works, you can visit the Network
                Advertising Initiative’s (“NAI”) educational page at
                http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
              </PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                You can opt out of targeted advertising by using the links
                below:
                <ul>
                  <li>
                    {" "}
                    Facebook: https://www.facebook.com/settings/?tab=ads{" "}
                  </li>
                  <li>
                    {" "}
                    Google: https://www.google.com/settings/ads/anonymous{" "}
                  </li>
                  <li>
                    Bing:
                    https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                  </li>
                </ul>
              </PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                Additionally, you can opt out of some of these services by
                visiting the Digital Advertising Alliance’s opt-out portal at:
                http://optout.aboutads.info/.
              </PrivacyAndTermsSpan>

              <PrivacyAndTermsHeader>Do not track</PrivacyAndTermsHeader>
              <PrivacyAndTermsSpan>
                Please note that we do not alter our Site’s data collection and
                use practices when we see a Do Not Track signal from your
                browser.
              </PrivacyAndTermsSpan>

              <PrivacyAndTermsHeader>Your rights</PrivacyAndTermsHeader>
              <PrivacyAndTermsSpan>
                If you are a European resident, you have the right to access
                personal information we hold about you and to ask that your
                personal information be corrected, updated, or deleted. If you
                would like to exercise this right, please contact us through the
                contact information below.
              </PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                Additionally, if you are a European resident we note that we are
                processing your information we might have with you to pursue our
                legitimate business interests listed above. Additionally, please
                note that your information will be transferred outside of
                Europe, including to Canada and the United States.
              </PrivacyAndTermsSpan>

              <PrivacyAndTermsHeader>Changes</PrivacyAndTermsHeader>
              <PrivacyAndTermsSpan>
                We may update this privacy policy from time to time in order to
                reflect, for example, changes to our practices or for other
                operational, legal or regulatory reasons.
              </PrivacyAndTermsSpan>

              <PrivacyAndTermsHeader>Minors</PrivacyAndTermsHeader>
              <PrivacyAndTermsSpan>
                If you are under 13 years of age, then you may not use or access
                the Site and its Services at any time or in any manner.
              </PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                Protecting the privacy of young children is especially
                important. For that reason, ECIRCUITMANIA does not knowingly
                collect or maintain personal information (as defined by the
                United States Children’s Online Privacy Protection Act) from
                persons under 13 years-of-age. If ECIRCUITMANIA learns that
                personal information of persons under 13 has been collected on
                or through the ECIRCUITMANIA Services, ECIRCUITMANIA will take
                appropriate steps to delete this information.
              </PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                If you are the parent or legal guardian of a child under 13 who
                has created an account on the ECIRCUITMANIA Services, then
                please Contact Support to have that child’s account terminated
                and personal information deleted.
              </PrivacyAndTermsSpan>
              <PrivacyAndTermsSpan>
                For residents of countries and regions where the processing of
                personal information is based on consent, ECIRCUITMANIA will not
                knowingly engage in that processing for users under the age of
                consent established by applicable data protection law. If we
                learn that we are engaged in that processing with such users, we
                will halt such processing and will take reasonable measures to
                promptly remove applicable information from our records.
              </PrivacyAndTermsSpan>

              <PrivacyAndTermsHeader>Contact us</PrivacyAndTermsHeader>
              <PrivacyAndTermsSpan>
                For more information about our privacy practices, if you have
                questions, or if you would like to make a complaint, please
                contact us by e mail at terms@ecircuitmania.com.
              </PrivacyAndTermsSpan>
            </p>
          </PrivacyAndTermsContainer>
        </Panel>
      </MaxWidth>
    </PageContent>
  );
};
