import { nonUndefined } from "Utility";
import { FsBracket } from "db/model/tournament/Segment/Bracket/FsBracket";
import { FsBracketMatch } from "db/model/tournament/Segment/Bracket/FsBracketMatch";

export type BracketNodeBase = {
  depth: number;
  isLower: boolean;
  match: FsBracketMatch;
  matchTitle?: string;
};

export type BracketType =
  | "SingleElim"
  | "DoubleElim"
  | "SingleElimShuffle"
  | "DoubleElimShuffle"
  | "Multilevel";

export const bracketTypes: string[] = [
  "SingleElim",
  "DoubleElim",
  "SingleElimShuffle",
  "DoubleElimShuffle",
  "Multilevel",
];

export const isBracketType = (
  value: string | undefined
): value is BracketType => {
  if (!value) {
    return false;
  }

  return bracketTypes.includes(value);
};

export const getBracketMatchIds = (bracket: FsBracket) => {
  return nonUndefined(bracket.matches?.flatMap((m) => m.matchId) ?? []);
};
