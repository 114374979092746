import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { FsCupModePoints } from "db/model/match/Set/CupMode/FsCupModePoints";
import { FsMatchTrack } from "db/model/match/Track/FsMatchTrack";
import { Scores } from "fsModel/Match/Scores";
import { applyReverseCupModeRoundScore } from "./Round";

export const applyReverseCupModeTrackScore = (
  track: FsMatchTrack,
  teams: FsMatchTeam[],
  runningScores: Scores,
  points: FsCupModePoints,
  startingPoints: number
) => {
  if (!track.rounds) {
    return;
  }

  for (let i = 0; i < track.rounds.length; i++) {
    const round = track.rounds[i];
    if (!round.times) {
      continue;
    }

    applyReverseCupModeRoundScore(
      round.times,
      teams,
      runningScores,
      points,
      startingPoints
    );
  }
};
