import styled from "styled-components";
import { Spinner } from "styles/components";
import { CenterFlexColumn } from "styles/layout";

export const Container = styled(CenterFlexColumn)`
  row-gap: 10px;
`;

export const UsernameInput = styled.input`
  font-size: 16pt;
  text-align: center;
  width: 220px;
  &:focus::placeholder {
    color: transparent;
  }
`;

export const CheckSpinner = styled(Spinner)`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  width: 15px;
  height: 15px;
  margin: auto;
`;

export const CheckPickButton = styled.button`
  width: 200px;
  height: 30px;
  padding: 2px;
  font-size: 14pt;
`;
