import { FsTimeAttackSet } from "db/model/match/Set/TimeAttack/FsTimeAttackSet";
import { newFsMatchSetBase } from "../FsMatchSet";
import { newFsTimeAttackFormat } from "./FsTimeAttackFormat";

export const newFsTimeAttackSet = (): FsTimeAttackSet => {
  return {
    ...newFsMatchSetBase(),
    format: newFsTimeAttackFormat(),
    tracks: [],
    type: "TimeAttack",
  };
};
