import { hoursToMilliseconds } from "date-fns";
import { FsMatch } from "db/model/match/FsMatch";
import { getTimeMs } from "TimeHelper";
import { getMatchSetScores, matchSetIsComplete } from "./Set/MatchSet";
import {
  getNumTeams,
  getTeams,
  matchCompleteByByesOrForfeits,
  matchHasData,
} from "./Match";

const MATCH_UNKNOWN_THRESHOLD = hoursToMilliseconds(4);
export type MatchStatus =
  | "Completed"
  | "Delayed"
  | "Live"
  | "Unknown"
  | "Upcoming";

export const getMatchStatus = (match: FsMatch): MatchStatus => {
  if (match.isDelayed) {
    return "Delayed";
  }

  if (isMatchComplete(match)) {
    return "Completed";
  }

  if (!match.startTimeMs || !considerMatchLiveOrUpcoming(match.startTimeMs)) {
    return "Unknown";
  }

  return hasMatchStarted(match) ? "Live" : "Upcoming";
};

export const hasMatchStarted = (match: FsMatch) => {
  const currentTimeMs = getTimeMs();
  if (!!match.startTimeMs && match.startTimeMs <= currentTimeMs) {
    return true;
  }

  return matchHasData(match);
};

export const isMatchComplete = (match: FsMatch) => {
  if (match.overrideComplete) {
    return true;
  }

  if (matchCompleteByByesOrForfeits(match)) {
    return true;
  }

  if (!match.sets?.length) {
    return false;
  }

  const teams = getTeams(match);
  const numTeams = getNumTeams(match);
  if (teams.length < numTeams) {
    return false;
  }

  for (let i = 0; i < match.sets.length; i++) {
    const set = match.sets[i];
    if (set.overrideComplete) {
      continue;
    }

    const scores = getMatchSetScores(set, teams, numTeams);
    if (!matchSetIsComplete(set, scores, numTeams)) {
      return false;
    }
  }

  return true;
};

export const considerMatchLiveOrUpcoming = (startTimeMs: number) => {
  return startTimeMs + MATCH_UNKNOWN_THRESHOLD > getTimeMs();
};
