import { ValidationProblem } from "model/validators/base";
import {
  ctorDataGridColumnBase,
  DataGridColumn,
  DataGridColumnBase,
  DataGridColumnBaseCtorArgs,
} from "../DataGridColumn";

export type ValidationColumn<T> = DataGridColumnBase & {
  validationGetter: (arg: T) => ValidationProblem[];
};

interface CtorArgs<T> extends DataGridColumnBaseCtorArgs {
  validationGetter: (arg: T) => ValidationProblem[];
}

export function ctorValidationColumn<T>(
  args: CtorArgs<T>
): ValidationColumn<T> {
  return {
    ...ctorDataGridColumnBase(args),
    validationGetter: args.validationGetter,
  };
}

export function isValidationColumn<T>(
  column: DataGridColumn<T>
): column is ValidationColumn<T> {
  return (column as ValidationColumn<T>).validationGetter !== undefined;
}
