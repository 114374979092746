import { CenterFlexColumn } from "styles/layout";
import {
  FieldContainer,
  HeaderContainer,
  NameFields,
  TabsContainer,
} from "./styles";
import { TeTournament } from "model/TournamentEditor/TeTournament";
import { selectIsEcmStaff } from "selectors/User";
import { useSelector } from "react-redux";
import { TournamentEditorTextArea } from "../styles";
import { TabBarOption, TabBarV2 } from "components/TabBarV2/TabBarV2";
import { StyledInput } from "components/Fields/styles";

interface Props {
  tournament: TeTournament;
  tabOptions: TabBarOption[];
  selectedTab: any;
  setSelectedTab: React.Dispatch<React.SetStateAction<any>>;
  update: () => void;
}

export const Header = ({
  tournament,
  tabOptions,
  selectedTab,
  setSelectedTab,
  update,
}: Props) => {
  const isEcmStaff = useSelector(selectIsEcmStaff);
  return (
    <HeaderContainer>
      <FieldContainer>
        <NameFields>
          {isEcmStaff && (
            <>
              <StyledInput
                value={tournament.id}
                onChange={(e) => {
                  tournament.id = e.target.value;
                  update();
                }}
                placeholder="Id"
              />
              <StyledInput
                value={tournament.filterName}
                onChange={(e) => {
                  tournament.filterName = e.target.value;
                  update();
                }}
                placeholder="Filter"
              />
            </>
          )}
          <StyledInput
            value={tournament.name}
            onChange={(e) => {
              tournament.name = e.target.value;
              update();
            }}
            placeholder="Name"
          />
          <StyledInput
            value={tournament.trigram}
            onChange={(e) => {
              tournament.trigram = e.target.value;
              update();
            }}
            placeholder="Trigram"
          />
        </NameFields>
        <CenterFlexColumn style={{ width: "100%" }}>
          <TournamentEditorTextArea
            placeholder="Tournament Description"
            style={{ resize: "none", width: "100%" }}
            rows={5}
            value={tournament.description}
            onChange={(e) => {
              tournament.description = e.target.value;
              update();
            }}
          />
        </CenterFlexColumn>
      </FieldContainer>
      <TabsContainer>
        <TabBarV2
          options={tabOptions}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </TabsContainer>
    </HeaderContainer>
  );
};
