import { nonUndefined } from "Utility";
import { TeDriver, mapTeDriverToFsData } from "./TeDriver";
import { TeOrg, mapTeOrgToFsData } from "./TeOrg";
import { Driver, Org, Team } from "model/IdData";
import { FsTeam } from "db/model/team/FsTeam";
import { FsTeamDriver } from "db/model/team/FsTeamDriver";

export type TeTeam = {
  existingTeamId: string | undefined;
  org: string | TeOrg | undefined;
  drivers: (string | TeDriver | undefined)[];
};

export const newTeTeam = (orgId?: string): TeTeam => {
  return {
    existingTeamId: undefined,
    org: orgId,
    drivers: [undefined],
  };
};

// todo - should all these mapTeToFs funcs return data as the IdData<T> version?
// they could pass in existing ids and do the getNextId function within here too?
export const mapTeTeam = (
  teTeam: TeTeam,
  teamId: string,
  newOrgId: number,
  newDriverId: number,
  existingOrgs: Org[],
  existingDrivers: Driver[]
): { team: Team; newDrivers: Driver[]; newOrg: Org | undefined } => {
  var orgId: string | undefined;
  var orgName: string | undefined;
  var orgTrigram: string | undefined;
  var newOrg: Org | undefined;
  const newDrivers: Driver[] = [];

  if (typeof teTeam.org === "string") {
    const existingOrg = existingOrgs.find((o) => o.id === teTeam.org);
    orgId = teTeam.org;
    orgName = existingOrg?.data.details?.at(0)?.name;
    orgTrigram = existingOrg?.data.details?.at(0)?.trigram;
  } else if (!!teTeam.org) {
    orgId = newOrgId.toString();
    orgName = teTeam.org.name;
    orgTrigram = teTeam.org.trigram;
    newOrg = {
      id: newOrgId.toString(),
      data: mapTeOrgToFsData(teTeam.org, teamId),
    };
  }

  let fsTeam: FsTeam;
  const teDrivers = nonUndefined(teTeam.drivers);
  if (teDrivers.length === 1) {
    const singleDriver = teDrivers[0];
    let driverId: string | undefined;
    let driverNickname: string | undefined;
    if (typeof singleDriver === "string") {
      driverId = singleDriver;
      driverNickname = existingDrivers.find((d) => d.id === singleDriver)?.data
        .nickname;
    } else {
      driverId = newDriverId.toString();
      driverNickname = singleDriver.nickname;
      newDrivers.push({
        id: driverId,
        data: mapTeDriverToFsData(singleDriver, teamId),
      });
    }

    fsTeam = {
      orgId,
      orgName,
      orgTrigram,
      orgHistory: undefined,
      driverId,
      driverNickname,
    };
  } else {
    var nextDriverId = newDriverId;
    const teamDrivers: FsTeamDriver[] = [];
    teDrivers.forEach((multiDriver) => {
      if (typeof multiDriver === "string") {
        teamDrivers.push({
          id: multiDriver,
          nickname: existingDrivers.find((d) => d.id === multiDriver)?.data
            .nickname,
        });
      } else {
        teamDrivers.push({
          id: nextDriverId.toString(),
          nickname: multiDriver.nickname,
        });
        newDrivers.push({
          id: nextDriverId.toString(),
          data: mapTeDriverToFsData(multiDriver, teamId),
        });
        nextDriverId++;
      }
    });

    fsTeam = {
      orgId,
      orgName,
      orgTrigram,
      orgHistory: undefined,
      drivers: teamDrivers,
    };
  }

  return {
    team: { id: teamId, data: fsTeam },
    newDrivers,
    newOrg,
  };
};
